/**
 * Copyright 2017 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import React, {PropTypes} from 'react';
import cx from 'classnames';
import actionCreators from '../../actions/actionCreators';
import {Button, ButtonDropdown, Icon, AlertDialog, ObjectSelector, Badge, Notification, RouteLink, Tooltip} from '..';
import {State} from 'react-router';
import intl from '@illumio-shared/utils/intl';
import StoreMixin from '../../mixins/StoreMixin';
import EndpointFilter from './EndpointFilter.jsx';
import TimeFilter, {getDefaultTimeOptions, getSAASTimeOptions, timeOptionExistsInOptions} from './TimeFilter.jsx';
import RestApiUtils from '../../utils/RestApiUtils';
import {ExplorerStore, TrafficStore, SessionStore, UserStore, OrgStore, SettingsOrgStore} from '../../stores';
import {ExplorerUtils} from '../../utils/Explorer';
import SaveFilterModal from '../../modals/SaveFilterModal.jsx';
import {GraphDataUtils, ServiceUtils} from '../../utils';
import {getId} from '../../utils/GeneralUtils';
import TrafficDBStorageMonitor from '../TrafficDBStorageMonitor/TrafficDBStorageMonitor';
import MapPageStore from '../../stores/MapPageStore';

const MAX_RECENTS = 10;

const RESULTS_SETTINGS = 'RESULTS_SETTINGS';

const SWAP_FILTER_OPTION = 'SWAP_FILTER_OPTION';

const TOGGLE_WORKLOADS_IN_IPLISTS = 'TOGGLE_WORKLOADS_IN_IPLISTS';

// list of allowed types for providers
const providerAllowedTypesSet = new Set([
  'role',
  'app',
  'env',
  'loc',
  'appgroups',
  'workloads',
  'containerWorkloads',
  'ipaddress',
  'cidrBlock',
  'fqdn',
  'transmission',
  'iplist',
  'label_group',
]);

// list of allowed types for consumers
const consumerAllowedTypesSet = new Set([
  'role',
  'app',
  'env',
  'loc',
  'appgroups',
  'workloads',
  'containerWorkloads',
  'ipaddress',
  'cidrBlock',
  'iplist',
  'label_group',
]);

const actionOptions = () => ({
  [intl('Common.Allowed')]: 'allowed',
  [intl('Common.NoRules')]: 'potentially_blocked',
  [intl('IlluminationMap.UnenforcedDeny')]: 'potentially_blocked_by_boundary',
  [intl('Common.Denied')]: 'blocked',
  [intl('Common.BlockedByDenyRules')]: 'blocked_by_boundary',
  [intl('Common.Unknown')]: 'unknown',
});

function migrateActionFilter(action) {
  if (typeof action === 'object') {
    return action;
  }

  switch (action) {
    case intl('Common.All'):
      return {};
    case intl('Common.Allowed'):
      return {
        [intl('Common.Allowed')]: ['allowed'],
      };
    case intl('Explorer.NoRule'):
      return {
        [intl('Explorer.NoRule')]: ['potentially_blocked'],
      };
    case intl('Common.Denied'):
      return {
        [intl('Common.Denied')]: ['blocked'],
      };
    case intl('IlluminationMap.UnenforcedDeny'):
      return {
        [intl('IlluminationMap.UnenforcedDeny')]: ['potentially_blocked_by_boundary'],
      };
    case intl('Common.BlockedByDenyRules'):
      return {
        [intl('Common.BlockedByDenyRules')]: ['blocked_by_boundary'],
      };
    case intl('Common.AllBlocked'):
      return {
        [intl('Explorer.NoRule')]: ['potentially_blocked'],
        [intl('Common.Denied')]: ['blocked'],
      };
    default:
      return {};
  }
}

function trimFilter(filter) {
  if (typeof filter !== 'string') {
    return;
  }

  // Add one space afte the , or : for readablity, then strip any double spaces
  return filter
    .replaceAll(',', ', ')
    .replaceAll(':', ': ')
    .replaceAll(/ +(?= )/g, '')
    .trim();
}

const recentCategory = {
  label: <div className="Explorer-Filter-Category">{intl('Explorer.Recent')}</div>,
  value: 'recent',
  category: true,
  notRemovable: true,
};

const favoriteCategory = {
  label: <div className="Explorer-Filter-Category">{intl('Explorer.Saved')}</div>,
  value: 'favorite',
  category: true,
  notRemovable: true,
};

/**
 * Add Tooltip and styling to the filter labels
 */
function getFormattedFilters(filters, notRemovable) {
  return (filters || []).map(filter => ({
    ...filter,
    value: filter.label,
    label: (
      <Tooltip location="fixed" content={filter.label} width={300}>
        <div className="Explorer-Filter-Value">{filter.label}</div>
      </Tooltip>
    ),
    notRemovable,
  }));
}

/**
 * Used to combine favorites and recents into the saved filters list with categories and styling
 */
function getAllSavedFilters(favorites, recents) {
  if (!(favorites || []).length && !Object.keys(recents || {}).length) {
    return [{label: intl('Common.NoSavedFilters', {className: 'Explorer-favorite-padding'}, {html: true})}];
  }

  // Sort and take the latest 10 unique recents
  const mostRecent = ExplorerUtils.getSortedRecents(recents);
  const uniqueRecents = ExplorerUtils.getUniqueRecents(mostRecent, MAX_RECENTS);

  const formattedRecents = getFormattedFilters(uniqueRecents, true);
  const formattedFavorites = getFormattedFilters(favorites, false);

  return [recentCategory, ...formattedRecents, favoriteCategory, ...formattedFavorites];
}

function getStateFromStores() {
  const filters = JSON.parse(localStorage.getItem('tx_filters')) || _.cloneDeep(ExplorerStore.getFilters());

  // Convert old time format
  if (filters.time === intl('Explorer.LastDay')) {
    filters.time = intl('Explorer.LastHours', {count: 24});
  }

  const includeWorkloads = _.isEmpty(filters) ? localStorage.getItem('includeWorkloads') : filters.includeWorkloads;

  if (filters) {
    filters.action = migrateActionFilter(filters.action);
  }

  const favorites = ExplorerStore.getFavorites();
  const recents = ExplorerStore.getRecents();
  const savedFilters = getAllSavedFilters(favorites, recents);

  let favorite;

  if (this.state) {
    favorite = this.state && this.state.favorite;

    // One time after loading the favorites, if the selected favorite is no longer in the list remove it
    if (
      !this.state.favoritesLoaded &&
      favorites &&
      favorite &&
      !favorites.some(item => trimFilter(item.label) === trimFilter(favorite.label))
    ) {
      localStorage.removeItem('chosen_favorite');
      favorite = null;
    }
  } else {
    // The first time through get the favorite from local storage
    favorite = JSON.parse(localStorage.getItem('chosen_favorite'));

    if (favorite) {
      // Clean the label
      favorite.label = trimFilter(favorite.label);
    }
  }

  const isSAAS = SessionStore.isPCESaaS();

  // EYE-76997 If it's SAAS, we are limiting the query to a org set time span
  const limitToMaxDays = isSAAS ? SettingsOrgStore.getExplorerMaxQueryTimespan() : null;

  // EYE-76997 If it's SAAS, we are limiting the time ranges to Last hour, day, week and Custom Range
  const timeOptions = () => (isSAAS ? getSAASTimeOptions(limitToMaxDays) : getDefaultTimeOptions());

  return {
    filters,
    Or: filters.Or,
    includeWorkloads,
    favorite,
    favorites,
    savedFilters,
    favoritesLoaded: Boolean(favorites),
    recents,
    user: SessionStore.getUserId(),
    queries: ExplorerStore.getQueries(),
    asyncEnabled: SessionStore.isAsyncExplorerEnabled(),
    maxDownloadResults: UserStore.getExplorerMaxDownloadResults(),
    showBoundaries: MapPageStore.getTotalEnforcementBoundaries() > 0,
    timeOptions,
    isSAAS,
    limitToMaxDays,
  };
}

const filterIsTransmissionOrFQDN = entry => entry.key === 'transmission' || entry.key === 'fqdn';

const FilterBar = React.createClass({
  mixins: [State, StoreMixin([ExplorerStore, SettingsOrgStore], getStateFromStores)],
  queryListPollingId: false,
  queryPollingId: false,

  getInitialState() {
    return {
      ports: {},
      showFilters: true,
    };
  },

  async componentDidMount() {
    const isAndOr = this.state.Or ? 'or' : 'and';

    actionCreators.setAndOrFlag(isAndOr);
    ExplorerStore.addHoverMenuActionListener(this.handleHoverSelection);
  },

  async componentWillMount() {
    const filters = {...this.state.filters};
    let waitForAppGroups = Object.keys(filters).find(
      filter =>
        Array.isArray(filters[filter]) &&
        filters[filter].length &&
        filters[filter].find(filterItem => filterItem.key === 'appgroups'),
    );

    if (TrafficStore.isTrafficRequested('appGroups')) {
      waitForAppGroups = false;
    } else {
      this.setState({waitForAppGroups: true});

      await GraphDataUtils.getMapLevelByTotalWorkloads();

      if (SessionStore.canUserViewEnforcementBoundaries()) {
        await RestApiUtils.enforcementBoundaries.getCollection('draft', true, {max_results: 1});
      }

      const result = await GraphDataUtils.getTraffic('appGroup', {route: 'explorer'});

      Object.keys(filters).forEach(filter => {
        if (Array.isArray(filters[filter]) && filters[filter].length) {
          filters[filter] = filters[filter].filter(
            filterItem =>
              filterItem.key !== 'appgroups' ||
              _.get(result, 'body.nodes', null).find(node => node.href === filterItem.href),
          );
        }
      });

      waitForAppGroups = false;
    }

    // Get the Recents first, so we don't overwrite them when making the auto fetch
    await Promise.all([
      RestApiUtils.kvPairs.getInstance(SessionStore.getUserId(), 'explorer_favorites'),
      RestApiUtils.kvPairs.getInstance(SessionStore.getUserId(), 'explorer_recents'),
    ]);

    // If uuid of the last search is saved, reload the data
    if (filters.uuid && ExplorerStore.getLoadedUuid() !== filters.uuid) {
      try {
        await RestApiUtils.trafficFlows.async.getResults(filters.uuid);
      } catch {
        // async queries have a max life of 24 hours. If the query runs later than this, it should fail silently.
      }
    }

    // Request the org settings
    await RestApiUtils.orgSettings.get();

    this.setState(
      {
        filters,
        waitForAppGroups,
        recents: ExplorerStore.getRecents(),
      },
      () => {
        if (this.state.filters && this.getQuery().go) {
          this.handleSetFilters();
        }

        if (sessionStorage.getItem('explorer_redirect') === 'true') {
          sessionStorage.setItem('explorer_redirect', 'false');
          this.handleSetFilters();
        }
      },
    );
  },

  componentWillUnmount() {
    ExplorerStore.removeHoverMenuActionListener(this.handleHoverSelection);
  },

  componentWillReceiveProps(nextProps) {
    if (!this.props.reload && nextProps.reload) {
      setTimeout(() => this.handleSetFilters(), 1000);
    }
  },

  setExclusionFilters(excludedIpRange, excludedService) {
    let consumerExclude = [];
    let portsExclude = [];

    if (excludedIpRange) {
      consumerExclude = [
        {
          href: excludedIpRange.href,
          name: excludedIpRange.name,
          key: 'iplist',
          value: excludedIpRange.ip_ranges,
        },
      ];
    }

    if (excludedService) {
      portsExclude = [
        {
          href: excludedService.href,
          name: excludedService.name,
          key: 'policyService',
          value: excludedService.service_ports || excludedService.windows_services,
        },
      ];
    }

    return {consumerExclude, portsExclude};
  },

  handleHoverSelection() {
    const filterSelection = ExplorerStore.getExplorerHoverSelection();

    this.handleAddItem(filterSelection.filterKey, filterSelection.filter);
  },

  sortItems(filters, type) {
    let allWorkloads = false;

    // Sort the filters into various types
    const types = {
      allWorkloads: [],
      labels: {
        role: [],
        app: [],
        env: [],
        loc: [],
      },
      ipaddress: [],
      cidrBlock: [],
      workloads: [],
      appgroups: [],
      fqdn: [],
      transmission: [],
      iplist: [],
      label_group: [],
    };

    filters.forEach(filter => {
      if (
        types[filter.key] ||
        types.labels[filter.key] ||
        filter.key === 'iplist' ||
        filter.key === 'containerWorkloads' ||
        filter.allWorkloads
      ) {
        if (filter.allWorkloads) {
          allWorkloads = true;
          types.allWorkloads.push({actors: 'ams'});
        } else if (filter.key === 'transmission' && type.includes('provider')) {
          types[filter.key].push({transmission: filter.href});
        } else if (filter.key === 'workloads' || filter.key === 'containerWorkloads') {
          types.workloads.push({workload: {href: filter.href}});
        } else if (filter.key === 'fqdn' && type.includes('provider')) {
          types[filter.key].push({fqdn: filter.href});
        } else if (filter.key === 'ipaddress' || filter.key === 'cidrBlock') {
          types[filter.key].push({ip_address: filter.href});
        } else if (filter.href && filter.href.includes('label_group')) {
          types.labels[filter.key].push({label_group: {href: filter.href}});
        } else if (filter.key === 'iplist') {
          // iplist now has href so it behaves just like labels/workload etc.
          types[filter.key].push({ip_list: {href: filter.href}});
        } else if (
          filter.key === 'appgroups' &&
          (TrafficStore.getAppGroupNode(filter.href) || TrafficStore.getCluster(filter.href))
        ) {
          types[filter.key].push(
            (TrafficStore.getAppGroupNode(filter.href) || TrafficStore.getCluster(filter.href)).labels.map(label => ({
              label: {href: label.href},
            })),
          );
        } else if (filter.key !== 'transmission' && filter.key !== 'fqdn' && filter.key !== 'containerWorkloads') {
          if (types.labels[filter.key]) {
            types.labels[filter.key].push({label: {href: filter.href}});
          } else {
            types[filter.key].push({label: {href: filter.href}});
          }
        }
      }
    });

    if (allWorkloads) {
      // Remove extraneous workload fitlers
      types.labels = {role: [], app: [], env: [], loc: []};
      types.appgroups = [];
      types.workloads = [];
    }

    return types;
  },

  createDefaultName(filters) {
    return filters
      ? trimFilter(
          Object.keys(filters).reduce((result, type) => {
            const filter = filters[type];

            if (type === 'includeWorkloads' && filter) {
              result = `${result} ${intl('Explorer.IncludeWorkloads')}`;
            }

            // exclude dateFrom and dateTo from constructing the name since time will be used
            // exclude uuid that we save with the filters and it is used for showing data on load
            if (!_.isEmpty(filter) && type !== 'dateFrom' && type !== 'dateTo' && type !== 'uuid') {
              result = `${result} ${this.getFriendlyFilterName(type)}: `;

              if (result.toLowerCase().includes('services') && type.includes('port')) {
                result = `${result} ${this.getPortProtocolFriendlyName(filter)} `;
              } else if (type === 'time') {
                result = `${result} ${filter} `;
              } else if (type === 'action') {
                result = `${result} ${Object.keys(filter).join(', ')} `;
              } else {
                const resultString = filter.map(f => {
                  if (typeof f === 'string') {
                    return f;
                  }

                  if (f.value && typeof f.value === 'string') {
                    return f.value;
                  }

                  if (Object.keys(f).includes('allWorkloads')) {
                    return intl('Workloads.All');
                  }

                  return f.name || f.hostname || '';
                });

                result = `${result} ${resultString}`;
              }
            }

            return result;
          }, ''),
        )
      : intl('Explorer.EmptyFilter');
  },

  getFriendlyFilterName(type) {
    switch (type) {
      case 'consumerInclude':
        return intl('Common.Source');
      case 'providerInclude':
        return intl('Common.Destination');
      case 'consumerExclude':
        return intl('Explorer.ConsumerIsNot');
      case 'providerExclude':
        return intl('Explorer.ProviderIsNot');
      case 'portsInclude':
        return intl('Port.PortProtocolProcessService');
      case 'portsExclude':
        return intl('Port.PortProtocolProcessServiceIsNot');
      default:
        return _.startCase(type);
    }
  },

  getPortProtocolFriendlyName(filters) {
    const name = filters.reduce((result, filter) => {
      if (filter && filter.value && Array.isArray(filter.value)) {
        if (filter.key === 'policyService') {
          return `${result} ${filter.name}`;
        }

        return `${result}  ${filter.value
          .reduce((acc, value) => [...acc, Object.values(value).filter(value => value !== null)], [])
          .map(item => item.join(', '))}`;
      }

      if (filter && filter.value && typeof filter.value === 'string') {
        return `${result} ${filter.value}`;
      }

      if (filter && typeof filter === 'string') {
        return `${result} ${filter}`;
      }

      return result;
    }, '');

    return name.replaceAll(',', ', ').trim();
  },

  getPortProtocol(item) {
    if (item.value.includes(' ')) {
      const portAndProtocol = item.value.split(' ');

      return {
        port: Number(portAndProtocol[0]),
        proto: ServiceUtils.reverseLookupProtocol(portAndProtocol[1]),
      };
    }

    if (!isNaN(Number(item.value))) {
      return {port: Number(item.value)};
    }

    return {proto: ServiceUtils.reverseLookupProtocol(item.value)};
  },

  getPortRange(item) {
    const portRange = item.value.split(' ');
    const portsInRange = portRange[0].split('-');

    if (item.value.includes(' ')) {
      return {
        port: Number(portsInRange[0]),
        to_port: Number(portsInRange[1]),
        proto: ServiceUtils.reverseLookupProtocol(portRange[1]),
      };
    }

    return {
      port: Number(portsInRange[0]),
      to_port: Number(portsInRange[1]),
    };
  },

  getPolicyService(item) {
    return item.value.map(service => {
      const result = {};

      if (service.hasOwnProperty('protocol') && service.protocol >= 0) {
        result.proto = service.protocol;
      } else if (service.hasOwnProperty('proto') && service.proto >= 0) {
        result.proto = service.proto;
      }

      if (service.hasOwnProperty('port') && service.port >= 0) {
        result.port = service.port;
      }

      if (service.hasOwnProperty('to_port')) {
        result.to_port = service.to_port;
      }

      if (service.hasOwnProperty('process_name') && service.process_name !== null) {
        result.process_name = service.process_name.split('\\').pop();
      }

      if (service.hasOwnProperty('service_name') && service.service_name !== null) {
        result.windows_service_name = service.service_name;
      }

      return result;
    });
  },

  formatPortFilterArray(items) {
    return items.reduce((services, item) => {
      if (item.key === 'portProtocol') {
        services.push(this.getPortProtocol(item));
      } else if (item.key === 'portRange') {
        services.push(this.getPortRange(item));
      } else if (item.key === 'policyService' && item && item.value && Array.isArray(item.value)) {
        return services.concat(this.getPolicyService(item));
      } else if (item.key === 'processName') {
        services.push({process_name: item.value.split('\\').pop()});
      } else if (item.key === 'windowsService') {
        services.push({windows_service_name: item.value});
      }

      return services;
    }, []);
  },

  formatandSortPortFilters(items) {
    const categories = {ports: [], processes: [], windowsServices: [], policyServices: []};

    items.forEach(item => {
      if (item.key === 'portProtocol') {
        categories.ports.push(this.getPortProtocol(item));
      } else if (item.key === 'portRange') {
        categories.ports.push(this.getPortRange(item));
      } else if (item.key === 'policyService' && item && item.value && Array.isArray(item.value)) {
        categories.policyServices = [...categories.policyServices, ...this.getPolicyService(item)];
      } else if (item.key === 'processName') {
        categories.processes.push({process_name: item.value.split('\\').pop()});
      } else if (item.key === 'windowsService') {
        categories.windowsServices.push({windows_service_name: item.value});
      }
    });

    return categories;
  },

  getProviderTransmissionExclude(providerInclude, providerExclude) {
    const includedTransmissionValues = [];
    const excludedTransmissionValues = [];
    const transmissionValues = ['unicast', 'broadcast', 'multicast'];

    (providerInclude || []).forEach(provider => {
      if (provider.key === 'transmission') {
        includedTransmissionValues.push(provider.href);
      }
    });

    (providerExclude || []).forEach(provider => {
      if (provider.key === 'transmission') {
        excludedTransmissionValues.push(provider.href);
      }
    });

    const intersection = _.difference(transmissionValues, includedTransmissionValues);

    // This check is necessary to ensure we don't push anything to exclude if user hasn't selected anything to exclude
    if (intersection.length < 3) {
      intersection.forEach(transmissionValue => {
        if (!excludedTransmissionValues.includes(transmissionValue)) {
          providerExclude.push({key: 'transmission', href: transmissionValue});
        }
      });
    }

    return providerExclude;
  },

  getProviderTransmissionInclude(providerInclude) {
    return providerInclude.filter(provider => provider && provider.key !== 'transmission');
  },

  async handleSetFilters(evt) {
    if (evt && evt.type === 'click') {
      this.props.onSetSpinner(true);

      setTimeout(() => {
        this.props.onSetSpinner(false);
      }, 200);
    }

    if (!this.props.enabled) {
      return;
    }

    const filters = this.addPartialPorts();

    this.setState({filters});

    const recentFilters = {...filters};
    const {Or, favorite, recents, user, asyncEnabled, maxDownloadResults} = this.state;

    // Cartesian products are only used for the included items.
    // Exclusions are all sent in one array and or'd on the backend
    filters.portsInclude =
      filters.portsInclude && filters.portsInclude.length
        ? ExplorerUtils.cartesianProductForServices(this.formatandSortPortFilters(filters.portsInclude))
        : [];

    filters.portsExclude =
      filters.portsExclude && filters.portsExclude.length ? this.formatPortFilterArray(filters.portsExclude) : [];

    // And arguments
    let consumerProviderArguments = {
      filter: {
        consumerInclude: [...filters.consumerInclude],
        providerInclude: [...filters.providerInclude],
        consumerExclude: [...filters.consumerExclude],
        providerExclude: [...filters.providerExclude],
      },
      variables: {consumerInclude: [], providerInclude: [], consumerExclude: [], providerExclude: []},
    };

    //Or arguments
    if (Or) {
      consumerProviderArguments = {
        filter: {
          consumerInclude: [...filters.consumerOrProviderInclude],
          providerInclude: [...filters.consumerOrProviderInclude],
          consumerExclude: [...filters.consumerOrProviderExclude],
          providerExclude: [...filters.consumerOrProviderExclude],
        },
        variables: {consumerInclude: [], providerInclude: [], consumerExclude: [], providerExclude: []},
      };
    }

    // First move the transmission values to the exclusion
    consumerProviderArguments.filter.providerExclude = this.getProviderTransmissionExclude(
      consumerProviderArguments.filter.providerInclude,
      consumerProviderArguments.filter.providerExclude,
    );
    consumerProviderArguments.filter.providerInclude = this.getProviderTransmissionInclude(
      consumerProviderArguments.filter.providerInclude,
    );

    // Loop through each type of Provider/Consumer combination
    Object.keys(consumerProviderArguments.filter).forEach(key => {
      if (key.includes('Include')) {
        //Assign each individual type of variable to its appropriate value
        consumerProviderArguments.variables[key] = ExplorerUtils.getNestedEndpointQuery(
          this.sortItems(consumerProviderArguments.filter[key], key),
        );
      } else {
        // For the exclude values just map the values without the cartesianProduct
        consumerProviderArguments.variables[key] = ExplorerUtils.getUnNestedEndpointQuery(
          this.sortItems(consumerProviderArguments.filter[key], key),
        );
      }
    });

    Object.keys(filters).forEach(filter => {
      if (filter.includes('consumer') && Array.isArray(filters[filter]) && filters[filter].length) {
        filters[filter].forEach(filterValue => {
          if (
            filterValue.value &&
            filterValue.key === 'iplist' &&
            (!filterValue.value ||
              (Array.isArray(filterValue.value) && filterValue.value.some(entry => 'fqdn' in entry && !this.state.Or)))
          ) {
            return actionCreators.openDialog(<AlertDialog message={intl('Explorer.FQDNNotSupported')} />);
          }
        });
      }
    });

    const {policyDecisions, boundaryDecisions} = ExplorerUtils.transformPolicyFilters(
      Object.values(filters.action).map(action => action[0]),
    );

    const data = {
      sources: {
        include: consumerProviderArguments.variables.consumerInclude,
        exclude: consumerProviderArguments.variables.consumerExclude.flat(), //Ensure that exclude is always a single array not double array [[{}]]
      },
      destinations: {
        include: consumerProviderArguments.variables.providerInclude,
        exclude: consumerProviderArguments.variables.providerExclude.flat(), //Ensure that exclude is always a single array not double array [[{}]]
      },
      services: {
        include: filters.portsInclude,
        exclude: filters.portsExclude,
      },
      sources_destinations_query_op: Or ? 'or' : 'and',
      start_date: filters.dateFrom
        ? new Date(filters.dateFrom).toISOString()
        : ExplorerUtils.getStartDate(filters.time).toISOString(),
      end_date: filters.dateTo
        ? new Date(filters.dateTo).toISOString()
        : ExplorerUtils.getEndDate(filters.time).toISOString(),
      policy_decisions: policyDecisions,
      boundary_decisions: boundaryDecisions,
      max_results: maxDownloadResults,
      exclude_workloads_from_ip_list_query: !filters.includeWorkloads,
    };

    if (asyncEnabled) {
      // If the current filters match the selected favorite, use the favorite name
      // Add a tag to the name, so we can show only the queries from the main explorer in the results list
      const name = `${ExplorerUtils.UI_EXPLORER_QUERY_PREFIX}${
        favorite && _.isEqual(favorite.filters, filters) ? favorite.label : this.createDefaultName(recentFilters)
      }`;

      data.query_name = name;

      const response = await RestApiUtils.trafficFlows.async.create(data);
      const queryHref = response?.body?.href;

      if (queryHref) {
        this.addRecent(recentFilters, getId(queryHref), recents, user);

        this.props.onStartNewQuery(queryHref);
      }
    } else {
      this.addRecent(recentFilters, this.createDefaultName(recentFilters), recents, user);

      await RestApiUtils.trafficFlows.get(data);
    }

    actionCreators.exceededWarningConfirm(false);
  },

  addPartialPorts() {
    const filters = {...this.state.filters};

    this.props.onFilterChange(filters);

    return filters;
  },

  addRecent(filters, queryId, recents, user) {
    // The PCE stores 24 hours of queries, to prevent missing any the available queries store 2 days worth of filters
    const twoDaysAgo = intl.utils.subtractTime(new Date(), 'd', 2);

    // If the recents are stored in an array, this is the old version, so start over
    const originalRecents = Array.isArray(recents) ? {} : recents;

    // Sort most recent to the top
    const recentValues = ExplorerUtils.getSortedRecents(originalRecents);

    const newRecents = recentValues.reduce((result, recent) => {
      // Keep the recent if it is within the last two days or the 100 most recent,
      // this will hopefully save enough to get 10 unique filters
      if (
        recent?.filters &&
        ((recent?.date && new Date(recent.date).getTime() > twoDaysAgo.getTime()) || Object.keys(result).length < 100)
      ) {
        result[recent.queryId] = {...recent};

        if (!recent.label) {
          result[recent.queryId].label = this.createDefaultName(recent.filters);
        }
      }

      return result;
    }, {});

    newRecents[queryId] = {filters, queryId, date: new Date(), label: this.createDefaultName(filters)};

    RestApiUtils.kvPair.update(user, 'explorer_recents', newRecents);
  },

  handleClearFilters() {
    let emptyFilters = _.cloneDeep(ExplorerStore.getEmptyFilters());

    emptyFilters = {...emptyFilters, Or: this.state.Or};
    this.props.onFilterChange(emptyFilters);
    localStorage.removeItem('chosen_favorite');
    this.setState({filters: emptyFilters, favorite: null});
  },

  handleSaveFilter(type) {
    if (type.label === intl('Common.SaveAs')) {
      this.handleSaveAsFilter();

      return;
    }

    // Handle the save case for edited filters
    const {favorite} = this.state;
    const filters = this.addPartialPorts();
    const newFavorite = {
      filters,
      label: trimFilter(favorite.label),
    };

    let favorites = [...this.state.favorites];

    // Clean all the labels
    favorites = favorites.map(favorite => ({
      ...favorite,
      label: trimFilter(favorite.label),
    }));

    // Move any the favorite to the top if it already exists
    favorites = favorites.filter(item => item.label && item.label !== favorite.label);

    // Add new Favorite to the top
    favorites.unshift(newFavorite);

    RestApiUtils.kvPair.update(this.state.user, 'explorer_favorites', favorites);
    localStorage.setItem('chosen_favorite', JSON.stringify(newFavorite));
    this.setState({favorite: newFavorite});
  },

  handleSaveAsFilter() {
    const filters = this.addPartialPorts();

    let name = this.createDefaultName(filters);

    if (this.state.favorite) {
      name = this.state.favorite.label;
    }

    actionCreators.openDialog(<SaveFilterModal name={name} onSave={_.partial(this.handleSaveFilterCommit, filters)} />);
  },

  handleSaveFilterCommit(filters, label) {
    if (!label) {
      return;
    }

    let favorites = [...this.state.favorites];

    // Clean all the labels
    label = trimFilter(label);
    favorites = favorites.map(favorite => ({
      ...favorite,
      label: trimFilter(favorite.label),
    }));

    const newFavorite = {filters, label: trimFilter(label)};

    favorites = favorites.filter(favorite => favorite.label && favorite.label !== label);

    // Add new filter to the top
    favorites.unshift(newFavorite);

    RestApiUtils.kvPair.update(this.state.user, 'explorer_favorites', favorites);
    localStorage.setItem('chosen_favorite', JSON.stringify(newFavorite));
    this.setState({favorite: newFavorite});
  },

  handleSelectFavoriteFilter(option) {
    const newOption = this.handleSelectSavedFilter(option);

    if (newOption) {
      localStorage.setItem('chosen_favorite', JSON.stringify(newOption));
      this.setState({favorite: newOption});
    }
  },

  handleSelectSavedFilter(selectedOption) {
    const option = {...selectedOption, label: selectedOption.value};

    if (option && option.filters) {
      const oldFavoritePortsInclude = option.filters.portsInclude.some(item => typeof item === 'string');
      const oldFavoritePortsExclude = option.filters.portsExclude.some(item => typeof item === 'string');

      if (
        option.filters.portsInclude &&
        Array.isArray(option.filters.portsInclude) &&
        option.filters.portsInclude.length &&
        oldFavoritePortsInclude
      ) {
        option.filters.portsInclude = option.filters.portsInclude.map(item => ({
          href: item,
          value: item,
          key: 'portProtocol',
        }));
      }

      if (
        option.filters.portsExclude &&
        Array.isArray(option.filters.portsExclude) &&
        option.filters.portsExclude.length &&
        oldFavoritePortsExclude
      ) {
        option.filters.portsExclude = option.filters.portsExclude.map(item => ({
          href: item,
          value: item,
          key: 'portProtocol',
        }));
      }

      option.filters.action = migrateActionFilter(option.filters.action);

      this.props.onFilterChange(option.filters);
      this.setState({filters: option.filters, Or: option.filters.Or});

      return option;
    }
  },

  handleRemoveFavorite(option) {
    let favorites = [...this.state.favorites];

    favorites = favorites.filter(favorite => favorite.label !== option.value);

    // If the chosen favorite is deleted, remove it as the favorite
    if (this.state.favorite && trimFilter(option.value) === trimFilter(this.state.favorite.label)) {
      localStorage.removeItem('chosen_favorite');
      this.setState({favorite: null});
    }

    RestApiUtils.kvPair.update(this.state.user, 'explorer_favorites', favorites);
  },

  handleSelectSettings(option) {
    const {value} = option;

    if (value === 'AND' || value === 'OR') {
      const filters = {...this.state.filters};

      filters.Or = !filters.Or;

      // do not clear all the filters, just consumers/providers
      const emptyFilters = ExplorerStore.getEmptyFilters();

      filters.consumerExclude = emptyFilters.consumerExclude;
      filters.consumerInclude = emptyFilters.consumerInclude;
      filters.providerExclude = emptyFilters.providerExclude;
      filters.providerInclude = emptyFilters.providerInclude;
      filters.consumerOrProviderExclude = emptyFilters.consumerOrProviderExclude;
      filters.consumerOrProviderInclude = emptyFilters.consumerOrProviderInclude;

      this.props.onFilterChange(filters);
      this.setState({filters, Or: !this.state.Or}, () => {
        const andOrValue = filters.Or ? 'or' : 'and';

        actionCreators.setAndOrFlag(andOrValue);
      });
    }

    if (value === RESULTS_SETTINGS) {
      this.props.showResultsSettingsDialog();
    }

    if (value === TOGGLE_WORKLOADS_IN_IPLISTS) {
      const includeWorkloads = !this.state.includeWorkloads;
      const newFilters = {...this.state.filters, includeWorkloads};

      localStorage.setItem('includeWorkloads', includeWorkloads);
      this.props.onFilterChange(newFilters);

      this.setState({filters: newFilters, includeWorkloads});
    }
  },

  handleTimeChange(time, {from, to} = {}) {
    const filters = {...this.state.filters, time, dateFrom: from, dateTo: to};

    this.props.onFilterChange(filters);
    this.setState({filters});
  },

  onActionChange(filter, value) {
    const action = {...this.state.filters.action};

    if (!value && !action[filter]) {
      action[filter] = null;
    }

    if (value) {
      action[filter] ||= [];
      action[filter].push(value);
    }

    const filters = {...this.state.filters, action};

    this.props.onFilterChange(filters);
    this.setState({filters});
  },

  onActionRemove(filter) {
    const action = {...this.state.filters.action};

    delete action[filter];

    const filters = {...this.state.filters, action};

    this.props.onFilterChange(filters);
    this.setState({filters});
  },

  handleFilterChange(filterKey, filter) {
    const filters = {...this.state.filters};

    filters[filterKey] = [];
    filters[filterKey].push(filter);
    this.props.onFilterChange(filters);
    this.setState({filters});
  },

  handleAddItem(filterKey, filter) {
    const filters = {...this.state.filters};
    let filterAlreadyExists = filters[filterKey].some(
      oldfilter => oldfilter.href?.split('/').pop() === filter.href?.split('/').pop(),
    );

    if (filter.key === 'cidrBlock') {
      filterAlreadyExists = false;
    }

    if (filterAlreadyExists) {
      _.defer(() => actionCreators.openDialog(<AlertDialog message={intl('Explorer.FilterAlreadyExists')} />));
    }

    if (!filter || !filterKey || filterAlreadyExists) {
      return 'wait';
    }

    filters[filterKey].push(filter);

    // Clear any partially entered ports
    if (filterKey.includes('port')) {
      filters[filterKey] = _.uniq(filters[filterKey]);
    }

    _.defer(() => {
      this.props.onFilterChange(filters);
      this.setState({filters});
    });
  },

  handleRemoveItem(filterKey, filter) {
    const filters = {...this.state.filters};

    filters[filterKey] = filters[filterKey].filter(currentFilter => !_.isEqual(currentFilter, filter));
    this.props.onFilterChange(filters);
    this.setState({filters});
  },

  handleCollapse() {
    const filters = this.addPartialPorts();

    this.setState({filters});
    this.props.onCollapse(!this.state.showFilters);
    this.setState({showFilters: !this.state.showFilters});
  },

  // swaps the left side with the right side while keeping transmission of FQDN items on the right side
  swapFilters(leftSide, rightSide) {
    const newLeftSide = _.reject(rightSide, filterIsTransmissionOrFQDN);
    const newRightSide = [
      ...leftSide,
      // we want to keep all the transmission or FQDN entries on the provider side
      ...rightSide.filter(filterIsTransmissionOrFQDN),
    ];

    return {newLeftSide, newRightSide};
  },

  // swaps the consumer provider values for include filter
  handleOnSwapIncludeClicked() {
    const {
      filters,
      filters: {consumerInclude, providerInclude},
    } = this.state;

    // EYE-79436 removing transmission from consumer include
    const {newLeftSide, newRightSide} = this.swapFilters(consumerInclude, providerInclude);
    const newFilters = {...filters, consumerInclude: newLeftSide, providerInclude: newRightSide};

    this.setState({
      filters: newFilters,
    });

    this.props.onFilterChange(newFilters);
  },

  // swaps the consumer provider values for exclude filter
  handleOnSwapExcludeClicked() {
    const {
      filters,
      filters: {consumerExclude, providerExclude},
    } = this.state;

    // EYE-79436 removing transmission or FQDN from consumer exclude
    const {newLeftSide, newRightSide} = this.swapFilters(consumerExclude, providerExclude);
    const newFilters = {...filters, consumerExclude: newLeftSide, providerExclude: newRightSide};

    this.setState({
      filters: newFilters,
    });

    this.props.onFilterChange(newFilters);
  },

  renderFilterItem(type, filter) {
    if (type === 'uuid') {
      return;
    }

    if (type.includes('port')) {
      return (
        <span className="ExplorerFilter-Name--item" key={`${type}item`}>
          {String(this.getPortProtocolFriendlyName(filter))}
        </span>
      );
    }

    if (type === 'time') {
      return (
        <span className="ExplorerFilter-Name--item" key={`${type}item`}>
          {`${filter} `}
        </span>
      );
    }

    if (type === 'action') {
      return (
        <span className="ExplorerFilter-Name--item" key={`${type}item`}>
          {`${Object.keys(filter).join(', ')} `}
        </span>
      );
    }

    return (
      <span className="ExplorerFilter-Name--item" key={`${type}item`}>
        {`${filter.map(
          f =>
            (f.value && (typeof f.value === 'string' ? f.value : '')) ||
            f.name ||
            f.hostname ||
            (typeof f === 'string' ? f : ''),
        )} `}
      </span>
    );
  },

  renderDefaultName(filters) {
    return Object.keys(filters).reduce((result, type) => {
      const filter = filters[type];

      if (!_.isEmpty(filter) && filter !== intl('Common.All')) {
        const filterItem = (
          <div>
            <span className="ExplorerFilter-Name--type" key={type}>{`${this.getFriendlyFilterName(type)}:`}</span>
            {this.renderFilterItem(type, filter)}
          </div>
        );

        result.push(filterItem);
      }

      return result;
    }, []);
  },

  renderFilters(filterRow, OrFilter) {
    const {handleAddItem, handleRemoveItem} = this;

    const {
      filters: {consumerInclude, providerInclude, consumerExclude, providerExclude},
    } = this.state;

    return Array.from(filterRow).map(([filterKey, value]) => {
      if (filterKey === SWAP_FILTER_OPTION) {
        let enabled = true;

        // if arrays are empty, disable the swap
        if (value === 'include' && consumerInclude.length === 0 && providerInclude.length === 0) {
          enabled = false;
        } else if (value === 'exclude' && consumerExclude.length === 0 && providerExclude.length === 0) {
          enabled = false;
        }

        const consumerSide = value === 'include' ? consumerInclude : consumerExclude;
        const providerSide = value === 'include' ? providerInclude : providerExclude;

        if (consumerSide.length > 0 && providerSide.length === 0) {
          enabled = consumerSide.some(item => providerAllowedTypesSet.has(item.key));
        }

        if (enabled && providerSide.length > 0 && consumerSide.length === 0) {
          enabled = providerSide.some(item => consumerAllowedTypesSet.has(item.key));
        }

        return (
          <Icon
            onClick={
              enabled ? (value === 'include' ? this.handleOnSwapIncludeClicked : this.handleOnSwapExcludeClicked) : null
            }
            tid={`swap-criteria-${value}`}
            name="flip-horizontal"
            helpText={intl('Explorer.SwapConsumersProviders')}
            size="medium"
            styleClass={enabled ? 'swapFilterIcon' : 'swapFilterIconDisabled'}
          />
        );
      }

      const props = {
        filters: this.state.filters && typeof this.state.filters === 'object' && this.state.filters[filterKey],
        addItem: _.partial(handleAddItem, filterKey),
        removeItem: _.partial(handleRemoveItem, filterKey),
        placeholder: [intl('Common.Select'), value].join(' '),
        filterKey,
        OrFilter,
      };

      const className =
        OrFilter &&
        (filterKey === 'consumerOrProviderInclude' ||
          filterKey === 'consumerOrProviderExclude' ||
          filterKey === 'providerOrConsumerInclude' ||
          filterKey === 'providerOrConsumerExclude')
          ? 'ExplorerFilter-Bar-Column-Streched'
          : 'ExplorerFilter-Bar-Column';

      return (
        <div className={className} data-tid={`${filterKey}-filter-row`} key={filterKey}>
          <EndpointFilter {...props} />
        </div>
      );
    });
  },

  render() {
    const {
      showFilters,
      waitForAppGroups,
      asyncEnabled,
      savedFilters,
      timeOptions,
      limitToMaxDays,
      filters,
      showBoundaries,
    } = this.state;
    const isConsumerOrProvider = OrgStore.reverseProviderConsumer();
    let includedFilterObjects;
    let excludedFilterObjects;
    let headerProviderConsumer;
    let andOr;

    const timeFilterTime = filters && typeof filters === 'object' && filters.time;

    if (this.state.Or) {
      // Case for OR

      // map guarantees the order when iterating
      const orIncludeMap = new Map();

      orIncludeMap.set(
        'consumerOrProviderInclude',
        isConsumerOrProvider ? intl('Explorer.OrIncludeCP') : intl('Explorer.OrIncludePC'),
      );
      orIncludeMap.set('portsInclude', intl('Explorer.PortsInclude'));

      const orExcludeMap = new Map();

      orExcludeMap.set(
        'consumerOrProviderExclude',
        isConsumerOrProvider ? intl('Explorer.OrExcludeCP') : intl('Explorer.OrExcludePC'),
      );
      orExcludeMap.set('portsExclude', intl('Explorer.PortsExclude'));

      const OrFilterTypes = {
        include: orIncludeMap,
        exclude: orExcludeMap,
      };

      includedFilterObjects = this.renderFilters(OrFilterTypes.include, true);
      excludedFilterObjects = this.renderFilters(OrFilterTypes.exclude, true);

      if (isConsumerOrProvider) {
        headerProviderConsumer = [
          <div className="ExplorerFilter-Bar-Column">{intl('Common.SourcesOrDestinations')}</div>,
          <div className="ExplorerFilter-Bar-ColumnSmall" />,
          <div className="ExplorerFilter-Bar-Column" />,
          <div className="ExplorerFilter-Bar-ColumnLast">{intl('Common.Services')}</div>,
        ];
      } else {
        headerProviderConsumer = [
          <div className="ExplorerFilter-Bar-Column">{intl('Common.DestinationsOrSources')}</div>,
          <div className="ExplorerFilter-Bar-ColumnSmall" />,
          <div className="ExplorerFilter-Bar-Column" />,
          <div className="ExplorerFilter-Bar-ColumnLast">{intl('Common.Services')}</div>,
        ];
      }
    } else {
      // Case for AND

      // map guarantees the order when iterating
      const andIncludeMap = new Map();
      const andExcludeMap = new Map();

      if (isConsumerOrProvider) {
        andIncludeMap.set('consumerInclude', intl('Explorer.SourceInclude'));
        andIncludeMap.set(SWAP_FILTER_OPTION, 'include');
        andIncludeMap.set('providerInclude', intl('Explorer.TargetInclude'));
        andIncludeMap.set('portsInclude', intl('Explorer.PortsInclude'));

        andExcludeMap.set('consumerExclude', intl('Explorer.SourceExclude'));
        andExcludeMap.set(SWAP_FILTER_OPTION, 'exclude');
        andExcludeMap.set('providerExclude', intl('Explorer.TargetExclude'));
        andExcludeMap.set('portsExclude', intl('Explorer.PortsExclude'));

        headerProviderConsumer = [
          <div className="ExplorerFilter-Bar-Column">{intl('Common.Sources')}</div>,
          <div className="ExplorerFilter-Bar-ColumnSmall" />,
          <div className="ExplorerFilter-Bar-Column">{intl('Common.Destinations')}</div>,
          <div className="ExplorerFilter-Bar-ColumnLast">{intl('Common.Services')}</div>,
        ];
      } else {
        andIncludeMap.set('providerInclude', intl('Explorer.TargetInclude'));
        andIncludeMap.set(SWAP_FILTER_OPTION, 'include');
        andIncludeMap.set('consumerInclude', intl('Explorer.SourceInclude'));
        andIncludeMap.set('portsInclude', intl('Explorer.PortsInclude'));

        andExcludeMap.set('providerExclude', intl('Explorer.TargetExclude'));
        andExcludeMap.set(SWAP_FILTER_OPTION, 'exclude');
        andExcludeMap.set('consumerExclude', intl('Explorer.SourceExclude'));
        andExcludeMap.set('portsExclude', intl('Explorer.PortsExclude'));

        headerProviderConsumer = [
          <div className="ExplorerFilter-Bar-Column">{intl('Common.Destinations')}</div>,
          <div className="ExplorerFilter-Bar-ColumnSmall" />,
          <div className="ExplorerFilter-Bar-Column">{intl('Common.Sources')}</div>,
          <div className="ExplorerFilter-Bar-ColumnLast">{intl('Common.Services')}</div>,
        ];
      }

      const andFilterTypes = {
        include: andIncludeMap,
        exclude: andExcludeMap,
      };

      includedFilterObjects = this.renderFilters(andFilterTypes.include);
      excludedFilterObjects = this.renderFilters(andFilterTypes.exclude);
    }

    const controlClasses = cx({
      'ExplorerFilter-Bar-Row': true,
      'ExplorerFilter-Bar-Row--last': showFilters,
    });

    if (isConsumerOrProvider) {
      andOr = [
        {
          value: 'OR',
          label: this.state.Or ? (
            <span className="Explorer-AndOr-Space">{intl('Common.SourcesAndDestinations')}</span>
          ) : (
            <span>
              <Icon name="check" styleClass="Explorer-AndOr-Space" size="small" />
              <span className="Explorer-AndOr-Font">{intl('Common.SourcesAndDestinations')}</span>
            </span>
          ),
        },
        {
          value: 'AND',
          label: this.state.Or ? (
            <span>
              <Icon name="check" styleClass="Explorer-AndOr-Space" size="small" />
              <span className="Explorer-AndOr-Font">{intl('Common.SourcesOrDestinations')}</span>
            </span>
          ) : (
            <span className="Explorer-AndOr-Space">{intl('Common.SourcesOrDestinations')}</span>
          ),
        },
      ];
    } else {
      andOr = [
        {
          value: 'OR',
          label: this.state.Or ? (
            <span className="Explorer-AndOr-Space">{intl('Common.DestinationsAndSources')}</span>
          ) : (
            <span>
              <Icon name="check" styleClass="Explorer-AndOr-Space" size="small" />
              <span className="Explorer-AndOr-Font">{intl('Common.DestinationsAndSources')}</span>
            </span>
          ),
        },
        {
          value: 'AND',
          label: this.state.Or ? (
            <span>
              <Icon name="check" styleClass="Explorer-AndOr-Space" size="small" />
              <span className="Explorer-AndOr-Font">{intl('Common.DestinationsOrSources')}</span>
            </span>
          ) : (
            <span className="Explorer-AndOr-Space">{intl('Common.DestinationsOrSources')}</span>
          ),
        },
      ];
    }

    const settings = [
      ...andOr,
      {value: 'separator', label: <div className="ExplorerFilter-separator" />, category: true},
      {
        value: RESULTS_SETTINGS,
        label: <div className="ExplorerFilter-ResultsSettings">{intl('Explorer.ResultsSettings')}</div>,
      },
      {value: 'separator', label: <div className="ExplorerFilter-separator" />, category: true},
      {
        value: TOGGLE_WORKLOADS_IN_IPLISTS,
        label: this.state.includeWorkloads ? (
          <span className="Explorer-AndOr-Space">{intl('Explorer.ExcludeWorkloadsFromIpList')}</span>
        ) : (
          <span>
            <Icon name="check" styleClass="Explorer-AndOr-Space" size="small" />
            <span className="Explorer-AndOr-Font">{intl('Explorer.ExcludeWorkloadsFromIpList')}</span>
          </span>
        ),
      },
    ];

    const policyFilter = this.state.filters && typeof this.state.filters === 'object' && this.state.filters.action;
    const singleValues = ExplorerUtils.filterPolicyFilterOptions(actionOptions(), policyFilter, showBoundaries);

    const favoriteDefault = this.state.favorite ? this.state.favorite.label : intl('Explorer.LoadFilter');
    const favoriteEdited = this.state.favorite && !_.isEqual(this.state.favorite.filters, this.state.filters);
    let favoriteLabel = favoriteDefault;

    if (this.state.favorite && favoriteEdited) {
      favoriteLabel = (
        <div className="ExplorerFilter-Favorites--updated">
          <Badge type="edited" />
          {favoriteLabel}
        </div>
      );
    }

    const {resultsButtonNumber, resultsButtonDisabled, onResultsButtonClicked} = this.props;

    const disableLoadFilter = !(savedFilters || []).length;

    return (
      <div className="ExplorerFilter-Bar">
        {this.props.trafficDBMigrationInProgress ? (
          <div className="ExplorerFilter-Bar-TrafficDBMigrationNotification">
            <Notification
              sidebar
              type="warning"
              title={intl('Health.Metrics.TrafficDBMigrationTitle')}
              message={
                <span>
                  {intl('Health.Metrics.TrafficDBMigrationBody')}
                  <RouteLink to="health.list">{intl('Health.Metrics.TrafficDBMigrationDetails')}</RouteLink>
                </span>
              }
            />
          </div>
        ) : null}
        {showFilters
          ? [
              <div className="ExplorerFilter-Bar-Row ExplorerFilter-Header" key="header">
                <div className="ExplorerFilter-Bar-Column1" />
                {headerProviderConsumer}
                <div className="ExplorerFilter-ProviderBar-Margin" data-tid="comp-button-settings">
                  <TrafficDBStorageMonitor />
                  <Button
                    text={intl('Explorer.ClearFilters')}
                    tid="save"
                    type="secondary"
                    onClick={this.handleClearFilters}
                  />
                  <ButtonDropdown
                    icon="settings"
                    type="secondary"
                    content="both"
                    options={settings}
                    tid="settings"
                    onSelect={this.handleSelectSettings}
                  />
                </div>
              </div>,
              <div className="ExplorerFilter-Bar-Row" key="include">
                <div className="ExplorerFilter-Bar-Column1">{intl('Common.Include')}</div>
                {includedFilterObjects}
              </div>,
              <div className="ExplorerFilter-Bar-Row" key="exclude">
                <div className="ExplorerFilter-Bar-Column1">{intl('Common.Exclude')}</div>
                {excludedFilterObjects}
              </div>,
            ]
          : null}
        <div className={controlClasses}>
          {showFilters ? (
            [
              <div className="ExplorerFilter-Bar-Column1" key="time-title">
                {intl('Explorer.Time')}
              </div>,
              <div className="ExplorerFilter-Bar-Column5" key="time">
                <TimeFilter
                  onChange={this.handleTimeChange}
                  time={timeFilterTime}
                  options={timeOptions}
                  limitCustomRangeToMaxDays={limitToMaxDays}
                />
              </div>,
              <div className="ExplorerFilter-Bar-Column6" key="action-title">
                {intl('Common.ReportedPolicy')}
              </div>,
              <div className="ExplorerFilter-Bar-Column7" key="action" data-tid="action-filter">
                <ObjectSelector
                  addItem={this.onActionChange}
                  removeItem={this.onActionRemove}
                  dropdownValues={{}}
                  facetMap={{}}
                  getFacetValues={_.noop}
                  initialValues={[]}
                  items={policyFilter}
                  placeholder={Object.keys(policyFilter).length ? '' : intl('Explorer.AllPolicyDecisions')}
                  returnValue={item => item}
                  singleValues={singleValues}
                  showCountOnFilter
                  tid="action"
                />
              </div>,
            ]
          ) : (
            <div className="ExplorerFilter-Bar-Column9 ExplorerFilter-Name" onClick={this.handleCollapse}>
              {this.renderDefaultName(this.state.filters)}
            </div>
          )}
          <div className="ExplorerFilter-Bar-Column4">
            <div className="ExplorerFilter-Save">
              {favoriteEdited ? (
                <ButtonDropdown
                  tid="saveAs"
                  text={intl('Explorer.SaveFilter')}
                  type="secondary"
                  options={[{label: intl('Common.Save')}, {label: intl('Common.SaveAs')}]}
                  onSelect={this.handleSaveFilter}
                  dual
                />
              ) : (
                <Button
                  text={intl('Explorer.SaveFilter')}
                  tid="save"
                  type="secondary"
                  onClick={this.handleSaveAsFilter}
                />
              )}
            </div>
            <div className="ExplorerFilter-Favorites">
              <ButtonDropdown
                tid="favorites"
                text={favoriteLabel}
                type="secondary"
                title={favoriteDefault}
                disabled={disableLoadFilter}
                options={savedFilters}
                onRemove={this.handleRemoveFavorite}
                onSelect={this.handleSelectFavoriteFilter}
                dual
              />
            </div>
            <div className="ExplorerFilter-Go">
              <Button
                text={intl('Common.Go')}
                tid="go"
                disabled={
                  this.props.buttonDisabled ||
                  waitForAppGroups ||
                  !timeOptionExistsInOptions(timeFilterTime, timeOptions())
                }
                onClick={this.handleSetFilters}
              />
            </div>
            {asyncEnabled ? (
              <div className="ExplorerFilter-Results">
                <Button
                  type="strong"
                  text={intl('Explorer.Results')}
                  counter={resultsButtonNumber}
                  tid="results"
                  disabled={resultsButtonDisabled}
                  onClick={onResultsButtonClicked}
                />
              </div>
            ) : null}
          </div>
          <div className="ExplorerFilter-Bar-Column8">
            <Icon name={showFilters ? 'caret-up' : 'caret-down'} size="xxlarge" onClick={this.handleCollapse} />
          </div>
        </div>
      </div>
    );
  },
});

FilterBar.propTypes = {
  enabled: PropTypes.bool.isRequired,
  buttonDisabled: PropTypes.bool.isRequired,
  onSetSpinner: PropTypes.func.isRequired,
  onCollapse: PropTypes.func.isRequired,
  reload: PropTypes.bool.isRequired,
  trafficDBMigrationInProgress: PropTypes.bool.isRequired,
  onResultsButtonClicked: PropTypes.func.isRequired,
  resultsButtonDisabled: PropTypes.bool.isRequired,
  resultsButtonNumber: PropTypes.number.isRequired,
  onStartNewQuery: PropTypes.func.isRequired,
  onFilterChange: PropTypes.func.isRequired,
  showResultsSettingsDialog: PropTypes.func.isRequired,
};

export default FilterBar;
