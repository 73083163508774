/**
 * Copyright 2015 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import {createStore} from '../lib/store';
import dispatcher from '../actions/dispatcher';
import Constants from '../constants';
import GraphStore from './GraphStore';
import MapPageStore from './MapPageStore';
import TrafficStore from './TrafficStore';
import ServiceStore from './ServiceStore';
import ServiceUtils from '../utils/ServiceUtils';
import RenderUtils from '../utils/RenderUtils';

// todo: temporary solution to keep traffic filters persistance acrossing sessions
export const defaultFilters = {
  ignoreServices: false,
  services: {
    '53 TCP': true,
    '53 UDP': true,
    '5353 TCP': true,
    '5353 UDP': true,
    '67 UDP': true,
    '68 UDP': true,
    '500 UDP': true,
    '4500 UDP': true,
  },
  allowInterAppTraffic: true,
  allowIntraAppTraffic: true,
  allowFqdnTraffic: true,
  allowInternetTraffic: true,
  allowIpListTraffic: true,
  allowAllowedTraffic: true,
  allowBlockedTraffic: true,
  allowUnknownTraffic: true,
  allowPotentiallyBlockedTraffic: true,
  allowAllowedAcrossBoundaryTraffic: true,
  allowBlockedByBoundaryTraffic: true,
  allowPotentiallyBlockedByBoundaryTraffic: true,
  allowOutboundTraffic: true,
  allowInboundTraffic: true,
  allowOldTraffic: true,
  timeFilter: {type: 'time', value: 'anytime'},
  allowBroadcastTraffic: false,
  allowMulticastTraffic: false,
  allowIcmpTraffic: true,
  trafficVolume: 1,
  vulnerabilitySeverity: 1,
  exposedVulnerabilities: false,
  showVulnerableBlockedTraffic: true,
  minFlow: -1,
  maxFlow: -1,
  visibility: true,
  selective: true,
  enforced: true,
  unmanaged: true,
  idle: true,
  highlight: false,
};

let filters = defaultFilters;
const filterVersion = localStorage.getItem('filterVersion');

// If the version is older than 20.2 start over
if (!filterVersion || filterVersion < 22.22) {
  localStorage.removeItem('trafficFilters');
  localStorage.removeItem('serviceFilters');
  localStorage.removeItem('trafficConnectionFilters');
  localStorage.removeItem('timeFilters');
}

localStorage.setItem('filterVersion', 22.22);

if (localStorage.getItem('trafficFilters')) {
  try {
    filters = JSON.parse(localStorage.getItem('trafficFilters'));
  } catch {
    //Catch issues from old versions of the filters
    localStorage.removeItem('trafficFilters');
    localStorage.removeItem('trafficConnectionFilters');
    localStorage.removeItem('serviceFilters');
    localStorage.removeItem('timeFilters');
  }

  // If the filters are old, clean them up
  if (filters.hasOwnProperty('hideIntraAppTraffic')) {
    // Save the service filter from the old version of filters
    // These can be time consuming to set
    const services = filters.services;

    filters = {...defaultFilters, services};
  }

  if (!filters.hasOwnProperty('vulnerabilitySeverity')) {
    filters.vulnerabilitySeverity ||= '3';
  }

  if (!filters.hasOwnProperty('exposedVulnerabilities')) {
    filters.exposedVulnerabilities ||= true;
  }

  if (!filters.hasOwnProperty('showVulnerableBlockedTraffic')) {
    filters.showVulnerableBlockedTraffic ||= false;
  }

  localStorage.setItem('trafficFilters', JSON.stringify(filters));
}

let checkboxes = {};
let matchingServices = [];
let portMap = {};

let trafficFilterPanelHidden = true;

// returns string of ports "25 TCP, 30-100 TCP, 102 UDP"
function getPortNames(servicePorts) {
  return _.map(
    servicePorts,
    servicePort =>
      `${servicePort.port + (servicePort.to_port ? `-${servicePort.to_port}` : '')} ${ServiceUtils.lookupProtocol(
        servicePort.proto || servicePort.protocol,
      ).toUpperCase()}`,
  ).join(', ');
}

function findPortsInRange(portsByProtocolObj, portsByProtocolArray, rangeArray) {
  // Group by protocol
  const rangeProtocolArray = Object.values(_.groupBy(rangeArray, v => v.protocol));

  // For each protocol, get ports which match the ranges
  return rangeProtocolArray.reduce((result, protocolArray) => {
    const protocol = ServiceUtils.lookupProtocol(protocolArray[0].protocol).toLocaleLowerCase();

    if (portsByProtocolObj[protocol]) {
      result = result.concat(
        getPortIntersections(portsByProtocolObj[protocol], portsByProtocolArray[protocol], protocolArray),
      );
    }

    return result;
  }, []);
}

function getPortIntersections(portsObj, portsArray, rangeVals) {
  return rangeVals.reduce((result, rangeVal) => {
    if (rangeVal.to_port) {
      // Ranges
      result = result.concat(
        portsArray.filter(portVal => portVal.port >= rangeVal.port && portVal.port <= rangeVal.to_port),
      );
    } else {
      //todo REMOVE when ICMP traffic is handled
      if (!rangeVal.port) {
        return;
      }

      // Single ports
      result.push(portsObj[rangeVal.port]);
    }

    return result;
  }, []);
}

function getServiceCheckboxValues(portMap, servicePorts) {
  const ignoreServices = TrafficStore.getServiceFilters().services;
  let addService = false;
  let checked = true;
  const ports = [];

  _.each(servicePorts, servicePort => {
    //todo Remove this when implimenting ICMP
    if (!servicePort || !servicePort.port) {
      return;
    }

    const portKey = `${servicePort.port} ${ServiceUtils.lookupProtocol(
      servicePort.proto || servicePort.protocol,
      false,
    ).toUpperCase()}`;
    const port = {
      key: portKey,
      checked: Boolean(ignoreServices[portKey]),
    };

    ports.push(port);

    if (portKey in portMap) {
      // only add policy service if >1 port is observed
      addService = true;

      // policy service is selected only if all of its ports are checked
      checked &&= portMap[portKey].checked;
    }
  });

  return {
    addService,
    checked,
    ports,
  };
}

function getTrafficInfo(traffics, portMap, processNames) {
  const ignoreServices = TrafficStore.getServiceFilters().services;
  const mapRoute = MapPageStore.getMapRoute();
  const href = MapPageStore.getMapType() === 'app' ? mapRoute.previd || mapRoute.id : mapRoute.id;

  _.each(traffics, link => {
    // Extract from only traffic's with the app group source or destinations
    let isFocussedAppGroupLink = false;

    if (MapPageStore.getMapType() === 'app') {
      // To compare partial 2-label match in app group. Example: href = 7x14 and links are between 7x11x14 and internet.
      const linkSourceLabels = link.source.href.split('-')[0].split('x');
      const linkTargetLabels = link.target.href.split('-')[0].split('x');

      if (href) {
        const hrefLabels = href.split('-')[0].split('x');

        isFocussedAppGroupLink =
          hrefLabels.every(label => linkSourceLabels.includes(label)) ||
          hrefLabels.every(label => linkTargetLabels.includes(label));
      }
    }

    if (MapPageStore.getMapType() !== 'app' || (MapPageStore.getMapType() === 'app' && isFocussedAppGroupLink)) {
      _.each(link.connections, connection => {
        const key = `${connection.port} ${connection.friendlyProtocol}`;

        if (!ServiceUtils.isIcmp(connection.proto || connection.protocol)) {
          portMap[key] = {
            checked: Boolean(ignoreServices[key]),
            name: connection.service,
            matchService: false,
          };

          if (connection.service !== 'unknown') {
            let ports = processNames[connection.service];

            ports ||= processNames[connection.service] = {};

            ports[key] = key;
          }
        }
      });
    }
  });
}

function setSelectedServices(data) {
  const processNames = {};
  const coreServicePorts = ['53 TCP', '53 UDP', '5353 TCP', '5353 UDP', '67 UDP', '68 UDP', '500 UDP', '4500 UDP'];

  // Default Values of Checkboxes, Matching Services and PortMap set to LOAD CORE SERVICE TRAFFIC

  checkboxes = {
    DNS: {
      checked: data?.value.hasOwnProperty(['53 TCP'])
        ? data.value['53 TCP']
        : filters.services.hasOwnProperty('53 TCP')
          ? filters.services['53 TCP']
          : true,
      ports: ['53 TCP', '53 UDP'],
    },
    mDNS: {
      checked: data?.value.hasOwnProperty(['5353 TCP'])
        ? data.value['5353 TCP']
        : filters.services.hasOwnProperty('5353 TCP')
          ? filters.services['5353 TCP']
          : true,
      ports: ['5353 TCP', '5353 UDP'],
    },
    DHCP: {
      checked: data?.value.hasOwnProperty(['67 UDP'])
        ? data.value['67 UDP']
        : filters.services.hasOwnProperty('67 UDP')
          ? filters.services['67 UDP']
          : true,
      ports: ['67 UDP', '68 UDP'],
    },
    IPSec: {
      checked: data?.value.hasOwnProperty(['500 UDP'])
        ? data.value['500 UDP']
        : filters.services.hasOwnProperty('500 UDP')
          ? filters.services['500 UDP']
          : true,
      ports: ['500 UDP', '4500 UDP'],
    },
  };

  matchingServices = [
    {href: 'DNS', name: 'DNS', portNames: '53 TCP, 53 UDP', range: false},
    {href: 'mDNS', name: 'mDNS', portNames: '5353 TCP, 5353 UDP', range: false},
    {href: 'DHCP', name: 'DHCP', portNames: '67 UDP, 68 UDP', range: false},
    {href: 'IPSec', name: 'IPSec', portNames: '500 UDP, 4500 UDP', range: false},
  ];

  portMap = {
    '53 TCP': {
      checked: data?.value.hasOwnProperty(['53 TCP'])
        ? data.value['53 TCP']
        : filters.services.hasOwnProperty('53 TCP')
          ? filters.services['53 TCP']
          : true,
      matchedService: true,
      name: 'DNS',
      coreService: true,
      allPorts: ['53 TCP', '53 UDP'],
    },
    '53 UDP': {
      checked: data?.value.hasOwnProperty(['53 UDP'])
        ? data.value['53 UDP']
        : filters.services.hasOwnProperty('53 UDP')
          ? filters.services['53 UDP']
          : true,
      matchedService: true,
      name: 'DNS',
      coreService: true,
      allPorts: ['53 TCP', '53 UDP'],
    },
    '5353 TCP': {
      checked: data?.value.hasOwnProperty(['5353 TCP'])
        ? data.value['5353 TCP']
        : filters.services.hasOwnProperty('5353 TCP')
          ? filters.services['5353 TCP']
          : true,
      matchedService: true,
      name: 'mDNS',
      coreService: true,
      allPorts: ['5353 TCP', '5353 UDP'],
    },
    '5353 UDP': {
      checked: data?.value.hasOwnProperty(['5353 UDP'])
        ? data.value['5353 UDP']
        : filters.services.hasOwnProperty('5353 UDP')
          ? filters.services['5353 UDP']
          : true,
      matchedService: true,
      name: 'mDNS',
      coreService: true,
      allPorts: ['5353 TCP', '5353 UDP'],
    },
    '67 UDP': {
      checked: data?.value.hasOwnProperty(['67 UDP'])
        ? data.value['67 UDP']
        : filters.services.hasOwnProperty('67 UDP')
          ? filters.services['67 UDP']
          : true,
      matchedService: true,
      name: 'DHCP',
      coreService: true,
      allPorts: ['67 UDP', '68 UDP'],
    },
    '68 UDP': {
      checked: data?.value.hasOwnProperty(['68 UDP'])
        ? data.value['68 UDP']
        : filters.services.hasOwnProperty('68 UDP')
          ? filters.services['68 UDP']
          : true,
      matchedService: true,
      name: 'DHCP',
      coreService: true,
      allPorts: ['67 UDP', '68 UDP'],
    },
    '500 UDP': {
      checked: data?.value.hasOwnProperty(['500 UDP'])
        ? data.value['500 UDP']
        : filters.services.hasOwnProperty('500 UDP')
          ? filters.services['500 UDP']
          : true,
      matchedService: true,
      name: 'IPSec',
      coreService: true,
      allPorts: ['500 UDP', '4500 UDP'],
    },
    '4500 UDP': {
      checked: data?.value.hasOwnProperty(['4500 UDP'])
        ? data.value['4500 UDP']
        : filters.services.hasOwnProperty('4500 UDP')
          ? filters.services['4500 UDP']
          : true,
      matchedService: true,
      name: 'IPSec',
      coreService: true,
      allPorts: ['500 UDP', '4500 UDP'],
    },
  };

  if (
    _.isEmpty(TrafficStore.getAllClusterTraffics()) &&
    _.isEmpty(TrafficStore.getAllWorkloadTraffics()) &&
    _.isEmpty(TrafficStore.getAllRoleTraffics()) &&
    _.isEmpty(TrafficStore.getAllMixedRoleTraffics())
  ) {
    return;
  }

  getTrafficInfo(TrafficStore.getAllRoleTraffics(), portMap, processNames);
  getTrafficInfo(TrafficStore.getAllMixedRoleTraffics(), portMap, processNames);
  getTrafficInfo(TrafficStore.getAllWorkloadTraffics(), portMap, processNames);
  getTrafficInfo(TrafficStore.getAllClusterTraffics(), portMap, processNames);

  const portsByProtocolObj = Object.keys(portMap).reduce(
    (result, port) => {
      const portObj = {port: parseInt(port.split(' ')[0], 10), protocol: port.split(' ')[1]};

      if (portObj.protocol === 'TCP') {
        result.tcp[portObj.port] = portObj;
      } else {
        result.udp[portObj.port] = portObj;
      }

      return result;
    },
    {tcp: {}, udp: {}, icmp: {}},
  );

  const portsByProtocolArray = {
    tcp: Object.values(portsByProtocolObj.tcp),
    udp: Object.values(portsByProtocolObj.udp),
  };

  _.each(ServiceStore.getAll(), service => {
    // Don't add the "All Services" service
    const servicePortNames = getPortNames(service.service_ports);
    const areServicePortsCoreService = servicePortNames.split(',').every(port => coreServicePorts.includes(port));

    if (
      (service.service_ports && service.service_ports.length && service.service_ports[0].port === -1) ||
      areServicePortsCoreService
    ) {
      return;
    }

    let results = {};
    const range =
      _.some(service.service_ports, 'to_port') || (service.service_ports && service.service_ports.length > 1);

    // if this service contains a port range,
    // it has multiple corresponding checkboxes
    if (range) {
      const portsInRange = findPortsInRange(portsByProtocolObj, portsByProtocolArray, service.service_ports);

      results = getServiceCheckboxValues(portMap, portsInRange);

      if (results.addService) {
        matchingServices.push({
          href: service.href,
          name: service.name,
          portNames: servicePortNames,
          ports: _.map(results.ports, 'key'),
          range,
        });
        // Add individual port checkboxes for the dropdown
        _.each(results.ports, port => {
          checkboxes[port.key] = {
            ports: [port.key],
            checked: port.checked,
          };
          portMap[port.key].matchService = true;
        });
        // Add "Select All" checkbox for dropdown
        checkboxes[service.href] = {
          ports: _.map(results.ports, 'key'),
          checked: _.every(results.ports, 'checked'),
        };
      }
    } else {
      // this service has only single ports, so it corresponds to only 1 checkbox
      results = getServiceCheckboxValues(portMap, service.service_ports);

      if (results.addService) {
        matchingServices.push({
          href: service.href,
          name: service.name,
          portNames: servicePortNames,
          range,
        });
        _.each(results.ports, port => {
          if (portMap[port.key]) {
            portMap[port.key].matchService = true;
          }
        });
        checkboxes[service.href] = {
          checked: results.checked,
          ports: _.map(results.ports, 'key'),
        };
      }
    }
  });

  // add discovered services
  _.each(processNames, (processPorts, key) => {
    const ports = [];
    let checked = true;

    //Delete Any Core Service Process
    coreServicePorts.forEach(port => {
      if (processPorts.hasOwnProperty(port)) {
        delete processPorts[port];
      }
    });

    _.each(processPorts, port => {
      if (!portMap[port].matchService) {
        ports.push(port);
        checked &&= portMap[port].checked;
      }
    });

    if (!_.isEmpty(ports)) {
      // only add disc. service if its ports haven't been matched to a service
      matchingServices.push({
        href: key,
        name: key,
        portNames: ports.join(', '),
        range: false,
      });
      checkboxes[key] = {
        checked,
        ports,
      };
      _.each(ports, portKey => (portMap[portKey].matchService = true));
    }
  });

  _.each(portMap, (port, key) => {
    // if the port didn't get matched to a service & has no process name,
    // it is an Unknown service and should be added to the filter
    if (port.name === 'unknown' && !port.matchService) {
      matchingServices.push({
        href: key,
        name: key,
        portNames: '',
        range: false,
      });
      checkboxes[key] = {
        checked: port.checked,
        ports: [key],
      };
    }

    if (port.coreService) {
      checkboxes[key] = {
        checked: port.checked,
        ports: [key],
      };
    }

    portMap[key] = port.checked; //reset portMap to only contain true/false values
  });
}

function highlightFilterButton() {
  const mapLevel = MapPageStore.getMapLevel();
  const mapType = MapPageStore.getMapType();
  const appMapVersion = MapPageStore.getAppMapVersion();
  const policyVersion = MapPageStore.getPolicyVersion();
  const timeFilter = filters.timeFilter && RenderUtils.getTimeFromFilter(filters.timeFilter) !== 'anytime';

  if (mapType === 'loc') {
    const showAllPolicyStates =
      filters.visibility && filters.enforced && filters.selective && filters.unmanaged && filters.idle;
    let showAllTraffic = true;

    if (mapLevel !== 'location') {
      showAllTraffic =
        filters.allowOutboundTraffic &&
        !timeFilter &&
        filters.allowAllowedTraffic &&
        filters.allowBlockedTraffic &&
        filters.allowBlockedByBoundaryTraffic &&
        filters.allowPotentiallyBlockedByBoundaryTraffic &&
        filters.allowPotentiallyBlockedTraffic &&
        (policyVersion === 'draft' || filters.allowUnknownTraffic) &&
        (policyVersion !== 'draft' || filters.allowAllowedAcrossBoundaryTraffic) &&
        filters.allowIntraAppTraffic &&
        filters.allowIpListTraffic &&
        filters.allowInterAppTraffic &&
        filters.allowInternetTraffic &&
        filters.allowInboundTraffic &&
        filters.allowFqdnTraffic &&
        !filters.allowBroadcastTraffic &&
        !filters.allowMulticastTraffic &&
        filters.allowIcmpTraffic &&
        parseInt(filters.trafficVolume, 10) === 1 &&
        !filters.ignoreServices;
    }

    filters.highlight = !showAllPolicyStates || !showAllTraffic;
  } else if (appMapVersion === 'vulnerability') {
    const showAllPolicyStates =
      filters.visibility && filters.enforced && filters.selective && filters.unmanaged && filters.idle;

    filters.highlight =
      !(
        !filters.exposedVulnerabilities &&
        !timeFilter &&
        filters.allowIntraAppTraffic &&
        filters.allowIpListTraffic &&
        filters.allowFqdnTraffic &&
        filters.allowInternetTraffic &&
        !filters.ignoreServices &&
        !filters.allowBroadcastTraffic &&
        !filters.allowMulticastTraffic &&
        filters.allowIcmpTraffic &&
        filters.vulnerabilitySeverity === 1 &&
        filters.showVulnerableBlockedTraffic
      ) || !showAllPolicyStates;
  } else {
    const showAllPolicyStates =
      filters.visibility && filters.enforced && filters.selective && filters.unmanaged && filters.idle;
    let showAllTraffic = true;

    showAllTraffic =
      filters.allowAllowedTraffic &&
      filters.allowBlockedTraffic &&
      filters.allowBlockedByBoundaryTraffic &&
      filters.allowPotentiallyBlockedByBoundaryTraffic &&
      filters.allowPotentiallyBlockedTraffic &&
      (policyVersion === 'draft' || filters.allowUnknownTraffic) &&
      (policyVersion !== 'draft' || filters.allowAllowedAcrossBoundaryTraffic) &&
      filters.allowIntraAppTraffic &&
      filters.allowIpListTraffic &&
      filters.allowInternetTraffic &&
      filters.allowFqdnTraffic &&
      !filters.allowBroadcastTraffic &&
      !filters.allowMulticastTraffic &&
      filters.allowIcmpTraffic &&
      !timeFilter &&
      parseInt(filters.trafficVolume, 10) === 1 &&
      !filters.ignoreServices;

    filters.highlight = timeFilter || filters.ignoreServices || !showAllPolicyStates || !showAllTraffic;
  }
}

function getLinkWeightsRange() {
  // todo: need fix TrafficStore to calculate sessionCount
  // don't use sessionCount because totalSessionCount is not calculated correctly in TrafficStore
  filters.minFlow = 0;
  filters.maxFlow = 0;

  for (const {
    data: {connections},
  } of Object.values(GraphStore.getGraphLinks())) {
    let connectionsWeight = 0;

    for (const {sessions} of Object.values(connections)) {
      connectionsWeight += sessions;
    }

    if (connectionsWeight > filters.maxFlow) {
      filters.maxFlow = connectionsWeight;
    }
  }
}

function setTrafficFilters(data) {
  filters[data.label] = data.value;
}

function policyStateFilters() {
  return {
    visibility: filters.visibility,
    selective: filters.selective,
    enforced: filters.enforced,
    unmanaged: filters.unmanaged,
    idle: filters.idle,
  };
}

function resetFiltersToDefault() {
  filters = {
    ignoreServices: false,
    services: {
      '53 TCP': true,
      '53 UDP': true,
      '5353 TCP': true,
      '5353 UDP': true,
      '67 UDP': true,
      '68 UDP': true,
      '500 UDP': true,
      '4500 UDP': true,
    },
    allowInterAppTraffic: true,
    allowIntraAppTraffic: true,
    allowFqdnTraffic: true,
    allowInternetTraffic: true,
    allowPotentiallyBlockedTraffic: true,
    allowIpListTraffic: true,
    allowAllowedTraffic: true,
    allowBlockedTraffic: true,
    allowUnknownTraffic: true,
    allowAllowedAcrossBoundaryTraffic: true,
    allowBlockedByBoundaryTraffic: true,
    allowPotentiallyBlockedByBoundaryTraffic: true,
    allowOutboundTraffic: true,
    allowInboundTraffic: true,
    allowOldTraffic: true,
    timeFilter: {type: 'time', value: 'anytime'},
    allowBroadcastTraffic: false,
    allowMulticastTraffic: false,
    allowIcmpTraffic: true,
    trafficVolume: 1,
    vulnerabilitySeverity: 1,
    exposedVulnerabilities: false,
    showVulnerableBlockedTraffic: true,
    minFlow: -1,
    maxFlow: -1,
    building: true,
    testing: true,
    visibility: true,
    selective: true,
    enforced: true,
    idle: true,
    unmanaged: true,
    highlight: false,
  };
}

export default createStore({
  dispatchHandler(action) {
    switch (action.type) {
      case Constants.UPDATE_MAP_ROUTE:
        dispatcher.waitFor([GraphStore.dispatchToken]);
        // Update selected services when changing map routes to populate ignore selected services.
        setSelectedServices();
        break;

      case Constants.NETWORK_TRAFFIC_GET_SUCCESS:
        dispatcher.waitFor([TrafficStore.dispatchToken]);
        setSelectedServices();
        break;

      case Constants.SELECT_SERVICE_FILTERS:
        dispatcher.waitFor([TrafficStore.dispatchToken]);
        // need update filters.services and ignoreServices for highlighting
        // we need think more about this. Because both TrafficFilterStore and TrafficStore
        // save services and allowOldTime values
        setTrafficFilters(action.data);
        setSelectedServices(action.data);
        break;

      case Constants.SELECT_TRAFFIC_CONNECTION_FILTERS:
        dispatcher.waitFor([TrafficStore.dispatchToken]);
        setTrafficFilters(action.data);
        break;

      case Constants.SELECT_TRAFFIC_TIME_FILTERS:
      case Constants.SELECT_TRAFFIC_FILTERS:
        setTrafficFilters(action.data);
        break;

      case Constants.RESET_DEFAULT_FILTERS:
        resetFiltersToDefault();
        break;

      case Constants.SET_TRAFFIC_FILTER_HIDDEN:
        trafficFilterPanelHidden = action.data;
        this.emitChange();

        return true;

      case Constants.SET_MAP_POLICY_VERSION:
        dispatcher.waitFor([MapPageStore.dispatchToken]);
        break;

      case Constants.SET_APP_MAP_VERSION:
        dispatcher.waitFor([MapPageStore.dispatchToken]);

        break;

      default:
        return true;
    }

    highlightFilterButton();
    getLinkWeightsRange();
    this.emitChange();
    // todo: temporary solution to keep traffic filters persistance acrossing sessions
    localStorage.setItem('trafficFilters', JSON.stringify(filters));

    return true;
  },

  getTransmissionFilters: () => {
    const coreServicePorts = ['53 TCP', '53 UDP', '5353 TCP', '5353 UDP', '67 UDP', '68 UDP', '500 UDP', '4500 UDP'];
    const allowCoreService =
      filters.ignoreServices &&
      coreServicePorts.some(port => filters.services.hasOwnProperty(port) && !filters.services[port]);

    // transmissionFilter = [Unicast, Broadcat, Multicast, CoreService];
    return [true, filters.allowBroadcastTraffic, filters.allowMulticastTraffic, allowCoreService];
  },

  getAll: () => filters,

  getSelectedServices: () => ({
    services: matchingServices,
    checkboxes,
    portMap,
  }),

  getPolicyStates: type => policyStateFilters(type),

  getHiddenPolicyStates: () =>
    _.reduce(
      policyStateFilters(),
      (result, value, policyState) => {
        if (!value) {
          result.push(policyState);
        }

        return result;
      },
      [],
    ),

  getHidden: () => trafficFilterPanelHidden,

  areFiltersDefault: () => filters.highlight,
});
