/**
 * Copyright 2014 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import {clearTimestamps as clearApiTimestamps} from '../lib/api';
import actionCreators from '../actions/actionCreators';
import Constants from '../constants';
import {createApiStore} from '../lib/store';
import Session from '../lib/session';
import ErrorUtils from '../utils/ErrorUtils.jsx';
import DialogStore from './DialogStore';
import loginServer from '../utils/loginServer';
import {BroadcastChannel} from 'broadcast-channel';
import {resetBrowserWarning} from '../components/BrowserWarning';
import {webStorageUtils} from '@illumio-shared/utils';
import {getVersion, parseVersion} from '../utils/GeneralUtils';
import {RestApiUtils} from '../utils';

const features = {};
const scopedRoles = new Set([
  'ruleset_manager',
  'limited_ruleset_manager',
  'ruleset_provisioner',
  'ruleset_viewer',
  'workload_manager',
]);

let coreServices = {};
let whatsNewCheckedVersion = null;

export function login(data) {
  if (!__DEV__) {
    // get rid of the session token in production (but we don't need it in dev too, because we use cookies)
    delete data.session_token;
  }

  Session.set(data);

  // For sharing user between tessereact<->legacy page. #USERJUMP
  window['--JUMPS_USER--'] = data;

  // Save current user id to local storage to understand next time if user is changed
  // Now we don't have better way to undersdand if it's new login or just opening of a new tab, because we use session storage
  const savedUserId = localStorage.getItem('user_id') || null;

  if (savedUserId !== data.user_id) {
    resetBrowserWarning(); // Always show beta browser warning message after login again
    localStorage.setItem('user_id', data.user_id);
  }
}

function changePassword() {
  const thisSession = Session.get();

  if (thisSession?.login_url) {
    window.location = thisSession.login_url + loginServer.path.changePassword;
  }
}

const logoutBroadcastChannel = new BroadcastChannel('userLogoutChannel', {webWorkerSupport: false});

logoutBroadcastChannel.onmessage = event => {
  logout(true, null, event, true);
};

function logout(saveCurrent, loginPath, logoutPath, broadcasted = false) {
  if (window.frameId === 'legacy-iframe') {
    //skip, parent window will handle logout

    return;
  }

  clearApiTimestamps();

  const thisSession = Session.get();

  if (!logoutPath) {
    if (loginPath) {
      if (loginPath.includes('?')) {
        logoutPath = loginPath.split('?').join(`${loginServer.path.logout}?`);
      } else {
        logoutPath = loginPath + loginServer.path.logout; // If there is no query params simply concat two paths
      }
    } else {
      logoutPath = _.get(thisSession, 'login_url', loginServer.path.login) + loginServer.path.logout;
    }
  }

  // clear PCE session
  RestApiUtils.users.logoutFromPCE(thisSession.id);

  Session.clear();
  localStorage.removeItem('user_id');

  //Clear session
  webStorageUtils.clearSession();

  if (saveCurrent) {
    Session.saveUriForLoginRedirect();
  }

  if (!broadcasted) {
    logoutBroadcastChannel.postMessage(logoutPath);
  }

  window.location = logoutPath;
}

const whatsNewBroadcastChannel = new BroadcastChannel('whatsNewChannel');

whatsNewBroadcastChannel.onmessage = action => {
  actionCreators.closeWhatsNew(action.data, true);
};

// populate from the client code
export default createApiStore(
  [
    'USERS_LOGIN_',
    'OPTIONAL_FEATURES_GET_',
    'METHODS_WORKFLOW_COMPLETE_INVITE_USER_',
    'METHODS_WORKFLOW_COMPLETE_INVITE_USER_TO_ORG_',
    'NFCS_GET_COLLECTION_',
  ],
  {
    dispatchHandler(action) {
      switch (action.type) {
        case Constants.USERS_LOGIN_SUCCESS:
          login(action.data);
          break;

        case Constants.API_UNAUTHORIZED_FAIL:
          if (Session.isValid()) {
            logout(true, action.response.headers.get('x-redirect'));
          }

          break;

        case Constants.USER_LOGOUT:
          logout(true);

          return;

        case Constants.USER_CHANGE_PASSWORD:
          changePassword();

          return;

        case Constants.API_FORBIDDEN_FAIL:
          DialogStore.openDialog(ErrorUtils.getErrorAlert(action.type), true);

          break;

        case Constants.OPTIONAL_FEATURES_GET_SUCCESS:
          action.data.forEach(feature => {
            features[feature.name] = feature;
          });

          break;

        case Constants.VEN_SOFTWARE_RELEASES_GET_COLLECTION_SUCCESS:
          if (action.data.length) {
            features.venLibrary = true;
          }

          break;
        case Constants.NFCS_GET_COLLECTION_SUCCESS:
          if (action.data.length) {
            features.nfcs = true;
          }

          break;
        case Constants.KVPAIRS_GET_INSTANCE_SUCCESS:
          if (action.options.params.key === 'whats_new') {
            whatsNewCheckedVersion = action.data ?? null;
          }

          break;
        case Constants.CLOSE_WHATS_NEW:
          whatsNewCheckedVersion = action.data;

          if (!action.broadcasted) {
            whatsNewBroadcastChannel.postMessage({type: 'WHATSNEW_DISMISS', data: action.data});
          }

        case Constants.SETTINGS_CORE_SERVICES_GET_SUCCESS:
          coreServices = action.data;

          break;
        default:
          return true;
      }

      this.emitChange();

      return true;
    },

    getProduct() {
      return Session.get().product_version.product_name;
    },

    getVersion() {
      return Session.get().product_version.version;
    },

    getUIVersion() {
      return getVersion(process.env.UI_VERSION);
    },

    getParsedVersion() {
      const version = this.getVersion();

      return version.match(/^(?<major>\d+)\.(?<minor>\d+)\.(?<patch>\d+).*/).groups;
    },

    getParsedVersionNumber() {
      return _.mapValues(this.getParsedVersion(), Number);
    },

    getParsedUIVersionNumber() {
      return parseVersion(this.getUIVersion());
    },

    getHelpVersion() {
      // For help documentation version, we ignore the month part of the version
      // Backend will return version : 19.3.0 [major.minor.patch] from file: product_version.yml, documentation
      // will use this format. We pull out major and minor versions to create the helpversion used in url
      const product = this.getProduct();
      const {major, minor} = this.getParsedVersion();

      return `${product}/${major}.${minor}/Content`;
    },

    getHelpLink() {
      return 'https://docs.illumio.com';
    },

    getSupportLink() {
      return Session.get().support_url;
    },

    hasValidSession() {
      return Session.isValid();
    },

    getSession() {
      return Session.get();
    },

    getUserId() {
      const session = Session.get();

      return session && session.user_id.toString();
    },

    getUserHref() {
      const session = Session.get();

      return session && session.href;
    },

    getUserPermissions() {
      return Session.getUserOrgInfo().permissions;
    },

    getUserRoles() {
      return Session.getUserRoles();
    },

    getOrgName() {
      return Session.getUserOrgInfo()?.display_name;
    },

    isUserAdmin() {
      return Session.getUserRoles().includes('admin');
    },

    isUserOwner() {
      return Session.getUserRoles().includes('owner');
    },

    isUserReadOnly() {
      return Session.getUserRoles().includes('read_only');
    },

    isUserSysAdmin() {
      return Session.isUserSystemAdmin();
    },

    isUserOnlySysAdmin() {
      return Session.isUserSystemAdmin() && Session.get().orgs.length === 1;
    },

    getSessionTimeout() {
      return Session.getSessionTimeout();
    },

    getCertExpiration() {
      return Session.getCertExpiration();
    },

    isCertSelfSigned() {
      return Session.getCertType();
    },

    isHealthEnabled() {
      return Session.healthEnabled() && !this.isUserScoped();
    },

    isPCESaaS() {
      // If Health is disabled, it means that the PCE is on SaaS which is not Supercluster
      return !Session.healthEnabled();
    },

    getClusterType() {
      return Session.getClusterType();
    },

    isSuperclusterLeader() {
      return Session.getClusterType() === 'leader';
    },

    isSuperclusterMember() {
      return Session.getClusterType() === 'member';
    },

    isFlowAnalyticsEnabled() {
      return Session.flowAnalyticsEnabled();
    },

    areVulnerabilitiesEnabled() {
      return features.vulnerability_analytics && features.vulnerability_analytics.enabled;
    },

    isLDAPEnabled() {
      return features.ldap_authentication_enabled && features.ldap_authentication_enabled.enabled;
    },

    arePkiAuthenticationEnabled() {
      return features.pki_authentication_enabled && features.pki_authentication_enabled.enabled;
    },

    // Ransomware readiness dashboard
    isRansomwareReadinessEnabled() {
      return features.ransomware_readiness_dashboard && features.ransomware_readiness_dashboard.enabled;
    },

    // Label edit warnings for enforced workloads
    isLabelEditWarningForEnforcedWorkloadsEnabled() {
      return (
        features.labels_editing_warning_for_enforcement_mode &&
        features.labels_editing_warning_for_enforcement_mode.enabled
      );
    },

    // Label edit warnings for enforced workloads
    isWindowsOutboundProcessEnforcementEnabled() {
      return features.windows_outbound_process_enforcement && features.windows_outbound_process_enforcement.enabled;
    },

    isReportingEnabled() {
      return features.reporting && features.reporting.enabled;
    },

    isCoreServicesSettingsEnabled() {
      return (this.isUserAdmin() || this.isUserOwner()) && features.core_services && features.core_services.enabled;
    },

    isCoreServicesEnabled() {
      return this.isCoreServicesSettingsEnabled() && coreServices?.enabled;
    },

    isLabelSettingsPageEnabled() {
      return __TARGET__ === 'core' && !this.isSuperclusterMember() && (this.isUserAdmin() || this.isUserOwner());
    },

    isVenLibraryEnabled() {
      return features.venLibrary && (!this.isUserScoped() || this.isUserWorkloadManager());
    },

    isAsyncExplorerEnabled() {
      return features.async_traffic_queries && features.async_traffic_queries.enabled;
    },

    isIlluminationApiEnabled() {
      return features.illumination?.enabled;
    },

    isIlluminationClassicEnabled() {
      return features.illumination_classic?.enabled;
    },

    isUserExternal() {
      return Session.isUserExternal();
    },

    isNetworkEnforcementNodeEnabled() {
      return features.network_enforcement_node && features.network_enforcement_node.enabled;
    },

    isIPForwardingEnabled() {
      return features?.ip_forwarding_firewall_setting?.enabled ?? false;
    },

    isUserWorkloadManager() {
      return Session.getUserRoles().includes('workload_manager');
    },

    isUserOnlyWorkloadManager() {
      return Session.getUserRoles().every(role => role === 'workload_manager');
    },

    isUserRulesetManager() {
      return (
        Session.getUserRoles().includes('ruleset_manager') || Session.getUserRoles().includes('limited_ruleset_manager')
      );
    },

    isUserOnlyLimitedRulesetManager() {
      return (
        !Session.getUserRoles().includes('ruleset_manager') &&
        Session.getUserRoles().includes('limited_ruleset_manager')
      );
    },

    isUserRulesetViewer() {
      return Session.getUserRoles().includes('ruleset_viewer');
    },

    isUserRulesetProvisioner() {
      return Session.getUserRoles().includes('ruleset_provisioner');
    },

    isUserScoped() {
      return !this.isUserSysAdmin() && Session.getUserRoles().every(role => scopedRoles.has(role));
    },

    isIlluminationMapEnabled() {
      return !this.isUserScoped() && this.isIlluminationApiEnabled();
    },

    isWorkloadEditEnabled() {
      return this.isUserAdmin() || this.isUserOwner() || this.isUserWorkloadManager();
    },

    isTrafficEnabled() {
      return (
        this.isUserAdmin() ||
        this.isUserOwner() ||
        this.isUserReadOnly() ||
        this.isUserRulesetViewer() ||
        this.isUserRulesetManager() ||
        this.isUserRulesetProvisioner()
      );
    },

    isGlobalRuleWritingEnabled() {
      return this.isUserAdmin() || this.isUserOwner();
    },

    isRuleWritingEnabled() {
      return !this.isSuperclusterMember() && (this.isUserAdmin() || this.isUserOwner() || this.isUserRulesetManager());
    },

    isExplorerEnabled() {
      return this.isTrafficEnabled() && this.isFlowAnalyticsEnabled();
    },

    isVirtualServiceEditEnabled() {
      return this.isUserAdmin() || this.isUserOwner();
    },

    isInfrastructureEnabled() {
      return !this.isUserScoped();
    },

    isEventsEnabled() {
      return !this.isUserScoped();
    },

    isSegmentationTemplatesEnabled() {
      return !this.isUserScoped();
    },

    isSettingsEnabled() {
      return !this.isUserScoped();
    },

    isLoadBalancerEnabled() {
      return !this.isUserScoped() && features.nfcs;
    },

    isPairingProfilesEnabled() {
      return !this.isUserScoped() || this.isUserWorkloadManager();
    },

    isGlobalEditEnabled() {
      return this.isUserAdmin() || this.isUserOwner();
    },

    isAccessRestrictionEnabled() {
      return this.isUserAdmin() || this.isUserOwner();
    },

    isUserWithReducedScope() {
      const roles = Session.getUserRoles();

      return roles.includes('workload_manager') && roles.length === 1 && !this.isUserSysAdmin();
    },

    canUserCUDExtraScopeRules() {
      const roles = Session.getUserRoles();

      return roles.includes('admin') || roles.includes('owner') || roles.includes('ruleset_manager');
    },

    canUserManageAndProvisionRuleSets() {
      return (
        this.isUserAdmin() || this.isUserOwner() || (this.isUserRulesetManager() && this.isUserRulesetProvisioner())
      );
    },

    canUserViewEnforcementBoundaries() {
      const roles = Session.getUserRoles();

      return (
        roles.includes('admin') ||
        roles.includes('owner') ||
        roles.includes('global_object_provisioner') ||
        roles.includes('read_only')
      );
    },

    getTheme() {
      return webStorageUtils.getItem('theme') ?? 'lightning';
    },

    // flag for supporting K8s - see https://jira.illum.io/browse/EYE-90904
    isKubernetesSupported() {
      return true;
    },

    // flag for UI Toggle - see https://jira.illum.io/browse/EYE-96054
    isUIToggleEnabled() {
      return false;
    },

    isWhatsNewEnabled() {
      const version = this.getParsedUIVersionNumber();

      return (
        !whatsNewCheckedVersion ||
        whatsNewCheckedVersion.major < version.major ||
        whatsNewCheckedVersion.minor < version.minor ||
        whatsNewCheckedVersion.patch < version.patch
      );
    },

    getWhatsNewDocUrl() {
      const origin = this.getHelpLink();
      const {major, minor} = this.getParsedUIVersionNumber();
      const product = this.getProduct();

      return `${origin}/${product}/${major}.${minor}/Content/LandingPages/Guides/whats-new-in-${major}-${minor}.htm`;
    },
  },
);
