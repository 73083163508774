/**
 * Copyright 2014 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import React from 'react';
import {NotFoundRoute, Redirect, Route} from 'react-router';
import Main from './Main.jsx';
import {
  AppMap,
  Components,
  ConnectivityCheck,
  FormComponents,
  JumpToNew,
  MapPage,
  PageNotFound,
  ResourceNotFound,
  TrafficEvents,
  TrafficParameters,
} from './pages';

// When defining the routes below, the create page must precede the detail page for proper routing
import {HelpMenuPopoutContainer} from './pages/HelpMenu';
import {
  GroupContainerWorkloads,
  GroupExplorer,
  GroupPairingProfiles,
  GroupRules,
  GroupVirtualServers,
  GroupVirtualServices,
  GroupVulnerabilities,
  GroupWorkloads,
} from './pages/Groups';
import {RulesetScopesAndRules} from './pages/Rulesets';
import {SegmentationTemplateDetail, SegmentationTemplateList} from './pages/SegmentationTemplates';
import {AppGroupType} from './pages/AppGroups';
import {Explorer} from './pages/Explorer';
import {WorkloadVulnerabilities} from './pages/Workloads';
import {
  ExtraScopeChoose,
  ExtraScopeConfigure,
  ExtraScopePreview,
  ExtraScopeSuccess,
  IntraScopeConfigure,
  IntraScopePreview,
  IntraScopeSuccess,
  IpListChoose,
  IpListConfigure,
  IpListPreview,
  IpListSuccess,
  RingFenceChoose,
  RingFencePreview,
  RingFenceSuccess,
  SelectAppGroup,
} from './pages/PolicyGenerator';
import {RuleSearch} from './pages/RuleSearch';

const Routes = (
  <Route name="app" path="/" handler={Main}>
    <Route path="/groups/:group/?" name="groups.view" handler={JumpToNew} />
    <Route path="/groups/wizard/?:step" name="groups.create" handler={JumpToNew} />
    <Route path="/groups/:id/wizard/?:step" name="groups.edit" handler={JumpToNew} />

    <Route path="/" name="landing" handler={JumpToNew} />
    <Route path="/welcome" name="welcome" handler={JumpToNew} />
    <Route path="/helpmenuold/?" name="helpmenu" handler={HelpMenuPopoutContainer} />

    <Route path="/offlinetimers/?" name="offlineTimers.server" handler={JumpToNew} />
    <Route path="/offlinetimers/edit?" name="offlineTimers.serverEdit" handler={JumpToNew} />

    <Route path="/myprofile/?" name="myprofile" handler={JumpToNew} />

    <Route path="/mymanagedtenants/?" name="mymanagedtenants.list" handler={JumpToNew} />
    <Route path="/mymanagedtenants/create/?" name="mymanagedtenants.create" handler={JumpToNew} />
    <Route path="/mymanagedtenants/:id/edit/?" name="mymanagedtenants.item.edit" handler={JumpToNew} />
    <Route path="/mymanagedtenants/:id/?" name="mymanagedtenants.item" handler={JumpToNew} />

    <Route path="/myroles/?" name="myroles.list" handler={JumpToNew} />

    <Route path="/apikeys/?" name="apikeys.list" handler={JumpToNew} />

    <Route path="/apikeysettings/?" name="apiKeySettings.view" handler={JumpToNew} />
    <Route path="/apikeysettings/edit?" name="apiKeySettings.edit" handler={JumpToNew} />

    <Route path="/securitysettings/:section?:mode?:grid?" name="securitysettings.section" handler={JumpToNew} />

    <Route path="/essentialservicerules/?" name="essentialservicerules" handler={JumpToNew} />
    <Route path="/venoperations/?" name="venoperations" handler={JumpToNew} />
    <Route path="/venoperations/edit/?" name="venoperations.edit" handler={JumpToNew} />
    <Route path="/policysettings/?" name="policySettings.detail" handler={JumpToNew} />
    <Route path="/policysettings/edit?" name="policySettings.edit" handler={JumpToNew} />

    <Route path="/pending/?" name="pending" handler={JumpToNew} />
    <Route path="/provision/?" name="provision" handler={JumpToNew} />
    <Route path="/provisioning/?" name="provisioning" handler={JumpToNew} />

    <Route path="/segmentationtemplates/?" name="segmentationTemplates.list" handler={SegmentationTemplateList} />
    <Route
      path="/segmentationtemplates/:id/?"
      name="segmentationTemplate.detail"
      handler={SegmentationTemplateDetail}
    />

    <Route path="/rbac/users/local/?" name="rbac.users.local.list" handler={JumpToNew} />
    <Route path="/rbac/users/external/?" name="rbac.users.external.list" handler={JumpToNew} />
    <Route path="/rbac/users/external/groups/?" name="rbac.users.external.groups.list" handler={JumpToNew} />
    <Route path="/rbac/users/local/:id/?" name="users.local.detail" handler={JumpToNew} />
    <Route path="/rbac/users/external/:id/?" name="users.external.detail" handler={JumpToNew} />
    <Route path="/rbac/users/external/groups/:id/?" name="users.external.groups.detail" handler={JumpToNew} />

    <Route path="/rbac/serviceaccounts/?" name="rbac.serviceAccounts.list" handler={JumpToNew} />
    <Route path="/rbac/serviceaccounts/:id/edit/?" name="rbac.serviceAccounts.item.edit" handler={JumpToNew} />
    <Route path="/rbac/serviceaccounts/create/?" name="rbac.serviceAccounts.create" handler={JumpToNew} />
    <Route path="/rbac/serviceaccounts/:id/?" name="rbac.serviceAccounts.item" handler={JumpToNew} />

    <Route path="/rbac/users/activity/?" name="rbac.users.activity.list" handler={JumpToNew} />
    <Route path="/rbac/users/activity/:id/?" name="users.activity.detail" handler={JumpToNew} />

    <Route path="/rbac/roles/global/?" name="rbac.roles.global.list" handler={JumpToNew} />
    <Route path="/rbac/roles/scope/?" name="rbac.roles.scope.list" handler={JumpToNew} />
    <Route path="/rbac/accesswizard/?" name="rbac.accesswizard" handler={JumpToNew} />

    <Route path="/landing/groups/:id/:pversion?" name="groupView" handler={JumpToNew} />

    <Route path="/reporting/downloads/?" name="reporting.downloads.list" handler={JumpToNew} />
    <Route path="/reporting/recurrence/?" name="reporting.recurrence.list" handler={JumpToNew} />

    <Route path="/rulesets/?" name="rulesets.list" handler={JumpToNew} />
    <Route path="/rulesets/:id/:pversion/:tab?" name="rulesets.item" handler={JumpToNew} />
    <Route
      path="/rulesets/rulesearch?:pversion?:search?:view?:providersOrConsumers?"
      name="rulesets.ruleSearch"
      handler={JumpToNew}
    />
    <Route path="/boundaries/?" name="boundaries.list" handler={JumpToNew} />
    <Route path="/boundaries/create/?" name="boundaries.create" handler={JumpToNew} />
    <Route path="/boundaries/enforcement/:id/:pversion/?" name="boundaries.item" handler={JumpToNew} />
    <Route path="/boundaries/enforcement/:id/:pversion/edit/?" name="boundaries.item.edit" handler={JumpToNew} />
    <Route
      path="/boundaries/enforcement/:id/:pversion/explorer/?"
      name="boundariesEnforcementExplorer"
      handler={GroupExplorer}
    />
    <Route
      path="/boundaries/enforcement/:id/:pversion/rulesearch/?"
      name="boundariesEnforcementRuleSearch"
      handler={RuleSearch}
    />
    <Route
      path="/boundaries/enforcement/:id/:pversion/proposedrules/?"
      name="boundariesEnforcementProposedRules"
      handler={RulesetScopesAndRules}
    />

    <Route path="/trustedproxy/?" name="trustedproxy.list" handler={JumpToNew} />

    <Route path="/corporatepublicips/?" name="corporatepublicips.list" handler={JumpToNew} />

    <Route path="/workloads/vens/?" name="workloads.vens.list" handler={JumpToNew} />
    <Route path="/workloads/vens/:id/?" name="workloads.vens.item" handler={JumpToNew} />
    <Route path="/venlibrary/?" name="venLibrary" handler={JumpToNew} />

    <Route path="/workloads/containers/?" name="workloads.containers.list" handler={JumpToNew} />
    <Route path="/workloads/containers/:id/?" name="containerWorkload" handler={JumpToNew} />
    <Route path="/workloads/containers/:id/containers/?" name="containerWorkloadContainers" handler={JumpToNew} />
    <Route path="/workloads/containers/:id/rules/?" name="containerWorkloadRules" handler={JumpToNew} />

    <Route path="/workloads/?" name="workloads.list" handler={JumpToNew} />
    <Route path="/workloads/create/?" name="workloadCreate" handler={JumpToNew} />
    <Route path="/workloads/:id/?" name="workloads.item" handler={JumpToNew} />
    <Route path="/workloads/:id/edit/?" name="workloadEdit" handler={JumpToNew} />
    <Route path="/workloads/:id/editnew/?" name="workloads.item.editnew" handler={JumpToNew} />
    <Route path="/workloads/:id/services/?" name="workloadServices" handler={JumpToNew} />
    <Route path="/workloads/:id/rules/?" name="workloadRules" handler={JumpToNew} />
    <Route path="/workloads/:id/boundaries/?" name="workloadBoundaries" handler={JumpToNew} />
    <Route path="/workloads/:id/virtualServices/?" name="workloadVirtualServices" handler={JumpToNew} />
    <Route path="/workloads/:id/report/?" name="workloadReport" handler={JumpToNew} />
    <Route path="/workloads/:id/vulnerabilities/?" name="workloadVulnerabilities" handler={WorkloadVulnerabilities} />
    <Route path="/workloads/:id/ransomwareprotection" name="workloadRansomwareProtection" handler={JumpToNew} />

    <Route path="/services/?" name="services.list" handler={JumpToNew} />
    <Route path="/services/:id/:pversion/edit/?" name="services.item.edit" handler={JumpToNew} />
    <Route path="/services/create/?" name="services.create" handler={JumpToNew} />
    <Route path="/services/:id/:pversion/?" name="services.item" handler={JumpToNew} />

    <Route path="/virtualservices/?" name="virtualServices.list" handler={JumpToNew} />
    <Route path="/virtualservices/:id/:pversion/?" name="virtualServices.item" handler={JumpToNew} />
    <Route path="/virtualservices/create/?" name="virtualServices.create" handler={JumpToNew} />
    <Route path="/virtualservices/:id/edit/?" name="virtualServices.item.edit" handler={JumpToNew} />
    <Route path="/virtualservices/:id/:pversion/workloads/?" name="virtualServiceWorkloads" handler={JumpToNew} />
    <Route
      path="/virtualservices/:id/:pversion/workloads/add/?"
      name="virtualServiceAddWorkloads"
      handler={JumpToNew}
    />
    <Route path="/virtualservices/:id/:pversion/containers/?" name="virtualServiceContainers" handler={JumpToNew} />
    <Route path="/virtualservices/:id/:pversion/?" name="virtualService" handler={JumpToNew} />

    <Route path="/labels/?" name="labels.list" handler={JumpToNew} />
    <Route path="/labels/create/?" name="labels.create" handler={JumpToNew} />
    <Route path="/labels/:id/edit/?" name="labels.item.edit" handler={JumpToNew} />
    <Route path="/labels/:id/?" name="labels.item" handler={JumpToNew} />

    <Route path="/labelgroups/?" name="labelGroups.list" handler={JumpToNew} />
    <Route path="/labelgroups/create/?" name="labelGroupCreate" handler={JumpToNew} />
    <Route path="/labelgroups/:id/:pversion?" name="labelGroups.item" handler={JumpToNew} />
    <Route path="/labelgroups/:id/summary/edit/?" name="labelGroupSummaryEdit" handler={JumpToNew} />
    <Route path="/labelgroups/:id/:pversion/:tab/?" name="labelGroupTab" handler={JumpToNew} />
    <Route path="/labelgroups/:id/:pversion/:tab/add/?" name="labelGroupAdd" handler={JumpToNew} />

    <Route path="/labelsettings/?" name="labelsettings.view" handler={JumpToNew} />

    <Route path="/pairingprofiles/?" name="pairingProfiles.list" handler={JumpToNew} />
    <Route path="/pairingprofiles/create?" name="pairingProfiles.create" handler={JumpToNew} />
    <Route path="/pairingprofiles/pair/?" name="pairingProfiles.pair" handler={JumpToNew} />
    <Route path="/pairingprofiles/:id/?" name="pairingProfiles.item" handler={JumpToNew} />
    <Route path="/pairingprofiles/:id/view/?" name="pairingProfiles.item.view" handler={JumpToNew} />
    <Route path="/pairingprofiles/:id/edit/?" name="pairingProfiles.item.edit" handler={JumpToNew} />

    {__DEV__ ? <Route path="/secureconnectgateway/?" name="secureconnectgateways" handler={JumpToNew} /> : null}
    <Route path="/secureconnectgateways/?" name="secureGateways.list" handler={JumpToNew} />
    <Route path="/secureconnectgateways/create/?" name="secureGatewayCreate" handler={JumpToNew} />
    <Route path="/secureconnectgateways/:id/edit/?" name="secureGatewayEdit" handler={JumpToNew} />
    <Route path="/secureconnectgateways/:id/:pversion/?" name="secureGateway" handler={JumpToNew} />

    <Route path="/flowcollection/?" name="flowCollectionFilters.list" handler={JumpToNew} />
    <Route path="/flowcollection/create/?" name="flowCollectionFilters.create" handler={JumpToNew} />
    <Route path="/flowcollection/:id/edit/?" name="flowCollectionFilters.edit" handler={JumpToNew} />

    <Route path="/accessrestrictions/?" name="accessRestrictions.list" handler={JumpToNew} />
    <Route path="/accessrestrictions/create/?" name="accessRestrictions.create" handler={JumpToNew} />
    <Route path="/accessrestrictions/:id/?" name="accessRestrictions.item" handler={JumpToNew} />
    <Route path="/accessrestrictions/:id/view/?" name="accessRestrictions.item.view" handler={JumpToNew} />
    <Route path="/accessrestrictions/:id/edit/?" name="accessRestrictions.item.edit" handler={JumpToNew} />

    <Route path="/iplists/?" name="iplists.list" handler={JumpToNew} />
    <Route path="/iplists/create/?" name="iplists.create" handler={JumpToNew} />
    <Route path="/iplists/:id/edit/?" name="iplists.edit" handler={JumpToNew} />
    <Route path="/iplists/:id/:pversion/?" name="iplists.item" handler={JumpToNew} />

    <Route path="/exportreports/?" name="reports.list" handler={JumpToNew} />

    <Route path="/usergroups/?" name="userGroups.list" handler={JumpToNew} />
    <Route path="/usergroups/create/?" name="userGroupCreate" handler={JumpToNew} />
    <Route path="/usergroups/:id/?" name="userGroup" handler={JumpToNew} />
    <Route path="/usergroups/:id/edit/?" name="userGroupEdit" handler={JumpToNew} />

    <Route path="/mapgroups/:id/workloads/:filter?/?" name="groupWorkloads" handler={GroupWorkloads} />
    <Route
      path="/mapgroups/:id/containerworkloads/:filter?/?"
      name="groupContainerWorkloads"
      handler={GroupContainerWorkloads}
    />
    <Route path="/mapgroups/:id/rules/?" name="groupRules" handler={GroupRules} />
    <Route path="/mapgroups/:id/pairingprofiles/?" name="groupPairingProfiles" handler={GroupPairingProfiles} />
    <Route path="/mapgroups/:id/virtualservices/?" name="groupVirtualServices" handler={GroupVirtualServices} />

    <Route path="/appgroups/:id/workloads/?:filter?/?" name="appGroupWorkloads" handler={GroupWorkloads} />
    <Route path="/appgroups/:id/members/?" name="appGroupMembers" handler={GroupWorkloads} />
    <Route
      path="/appgroups/:id/containerworkloads/?:filter?/?"
      name="appGroupContainerWorkloads"
      handler={GroupContainerWorkloads}
    />
    <Route path="/appgroups/:id/rules/?" name="appGroupRules" handler={GroupRules} />
    <Route path="/appgroups/:id/pairingprofiles/?" name="appGroupPairingProfiles" handler={GroupPairingProfiles} />
    <Route path="/appgroups/:id/vulnerabilities/?" name="appGroupVulnerabilities" handler={GroupVulnerabilities} />
    <Route path="/appgroups/:id/virtualservices/?" name="appGroupVirtualServices" handler={GroupVirtualServices} />
    <Route path="/appgroups/:id/virtualservers/?" name="appGroupVirtualServers" handler={GroupVirtualServers} />
    <Route path="/appgroups/:id/explorer/?" name="appGroupExplorer" handler={GroupExplorer} />
    <Route path="/appgroups/:id/illuminationplus/?" name="appGroupIllumination" handler={JumpToNew} />
    <Route path="/appgroups/:id/ransomwareprotection/?" name="appGroupRansomwareProtection" handler={JumpToNew} />
    <Route
      path="/appgroups/:id/proposedRules/:uuid?"
      name="appGroupExplorerProposedRules"
      handler={RulesetScopesAndRules}
    />
    <Route path="/appgroups/:id/policygenerator/?" name="appGroupPolicyGenerator" handler={SelectAppGroup} />
    <Route
      path="/appgroups/:id/policygenerator/intrascope/configure/?"
      name="appGroupIntraScopeConfigure"
      handler={IntraScopeConfigure}
    />
    <Route
      path="/appgroups/:id/policygenerator/intrascope/preview/?"
      name="appGroupIntraScopePreview"
      handler={IntraScopePreview}
      noAutoLogout
    />
    <Route
      path="/appgroups/:id/policygenerator/intrascope/success/?"
      name="appGroupIntraScopeSuccess"
      handler={IntraScopeSuccess}
    />
    <Route
      path="/appgroups/:id/policygenerator/extrascope/choose/?"
      name="appGroupExtraScopeChoose"
      handler={ExtraScopeChoose}
    />
    <Route
      path="/appgroups/:id/policygenerator/extrascope/configure/?"
      name="appGroupExtraScopeConfigure"
      handler={ExtraScopeConfigure}
    />
    <Route
      path="/appgroups/:id/policygenerator/extrascope/preview/?"
      name="appGroupExtraScopePreview"
      handler={ExtraScopePreview}
      noAutoLogout
    />
    <Route
      path="/appgroups/:id/policygenerator/extrascope/success/?"
      name="appGroupExtraScopeSuccess"
      handler={ExtraScopeSuccess}
    />
    <Route path="/appgroups/:id/policygenerator/iplist/choose/?" name="appGroupIpListChoose" handler={IpListChoose} />
    <Route
      path="/appgroups/:id/policygenerator/iplist/configure/?"
      name="appGroupIpListConfigure"
      handler={IpListConfigure}
    />
    <Route
      path="/appgroups/:id/policygenerator/iplist/preview/?"
      name="appGroupIpListPreview"
      handler={IpListPreview}
      noAutoLogout
    />
    <Route
      path="/appgroups/:id/policygenerator/iplist/success/?"
      name="appGroupIpListSuccess"
      handler={IpListSuccess}
    />

    <Route path="/versions/?" name="versions.list" handler={JumpToNew} />
    <Route path="/versions/:pversion/?" name="versions.item.detail" handler={JumpToNew} />

    <Route path="/health/?" name="health.list" handler={JumpToNew} />
    <Route path="/health/:fqdn/?" name="healthDetail" handler={JumpToNew} />

    <Route path="/switches/?" name="switches.list" handler={JumpToNew} />
    <Route path="/switches/create?" name="switches.create" handler={JumpToNew} />
    <Route path="/switches/:id/?" name="switches.item.summary" handler={JumpToNew} />
    <Route path="/switches/:id/edit/?" name="switches.item.edit" handler={JumpToNew} />

    <Route path="/coreservices/?" name="coreservices.list" handler={JumpToNew} />
    <Route path="/coreservices/:id?:tab?" name="coreservices.item" handler={JumpToNew} />
    <Route path="/coreservicesettings/?" name="coreservicesettings" handler={JumpToNew} />

    <Route path="/loadbalancers/?" name="loadBalancers.list" handler={JumpToNew} />
    <Route path="/loadbalancers/:id/edit/?" name="loadBalancerEdit" handler={JumpToNew} />
    <Route path="/loadbalancers/:id/:tab/?" name="loadBalancerView" handler={JumpToNew} />
    <Route path="/loadbalancers/create/?" name="loadBalancersCreate" handler={JumpToNew} />

    <Route path="/containerclusters/?" name="containerClusters.list" handler={JumpToNew} />
    <Route path="/containerclusters/create/?" name="containerClusters.create" handler={JumpToNew} />
    <Route path="/containerclusters/:id/summary/?" name="containerClusters.item" handler={JumpToNew} />
    <Route path="/containerclusters/:id/profiles/?" name="containerClusters.item.profiles.list" handler={JumpToNew} />
    <Route
      path="/containerclusters/:id/profiles/create/?"
      name="containerClusters.item.profiles.create"
      handler={JumpToNew}
    />
    <Route
      path="/containerclusters/:id/profiles/:profileId/?"
      name="containerClusters.item.profiles.item"
      handler={JumpToNew}
    />
    <Route
      path="/containerclusters/:id/profiles/:profileId/edit?"
      name="containerClusters.item.profiles.edit"
      handler={JumpToNew}
    />
    <Route path="/containerclusters/:id/workloads/?" name="containerClusters.item.workloads" handler={JumpToNew} />
    <Route
      path="/containerclusters/:id/containerworkloads/?"
      name="containerClusters.item.containerWorkloads"
      handler={JumpToNew}
    />
    <Route
      path="/containerclusters/:id/serviceBackends/?"
      name="containerClusters.item.serviceBackends"
      handler={JumpToNew}
    />
    <Route path="/containerclusters/:id/edit/?" name="containerClusters.item.edit" handler={JumpToNew} />

    <Route path="/networks/?" name="networks.list" handler={JumpToNew} />

    <Route path="/virtualservers/?" name="virtualServers.list" handler={JumpToNew} />
    <Route path="/virtualservers/:id/summary/edit/?" name="virtualServerSummaryEdit" handler={JumpToNew} />
    <Route path="/virtualservers/:id/members/edit/?" name="virtualServerMembersEdit" handler={JumpToNew} />
    <Route path="/virtualservers/:id/:pversion/summary/?" name="virtualServers.item" handler={JumpToNew} />
    <Route path="/virtualservers/:id/:pversion/members/?" name="virtualServers.item.members.view" handler={JumpToNew} />
    <Route path="/virtualservers/:id/:pversion/rules/?" name="virtualServers.item.rules" handler={JumpToNew} />
    <Route path="/virtualservers/:id/:pversion/:tab/?" name="virtualServerTab" handler={JumpToNew} />

    <Route path="/events/?" name="events" handler={JumpToNew} />
    <Route path="/events/:section/?" name="events.list" handler={JumpToNew} />
    <Route path="/events/list/:id/?" name="events.detail" handler={JumpToNew} />

    <Route path="/events/config/:mode?" name="events.config.detail" handler={JumpToNew} />
    <Route path="/events/config/destination/add/?" name="events.config.destination.add" handler={JumpToNew} />
    <Route path="/events/config/destination/:id/edit/?" name="events.config.destination.edit" handler={JumpToNew} />

    <Route path="/trafficevents/?" name="trafficevents" handler={TrafficEvents} />
    <Route path="/connectivity/?" name="connectivity" handler={ConnectivityCheck} />
    <Route path="/api/?" name="apiexplorer" handler={JumpToNew} />

    <Route path="/map/?" name="map" handler={MapPage} />
    <Route path="/map/:type/:id/?" name="mapLevel" handler={MapPage} />
    <Route path="/map/:prevtype/:previd/:type/:id/?" name="prevMapLevel" handler={MapPage} />

    <Route path="/appgroup/?" name="appMap" handler={AppMap} />
    <Route path="/appgroups/:id/appgroup/:type/?" name="appMapLevel" handler={AppMap} />
    <Route path="/appgroups/:id/appgroup/:prevtype/:previd/:type/?" name="prevAppMapLevel" handler={AppMap} />

    <Route path="/appgrouptype/?" name="appGroupType" handler={AppGroupType} />

    <Route path="/appgroupslist/?" name="appGroupList" handler={JumpToNew} />

    <Route path="/supportbundles/?" name="supportBundles" handler={JumpToNew} />
    <Route path="/supportbundles/pce/?" name="supportBundles.pce.list" handler={JumpToNew} />
    <Route path="/supportbundles/ven/?" name="supportBundles.ven.list" handler={JumpToNew} />

    <Route path="outboundpolicy/?" name="outboundpolicy" handler={JumpToNew} />

    <Route path="/policygenerator/?" name="policygenerator" handler={SelectAppGroup} />
    <Route path="/policygenerator/intrascope/configure/?" name="intraScopeConfigure" handler={IntraScopeConfigure} />
    <Route
      path="/policygenerator/intrascope/preview/?"
      name="intraScopePreview"
      handler={IntraScopePreview}
      noAutoLogout
    />
    <Route path="/policygenerator/intrascope/success/?" name="intraScopeSuccess" handler={IntraScopeSuccess} />
    <Route path="/policygenerator/extrascope/choose/?" name="extraScopeChoose" handler={ExtraScopeChoose} />
    <Route path="/policygenerator/extrascope/configure/?" name="extraScopeConfigure" handler={ExtraScopeConfigure} />
    <Route
      path="/policygenerator/extrascope/preview/?"
      name="extraScopePreview"
      handler={ExtraScopePreview}
      noAutoLogout
    />
    <Route path="/policygenerator/extrascope/success/?" name="extraScopeSuccess" handler={ExtraScopeSuccess} />
    <Route path="/policygenerator/iplist/choose/?" name="ipListChoose" handler={IpListChoose} />
    <Route path="/policygenerator/iplist/configure/?" name="ipListConfigure" handler={IpListConfigure} />
    <Route path="/policygenerator/iplist/preview/?" name="ipListPreview" handler={IpListPreview} noAutoLogout />
    <Route path="/policygenerator/iplist/success/?" name="ipListSuccess" handler={IpListSuccess} />
    <Route path="/policygenerator/segmentation/choose/?" name="ringFenceChoose" handler={RingFenceChoose} />
    <Route
      path="/policygenerator/segmentation/preview/?"
      name="ringFencePreview"
      handler={RingFencePreview}
      noAutoLogout
    />
    <Route path="/policygenerator/segmentation/success/?" name="ringFenceSuccess" handler={RingFenceSuccess} />

    <Route path="/componentsold/forms/?" name="formcomponents" handler={FormComponents} />
    <Route path="/componentsold/?" name="componentsold" handler={Components} />
    <Route path="/components/list?" name="components.list" handler={JumpToNew} />
    <Route path="/components/forms?" name="components.forms" handler={JumpToNew} />
    <Route path="/components/diff?" name="components.diff" handler={JumpToNew} />
    <Redirect path="/components/?" to="components.list" />
    <Route path="/versionmismatch/?" name="versionmismatch" handler={JumpToNew} />
    <Route path="/trafficparameters/?" name="trafficParameters" handler={TrafficParameters} />

    <Route path="/authenticationsettings/?" name="authenticationSettings.main" handler={JumpToNew} />

    <Route path="/explorer/?" name="explorer" handler={Explorer} />
    <Route path="/explorer/proposedrules/:uuid/?" name="explorerProposedRules" handler={RulesetScopesAndRules} />
    <Route path="/illuminationplus/?" name="illumination" handler={JumpToNew} />

    <Route path="/dashboard/?" name="dashboard" handler={JumpToNew} />

    <Route path="/ransomwareDashboard/?" name="ransomwareDashboard" handler={JumpToNew} />

    <Route path="/resourcenotfound/?" name="resourceNotFound" handler={ResourceNotFound} />

    <Route path="/cloudintegrations" name="cloudIntegrations" handler={JumpToNew} />

    <NotFoundRoute title="Page not found" handler={PageNotFound} />
  </Route>
);

// Map of routes props by compound key ({'app': {}, 'app.workloads': {}, 'app.policygenerator': {}})
export const RoutesMap = (function flattenRoutes(result, route, parentRoute) {
  let name = route.name;

  if (parentRoute) {
    name = `${parentRoute.name}.${name}`;
  }

  result.set(name, _.omit(route, ['children']));

  if (route.children) {
    React.Children.forEach(route.children, child => {
      if (child && child.props) {
        flattenRoutes(result, child.props, route);
      }
    });
  }

  return result;
})(new Map(), Routes.props);

export default Routes;
