/**
 * Copyright 2017 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import intl from '@illumio-shared/utils/intl';
import {PropTypes} from 'react';
import {Grid, Button} from '..';
import {ToolBar} from '../ToolBar';
import {GridDataUtils, HealthUtils, RenderUtils} from '../../utils';

import ReactCSSTransitionGroup from 'react-addons-css-transition-group';

RingFenceAppGroupGrid.propTypes = {
  appGroups: PropTypes.array,
  subtitle: PropTypes.string,
  onRowSelect: PropTypes.func,
  onRowClick: PropTypes.func,
  selection: PropTypes.array,
  sorting: PropTypes.array.isRequired,
  onSort: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
  hover: PropTypes.string,
  onHover: PropTypes.func,
  onUnhover: PropTypes.func,
};

export default function RingFenceAppGroupGrid(props) {
  const testGridData = props.appGroups.map(appGroup => ({
    caret: ' ',
    coverage: appGroup.intraGroupCoverage
      ? appGroup.intraGroupCoverage.num_rules / appGroup.intraGroupCoverage.num_services
      : 0,
    connectionsWithRules: appGroup.intraGroupCoverage ? appGroup.intraGroupCoverage.num_rules : 0,
    name: appGroup.name,
    href: appGroup.href,
    connections: intl('PolicyGenerator.Connections', {
      count: appGroup.intraGroupCoverage ? appGroup.intraGroupCoverage.num_services : 0,
    }),
    noConnections: !appGroup.intraGroupCoverage || !appGroup.intraGroupCoverage.num_services,
    lastCalculated: appGroup.lastCalculated,
    updated: appGroup.updated,
    caps: appGroup.caps,
  }));

  const rowSelectable = row =>
    !row.deleted &&
    row.update_type !== 'delete' &&
    row.name !== intl('PolicyGenerator.MissingLabels') &&
    row.caps.rulesets.includes('write');

  const columns = [
    {
      key: 'name',
      style: 'consuming-app-group',
      label: intl('Common.Name'),
      format: value => <span title={value}>{RenderUtils.truncateAppGroupName(value, 40, [20, 10, 10])}</span>,
      sortable: true,
      // The Grid component doesn't do case insensitive sorting by default
      sortValue: value => value.toLocaleLowerCase(),
    },
    {
      key: 'connections',
      style: 'connections',
      label: intl('Common.Connections'),
      sortable: true,
      // The value of row.connections would be of format "XX Connections" (Example: "52 Connections")
      // We want to sort on the number "XX" ("52" for above example)
      sortValue: value => Number(value.split(' ')[0]),
    },
    {
      key: 'coverage',
      style: 'coverage',
      label: intl('PolicyGenerator.Grid.Coverage'),
      format: (value, row) => {
        if (row.noConnections) {
          return intl('Common.None');
        }

        if (row.lastCalculated < row.updated) {
          return [
            <Button
              type="secondary"
              content="icon-only"
              icon="refresh"
              onClick={evt => props.onRefresh(evt, row.href)}
            />,
            <div className="PolicyGenerator-refresh">{intl('Common.Refresh')}</div>,
          ];
        }

        return (
          <div className="ConsumingAppGroupGrid-countBar">
            <div className="ConsumingAppGroupGrid-countBarContent">
              <div className="ConsumingAppGroupGrid-countBarLabel">
                {intl('PolicyGenerator.Grid.ConnectionWithRules', {count: row.connectionsWithRules})}
              </div>
              <div className="AppGroupCoverage-card-info-count-bar">
                <div className="AppGroupCoverage-card-info-count-bar-fill" style={{width: `${value * 100}%`}}>
                  <ReactCSSTransitionGroup
                    transitionName="AppGroupCoverage-card-info-count-bar-fill-value"
                    transitionAppear={true}
                    transitionAppearTimeout={5000}
                    transitionEnterTimeout={0}
                    transitionLeaveTimeout={0}
                  >
                    <div className="AppGroupCoverage-card-info-count-bar-fill-value" />
                  </ReactCSSTransitionGroup>
                </div>
              </div>
            </div>
          </div>
        );
      },
      sortable: true,
    },
    {
      key: 'lastCalculated',
      format: (value, row) => (
        <div
          className="PolicyGenerator-last-calculated"
          onMouseEnter={_.partial(props.onHover, row.href)}
          onMouseLeave={props.onUnhover}
        >
          {props.hover === row.href ? (
            <Button
              type="secondary"
              content="icon-only"
              icon="refresh"
              onClick={evt => props.onRefresh(evt, row.href)}
            />
          ) : (
            HealthUtils.getIllumincationSync(new Date(value).getTime())
          )}
        </div>
      ),
      style: 'date',
      label: intl('PolicyGenerator.LastCalculated'),
      sortable: true,
    },
  ];

  return (
    <div className="ConsumingAppGroupGrid-wrapper">
      <ToolBar>
        <div className="ConsumingAppGroupGrid-title-bar">
          <div className="ConsumingAppGroupGrid-title-section">
            <div className="ConsumingAppGroupGrid-title">{intl('PolicyGenerator.RingFence.ChooseApplications')}</div>
            {intl('PolicyGenerator.RingFence.Description')}
            <div className="ConsumingAppGroupGrid-subtitle">
              {intl('PolicyGenerator.Grid.NumberSelected', {subtitle: props.subtitle, count: props.selection.length})}
            </div>
          </div>
        </div>
      </ToolBar>
      <div className="ConsumingAppGroupGrid-grid">
        <Grid
          editable={true}
          editingId={props.editingId}
          idField="href"
          data={testGridData}
          columns={columns}
          selectable={true}
          selection={props.selection}
          onRowSelectToggle={props.onRowSelect}
          onRowClick={row => rowSelectable(row) && props.onRowClick(row)}
          rowClass={GridDataUtils.formatStatusRowClass}
          notificationUnderRow={true}
          rowSelectable={rowSelectable}
          sortable={true}
          sorting={props.sorting}
          onSort={props.onSort}
          filteredSelect={props.filteredSelect}
          onFilteredSelect={props.onFilteredSelect}
        />
      </div>
    </div>
  );
}
