/**
 * Copyright 2016 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import React from 'react';
import cx from 'classnames';
import intl from '@illumio-shared/utils/intl';
import Slider from 'react-rangeslider';
import {TrafficStore, SessionStore, OrgStore} from '../../stores';
import {PolicyGeneratorUtils, RenderUtils, GridDataUtils} from '../../utils';
import actionCreators from '../../actions/actionCreators';
import PolicyGeneratorLargeServices from '../../modals/PolicyGeneratorLargeServices.jsx';
import {RouterMixin, PolicyGeneratorMixin, StoreMixin} from '../../mixins';
import {ProgressBar, Button, Radio, RadioGroup, AlertDialog, Banner} from '../../components';
import {
  ConfigureExtraConnectionsGrid,
  CoverageProgressBar,
  PolicyGeneratorConfigGridFilter,
  TrafficFilter,
} from '../../components/PolicyGenerator';

function getStateFromStores() {
  const traffic = TrafficStore.getAllRoleTraffics();
  const state = this.getPolicyGeneratorValues() || {};
  const rulesetId = state.type === 'replace' && state.ruleset && state.ruleset.href.split('/').pop();

  if (_.isEmpty(state.extraConfig)) {
    state.extraConfig = {level: 'role', type: 'tiertotier'};
  }

  let newState = {...state};

  if (state.appGroup) {
    const roles = this.getAppGroupRoles(TrafficStore.getAllRoleNodes(), state.appGroup.href).map(role => role.href);

    newState = PolicyGeneratorUtils.parseExtraAppGroupConfigTraffic(
      traffic,
      state.appGroup,
      state.selectedConnectedAppGroups,
      rulesetId,
      state.extraConfig,
      roles,
      state.optimizeLevel,
    );
    newState.ruleCoverage = PolicyGeneratorUtils.calculateExtraRuleCoverage(
      newState.extraTableTraffic,
      newState.counts.extraConnectionsWorkingTotal,
      state.extraExclusions,
      state.extraConfig.level,
    );
    newState.closedVulnerabilities = PolicyGeneratorUtils.calculateClosedVulnerabilities(
      newState.vulnerabilities,
      state.extraExclusions,
    );
    newState.rulesetId = rulesetId;
  } else {
    newState.totalConnections = 0;
    newState.ruleCoverage = 0;
  }

  const id = this.getParams() && this.getParams().id;

  if (id && (!state.appGroup || id !== state.appGroup.href) && TrafficStore.getNodeForPolicyGenerator(id)) {
    this.setAppGroup(TrafficStore.getNodeForPolicyGenerator(id));
  }

  return newState;
}

export default React.createClass({
  mixins: [RouterMixin, PolicyGeneratorMixin, StoreMixin(TrafficStore, getStateFromStores)],

  getInitialState() {
    return {
      vulnerabilitiesEnabled: SessionStore.areVulnerabilitiesEnabled(),
      // TODO: We can store this sorting info in some storage
      sorting: [{key: 'type', direction: false}],
      showProgressBar: 'none',
      providerConsumerOrder: OrgStore.providerConsumerOrder(),
    };
  },

  componentDidMount() {
    if (!this.state.appGroup) {
      this.transitionTo('policygenerator');

      return;
    }

    this.getData(this.state, 'extraselected');
    this.getPolicyGeneratorRuleset(this.state.appGroup);
    this.setState(getStateFromStores.call(this));
    window.addEventListener('scroll', this.handleScroll);
  },

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  },

  async componentWillReceiveProps() {
    const id = this.getParams() && this.getParams().id;
    const appGroup = TrafficStore.getNodeForPolicyGenerator(id);

    if (id && (!this.state.appGroup || id !== this.state.appGroup.href) && appGroup) {
      this.setAppGroup(appGroup);

      const ruleset = await this.getPolicyGeneratorRuleset(appGroup);

      this.setState({ruleset, showProgress: true, appGroup}, () => {
        //reload the data for the new app group
        this.getData(this.state, 'extraselected');
      });
    }
  },

  getFlattenedFilterTableData(extraConnectionFilters) {
    return _.flatMap(this.state.extraTableTraffic, appGroupTableData =>
      appGroupTableData.filter(row => row.filterKey.includes(extraConnectionFilters || '')),
    );
  },

  getCompleteServices() {
    this.setTypeSelect('microsegmentation');
    actionCreators.updateForAll();
    _.defer(() => this.getData(this.state, 'extra'));
  },

  setTypeSelect(extraType) {
    this.handleConfigSelect({
      level: this.state.extraConfig.level,
      type: extraType,
    });
  },

  handleTypeSelect(evt) {
    const extraType = evt.target.value;

    // Warn the user if there are high numbers of connections before selecting Microsegmentation
    if ((extraType === 'microsegmentation' || extraType === 'auto') && this.state.counts.missingConnections) {
      actionCreators.openDialog(
        <PolicyGeneratorLargeServices
          selectAllServices={_.partial(this.setTypeSelect, 'tiertotier')}
          selectSpecifiedServices={this.getCompleteServices}
        />,
      );

      return;
    }

    this.setTypeSelect(extraType);
  },

  handleLevelSelect(evt) {
    this.handleConfigSelect({
      level: evt.target.value,
      type: this.state.extraConfig.type,
    });
  },

  handleSort(key, direction) {
    const sorting = [];

    if (key) {
      sorting.push({key, direction});
    }

    this.setState({sorting});
  },

  setExclusions(exclusions) {
    const ruleCoverage = PolicyGeneratorUtils.calculateExtraRuleCoverage(
      this.state.extraTableTraffic,
      this.state.counts.extraConnectionsWorkingTotal,
      exclusions,
    );
    const closedVulnerabilities = PolicyGeneratorUtils.calculateClosedVulnerabilities(
      this.state.vulnerabilities,
      exclusions,
    );

    this.setExtraExclusions(exclusions);
    this.setState({extraExclusions: exclusions, ruleCoverage, closedVulnerabilities});
  },

  handleConfigSelect(extraConfig) {
    const {extraTrafficByGroup, type, optimizeLevel, extraExclusions, targetNodes, rulesetId, counts} = this.state;
    const extraTableTraffic = PolicyGeneratorUtils.getExtraTableData(
      extraTrafficByGroup,
      extraConfig,
      type === 'replace' && rulesetId,
      optimizeLevel,
    );
    const vulnerabilities = PolicyGeneratorUtils.getExtraVulnerabilities(extraTableTraffic, targetNodes, optimizeLevel);
    const ruleCoverage = PolicyGeneratorUtils.calculateExtraRuleCoverage(
      extraTableTraffic,
      counts.extraConnectionsWorkingTotal,
      extraExclusions,
      extraConfig.level,
    );
    const closedVulnerabilities = PolicyGeneratorUtils.calculateClosedVulnerabilities(vulnerabilities, extraExclusions);

    this.setExtraConfig(extraConfig);
    this.setState({extraConfig, extraTableTraffic, ruleCoverage, vulnerabilities, closedVulnerabilities});
  },

  handleOptimizeLevelChange(optimizeLevel) {
    const {extraTrafficByGroup, extraConfig, type, extraExclusions, targetNodes, rulesetId, counts} = this.state;
    const extraTableTraffic = PolicyGeneratorUtils.getExtraTableData(
      extraTrafficByGroup,
      extraConfig,
      type === 'replace' && rulesetId,
      optimizeLevel,
    );
    const vulnerabilities = PolicyGeneratorUtils.getExtraVulnerabilities(extraTableTraffic, targetNodes, optimizeLevel);
    const ruleCoverage = PolicyGeneratorUtils.calculateExtraRuleCoverage(
      extraTableTraffic,
      counts.extraConnectionsWorkingTotal,
      extraExclusions,
      extraConfig.level,
    );
    const closedVulnerabilities = PolicyGeneratorUtils.calculateClosedVulnerabilities(vulnerabilities, extraExclusions);

    this.setOptimizeLevel(optimizeLevel);
    this.setState({optimizeLevel, extraTableTraffic, ruleCoverage, vulnerabilities, closedVulnerabilities});
  },

  handleRowToggle(row) {
    const exclusions = [...this.state.extraExclusions];

    if (exclusions.includes(row)) {
      const rowIndex = exclusions.indexOf(row);

      exclusions.splice(rowIndex, 1);
    } else {
      exclusions.push(row);
    }

    this.setExclusions(exclusions);
  },

  handleIncludeAll() {
    const {extraExclusions, extraConnectionFilters} = this.state;
    const rows = this.getFlattenedFilterTableData(extraConnectionFilters);
    const exclusions = [...extraExclusions];

    rows.forEach(row => {
      if (extraExclusions.includes(row.key)) {
        const rowIndex = exclusions.indexOf(row.key);

        exclusions.splice(rowIndex, 1);
      }
    });
    this.setExclusions(exclusions);
  },

  handleExcludeAll() {
    const {extraExclusions, extraConnectionFilters} = this.state;
    const rows = this.getFlattenedFilterTableData(extraConnectionFilters);
    const exclusions = [...extraExclusions];

    rows.forEach(row => {
      if (!extraExclusions.includes(row.key)) {
        exclusions.push(row.key);
      }
    });
    this.setExclusions(exclusions);
  },

  handleScroll() {
    let top = 0;

    if (this.table) {
      // Find the top of the table, because the top div can vary in size
      top = this.table.getBoundingClientRect().top;
    }

    const bar = this.state.showProgressBar;
    const buttons = this.state.bottomButtons;
    const pinAt = 75;

    if (bar !== 'top' && top < pinAt) {
      this.setState({showProgressBar: 'top'});
    } else if (bar !== 'none' && top > pinAt) {
      this.setState({showProgressBar: 'none'});
    }

    if (buttons && top > pinAt) {
      this.setState({bottomButtons: false});
    } else if (!buttons && top < pinAt) {
      this.setState({bottomButtons: true});
    }
  },

  handleBack() {
    const id = this.getParams() && this.getParams().id;

    if (id) {
      this.transitionTo('appGroupExtraScopeChoose', {id});
    } else {
      this.transitionTo('extraScopeChoose');
    }
  },

  handleNext() {
    if (this.state.ruleCoverage) {
      this.handleForceNext();

      return;
    }

    actionCreators.openDialog(
      <AlertDialog
        title={intl('PolicyGenerator.NoConnections')}
        message={intl('PolicyGenerator.AllExcluded')}
        onConfirm={this.handleForceNext}
      />,
    );
  },

  handleForceNext() {
    const id = this.getParams() && this.getParams().id;

    if (id) {
      this.transitionTo('appGroupExtraScopePreview', {id});
    } else {
      this.transitionTo('extraScopePreview');
    }
  },

  handleAddFilter(extraConnectionFilters) {
    this.setExtraConnectionFilters(extraConnectionFilters);
    this.setState({extraConnectionFilters});
  },

  handleRemoveFilter() {
    this.setExtraConnectionFilters(null);
    this.setState({extraConnectionFilters: null});
  },

  handleTransmissionFilterChange(filters) {
    this.setExtraTransmissionFilters(filters);
    this.setState({extraTransmissionFilters: filters}, () => this.getAppGroupTraffic(this.state, 'extra'));
  },

  render() {
    const {
      appGroup,
      extraTableTraffic,
      connectedAppGroups,
      ruleCoverage,
      extraExclusions,
      counts,
      loadingSpinner,
      modal,
      extraConnectionFilters,
      showProgressBar,
      bottomButtons,
      vulnerabilitiesEnabled,
      closedVulnerabilities,
      vulnerabilities,
      optimizeLevel,
      rulesetId,
      extraTransmissionFilters,
      providerConsumerOrder,
    } = this.state;

    if (!appGroup) {
      return null;
    }

    const workingTotal = counts.extraConnectionsWorkingTotal;
    const percent = workingTotal ? ruleCoverage / workingTotal : 0;
    const totalPercent = counts.extraConnections
      ? (ruleCoverage + counts.extraConnectionRules) / counts.extraConnections
      : 0;
    const excluded = workingTotal ? workingTotal - ruleCoverage : 0;

    const progressBar = (
      <CoverageProgressBar
        connections={counts.extraConnections}
        connectionRules={counts.extraConnectionRules}
        ruleCoverage={ruleCoverage}
        excluded={excluded}
        direction="horizontal"
        percent={percent}
      />
    );

    let {extraConfig} = this.state;

    if (_.isEmpty(extraConfig)) {
      extraConfig = {level: 'appgroup', type: 'tiertotier'};
    }

    let trafficTables = [];
    let firstAppGroupFlag = true;
    let lastAppGroupCounter = 0;
    let totalLength = 0;
    let allIncluded = true;
    let allExcluded = true;
    let allMissingRoles = true;
    const maxRulesPerGroup = Math.ceil(this.getMaxTableRules() / (_.size(extraTableTraffic) || 1));
    let totalMissing = 0;

    if (extraTableTraffic && _.size(extraTableTraffic)) {
      let filteredTraffic = {};

      _.forEach(extraTableTraffic, (appGroupTableData, appGroup) => {
        filteredTraffic[appGroup] = appGroupTableData.filter(appGroupTableRow =>
          appGroupTableRow.filterKey.includes(extraConnectionFilters || ''),
        );
        totalLength += filteredTraffic[appGroup].length;

        if (filteredTraffic[appGroup].length > maxRulesPerGroup) {
          totalMissing += filteredTraffic[appGroup].length - maxRulesPerGroup;
          filteredTraffic[appGroup] = filteredTraffic[appGroup].slice(0, maxRulesPerGroup);
        }

        _.forEach(filteredTraffic[appGroup], appGroupTableData => {
          const rowIncluded = extraExclusions && !extraExclusions.includes(appGroupTableData.key);

          allIncluded &&= rowIncluded;
          allExcluded &&= !rowIncluded;

          allMissingRoles &&= GridDataUtils.areRolesMissing('extra', extraConfig, {
            provider: appGroupTableData.target,
            consumer: appGroupTableData.source,
          });
        });

        if (_.isEmpty(filteredTraffic[appGroup])) {
          delete filteredTraffic[appGroup];
        }
      });

      if (_.isEmpty(filteredTraffic)) {
        filteredTraffic = [[]];
        allIncluded = false;
        allExcluded = false;
      }

      trafficTables = _.map(filteredTraffic, (appGroupExtraTableTraffic, appGroup) => {
        lastAppGroupCounter += 1;

        const numAppGroups = Object.keys(filteredTraffic).length;
        const extraGrid = (
          <ConfigureExtraConnectionsGrid
            numAppGroups={numAppGroups}
            trafficData={appGroupExtraTableTraffic}
            title={appGroup}
            exclusions={extraExclusions}
            firstAppGroup={firstAppGroupFlag}
            onRowToggle={this.handleRowToggle}
            bottomSpacing={lastAppGroupCounter < numAppGroups}
            config={extraConfig}
            onInclusionToggle={this.handleInclusionToggle}
            sorting={this.state.sorting}
            onSort={this.handleSort}
            firstGrid={lastAppGroupCounter === 1}
            showProgressBar={showProgressBar}
            rulesetId={rulesetId}
            providerConsumerOrder={providerConsumerOrder}
          />
        );

        firstAppGroupFlag = false;

        return extraGrid;
      });
    }

    if (loadingSpinner && !modal) {
      let message;

      switch (loadingSpinner) {
        case 'rules':
          message = intl('PolicyGenerator.Spinner.CalculatingRuleCoverage');
          break;
        case 'vulnerabilities':
          message = intl('PolicyGenerator.Spinner.CalculatingVulnerabilityData');
          break;
        default:
          message = intl('PolicyGenerator.Spinner.RecalculatingFlowData');
      }

      return (
        <div className="RBConfigure RBConfigure--Extra">
          <Banner type="progresscentered" header={intl('PolicyGenerator.CalculationInProgress')} message={message} />
        </div>
      );
    }

    const toolbarClasses = cx({
      'PolicyGeneratorGrid-toolbar': true,
      'PolicyGeneratorGrid-toolbar-none': showProgressBar === 'none',
      'PolicyGeneratorGrid-toolbar-top': showProgressBar === 'top',
      'PolicyGeneratorGrid-toolbar-inline': showProgressBar === 'inline',
    });

    const className = bottomButtons ? 'RBConfigure-Content-Nav--show' : 'RBConfigure-Content-Nav--hidden';

    return (
      <div className="RBConfigure RBConfigure--Extra">
        <div className="RBConfigure-Content">
          <ProgressBar
            steps={[
              intl('PolicyGenerator.SelectApplications'),
              [intl('PolicyGenerator.ExtraScope.ChooseExtraScope'), <br />, intl('Common.Applications')],
              intl('PolicyGenerator.ExtraScope.ConfigureExtraScope'),
              intl('PolicyGenerator.PreviewRules'),
            ]}
            active={2}
          />
          <div className="RBConfigure-Content-Nav">
            <Button text={intl('Common.Back')} type="secondary" onClick={this.handleBack} tid="back" />
            <Button text={intl('Common.Next')} onClick={this.handleNext} tid="next" />
          </div>
          <div className="RBConfigure-Content-Nav-Wrapper">
            <div className={className}>
              <div className="RBConfigure-Content-Nav">
                <Button text={intl('Common.Back')} type="secondary" onClick={this.handleBack} tid="back" />
                <Button text={intl('Common.Next')} onClick={this.handleNext} tid="next" />
              </div>
            </div>
          </div>
          <div className="RBConfigure-Content-AppGroup">
            <div className="RBConfigure-Content-AppGroup-Choose">
              <h2 className="RBConfigure-Content-AppGroup-Choose-Title">
                {intl('PolicyGenerator.ExtraScope.Options.Title', {count: Object.keys(connectedAppGroups).length})}
              </h2>
              <div className="RBConfigure-Content-AppGroup-Choose-Values">
                <h3 className="RBConfigure-Content-AppGroup-Choose-Values-RadioGroupTitle">
                  {intl('PolicyGenerator.ServiceRuleConfig')}
                </h3>
                <RadioGroup value={extraConfig.type}>
                  <Radio
                    value="tiertotier"
                    onChange={this.handleTypeSelect}
                    checked={extraConfig.type === 'tiertotier'}
                    label={intl('Common.AllServices')}
                    tid="allservices"
                  >
                    {intl('PolicyGenerator.Options.AllServices.Body', {level: extraConfig.level})}
                  </Radio>
                  <Radio
                    value="microsegmentation"
                    onChange={this.handleTypeSelect}
                    checked={extraConfig.type === 'microsegmentation'}
                    label={intl('PolicyGenerator.Options.SpecifiedServices.Title')}
                    tid="specifiedservices"
                  >
                    {intl('PolicyGenerator.Options.SpecifiedServices.Body', {level: extraConfig.level})}
                  </Radio>
                  {vulnerabilitiesEnabled ? (
                    <Radio
                      value="auto"
                      onChange={this.handleTypeSelect}
                      checked={extraConfig.type === 'auto'}
                      label={intl('PolicyGenerator.IntraScope.Options.AutoLevel.Title')}
                      tid="auto"
                    >
                      {intl('PolicyGenerator.IntraScope.Options.AutoLevel.Body')}
                    </Radio>
                  ) : null}
                  {extraConfig.type === 'auto' ? (
                    <div className="RBConfigure-Content-AppGroup-Choose-Level">
                      {intl('Common.Severity')}
                      <div className="RBConfigure-Content-AppGroup-Choose-Level-Slider">
                        <div className="VulnerabilitySeverity">
                          <Slider
                            min={1}
                            max={5}
                            step={1}
                            labels={RenderUtils.getVulnerabilityOptions()}
                            value={optimizeLevel}
                            onChange={this.handleOptimizeLevelChange}
                          />
                        </div>
                      </div>
                    </div>
                  ) : null}
                </RadioGroup>
              </div>
              <div className="RBConfigure-Content-AppGroup-Choose-Image">
                <img
                  className="RBConfigure-Content-AppGroup-Choose-Image-Img"
                  alt={`${extraConfig.level} ${extraConfig.type}`}
                  src={`images/policygenerator/${extraConfig.level}-${extraConfig.type}.png`}
                />
              </div>
            </div>
            <div className="RBConfigure-Content-AppGroup-Info">
              <p>
                <strong>{intl('Common.Application')}:</strong>{' '}
                {`${appGroup.name} - ${intl('Workloads.WorkloadsNumber', {count: appGroup.workloads})}`}
              </p>
              <p>
                <strong>{intl('PolicyGenerator.ExtraScopeConnections')}</strong>
                <br />
                {intl('ApplicationsCoverage.RuleCoverageStrong', {val: totalPercent}, {html: true})}
              </p>
              <CoverageProgressBar
                connections={counts.extraConnections}
                connectionRules={counts.extraConnectionRules}
                ruleCoverage={ruleCoverage}
                excluded={excluded}
                direction="vertical"
                type="extra"
                percent={percent}
                totals={vulnerabilitiesEnabled && vulnerabilities.counts}
                closed={vulnerabilitiesEnabled && closedVulnerabilities}
              />
            </div>
          </div>
          <div className="PolicyGeneratorGrid-wrapper">
            <div className={toolbarClasses}>
              <div className="PolicyGeneratorGrid-title-bar">
                <div className="PolicyGeneratorGrid-title-section">
                  {PolicyGeneratorUtils.getExtraConnectionsGridTitle(extraConfig)}
                  <div className="PolicyGeneratorGrid-subtitle">
                    <TrafficFilter
                      onSetFilter={this.handleTransmissionFilterChange}
                      filters={extraTransmissionFilters}
                    />
                  </div>
                </div>
              </div>
              <div className="PolicyGeneratorGrid-grid-controls">
                <div className="PolicyGeneratorGrid-inc-exc">
                  {allMissingRoles ? (
                    <div className="PolicyGeneratorGrid-inc-exc-switch">
                      <div
                        className="PolicyGeneratorGrid-inc-exc-switch--single PolicyGeneratorGrid-exc-active"
                        data-tid="rule-exclude"
                      >
                        {intl('PolicyGenerator.Excluded')}
                      </div>
                    </div>
                  ) : (
                    <div className="PolicyGeneratorGrid-inc-exc-switch">
                      <div
                        className={allIncluded ? 'PolicyGeneratorGrid-inc-active' : 'PolicyGeneratorGrid-inc'}
                        onClick={allIncluded ? _.noop : this.handleIncludeAll}
                        data-tid="rule-include"
                      >
                        {extraConnectionFilters
                          ? intl('PolicyGenerator.IncludeSome', {count: totalLength})
                          : intl('PolicyGenerator.IncludeAll')}
                      </div>
                      <div
                        className={allExcluded ? 'PolicyGeneratorGrid-exc-active' : 'PolicyGeneratorGrid-exc'}
                        onClick={allExcluded ? _.noop : this.handleExcludeAll}
                        data-tid="rule-exclude"
                      >
                        {extraConnectionFilters
                          ? intl('PolicyGenerator.ExcludeSome', {count: totalLength})
                          : intl('PolicyGenerator.ExcludeAll')}
                      </div>
                    </div>
                  )}
                </div>
                <div className="PolicyGeneratorGrid-grid-Filter">
                  <PolicyGeneratorConfigGridFilter
                    onAddFilter={this.handleAddFilter}
                    onRemoveFilter={this.handleRemoveFilter}
                    filter={extraConnectionFilters}
                  />
                </div>
              </div>
              {progressBar}
            </div>
            <div ref={node => (this.table = node)} data-tid="policy-generator-grid">
              {trafficTables}
            </div>
            {totalMissing > 0 && (
              <div className="PolicyGeneratorGrid-more">
                {intl('PolicyGenerator.Grid.MoreRules', {count: totalMissing})}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  },
});
