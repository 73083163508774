/**
 * Copyright 2017 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import intl from '@illumio-shared/utils/intl';
import React from 'react';
import Constants from '../../constants';
import StoreMixin from '../../mixins/StoreMixin';
import RouterMixin from '../../mixins/RouterMixin';
import {Select} from '../../components/FormComponents';
import {Banner, Button, Notification} from '../../components';
import {MapNotification, MapNotificationContainer} from '../Map/MapNotification';
import {ExplorerStore, OrgStore, TrafficStore, SessionStore, HealthStore} from '../../stores';
import {FilterBar, NavPathPanel, LinkTable, VizPanel, UnmanagedIps, DomainTable} from '../../components/Explorer';
import {RestApiUtils} from '../../utils';
import {ExplorerUtils} from '../../utils/Explorer';
import actionCreators from '../../actions/actionCreators';
import ExplorerActions from '../../actions/ExplorerActions';
import AsyncQueryResultsDialog from './AsyncQueryResultsDialog';
import ResultsSettingsDialog from './ResultsSettingsDialog';
import {getId} from '../../utils/GeneralUtils';

const POLLING_INTERVAL = 10_000;

const formatTypes = () => {
  const types = [
    {key: 'parallelCoordinates', value: intl('Explorer.ParallelCoordinates')},
    {key: 'table', value: intl('Explorer.Table')},
  ];

  types.push(
    {key: 'unmanagedIps', value: intl('Common.UnmanagedIPAddresses')},
    {key: 'domaintable', value: intl('Common.DomainTable')},
  );

  return types;
};

/**
 * Used to detect which background queries have completed
 * Will return both the list of completed background queries, and list of background queries still waiting
 */
const getNotificationAndBackgroundLists = (queries, currentNotifications, backgroundQueries) =>
  (backgroundQueries || []).reduce(
    (result, backgroundHref) => {
      const query = queries.find(query => query.href === backgroundHref);

      // If the query is still pending, leave it in the background list
      if (!query || ExplorerUtils.isQueryPending(query.status)) {
        result.backgroundQueries.push(backgroundHref);

        return result;
      }

      // If the query is complete, add to the notification list
      // Ignoring any killed or deleted queries
      if (ExplorerUtils.isQueryComplete(query.status)) {
        result.backgroundNotificationList.push(backgroundHref);
      }

      return result;
    },
    {backgroundNotificationList: [...(currentNotifications || [])], backgroundQueries: []},
  );

function getStateFromStore() {
  const org = OrgStore.getOrg();
  const appGroupsDefaultSet = org && org.app_groups_default && org.app_groups_default.length;
  const queries = ExplorerStore.getFilteredQueries();
  const aggregationLevel = this.state?.aggregationLevel || 'labels';
  const {backgroundNotificationList, backgroundQueries} = getNotificationAndBackgroundLists(
    queries || [],
    this.state?.backgroundNotificationList || [],
    this.backgroundPendingQueries || [],
  );

  this.backgroundPendingQueries = backgroundQueries;

  return {
    ...ExplorerStore.getSelected(),
    vizLinks: ExplorerStore.getVizLinks(),
    tableLinks: aggregationLevel === 'labels' ? ExplorerStore.getAggregatedTableLinks() : ExplorerStore.getTableLinks(),
    ipTraffic: ExplorerStore.getIpTraffic(),
    domainTraffic: ExplorerStore.getDomainTraffic(),
    isDataReady: ExplorerStore.getIsDataReady(),
    appGroupDisabled: !appGroupsDefaultSet,
    explorerEnabled: SessionStore.isExplorerEnabled(),
    asyncEnabled: SessionStore.isAsyncExplorerEnabled(),
    asyncStatus: ExplorerStore.getNewQueryStatus(),
    status: ExplorerStore.getStatus(),
    trafficDBMigrationInProgress: HealthStore.isTrafficDBMigrationInProgress(),
    explorerType: ExplorerStore.getExplorerType(),
    loadedQuery: ExplorerStore.getLoadedQuery(),
    queries,
    backgroundNotificationList,
    isSuperclusterMember: SessionStore.isSuperclusterMember(),
  };
}

export default Object.assign(
  React.createClass({
    mixins: [RouterMixin, StoreMixin([ExplorerStore, TrafficStore, SessionStore, HealthStore], getStateFromStore)],

    getInitialState() {
      const format =
        ExplorerStore.getExplorerType() === 'main'
          ? localStorage.getItem('explorerFormat') || intl('Explorer.Table')
          : intl('Explorer.Table');

      const aggregationLevel = localStorage.getItem('aggregation_level_main') || 'labels';

      return {
        format,
        aggregationLevel,
        spinnerbreak: false,
        asyncQueryResultsDialogVisible: false,
        asyncQueryloadingIndicators: {},
        resultsSettingsDialogVisible: false,
        clusterMemberLimitedResultsDialogShow: false,
      };
    },

    async componentDidMount() {
      actionCreators.setExplorerType('main');

      if (!localStorage.getItem('disable_explorer_services')) {
        RestApiUtils.services.getCollection({max_results: 100_000});
      }

      if (this.state.asyncEnabled) {
        this.pollQueryList();
      }

      // EYE-77680 If PCE is a cluster member, show limited data info
      if (this.state.isSuperclusterMember && !this.state.clusterMemberLimitedResultsDialogShow) {
        this.setState({
          clusterMemberLimitedResultsDialogShow: true,
        });
      }
    },

    componentWillUnmount() {
      this.stopQueryListPolling();
      this.stopQueryPolling();
    },

    /**
     * Used to start polling the query list
     */
    async pollQueryList() {
      await RestApiUtils.trafficFlows.async.getCollection();

      this.queryListPollingId = setTimeout(() => this.pollQueryList(), POLLING_INTERVAL);
    },

    /**
     * Used to stop the query list polling
     */
    stopQueryListPolling() {
      if (this.queryListPollingId) {
        clearTimeout(this.queryListPollingId);
      }
    },

    handleFormatChange(value) {
      this.setState({format: value}, () => {
        localStorage.setItem('explorerFormat', value);
      });
    },

    handleCollapse(collapse) {
      this.setState({collapse});
    },

    handleSpinner(spinnerbreak) {
      this.setState({spinnerbreak});
    },

    handleEdit() {
      this.setState({reload: true}, () => this.setState({reload: false}));
    },

    handleNotificationClose() {
      localStorage.setItem('scoped_explorer_warning_cleared', true);
      // This is only needed to force a re-render
      this.setState({messageClose: true});
    },

    /**
     * Used when the user clicks "Go" for a new query
     */
    handleStartNewQuery(queryHref) {
      this.handleNewAction();

      this.pendingQuery = queryHref;

      // Restart the the query list polling
      this.stopQueryListPolling();
      this.pollQueryList();

      ExplorerUtils.pollQuery(queryHref, this.setQueryPollId);
    },

    /**
     * Used to save the polling timer id
     */
    setQueryPollId(id) {
      this.queryPollingId = id;

      // When a query is complete
      if (!id) {
        // Restart the the query list polling
        this.stopQueryListPolling();
        this.pollQueryList();
        this.pendingQuery = null;
      }
    },

    /**
     * Used to stop the existing query poll
     */
    stopQueryPolling() {
      if (this.queryPollingId) {
        clearTimeout(this.queryPollingId);
      }
    },

    /**
     * Used to start a new action if the user changes a filter value
     */
    handleFilterChange(filters) {
      ExplorerActions.setFilters(filters);

      this.handleNewAction();
      actionCreators.clearNewExplorerQuery();
    },

    /**
     * Used to clean up an existing query and move it to the background
     */
    handleNewAction() {
      this.stopQueryPolling();

      if (this.pendingQuery) {
        this.backgroundPendingQueries.push(this.pendingQuery);
        this.pendingQuery = null;
      }
    },

    handleClusterMemberLimitedResultsHide() {
      this.setState({
        clusterMemberLimitedResultsDialogShow: false,
      });
    },

    /**
     * Used hide the async spinner, and move the current query to the background
     */
    handleHideAsyncSpinner() {
      this.handleNewAction();
      actionCreators.clearNewExplorerQuery();
    },

    /**
     * Used to close the completed query notification and clear the list
     */
    handleCloseCompletedQueryNotification(event) {
      event.stopPropagation();

      this.setState({
        backgroundNotificationList: [],
      });
    },

    /**
     * Used when the user clicks on the completed query notification
     */
    handleLoadBackgroundQuery() {
      const {backgroundNotificationList} = this.state;
      const numQueries = backgroundNotificationList.length;

      // If more than one query is done, load the list for the user to choose
      if (numQueries > 1) {
        this.setState({
          asyncQueryResultsDialogVisible: true,
          backgroundNotificationList: [],
        });

        return;
      }

      // For a single completed query load the results
      if (numQueries === 1) {
        RestApiUtils.trafficFlows.async.getResults(getId(backgroundNotificationList[0]));
      }

      this.setState({
        backgroundNotificationList: [],
        // hide the query results dialog
        asyncQueryResultsDialogVisible: false,
      });
    },

    /**
     * Used to show the async query results dialog
     */
    handleResultsButtonClicked() {
      this.setState({
        asyncQueryResultsDialogVisible: true,
      });
    },

    /**
     * Used to hide the async query results dialog
     */
    handleAsyncQueryResultsDialogClose() {
      this.setState({
        asyncQueryResultsDialogVisible: false,
      });
    },

    /**
     * Used to indicate than an action is running at the async query dialog
     *
     * @param {string} uuid The uid of the entry
     * @param {string} action The action performed
     */
    onAsyncQueryLoadingIndicatorsAdd(uuid, action) {
      const key = uuid + action;

      this.setState(prevState => {
        prevState.asyncQueryloadingIndicators[key] = true;

        return {
          ...prevState,
          asyncQueryloadingIndicators: {...prevState.asyncQueryloadingIndicators},
        };
      });
    },

    /**
     * Used to remove an running action from async query dialog
     *
     * @param {string} uuid The uid of the entry
     * @param {string} action The action performed
     */
    onAsyncQueryLoadingIndicatorsDelete(uuid, action) {
      const key = uuid + action;

      this.setState(prevState => ({
        ...prevState,
        asyncQueryloadingIndicators: _.omit(prevState.asyncQueryloadingIndicators, key),
      }));
    },

    /**
     * Used by the async query dialog to check if a loading indicator exists
     *
     * @param {string} uuid The uid of the entry
     * @param {string} action The action performed
     */
    onAsyncQueryloadingIndicatorsCheck(uuid, action) {
      const key = uuid + action;

      return this.state.asyncQueryloadingIndicators[key];
    },

    /**
     * Used to show the results settings dialog
     */
    showResultsSettingsDialog() {
      this.setState({
        resultsSettingsDialogVisible: true,
      });
    },

    /**
     * Used to show the results settings dialog
     */
    hideResultsSettingsDialog() {
      this.setState({
        resultsSettingsDialogVisible: false,
      });
    },

    // Used to hide the results settings dialog when ok or canceled were clicked
    handleResultsSettingsDialogOnClose() {
      this.hideResultsSettingsDialog();
    },

    handleAggregationChange(aggregationLevel) {
      localStorage.setItem('aggregation_level_main', aggregationLevel);
      this.setState({
        tableLinks:
          aggregationLevel === 'labels' ? ExplorerStore.getAggregatedTableLinks() : ExplorerStore.getTableLinks(),
        aggregationLevel,
      });
    },

    handleCreateRules(selected) {
      const {tableLinks} = this.state;
      const uuid = ExplorerStore.getLoadedUuid();

      const selectedRows = tableLinks.reduce((result, link) => {
        if (
          link.rules &&
          !link.rules.length &&
          link.ruleWritingAvailable &&
          (selected === 'all' || selected.includes(link.index))
        ) {
          result.push(link.linkKey);
        }

        return result;
      }, []);

      localStorage.setItem('selectedLinks', JSON.stringify(selectedRows));
      this.transitionTo('explorerProposedRules', {uuid});
    },

    handleGoToIlluminationPlus() {
      this.transitionTo('illumination');
    },

    render() {
      const {
        format,
        vizLinks,
        tableLinks,
        ipTraffic,
        domainTraffic,
        isDataReady,
        selectedSource,
        selectedTarget,
        clickedTick,
        clickedTickPath,
        collapse,
        appGroupDisabled,
        explorerEnabled,
        spinnerbreak,
        reload,
        trafficDBMigrationInProgress,
        explorerType,
        asyncQueryResultsDialogVisible,
        queries,
        loadedQuery,
        status,
        asyncStatus,
        backgroundNotificationList,
        resultsSettingsDialogVisible,
        asyncEnabled,
        clusterMemberLimitedResultsDialogShow,
        aggregationLevel,
      } = this.state;

      const filterBarEnabled = !appGroupDisabled && explorerEnabled;

      const allQueriesNum = Array.isArray(queries) ? queries.length : 0;
      const pendingQueriesNum = Array.isArray(queries)
        ? queries.filter(query => ExplorerUtils.isQueryPending(query.status)).length
        : 0;
      const queryResultsButtonDisabled = !allQueriesNum;
      const showBackgroundNotification = backgroundNotificationList.length;
      const message = [
        String(intl('Explorer.DeprecationNoticePart1')),
        <span className="Explorer-Link" onClick={this.handleGoToIlluminationPlus}>
          {intl('Common.IlluminationPlus')}
        </span>,
        ` ${intl('Explorer.DeprecationNoticePart2')}`,
      ];
      const notifications = [<Notification type="instruction" message={message} />];

      if (SessionStore.isUserScoped() && !localStorage.getItem('scoped_explorer_warning_cleared')) {
        notifications.push(
          <Notification
            type="warning"
            closeable
            message={intl('Explorer.ScopedUserWarningExplorer')}
            onClose={this.handleNotificationClose}
          />,
        );
      }

      // Notify for each region that did not respond
      (loadedQuery?.regions || []).forEach(region => {
        if (region && region.hasOwnProperty('responded') && !region.responded) {
          notifications.push(
            <Notification
              type="warning"
              message={
                <div>
                  <span className="Explorer-Warning">{intl('Explorer.IncompleteResults')}</span>
                  {intl('Explorer.SuperClusterWarning', {...region})}
                </div>
              }
            />,
          );
        }
      });

      let navPath = (
        <div className="Explorer-navPathPanel">
          {format === intl('Explorer.ParallelCoordinates') && (
            <NavPathPanel
              selectedSource={selectedSource}
              selectedTarget={selectedTarget}
              clickedTick={clickedTick}
              clickedTickPath={clickedTickPath}
              isDataReady={isDataReady}
            />
          )}
        </div>
      );

      const timestamp = loadedQuery?.updated_at ? (
        <div className="Explorer-timestamp">
          <span className="Explorer-format-label">{intl('Common.Timestamp')}:</span>
          {intl.date(loadedQuery.created_at, 'L_HH_mm_ss')}
        </div>
      ) : null;

      const formatPicker = (
        <div className="Explorer-format-timestamp">
          {timestamp}
          <div className="Explorer-format">
            <span className="Explorer-format-label">{intl('Explorer.Format')}</span>
            <Select options={formatTypes()} value={format} onChange={this.handleFormatChange} tid="formattype" />
          </div>
        </div>
      );

      let table;
      let vizPanel;
      let ipTable;
      let banner;
      let domainTable;
      let showData = false;

      if (!explorerEnabled) {
        banner = <Banner type="notice" header={intl('Explorer.Disabled')} />;
        navPath = null;
      } else if (appGroupDisabled) {
        const config = (
          <Button
            text={intl('Applications.SetApplicationType')}
            onClick={() => this.transitionTo('appGroupType')}
            disabled={!SessionStore.isUserOwner() || SessionStore.isSuperclusterMember()}
          />
        );

        banner = <Banner type="notice" header={intl('Explorer.ApplicationDisabled')} content={config} />;
        navPath = null;
      } else if ((status === Constants.STATUS_BUSY || asyncStatus === Constants.STATUS_BUSY) && !spinnerbreak) {
        showData = true;
        banner = (
          <Banner
            type="progresscenterednooverlay"
            header={intl('PolicyGenerator.CalculationInProgress')}
            message={
              asyncStatus === Constants.STATUS_BUSY ? (
                <span>
                  {intl('PolicyGenerator.Spinner.ExploringAsyncData')}
                  <span className="Explorer-Link" onClick={this.handleHideAsyncSpinner} data-tid="explorer-banner-hide">
                    {intl('Common.Hide')}
                  </span>
                </span>
              ) : (
                intl('PolicyGenerator.Spinner.ExploringData')
              )
            }
          />
        );
      } else if (
        filterBarEnabled &&
        isDataReady &&
        ((format === intl('Explorer.ParallelCoordinates') && !vizLinks.length) ||
          (format === intl('Explorer.Table') && !tableLinks.length) ||
          (format === intl('Common.DomainTable') && !domainTraffic.length) ||
          (format === intl('Common.UnmanagedIPAddresses') && !ipTraffic.length))
      ) {
        banner = (
          <Banner
            type="info"
            header={[
              intl('Explorer.NoTraffic'),
              <br />,
              intl('Explorer.SelectNewFilters'),
              <br />,
              intl('Explorer.ClickGo'),
            ]}
          />
        );
      } else if (filterBarEnabled && !tableLinks.length && !isDataReady) {
        banner = <Banner type="info" header={[intl('Explorer.SelectFilters'), <br />, intl('Explorer.ClickGo')]} />;
      }

      if (filterBarEnabled && format === intl('Explorer.Table')) {
        table = (
          <LinkTable
            links={tableLinks}
            onEdit={this.handleEdit}
            groupMap={this.state.groupMap}
            type="main"
            banner={banner}
            showData={tableLinks.length && showData}
            onAggregationChange={this.handleAggregationChange}
            aggregationLevel={aggregationLevel}
            onCreateRules={this.handleCreateRules}
          />
        );
      } else if (filterBarEnabled && format === intl('Explorer.ParallelCoordinates')) {
        vizPanel = (
          <VizPanel
            links={vizLinks}
            selectedSource={selectedSource}
            selectedTarget={selectedTarget}
            clickedTick={clickedTick}
            clickedTickPath={clickedTickPath}
            isDataReady={isDataReady}
            collapse={collapse}
            banner={banner}
            showData={vizLinks.length && showData}
          />
        );
      } else if (filterBarEnabled && format === intl('Common.UnmanagedIPAddresses')) {
        ipTable = (
          <UnmanagedIps
            ipTraffic={ipTraffic}
            onSave={this.handleEdit}
            type={explorerType}
            banner={banner}
            showData={ipTraffic.length && showData}
          />
        );
      } else if (filterBarEnabled && format === intl('Common.DomainTable')) {
        domainTable = (
          <DomainTable
            domainTraffic={domainTraffic}
            onSave={this.handleEdit}
            banner={banner}
            showData={domainTraffic.length && showData}
          />
        );
      }

      if (!explorerEnabled) {
        return (
          <div className="Explorer ExplorerDisplay" data-tid="traffic-analyzer">
            <div className="Explorer-banner">{banner}</div>
          </div>
        );
      }

      return (
        <div className="Explorer ExplorerDisplay" data-tid="traffic-analyzer">
          <FilterBar
            enabled={filterBarEnabled}
            buttonDisabled={appGroupDisabled}
            onSetSpinner={this.handleSpinner}
            onCollapse={this.handleCollapse}
            reload={reload}
            trafficDBMigrationInProgress={trafficDBMigrationInProgress}
            onResultsButtonClicked={this.handleResultsButtonClicked}
            resultsButtonDisabled={queryResultsButtonDisabled}
            resultsButtonNumber={pendingQueriesNum}
            onStartNewQuery={this.handleStartNewQuery}
            onFilterChange={this.handleFilterChange}
            showResultsSettingsDialog={this.showResultsSettingsDialog}
          />
          {notifications}
          <div className="Explorer-navigation">
            <div>{navPath}</div>
            {formatPicker}
          </div>
          {table}
          {vizPanel}
          {ipTable}
          {domainTable}
          {asyncQueryResultsDialogVisible && (
            <AsyncQueryResultsDialog
              data={queries}
              indicatorAdd={this.onAsyncQueryLoadingIndicatorsAdd}
              indicatorDelete={this.onAsyncQueryLoadingIndicatorsDelete}
              indicatorCheck={this.onAsyncQueryloadingIndicatorsCheck}
              onClose={this.handleAsyncQueryResultsDialogClose}
              onLoadQuery={this.handleNewAction}
              handleShowResultsSettingsClick={this.showResultsSettingsDialog}
            />
          )}
          {resultsSettingsDialogVisible && (
            <ResultsSettingsDialog onClose={this.handleResultsSettingsDialogOnClose} showMaxDBResults={asyncEnabled} />
          )}

          <MapNotificationContainer>
            {clusterMemberLimitedResultsDialogShow && (
              <MapNotification
                type="info"
                message={intl('Explorer.MemberShowsPartialPCEData')}
                onClose={this.handleClusterMemberLimitedResultsHide}
              />
            )}
            {showBackgroundNotification && (
              <MapNotification
                type="check"
                clickable
                onClose={this.handleCloseCompletedQueryNotification}
                onClick={this.handleLoadBackgroundQuery}
                message={intl('Explorer.ResultsAvailable')}
              />
            )}
          </MapNotificationContainer>
        </div>
      );
    },
  }),
  {viewName: () => intl('Common.Explorer'), isAvailable: () => SessionStore.isExplorerEnabled()},
);
