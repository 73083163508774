/**
 * Copyright 2016 Illumio, Inc. All Rights Reserved.
 */
import React from 'react';
import _ from 'lodash';
import intl from '@illumio-shared/utils/intl';
import request from '../../lib/request';
import actionCreators from '../../actions/actionCreators';
import SegmentationTemplateLogin from '../../modals/SegmentationTemplateLogin';
import SegmentationTemplateUpload from '../../modals/SegmentationTemplateUpload';
import {SegmentationTemplateStore, SessionStore} from '../../stores';
import {StoreMixin, UserMixin, RouterMixin} from '../../mixins';
import RestApiUtils from '../../utils/RestApiUtils';
import {submitFormTo} from '../../utils/dom';
import Constants from '../../constants';
import {SpinnerOverlay, SegmentationTemplate} from '../../components';

function getStateFromStores() {
  const templates = SegmentationTemplateStore.getAll();
  const windowsTemplates = [];
  const linuxTemplates = [];
  const universalTemplates = [];
  const installStatusCalculated = SegmentationTemplateStore.getInstallStatus();

  if (templates !== 'error') {
    for (const templateKey in templates) {
      if (templates[templateKey].os_family === 'Windows') {
        windowsTemplates.push(templates[templateKey]);
      } else if (templates[templateKey].os_family === 'Linux') {
        linuxTemplates.push(templates[templateKey]);
      } else {
        universalTemplates.push(templates[templateKey]);
      }
    }
  }

  let installing = false;

  if (_.some(templates, template => template.status === 'installing')) {
    installing = true;
  }

  return {
    windowsTemplates,
    linuxTemplates,
    universalTemplates,
    templates,
    installing,
    installStatusCalculated,
    status: [SegmentationTemplateStore.getStatus()],
  };
}

export default Object.assign(
  React.createClass({
    mixins: [RouterMixin, UserMixin, StoreMixin([SegmentationTemplateStore], getStateFromStores)],

    componentWillMount() {
      // Users who have scoped read are blocked from accessing this component.
      if (!SessionStore.isSegmentationTemplatesEnabled()) {
        this.replaceWith('landing');

        return;
      }

      this.populateSegmentationTemplateStore();
    },

    async componentDidMount() {
      // Users who have scoped read are blocked from accessing this component.
      if ((!this.state.templates || !_.size(this.state.templates)) && SessionStore.isSegmentationTemplatesEnabled()) {
        // Try to reach support portal first
        await this.fetchSegmentationTemplates();
      }
    },

    componentWillUnmount() {
      actionCreators.closeDialog();
    },

    async fetchSegmentationTemplates() {
      try {
        // Request templates json from foreign support portal source
        const segmentationTemplatesResponse = await request({
          url: `${SessionStore.getSupportLink()}/downloads/segmentation-templates.json`,
          mode: 'cors',
          method: 'GET',
          credentials: 'include',
          timeout: 5000,
        });
        const json = await segmentationTemplatesResponse.json();

        actionCreators.segmentationTemplateParseData(json);
      } catch (error) {
        if (error.status >= 400 && error.status < 500) {
          // If server returned 4xx error, consider it like authorization failure and redirect to support login page
          await this.loginToSupportPortal();
        } else {
          // If it's other error (support server cannot be reached or broken) show popup for uploading templates manually
          this.handleUpload();
        }
      }
    },

    loginToSupportPortal() {
      return new Promise(resolve => {
        const form = `${SessionStore.getSupportLink()}/pce-support-integration.html`;

        actionCreators.openDialog(
          <SegmentationTemplateLogin
            fileError={this.state.templates === 'error'}
            onCancel={() => {
              resolve(); // Resolve to fetch next RestApiUtils calls in componentDidMount
            }}
            onLoadFile={() => {
              resolve(); // Resolve to fetch next RestApiUtils calls in componentDidMount
              this.handleUpload();
            }}
            onConfirm={() => {
              // We can't send request using fetch api,
              // because response returns redirection (302) with 'Location' header that we need to read and handle manually,
              // and fetch has response='manually' option to do it, but it can't be polyfilled using xhr (IE, Safari).
              // That's why we have to use old-school way - create Form and submit it to remote support address
              submitFormTo(form, {pce_hostname: window.location.origin});
              // Never call resolve() in this case to prevent RestApiUtils fetching during browser navigation
            }}
          />,
        );
      });
    },

    handleUpload() {
      actionCreators.openDialog(
        <SegmentationTemplateUpload
          onCancel={() => {
            this.loginToSupportPortal();
          }}
        />,
      );
    },

    handleViewDetails(params) {
      this.transitionTo('segmentationTemplate.detail', params);
    },

    async populateSegmentationTemplateStore() {
      actionCreators.segmentationTemplatesClearInstalledTemplates();
      await RestApiUtils.services.getCollection({name: 'All Services'}, 'draft', true);
      await RestApiUtils.services.getCollection({name: 'ICMP'}, 'draft', true);
      await RestApiUtils.ipLists.getInstance('any', 'draft', true);
      await RestApiUtils.ruleSets.getCollection(
        {
          representation: 'rule_set_scopes',
          external_data_set: 'illumio_segmentation_templates',
        },
        'draft',
        true,
      );
      await RestApiUtils.labels.getCollection(
        {
          external_data_set: 'illumio_segmentation_templates',
        },
        true,
      );
      await RestApiUtils.services.getCollection(
        {
          external_data_set: 'illumio_segmentation_templates',
        },
        'draft',
        true,
      );
      await RestApiUtils.ipLists.getCollection(
        {
          external_data_set: 'illumio_segmentation_templates',
        },
        'draft',
        true,
      );
      await RestApiUtils.labelGroups.getCollection(
        {
          external_data_set: 'illumio_segmentation_templates',
        },
        'draft',
        true,
      );
      actionCreators.segmentationTemplatesCompareInstalledTemplates();
    },

    renderCards(templates, os) {
      if (!templates.length) {
        return null;
      }

      return (
        <div>
          <div className="SegmentationTemplateList-os" data-tid="templates-catalogue-os">
            {os}
          </div>
          {templates.map((template, index) => (
            <SegmentationTemplate
              key={index}
              onViewDetails={this.handleViewDetails}
              disableInstall={this.isUserReadOnly()}
              template={template}
              showButton={this.state.installStatusCalculated}
            />
          ))}
        </div>
      );
    },

    render() {
      const {state} = this;

      if (!SessionStore.isSegmentationTemplatesEnabled()) {
        return null;
      }

      if (this.state.templates === 'error') {
        const form = `${SessionStore.getSupportLink()}/pce-support-integration.html`;

        return (
          <SegmentationTemplateLogin
            fileError
            onCancel={() => {
              actionCreators.segmentationTemplateParseData([{illumio_security_templates: []}]);
            }}
            onLoadFile={() => {
              this.handleUpload();
            }}
            onConfirm={() => {
              // We can't send request using fetch api,
              // because response returns redirection (302) with 'Location' header that we need to read and handle manually,
              // and fetch has response='manually' option to do it, but it can't be polyfilled using xhr (IE, Safari).
              // That's why we have to use old-school way - create Form and submit it to remote support address
              submitFormTo(form, {pce_hostname: window.location.origin});
              // Never call resolve() in this case to prevent RestApiUtils fetching during browser navigation
            }}
          />
        );
      }

      return (
        <div className="ListPage SegmentationTemplateList">
          {state.status.includes(Constants.STATUS_BUSY) || state.installing || !state.installStatusCalculated ? (
            <SpinnerOverlay />
          ) : null}
          <div className="SegmentationTemplateList-catalogue" data-tid="templates-catalogue">
            {this.renderCards(state.windowsTemplates, intl('SegmentationTemplates.ForWindows'))}
            {this.renderCards(state.linuxTemplates, intl('SegmentationTemplates.ForLinux'))}
            {this.renderCards(state.universalTemplates, intl('SegmentationTemplates.Universal'))}
          </div>
        </div>
      );
    },
  }),
  {
    viewName: () => intl('SegmentationTemplates.SegmentationTemplates'),
    isAvailable: () => SessionStore.isSegmentationTemplatesEnabled(),
  },
);
