/**
 * Copyright 2016 Illumio, Inc. All Rights Reserved.
 */
import React, {PropTypes} from 'react';
import intl from '@illumio-shared/utils/intl';
import {Form} from '../FormComponents';
import {OSLabelSelect} from '..';
import {looseEqual} from '../../utils/GeneralUtils';
import {WorkloadUtils} from '../../utils';

export default React.createClass({
  propTypes: {
    type: PropTypes.string,
    model: PropTypes.object,
    onCancel: PropTypes.func,
    onSubmit: PropTypes.func.isRequired,
  },

  getInitialState() {
    return {
      model: {},
      errorModel: {},
      initialModel: {},
      dupeMode: false,
      editMode: false,
      selectedLabels: [],
    };
  },

  componentWillMount() {
    const resourceType = 'rule_sets';

    this.formFields = [
      {
        type: 'fieldset',
        name: 'general',
        title: intl('Common.General'),
        fields: [
          {
            type: 'field',
            label: intl('Common.Name'),
            name: 'name',
            required: true,
            controls: [
              {
                type: 'text',
                maxlength: 255,
                name: 'name',
                placeholder: intl('Rulesets.CreatePage.Placeholder.RulesetName'),
              },
            ],
          },
          {
            type: 'field',
            label: intl('Common.Description'),
            name: 'description',
            controls: [
              {
                type: 'textarea',
                maxlength: 255,
                name: 'description',
                placeholder: intl('Rulesets.CreatePage.Placeholder.RulesetDescription'),
              },
            ],
          },
          {
            type: 'field',
            name: 'enabled',
            label: intl('Common.Enabled'),
            hidden: () => !this.state.editMode,
            controls: [
              {
                type: 'radiogroup',
                name: 'enabled',
                display: 'horizontal',
                options: [
                  {value: 'true', label: intl('Common.Enabled')},
                  {value: 'false', label: intl('Common.Disabled')},
                ],
              },
            ],
          },
        ],
      },
      {
        type: 'fieldset',
        name: 'scope',
        title: intl('Common.Scope'),
        hidden: () => this.state.dupeMode || this.state.editMode,
        fields: [
          {
            type: 'field',
            label: intl('Common.Application'),
            name: 'applabel',
            required: true,
            controls: [
              {
                type: 'custom',
                name: 'applabel',
                getRef: refs => refs.objectSelector.refs.itemInput,
                getControl: props => (
                  <OSLabelSelect
                    placeholder={intl('Rulesets.CreatePage.Placeholder.AppScope')}
                    onChange={(added, removed) => {
                      props.onChange(removed ? undefined : added);
                    }}
                    type="app"
                    allowMultiSelect={false}
                    defaultSelected={true}
                    all={true}
                    allowGroup={true}
                    selected={props.value ? [props.value] : []}
                    key={props.key}
                    error={props.error}
                    disabled={props.disabled}
                    onFocus={props.onFocus}
                    //onBlur={props.onBlur} commented out because label groups in object selector wont work when blur method supplied...
                    tid={props.tid}
                    ref={props.ref}
                    tabIndex={props.tabIndex}
                    resourceType={resourceType}
                    selectedLabels={this.state.selectedLabels}
                  />
                ),
              },
            ],
          },
          {
            type: 'field',
            label: intl('Common.Environment'),
            name: 'envlabel',
            required: true,
            controls: [
              {
                type: 'custom',
                name: 'envlabel',
                getRef: refs => refs.objectSelector.refs.itemInput,
                getControl: props => (
                  <OSLabelSelect
                    placeholder={intl('Rulesets.CreatePage.Placeholder.EnvScope')}
                    onChange={(added, removed) => {
                      props.onChange(removed ? undefined : added);
                    }}
                    type="env"
                    allowMultiSelect={false}
                    defaultSelected={true}
                    all={true}
                    allowGroup={true}
                    selected={props.value ? [props.value] : []}
                    key={props.key}
                    error={props.error}
                    disabled={props.disabled}
                    onFocus={props.onFocus}
                    //onBlur={props.onBlur} commented out because label groups in object selector wont work when blur method supplied...
                    tid={props.tid}
                    ref={props.ref}
                    tabIndex={props.tabIndex}
                    resourceType={resourceType}
                    selectedLabels={this.state.selectedLabels}
                  />
                ),
              },
            ],
          },
          {
            type: 'field',
            label: intl('Common.Location'),
            name: 'loclabel',
            required: true,
            controls: [
              {
                type: 'custom',
                name: 'loclabel',
                getRef: refs => refs.objectSelector.refs.itemInput,
                getControl: props => (
                  <OSLabelSelect
                    placeholder={intl('Rulesets.CreatePage.Placeholder.LocScope')}
                    onChange={(added, removed) => {
                      props.onChange(removed ? undefined : added);
                    }}
                    type="loc"
                    allowMultiSelect={false}
                    defaultSelected={true}
                    all={true}
                    allowGroup={true}
                    selected={props.value ? [props.value] : []}
                    key={props.key}
                    error={props.error}
                    disabled={props.disabled}
                    onFocus={props.onFocus}
                    //onBlur={props.onBlur} commented out because label groups in object selector wont work when blur method supplied...
                    tid={props.tid}
                    ref={props.ref}
                    tabIndex={props.tabIndex}
                    resourceType={resourceType}
                    selectedLabels={this.state.selectedLabels}
                  />
                ),
              },
            ],
          },
        ],
      },
    ];
  },

  componentDidMount() {
    this.setInitialModel(this.props.model);
  },

  componentWillReceiveProps(nextProps) {
    if (this.areModelsDifferent(this.state.initialModel, this.getFriendlyModel(nextProps.model))) {
      this.setInitialModel(nextProps.model);
    }
  },

  componentDidUpdate(prevProps, prevState) {
    const {model} = this.state;
    const {model: prevModel} = prevState;

    if (
      model.applabel !== prevModel.applabel ||
      model.envlabel !== prevModel.envlabel ||
      model.loclabel !== prevModel.loclabel
    ) {
      this.setState(() => {
        const {applabel: app, envlabel: env, loclabel: loc} = model;

        const selectedLabels = WorkloadUtils.getSelectedLabels({
          ...(app && {app}),
          ...(env && {env}),
          ...(loc && {loc}),
        });

        return {
          selectedLabels,
        };
      });
    }
  },

  getFriendlyModel(unfriendlyModel) {
    const model = unfriendlyModel || {};
    const friendlyModel = {
      href: model.href,
      name: model.name,
      description: model.description,
      applabel: model.applabel,
      envlabel: model.envlabel,
      loclabel: model.loclabel,
    };

    if (model.enabled !== undefined && model.href) {
      friendlyModel.enabled = model.enabled ? 'true' : 'false';
    }

    return friendlyModel;
  },

  setInitialModel(initialModel) {
    const model = this.getFriendlyModel(initialModel);
    //these are required as state variables since create and duplicate are rendered on the same page, and a user can route directly between them
    const editMode = Boolean(initialModel && model.href);
    const dupeMode = Boolean(initialModel && !model.href);

    this.setState({model, initialModel: model, editMode, dupeMode});
  },

  handleChange(modelUpdates) {
    this.setState(previousState => ({model: {...previousState.model, ...modelUpdates}}));
  },

  handleErrorChange(errorModelUpdates) {
    this.setState(previousState => ({errorModel: {...previousState.errorModel, ...errorModelUpdates}}));
  },

  handleSubmit() {
    const model = {
      enabled: this.state.model.enabled === 'true' || this.state.model.enabled === undefined,
      name: this.state.model.name,
    };

    if (this.state.model.description || this.state.model.description === '') {
      model.description = this.state.model.description;
    }

    if (this.state.model.applabel || this.state.model.envlabel || this.state.model.loclabel) {
      const scope = [];

      if (this.state.model.applabel && this.state.model.applabel.type !== 'all') {
        scope.push({
          [String(this.state.model.applabel.href.includes('label_group') ? 'label_group' : 'label')]: {
            href: this.state.model.applabel.href,
          },
        });
      }

      if (this.state.model.envlabel && this.state.model.envlabel.type !== 'all') {
        scope.push({
          [String(this.state.model.envlabel.href.includes('label_group') ? 'label_group' : 'label')]: {
            href: this.state.model.envlabel.href,
          },
        });
      }

      if (this.state.model.loclabel && this.state.model.loclabel.type !== 'all') {
        scope.push({
          [String(this.state.model.loclabel.href.includes('label_group') ? 'label_group' : 'label')]: {
            href: this.state.model.loclabel.href,
          },
        });
      }

      model.scopes = [scope];
    }

    this.props.onSubmit(model);
  },

  areModelsDifferent(original, compare) {
    return (
      !looseEqual(original.name, compare.name) ||
      !looseEqual(original.description, compare.description) ||
      !looseEqual(original.enabled, compare.enabled) ||
      !looseEqual(original.applabel, compare.applabel) ||
      !looseEqual(original.envlabel, compare.envlabel) ||
      !looseEqual(original.loclabel, compare.loclabel)
    );
  },

  hasChanged() {
    return this.areModelsDifferent(this.state.initialModel, this.state.model);
  },

  clearForm() {
    this.setInitialModel();
  },

  render() {
    return (
      <Form
        type={this.props.type}
        fields={this.formFields}
        model={this.state.model}
        errorModel={this.state.errorModel}
        onChange={this.handleChange}
        onErrorChange={this.handleErrorChange}
        onSubmit={this.handleSubmit}
        onCancel={this.props.onCancel}
      />
    );
  },
});
