/**
 * Copyright 2016 Illumio, Inc. All Rights Reserved.
 */
/**
 * Bulletproof methods to deal with localStorage/sessionStorage
 */

import JSONBig from 'json-bigint-keep-object-prototype-methods';

const JSONBigIntNative = JSONBig({useNativeBigInt: true, objectProto: true});

export function getItem(
  key: string,
  {session = false, parse = true, remove = false} = {},
): string | unknown | null | undefined {
  let result: unknown;
  let value;

  try {
    value = (session ? sessionStorage : localStorage).getItem(key);

    if (value) {
      result = parse ? JSONBigIntNative.parse(value) : value;

      if (remove) {
        removeItem(key, {session});
      }
    }
  } catch (error) {
    console.warn(`Can not parse value (${value}) of ${key} from ${session ? 'session' : 'local'}Storage`, error);
  }

  return result === null ? undefined : result;
}

export function setItem(key: string, value: unknown, {session = false, stringify = true} = {}): undefined {
  if (value === undefined) {
    // JSON.stringify of undefined returns undefined, that localStorage will cast to string 'undefined',
    // and then after getItem this value will be unparsable by JSON.parse
    // So not setting this value is right behavior - getItem will return expected undefined
    return;
  }

  try {
    (session ? sessionStorage : localStorage).setItem(
      key,
      stringify ? JSONBigIntNative.stringify(value) : (value as string),
    );
  } catch (error) {
    console.warn(`Can not set value (${value}) of ${key} to ${session ? 'session' : 'local'}Storage`, error);
  }
}

export function removeItem(key: string, {session = false} = {}): void {
  try {
    (session ? sessionStorage : localStorage).removeItem(key);
  } catch (error) {
    console.warn(`Can not remove ${session ? 'session' : 'local'}Storage item${key}. Set null`, error);
    setItem(key, null);
  }
}

export function clear({session = false} = {}): void {
  try {
    (session ? sessionStorage : localStorage).clear();
  } catch (error) {
    console.warn(`Can not clear ${session ? 'session' : 'local'}Storage`, error);
  }
}

/* Aliases using sessionStorage */

export function getSessionItem(key: string, options?: {session?: boolean}): string | unknown | null | undefined {
  return getItem(key, {...options, session: true});
}

export function setSessionItem(key: string, value: unknown, options?: {session?: boolean}): undefined {
  return setItem(key, value, {...options, session: true});
}

export function removeSessionItem(key: string): void {
  return removeItem(key, {session: true});
}

export function clearSession(): void {
  return clear({session: true});
}
