/**
 * Copyright 2014 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import intl from '@illumio-shared/utils/intl';
import {createApiStore} from '../lib/store';
import Constants from '../constants';
import TrafficFilterStore from './TrafficFilterStore';
import ServiceUtils from '../utils/ServiceUtils';
import RuleGraphUtils from '../utils/RuleGraphUtils';
import RenderUtils from '../utils/RenderUtils';
import GeneralUtils from '../utils/GeneralUtils';
import MapPageStore from './MapPageStore';
import VersionStore from './VersionStore';
import OrgStore from './OrgStore';
import SessionStore from './SessionStore';
import dispatcher from '../actions/dispatcher';
import {GraphDataUtils} from '../utils';

const UPDATE_EVENT = 'update';
const APP_GROUPS_LOADED_EVENT = 'appGroupsLoaded';
const LOCATIONS_LOADED_EVENT = 'locationsLoaded';
const WORKLOADS_LOADED_EVENT = 'workloadsLoaded';
const FLOW_TIME_DIFFERENCE = 60 * 30;
const CURRENT_TRAFFIC_VERSION = 3;
const trafficClasses = ['unicast', 'broadcast', 'multicast', 'core_service'];
const policyDecisions = RenderUtils.policyDecisions();

let locationNodes = {};
const clusterNodes = {};
let clusterTraffics = {};
let appGroupNodes = {};
const phantomAppGroupNodes = {};
let appGroupTraffics = {};
let graphReadyAppGroupTraffic = {};
let workloadTraffics = {};
const workloadNodes = {};
let roleTraffics = {};
const roleNodes = {};
let mixedRoleTraffics = {};
let appGroupsType;
const fqdns = [];
const reverseFqdnLookup = {};
const clusterCaps = {};
let ringFenceRules = {};
let oldFocused;

let rules = {};
const groupSearch = {};
let ipLists = {};
const nodeVulnerabilities = {};

const clustersLoaded = {};
let loadedLocationSummarySuccess = false;
let loadedAppGroupsSuccess = false;
let loadedLocationSummary = {loaded: {}, requested: {}};
let loadedAppGroupsForTraffic = {loaded: {}, requested: {}};
let loadedClustersForTraffic = {loaded: {}, requested: {}};
let loadedRolesForTraffic = {loaded: {}, requested: {}};
let loadedWorkloadsForTraffic = {loaded: {}, requested: {}};
let loadedNodesForVulnerabilities = {loaded: {}, requested: {}};
let loadedForCaps = {loaded: {}, requested: {}};

const clearOnNext = new Set();
let expandedRoleHrefs = [];
let roleThreshold = parseInt(localStorage.getItem('role_collapse'), 10) || 1; // change based on traffic parameters
let trafficForRuleCoverage = {}; // Contains the avenger data of the links

let requestedHrefsForRuleCoverage = [];
const nodeIpListTrafficMap = {};
let ruleBuilderCoverageIsLoaded = false;
let matchingAppGroupType = matchingAppGroupTypes();
let broadcastTrafficLoaded = false;
let multicastTrafficLoaded = false;

// Traffic filters from localStorage not from store
// to avoid self calling loop between TrafficStore and TrafficFilterStore
let serviceFilters = localStorage.getItem('serviceFilters')
  ? JSON.parse(localStorage.getItem('serviceFilters'))
  : {
      ignoreServices: false,
      services: {},
    };

const filterVersion = localStorage.getItem('connectionFilterVersion');

// If the version is older than 20.2 start over
if (!filterVersion || filterVersion < 22.2) {
  localStorage.removeItem('connectionFilters');
  localStorage.removeItem('trafficTimeFilters');
}

localStorage.setItem('connectionFilterVersion', '22.2');

const defaultConnectionFilters = {
  allowIcmpTraffic: true,
  allowBroadcastTraffic: false,
  allowMulticastTraffic: false,
  allowBlockedTraffic: true,
  allowPotentiallyBlockedTraffic: true,
  allowBlockedByBoundaryTraffic: true,
  allowPotentiallyBlockedByBoundaryTraffic: true,
  allowAllowedAcrossBoundaryTraffic: true,
  allowAllowedTraffic: true,
  allowUnknownTraffic: true,
};
let connectionFilters = localStorage.getItem('connectionFilters');
let areConnectionFiltersComplete;

if (connectionFilters) {
  connectionFilters = JSON.parse(connectionFilters);
  areConnectionFiltersComplete = Object.keys(defaultConnectionFilters).every(key =>
    connectionFilters.hasOwnProperty(key),
  );
}

if (!areConnectionFiltersComplete) {
  connectionFilters = defaultConnectionFilters;
}

localStorage.setItem('connectionFilters', JSON.stringify(connectionFilters));

let trafficTimeFilters = JSON.parse(localStorage.getItem('trafficTimeFilters')) || {
  timeFilter: {type: 'time', value: 'anytime'},
};

const coreServicesPortProto = ['53 TCP', '53 UDP', '5353 TCP', '5353 UDP', '67 UDP', '68 UDP', '500 UDP', '4500 UDP'];

const isCoreServiceAllowed = () =>
  serviceFilters.ignoreServices &&
  Object.keys(serviceFilters.services).find(
    portProto =>
      // If the service is not ignored and is a core service
      !serviceFilters.services[portProto] && coreServicesPortProto.includes(portProto),
  );

let coreServiceAllowed = isCoreServiceAllowed();

function matchingAppGroupTypes() {
  // Return after looking at the first app group to make this fast at scale
  if (!Array.isArray(appGroupsType) || !appGroupsType.length) {
    return true;
  }

  const firstNode = Object.values(appGroupNodes)[0];

  return !firstNode || firstNode.href.split('x').length === appGroupsType.length;
}

// Make an array of unique fqdns because the backend can no longer guarentee the fqdn indicies are the same across APIs
function addFqdns(fqdnData) {
  Object.values(fqdnData).forEach(fqdn => {
    if (!reverseFqdnLookup[fqdn]) {
      reverseFqdnLookup[fqdn] = fqdns.length;
      fqdns.push(fqdn);
    }
  });
}

function sortIpLists(ipListIndexes) {
  // Handle the old cached graphs with the iplist details in the node
  if (isNaN(ipListIndexes[0])) {
    return _.map(ipListIndexes, 'href').sort();
  }

  return ipListIndexes.map(index => ipLists[index] && ipLists[index].href).sort();
}

// getters
function getNodeType(node) {
  switch (node.type) {
    case 'cluster':
      return 'group';
    case 'ip_list':
      return 'ipList';
    case 'app_group':
      return 'appGroup';
    case 'virtual_service':
      return 'virtualService';
    default:
      return node.type;
  }
}

function getNodeHref(node) {
  if (node.type === 'ip_list') {
    return sortIpLists(node.ip_lists);
  }

  if (node.type === 'internet') {
    return node.subtype || node.type;
  }

  return node.href || node.key;
}

function getNodeLabels(node, labels) {
  return node.label_ids.map(labelId => labels[labelId] && labels[labelId].label).filter(label => label); // returns {href: href}
}

function getNodeLabelsObject(node, labels) {
  return node.label_ids.reduce((result, labelId) => {
    const label = labels[labelId] && labels[labelId].label;

    if (label) {
      result[label.key] = label || {};
    }

    return result;
  }, {});
}

function getNodeAddresses(node, fqdn) {
  if (node.type === 'internet') {
    return node.address ? [node.address] : [];
  }

  if (node.traffic_workload) {
    return [node.traffic_workload.address];
  }

  if (node.ip_lists) {
    // If the IP list was matched based on the fqdn, add that to the IP information
    if (fqdn && fqdn !== 'unknown') {
      return [`${node.address} - ${fqdn}`];
    }

    return [node.address];
  }

  if (node.virtual_servers) {
    return [node.address];
  }
}

// Convert Entity to Rule Coverage Format
function getRuleCoverageEntity(entity) {
  switch (entity.type) {
    case 'internet':
      return {
        actors: 'all',
      };
    case 'ipList':
    case 'fqdn':
      if (!entity.href.includes('ip_list')) {
        return {
          actors: 'all',
        };
      }

      return {
        ip_list: {
          href: entity.href,
        },
      };
    case 'workload':
      const workload = workloadNodes[entity.href];

      if (!entity.href) {
        return {actors: 'ams'};
      }

      if (workload.subType === 'container') {
        return {
          container_workload: {
            href: entity.href,
          },
        };
      }

      return {
        workload: {
          href: entity.href,
        },
      };
    case 'virtualService':
      const virtualService = workloadNodes[entity.href];

      if (virtualService.subType === 'virtual_server') {
        return {
          virtual_server: {
            href: entity.href,
          },
        };
      }

      return {
        virtual_service: {
          href: entity.href,
        },
      };
    case 'role':
      return {
        labels: roleNodes[entity.href] && roleNodes[entity.href].labels.map(label => ({href: label.href})),
      };
    case 'group':
      return {
        labels: clusterNodes[entity.href] && clusterNodes[entity.href].labels.map(label => ({href: label.href})),
      };
    case 'appGroup':
      return {
        labels: appGroupNodes[entity.href] && appGroupNodes[entity.href].labels.map(label => ({href: label.href})),
      };
  }
}

function getNodeByHref(href) {
  return appGroupNodes[href] || clusterNodes[href] || workloadNodes[href] || roleNodes[href] || locationNodes[href];
}

function getTrafficByHref(href) {
  return clusterTraffics[href] || workloadTraffics[href] || roleTraffics[href] || mixedRoleTraffics[href];
}

function getTrafficsByType(nodeType) {
  let traffics = workloadTraffics;

  if (nodeType === 'groups') {
    traffics = clusterTraffics;
  } else if (nodeType === 'roles') {
    traffics = roleTraffics;
  } else if (nodeType === 'appGroups') {
    traffics = appGroupTraffics;
  }

  return traffics;
}

// Get nodes contained in a scope from the api request.
// Example: Request was for workload traffic(nodeType) in cluster_key(scopeType) AxBxC(scope)
// Return all the workloads in the cluster AxBxC.
// If the scope and node type are the same, return the scope itself.
function getNodesForScope(rawLabels, nodeType, scopeType, scopes, labels) {
  if (nodeType === scopeType) {
    return scopes;
  }

  if (scopeType === 'all') {
    switch (nodeType) {
      case 'groups':
        return _.map(clusterNodes, 'href');
      case 'roles':
        return _.map(roleNodes, 'href');
      case 'workloads':
        return _.map(workloadNodes, 'href');
      default:
    }
  }

  if (scopeType === 'location' && nodeType === 'groups') {
    //Find clusters which contain all the labels in the request
    // reject all role labels
    labels = labels.flat().filter(label => {
      const labelId = _.last(label.split('/'));

      return rawLabels[labelId] && rawLabels[labelId].label && rawLabels[labelId].label.key !== 'role';
    });

    return _.transform(
      clusterNodes,
      (result, cluster) => {
        if ((labels.length && _.intersection(labels, _.map(cluster.labels, 'href')).length) || !labels.length) {
          result.push(cluster.href);
        }
      },
      [],
    );
  }

  if (scopeType === 'groups' || scopeType === 'appGroups') {
    const nodes = nodeType === 'roles' ? roleNodes : workloadNodes;

    return _.transform(
      nodes,
      (result, node) => {
        const isNodeInScopes =
          scopeType === 'groups' ? scopes.includes(node.clusterParent) : scopes.includes(node.appGroupParent);

        if (isNodeInScopes && (_.isEmpty(labels) ? true : RuleGraphUtils.isNodeInScopes(node, labels))) {
          result.push(node.href);
        }
      },
      [],
    );
  }

  if (scopeType === 'roles') {
    return _.transform(
      workloadNodes,
      (result, node) => {
        if (
          scopes.includes(node.roleParent) &&
          (_.isEmpty(labels) ? true : RuleGraphUtils.isNodeInScopes(node, labels))
        ) {
          result.push(node.href);
        }
      },
      [],
    );
  }
}

function getLoadedTrafficByKey(type, key, labelsKey, exclude, connected, version) {
  let loadedNodes;

  switch (type) {
    case 'locations':
      loadedNodes = loadedLocationSummary[version];
      break;
    case 'appGroups':
      loadedNodes = loadedAppGroupsForTraffic[version];
      break;
    case 'groups':
      loadedNodes = loadedClustersForTraffic[version];
      break;
    case 'roles':
      loadedNodes = loadedRolesForTraffic[version];
      break;
    case 'workloads':
      loadedNodes = loadedWorkloadsForTraffic[version];
      break;
    default:
      return;
  }

  return loadedNodes[key] === [labelsKey, exclude, connected].join(',');
}

function setLoadedTrafficKeys(type, keys, labelsKey, exclude, connected, version) {
  let loadedNodes;

  switch (type) {
    case 'locations':
      loadedNodes = loadedLocationSummary[version];
      break;
    case 'appGroups':
      loadedNodes = loadedAppGroupsForTraffic[version];
      break;
    case 'groups':
      loadedNodes = loadedClustersForTraffic[version];
      break;
    case 'roles':
      loadedNodes = loadedRolesForTraffic[version];
      break;
    case 'workloads':
      loadedNodes = loadedWorkloadsForTraffic[version];
      break;
    default:
      return;
  }

  // The traffic loaded nodes keyed by [node_key][labels]
  // Where node_key can equal 'no_key', and labels can be 'no_labels'
  if (keys.length) {
    keys.forEach(key => {
      loadedNodes[key] ||= {};

      loadedNodes[key] = [labelsKey, exclude, connected].join(',');
    });
  }
}

// setters
function setExpandedRoles(data) {
  const maximumExpandedRoles = parseInt(localStorage.getItem('maximum_expanded_roles'), 10) || 3; // change based on traffic parameters

  if (expandedRoleHrefs.length >= maximumExpandedRoles) {
    expandedRoleHrefs = _.takeRight(expandedRoleHrefs, maximumExpandedRoles - 1);
  }

  expandedRoleHrefs = _.union(expandedRoleHrefs, data);
}

function resetTrafficFilters() {
  serviceFilters = {
    ignoreServices: false,
    services: {},
  };

  localStorage.setItem('serviceFilters', JSON.stringify(serviceFilters));

  trafficTimeFilters = {timeFilter: {type: 'time', value: 'anytime'}};
  localStorage.setItem('trafficTimeFilters', JSON.stringify(trafficTimeFilters));

  connectionFilters = {
    allowIcmpTraffic: true,
    allowBroadcastTraffic: false,
    allowMulticastTraffic: false,
    allowBlockedTraffic: true,
    allowBlockedByBoundaryTraffic: true,
    allowPotentiallyBlockedByBoundaryTraffic: true,
    allowPotentiallyBlockedTraffic: true,
    allowAllowedAcrossBoundaryTraffic: true,
    allowAllowedTraffic: true,
    allowUnknownTraffic: true,
  };
  localStorage.setItem('connectionFilters', JSON.stringify(connectionFilters));

  coreServiceAllowed = false;

  // old traffic shouldn't be hide if in draft view
  const isDraftView = MapPageStore.getPolicyVersion() === 'draft';

  calculateFilteredConnections(workloadTraffics, isDraftView);
  calculateFilteredConnections(clusterTraffics, isDraftView);
  calculateFilteredConnections(roleTraffics, isDraftView);
  calculateFilteredConnections(mixedRoleTraffics, isDraftView);
  aggregateAllAppGroupTraffic();
}

// set filters for ignoreServices and hideOldTraffic
function setTrafficFilters(data, filterType) {
  // When filter type is connectionFilters these are bypassed.
  if (filterType === 'serviceFilters') {
    serviceFilters[data.label] = data.value;
    localStorage.setItem('serviceFilters', JSON.stringify(serviceFilters));
  }

  if (filterType === 'timeFilters') {
    trafficTimeFilters[data.label] = data.value;
    localStorage.setItem('trafficTimeFilters', JSON.stringify(trafficTimeFilters));
  }

  if (filterType === 'connectionFilters') {
    connectionFilters[data.label] = data.value;
    localStorage.setItem('connectionFilters', JSON.stringify(connectionFilters));
  }

  coreServiceAllowed = isCoreServiceAllowed();

  // old traffic shouldn't be hide if in draft view
  const isDraftView = MapPageStore.getPolicyVersion() === 'draft';

  calculateFilteredConnections(workloadTraffics, isDraftView);
  calculateFilteredConnections(clusterTraffics, isDraftView);
  calculateFilteredConnections(roleTraffics, isDraftView);
  calculateFilteredConnections(mixedRoleTraffics, isDraftView);
  aggregateAllAppGroupTraffic();
}

function isTransmissionfiltered(transmission) {
  switch (transmission) {
    case 'broadcast':
      return !connectionFilters.allowBroadcastTraffic;
    case 'multicast':
      return !connectionFilters.allowMulticastTraffic;
    case 'core_service':
      return !coreServiceAllowed;
  }

  return false;
}

function isConnectionFiltered(connection, lastProvisionTime = VersionStore.getLatestProvisionTime()) {
  if (!connection) {
    return false;
  }

  // See if the connection is within ignored services.  if it's not, increment filtered connections/sessions
  const serviceKey = `${connection.port} ${connection.friendlyProtocol}`;
  const version = MapPageStore.getPolicyVersion();
  const isServiceFiltered = serviceFilters.ignoreServices
    ? serviceFilters.services[serviceKey]
    : coreServicesPortProto.includes(serviceKey);

  let isTimeFiltered = false;

  // Time filters
  if (trafficTimeFilters.timeFilter) {
    const filterTime = RenderUtils.getTimeFromFilter(trafficTimeFilters.timeFilter, lastProvisionTime);

    isTimeFiltered =
      connection.timestamp && filterTime && filterTime !== 'anytime' && connection.timestamp < filterTime;
  }

  // Conditions to check a ICMP, Broadcast or Multicast connection has to be filtered.
  const isIcmpTraffic =
    !connectionFilters.allowIcmpTraffic &&
    [intl('Protocol.ICMP'), intl('Protocol.ICMPv6')].includes(connection.friendlyProtocol);
  const isBroadCastTraffic = !connectionFilters.allowBroadcastTraffic && connection.connectionClass === 'B';
  const isMultiCastTraffic = !connectionFilters.allowMulticastTraffic && connection.connectionClass === 'M';

  // Get the current filters
  const policyFilters = policyDecisions.map(
    policyDecision => !connectionFilters[`allow${_.upperFirst(policyDecision)}Traffic`],
  );
  // Get the policy values of the current connection
  const policyValues = policyDecisions.map(policyDecision =>
    RenderUtils.isConnection(policyDecision, connection, version),
  );
  // If every connection policy is filtered remove the connection
  const filteredPolicy = policyValues.every((policy, index) => !policy || policyFilters[index]);

  return (
    isServiceFiltered || isTimeFiltered || isIcmpTraffic || isBroadCastTraffic || isMultiCastTraffic || filteredPolicy
  );
}

function calculateFilteredConnections(traffics) {
  const version = MapPageStore.getPolicyVersion();

  _.forOwn(traffics, traffic => {
    traffic.filteredConnections = 0;
    traffic.filteredSessions = 0;
    traffic.filteredRules = 0;
    traffic.filteredDenyRules = 0;
    traffic.filteredAllowDenyRules = 0;

    policyDecisions.forEach(policyDecision => (traffic.filtered[policyDecision] = 0));

    _.forOwn(traffic.connections, connection => {
      if (!isConnectionFiltered(connection)) {
        const policyValues = policyDecisions.map(policyDecision =>
          RenderUtils.isConnection(policyDecision, connection, version),
        );

        // Get the current filters
        const policyFilters = policyDecisions.map(
          policyDecision => connectionFilters[`allow${_.upperFirst(policyDecision)}Traffic`],
        );

        // If this connection's policy matches a filter
        if (policyValues.some((policyValue, index) => policyFilters[index] && policyValue)) {
          traffic.filteredSessions += connection.sessions;
          traffic.filteredConnections += 1;
        }

        const draftAllowed = RenderUtils.isConnection('allowed', connection, 'draft');
        const draftBlocked = RenderUtils.isConnection('blockedByBoundary', connection, 'draft');
        const draftPotentiallyBlocked = RenderUtils.isConnection('potentiallyBlockedByBoundary', connection, 'draft');

        if (draftAllowed && draftBlocked) {
          traffic.filteredAllowDenyRules += connection.sessions;
        } else if (draftAllowed) {
          traffic.filteredRules += connection.sessions;
        } else if (draftBlocked || draftPotentiallyBlocked) {
          traffic.filteredDenyRules += connection.sessions;
        }

        policyDecisions.forEach((policyDecision, index) => {
          // Count this policy state if the filter matches this connection
          if (
            policyFilters[index] &&
            policyValues[index] &&
            // and none of the higher priority policyDecisions are counted
            policyDecisions.every(
              (comparePolicyDecision, compareIndex) =>
                compareIndex <= index || !policyFilters[compareIndex] || !policyValues[compareIndex],
            )
          ) {
            traffic.filtered[policyDecision] += connection.sessions;
          }
        });
      }
    });
  });
}

function addRequestedNodesForVulnerabilities(nodesHref) {
  nodesHref.forEach(nodeHref => {
    loadedNodesForVulnerabilities.requested[nodeHref] = true;
  });
}

function addRequestedNodesForCaps(nodesHref) {
  nodesHref.forEach(nodeHref => {
    loadedForCaps.requested[nodeHref] = true;
  });
}

function addRequestedClustersForCaps(clustersHref) {
  clustersHref.forEach(clustersHref => {
    loadedForCaps.requested[clustersHref] = true;
  });
}

function removeExpandedRoles(data) {
  expandedRoleHrefs = _.difference(expandedRoleHrefs, data);
}

function removeNodes(nodesHref = [], built) {
  nodesHref.forEach(nodeHref => {
    // delete appGroupNodes[nodeHref];
    if (built && clusterNodes[nodeHref] && clusterNodes[nodeHref].built < built) {
      delete clusterNodes[nodeHref];
    }

    delete roleNodes[nodeHref];
    delete workloadNodes[nodeHref];
    delete loadedForCaps.requested[nodeHref];
  });
}

function removeLoadedTraffic(type, nodesHref, version = ['loaded', 'requested'], filtered) {
  let loadedNodes;

  switch (type) {
    case 'appGroups':
      loadedNodes = loadedAppGroupsForTraffic;
    case 'groups':
      loadedNodes = loadedClustersForTraffic;
      break;
    case 'roles':
      loadedNodes = loadedRolesForTraffic;
      break;
    case 'workloads':
      loadedNodes = loadedWorkloadsForTraffic;
      break;
    case 'vulnerabilities':
      loadedNodes = loadedNodesForVulnerabilities;
    default:
      return;
  }

  // If filtered is set, then only clear the filtered items
  nodesHref.forEach(href => {
    ['unicast', 'broadcast', 'multicast', 'core_service'].forEach(transmission => {
      if (version.includes('loaded') && (!filtered || isTransmissionfiltered(transmission))) {
        delete loadedNodes.loaded[[href, transmission].join('x')];
      }

      if (version.includes('requested') && (!filtered || isTransmissionfiltered(transmission))) {
        delete loadedNodes.requested[[href, transmission].join('x')];
      }
    });
  });
}

// When policy changes or traffic is cleared,
// remove the rule data for applicable scopes
function removeRuleLoadedLinksForScope(data) {
  const scopes = data.oldScopes || [];
  let newScopes = [];
  let newNodes = [];

  if (data.rulesetId) {
    if (data.rule) {
      // a specific rule was modified
      const scopesAndNodes = RuleGraphUtils.getAllScopesAndNodesFromRule(data.rulesetId, data.rule);

      newScopes = scopesAndNodes.scopes;
      newNodes = scopesAndNodes.nodes;
    } else {
      // a whole ruleset was modified
      newScopes = RuleGraphUtils.getScopeByRulesetId(data.rulesetId);
    }
  } else if (data.rulesetHref) {
    newScopes = RuleGraphUtils.getScopeByRulesetHref(data.rulesetHref);
  }

  RuleGraphUtils.mergeScopes(scopes, newScopes);

  const labelGroup = _.find(scopes, scope => _.find(scope, label => label.includes('label_group')));

  if (labelGroup) {
    requestedHrefsForRuleCoverage = [];
    rules = {};
  } else {
    // Remove the Links in the scopes
    requestedHrefsForRuleCoverage = _.reject(requestedHrefsForRuleCoverage, linkHref => {
      const traffic = getTrafficByHref(linkHref);
      let sourceInScope;
      let targetInScope;

      // If the traffic has been removed, skip this and just clear out the rule, and requested flag
      if (traffic) {
        sourceInScope = RuleGraphUtils.isNodeInScopes(getNodeByHref(traffic.source.href), scopes);
        targetInScope = RuleGraphUtils.isNodeInScopes(getNodeByHref(traffic.target.href), scopes);
      }

      if (!traffic || sourceInScope || targetInScope) {
        delete rules[linkHref];

        return true;
      }
    });
  }

  const nodesHref = _.union(data.oldNodes, newNodes);

  removeRuleLoadedLinksForNodes(nodesHref);
}

// When policy changes or traffic is cleared,
// remove the rules associated with a set of nodes
function removeRuleLoadedLinksForNodes(nodesHref) {
  requestedHrefsForRuleCoverage = _.reject(requestedHrefsForRuleCoverage, linkHref =>
    _.find(nodesHref, nodeHref => {
      if (linkHref.includes(nodeHref)) {
        // TODO: remove the rules from the traffic here as well.
        delete rules[linkHref];

        return true;
      }
    }),
  );
}

// When traffic is cleared,
// remove traffic associated with a set of scopes
function removeTrafficForScopes(scopes, deleteTraffic) {
  // If we only change the role, don't reload the location summary
  if (!RuleGraphUtils.onlyRoleChange(scopes)) {
    loadedLocationSummary = {loaded: {}, requested: {}};
  }

  let workloadsToRemove = [];
  let rolesToRemove = [];
  let clustersToRemove = [];

  if (_.isEmpty(scopes)) {
    // case: clear org traffic, so we send/receive empty array of scopes
    workloadsToRemove = _.map(workloadNodes, node => node.href);
    rolesToRemove = _.map(roleNodes, node => node.href);
    clustersToRemove = [..._.map(clusterNodes, node => node.href), ..._.map(appGroupNodes, node => node.href)];

    removeTrafficForNodes('workloads', workloadsToRemove, true, true);
    removeTrafficForNodes('roles', rolesToRemove, true, true);
    removeTrafficForNodes('groups', clustersToRemove, true, true);

    loadedClustersForTraffic = {loaded: {}, requested: {}};
    loadedRolesForTraffic = {loaded: {}, requested: {}};
    loadedWorkloadsForTraffic = {loaded: {}, requested: {}};
    loadedNodesForVulnerabilities = {loaded: {}, requested: {}};
  } else {
    const scopesArray = _.map(scopes, scope => {
      if (_.every(scope, label => !label.href)) {
        // if all the labels in the scope don't have href
        // then we must have been passed an array of href strings
        // so just return with the scope
        return scope;
      }

      return _.map(scope, 'href');
    });
    const clusterScopesArray = _.map(scopes, scope => {
      if (_.every(scope, label => !label.href)) {
        return scope;
      }

      return _.transform(
        scope,
        (result, label) => {
          if (label.key !== 'role' && label.key !== 'loc') {
            result.push(label.href);
          }
        },
        [],
      );
    });

    // for  workloads, we want to remove the parent roles that we've loaded
    // and for roles, we want to remove the parent clusters that we've loaded
    const requestedAppGroupKeys = Object.keys(loadedAppGroupsForTraffic.requested);
    const requestedClusterKeys = Object.keys(loadedClustersForTraffic.requested);
    const requestedRoleKeys = Object.keys(loadedRolesForTraffic.requested);
    const requestedWorkloadKeys = Object.keys(loadedWorkloadsForTraffic.requested);

    const removeCast = href =>
      ['unicast', 'broadcast', 'multicast'].reduce((result, cast) => result.replace(`x${cast}`, ''), href);

    new Set([...requestedAppGroupKeys, ...requestedClusterKeys, ...requestedWorkloadKeys]).forEach(nodeHrefCast => {
      const nodeHref = removeCast(nodeHrefCast);
      const node = getNodeByHref(nodeHref);

      if (RuleGraphUtils.isNodeInScopes(node, clusterScopesArray)) {
        clustersToRemove.push(nodeHref);
      }
    });

    new Set(requestedRoleKeys).forEach(nodeHrefCast => {
      const nodeHref = removeCast(nodeHrefCast);
      const node = getNodeByHref(nodeHref);

      if (RuleGraphUtils.isRequestedRoleInScopes(node, clusterScopesArray)) {
        clustersToRemove.push(nodeHref);
      }
    });

    _.forOwn(loadedWorkloadsForTraffic.requested, (value, nodeHrefCast) => {
      const nodeHref = removeCast(nodeHrefCast);
      const node = getNodeByHref(nodeHref);

      if (RuleGraphUtils.isNodeInScopes(node, scopesArray)) {
        rolesToRemove.push(nodeHref);
      }
    });

    const vulnerabilitiesToRemove = [];

    _.forOwn(loadedNodesForVulnerabilities.requested, (value, nodeHrefCast) => {
      const nodeHref = removeCast(nodeHrefCast);
      const node = getNodeByHref(nodeHref);

      if (RuleGraphUtils.isNodeInScopes(node, scopesArray)) {
        vulnerabilitiesToRemove.push(nodeHref);
      }
    });

    removeLoadedTraffic('workloads', rolesToRemove);
    removeLoadedTraffic('workloads', clustersToRemove);
    removeLoadedTraffic('roles', clustersToRemove);
    removeLoadedTraffic('vulnerabilities', vulnerabilitiesToRemove);
    removeLoadedTraffic('groups', clustersToRemove);
    removeLoadedTraffic('appGroups', clustersToRemove);

    if (deleteTraffic) {
      _.forOwn({...workloadNodes, ...roleNodes, ...clusterNodes}, node => {
        if (node.type === 'group') {
          if (RuleGraphUtils.isNodeInScopes(node, clusterScopesArray)) {
            clustersToRemove.push(node.href);
          }
        } else if (RuleGraphUtils.isNodeInScopes(node, scopesArray)) {
          // if node is in the provided scopes and we should remove it
          // see what its type is, so we can remove it from there
          if (node.type === 'workload') {
            workloadsToRemove.push(node.href);
          } else if (node.type === 'role') {
            rolesToRemove.push(node.href);
          }
        }
      });

      removeTrafficForNodes('workloads', workloadsToRemove, true, true, deleteTraffic);
      removeTrafficForNodes('roles', rolesToRemove, true, true, deleteTraffic);

      if (!RuleGraphUtils.onlyRoleChange(scopes)) {
        removeTrafficForNodes('groups', clustersToRemove, true, true, deleteTraffic);
      }
    }
  }
}

// When traffic is cleared,
// remove traffic for a set of nodes
function removeTrafficForNodes(
  nodeType,
  nodes = [],
  removeRules,
  forceDelete,
  deleteTraffic,
  isSingleClusterLink,
  built,
  connectedGroup,
) {
  const nodesObj = _.countBy(nodes);
  const traffics = getTrafficsByType(nodeType);

  nodes.forEach(node => {
    delete nodeIpListTrafficMap[node];

    if (nodeType === 'workloads') {
      delete workloadNodes[node];
    } else if (nodeType === 'roles') {
      if (!connectedGroup) {
        delete roleNodes[node];
      }
    } else if (
      nodeType === 'groups' &&
      MapPageStore.getMapLevel() !== 'full' &&
      (!clusterNodes[node] || !built || clusterNodes[node].built < built)
    ) {
      delete clusterNodes[node];
    }
  });

  _.forOwn(traffics, (traffic, trafficHref) => {
    // if at least one side is managed and in nodesObj, we should delete the link
    // Clear traffic if current or received traffic is simple, or if private_addresses are requested
    // Leave the cluster simple traffic intact, so we can load the full traffic link by link

    // Only delete the intrascope traffic of the connected groups
    if (
      (nodesObj[traffic.source.href] || nodesObj[traffic.target.href]) &&
      (!connectedGroup ||
        nodeType !== 'roles' ||
        !RenderUtils.isExtraScopeTraffic(traffic.source.href, traffic.target.href))
    ) {
      if ((traffic.type === 'simple' && !isSingleClusterLink) || forceDelete) {
        if (nodeType === 'workloads') {
          const roleParentTraffic = roleTraffics[traffic.roleParentTraffic];

          if (roleParentTraffic) {
            delete roleParentTraffic.childrenTraffics[trafficHref];
          }

          const mixedRoleParentTraffic = mixedRoleTraffics[traffic.mixedRoleParentTraffic];

          if (mixedRoleParentTraffic) {
            delete mixedRoleParentTraffic.childrenTraffics[trafficHref];
          }
        } else if (deleteTraffic && nodeType === 'roles') {
          // only if deleting traffic for a cluster
          // note(swu): take out in refactor
          _.forOwn(traffic.childrenTraffics, (workloadTraffic, key) => {
            delete workloadTraffics[key];
          });
        }

        delete traffics[trafficHref];
        delete trafficForRuleCoverage[trafficHref];
      } else {
        traffics[trafficHref].serviceNum = 0;
      }

      if (removeRules) {
        delete rules[trafficHref];
        requestedHrefsForRuleCoverage = _.reject(requestedHrefsForRuleCoverage, href => href === trafficHref);
      }
    }
  });
}

function calculateWorkloadParents(node) {
  // get exactly app/env/loc labels to avoid bugs when we just create a role label href without key === 'role'
  const clusterLabels = _.filter(
    node.labels,
    label => label?.key && (label.key === 'loc' || label.key === 'app' || label.key === 'env'),
  );

  node.clusterParent = clusterLabels
    .map(label => GeneralUtils.getId(label.href))
    .sort((a, b) => RenderUtils.collator.compare(a, b));
  node.clusterParent = node.clusterParent.join('x') || 'discovered';
  node.appGroupParent = RenderUtils.getAppGroupParent(node, appGroupsType);

  const role = _.find(node.labels, label => label.key === 'role');

  node.roleParent = `${node.clusterParent}-${role ? _.last(role.href.split('/')) : 'discovered'}`;

  clusterNodes[node.clusterParent] ||= {
    type: 'group',
    href: node.clusterParent,
    labels: clusterLabels,
    caps: {rulesets: [], workloads: []},
  };

  roleNodes[node.roleParent] ||= {
    type: 'role',
    href: node.roleParent,
    labels: node.labels,
    clusterParent: node.clusterParent,
    appGroupParent: node.appGroupParent,
    consumingTraffic: {byLink: {}, byPort: {}},
    providingTraffic: {byLink: {}, byPort: {}},
    roleHref: (role && role.href) || 'discovered',
    name: (role && role.value) || 'discovered',
    counts: {},
    caps: {rulesets: [], workloads: []},
  };
}

// This function limits the number of IP Addresses / link
// without losing any new IP Lists
function limitIpListTraffic(source, target) {
  const sourceType = getNodeType(source);
  const targetType = getNodeType(target);
  let ipLists;
  let node;
  let address;
  let direction;
  let dropTraffic = true;
  let countAddress = false;

  if (sourceType === 'ipList') {
    address = source.address;
    ipLists = getNodeHref(source);
    node = getNodeHref(target);
    direction = 'inbound';
  }

  if (targetType === 'ipList') {
    address = target.address;
    ipLists = getNodeHref(target);
    node = getNodeHref(source);
    direction = 'outbound';
  }

  //New IP List traffic for this node
  nodeIpListTrafficMap[node] ||= {
    inbound: {count: 0},
    outbound: {count: 0},
  };

  const nodeIpListTrafficMapLocal = nodeIpListTrafficMap[node][direction];

  ipLists.forEach(ipList => {
    // New IP List for this traffic
    if (!nodeIpListTrafficMapLocal[ipList]) {
      nodeIpListTrafficMapLocal[ipList] = {};
      // Keep this traffic if it's new ipList
      dropTraffic = false;
    }

    // Count each New Address for this IP List
    if (!nodeIpListTrafficMapLocal[ipList][address]) {
      nodeIpListTrafficMapLocal[ipList][address] = address;
      countAddress = true;
    }
  });

  if (countAddress) {
    nodeIpListTrafficMapLocal.count++;
  }

  if (nodeIpListTrafficMapLocal.count <= (parseInt(localStorage.getItem('addresses_per_ip_link'), 10) || 500)) {
    // Keep this traffic if the total addresses for this link are < 500
    return false;
  }

  return dropTraffic;
}

function parseProjectedVulnerabilities(roles) {
  if (!roles || !roles.length) {
    return;
  }

  roles.forEach(role => {
    let aggregatedValues;
    const labelIds = role.labels.filter(label => label.key !== 'role').map(label => label.href.split('/').pop());
    const cluster = labelIds.sort((a, b) => RenderUtils.collator.compare(a, b)).join('x');
    const roleLabel = role.labels.filter(label => label.key === 'role');
    const roleId = roleLabel.length ? roleLabel[0].href.split('/').pop() : 'discovered';
    const nodeHref = `${cluster}-${roleId}`;

    const vulnerabilities = Array.isArray(role.aggregated_detected_vulnerabilities)
      ? role.aggregated_detected_vulnerabilities
      : role.aggregated_detected_vulnerabilities?.aggregated_detected_vulnerabilities;
    const instances = (vulnerabilities ?? []).reduce((result, vulnerability) => {
      if (!vulnerability.port || !vulnerability.proto) {
        return result;
      }

      // Accumulate the Wide Exposures by Port for label based || workload based
      const instance = {
        port: vulnerability.port,
        protocol: vulnerability.proto,
        severity: vulnerability.vulnerability.score / 10,
        wideExposure: vulnerability.vulnerable_port_wide_exposure,
        vulnerablePortExposure: vulnerability.vulnerable_port_exposure || 0,
        vulnerabilityExposureScore: vulnerability.vulnerability_exposure_score / 10,
        details: vulnerability.details,
      };

      // Add vulnerabilities indexed by port/protocol
      const key = [vulnerability.port, vulnerability.proto, vulnerability.vulnerability.href].join(',');

      // Collect the vulnerabilities per node
      aggregatedValues = RenderUtils.aggregateVulnerabilityValues(aggregatedValues, instance, key);

      result[key] = instance;

      return result;
    }, {});

    if (nodeVulnerabilities[nodeHref]) {
      nodeVulnerabilities[nodeHref].projected = {aggregatedValues, instances};
    }
  });
}

// This function parses the Vulnerability data for both the data returned from the aggregated labels api and the workload api
// Returns two items:
//   The aggregated counts for the node (role or workload - groups need to be added later)
//   The vulnerability instances organized by port
// Creates fake traffic to be used for indicating exposure to ipLists or the internet
// Updates the existing traffic with new vulnerability data
function parseVulnerabilities(vulnerabilities, nodeHref) {
  let aggregatedValues;

  /**
   *  If viewing vulnerabilities from App Groups, vulnerabilities uses key name for aggregated_detected_vulnerabilities
   *  If viewing vulnerabilities from Workload Details, vulnerabilities uses key name for workload_detected_vulnerabilities
   */
  const detectedVulnerabilities =
    vulnerabilities.workload_detected_vulnerabilities || vulnerabilities.aggregated_detected_vulnerabilities || [];
  const instances = detectedVulnerabilities.reduce((result, vulnerability) => {
    // Add vulnerabilities indexed by port/protocol
    const key = [vulnerability.port, vulnerability.proto].join(',');
    const node = getNodeByHref(nodeHref);
    const severity = vulnerability.vulnerability.score / 10;
    const vulnerablePortExposure = vulnerability.hasOwnProperty('vulnerable_port_exposure')
      ? vulnerability.vulnerable_port_exposure
      : vulnerability.port_exposure;
    const wideExposure = vulnerability.vulnerable_port_wide_exposure || vulnerability.port_wide_exposure;
    let vulnerabilityExposureScore = null;

    if (!vulnerability.hasOwnProperty('vulnerability_exposure_score')) {
      vulnerabilityExposureScore =
        vulnerablePortExposure === null
          ? null
          : (Math.floor(Math.sqrt(vulnerablePortExposure) * (Math.pow(severity, 3) / 10)) / 10) *
            (vulnerability.num_workloads || 1);
    } else if (vulnerability.vulnerability_exposure_score !== null) {
      vulnerabilityExposureScore = vulnerability.vulnerability_exposure_score / 10;
    }

    // Accumulate the Wide Exposures by Port for label based || workload based
    const instance = {
      port: vulnerability.port,
      protocol: vulnerability.proto,
      severity,
      wideExposure,
      numWorkloads: vulnerability.num_workloads,
      vulnerablePortExposure,
      vulnerabilityScore: severity * (vulnerability.num_workloads || 1),
      vulnerabilityExposureScore,
      details: vulnerability.vulnerability,
    };

    // Collect the vulnerabilities per node
    aggregatedValues = RenderUtils.aggregateVulnerabilityValues(aggregatedValues, instance, key);

    result[key] = (result[key] || []).concat([instance]);

    // Update the existing traffic
    // Find all the existing consuming traffic for this node on this port/protocol
    const consumingTraffics = node && node.consumingTraffic && node.consumingTraffic.byPort[key];

    _.forEach(consumingTraffics, (consumingTraffic, href) => {
      const traffic = getTrafficByHref(href);

      if (traffic) {
        // Aggregate the max severity at the traffic level
        traffic.maxVulnerabilitySeverity = Math.max(traffic.maxVulnerabilitySeverity, severity);

        if (vulnerablePortExposure) {
          traffic.maxExpVulnerabilitySeverity = Math.max(traffic.maxExpVulnerabilitySeverity, severity);
        }

        // For each process within this port/protocol update the traffic connection
        consumingTraffic.forEach(trafficConnection => {
          const connection = traffic.connections[trafficConnection];

          if (connection) {
            connection.vulnerabilities ||= {instances: []};

            // Collect Vulnerabilities per connection
            connection.vulnerabilities.aggregatedValues = RenderUtils.aggregateVulnerabilityValues(
              connection.vulnerabilities.aggregatedValues,
              instance,
            );
            connection.vulnerabilities.instances = (connection.vulnerabilities.instances || []).concat([instance]);
          }
        });
      }
    });

    return result;
  }, {});

  if (aggregatedValues) {
    nodeVulnerabilities[nodeHref] = {aggregatedValues, instances};
  }
}

// This calculates the vulnerabilities stored in the traffic data
// It also adds references in the targetNode data to this traffic so that if the vulnerability data
//   is parsed after the traffic, we can come back to the traffic and fill in this data
// The data stored here is:
//   1. At the top level of the traffic, the maxSeverity for all the connections
//   2. At the connection level, the aggregatedValues and an array of all the vulnerability intances for that connection
function calculateTrafficVulnerability(traffic, key) {
  const targetNode = getNodeByHref(traffic.target.href);

  if (targetNode) {
    const connection = traffic.connections[key];
    const targetVulnerabilities = nodeVulnerabilities[targetNode.href];
    const portKey = [connection.port, connection.protocol].join(',');
    const href = traffic.href;

    if (targetNode.consumingTraffic) {
      const consumingTraffic = targetNode.consumingTraffic.byPort;

      consumingTraffic[portKey] ||= {};

      // Aggregate all the edges by port and protocol
      consumingTraffic[portKey][href] = consumingTraffic[portKey][href]
        ? [...consumingTraffic[portKey][href], key]
        : [key];
    }

    // Check for vulnerabilities on the target node which match this port/protocol
    const instances = targetVulnerabilities && targetVulnerabilities.instances[portKey];

    (instances || []).forEach(instance => {
      connection.vulnerabilities ||= {};

      // Collect Vulnerabilities per connection
      connection.vulnerabilities.aggregatedValues = RenderUtils.aggregateVulnerabilityValues(
        connection.vulnerabilities.aggregatedValues,
        instance,
      );
      connection.vulnerabilities.instances = (connection.vulnerabilities.instances || []).concat([instance]);

      // Aggregate the max vulnerability severity at the traffic level
      traffic.maxVulnerabilitySeverity = Math.max(
        traffic.maxVulnerabilitySeverity,
        connection.vulnerabilities.aggregatedValues.maxSeverity,
      );
      traffic.maxExpVulnerabilitySeverity = Math.max(
        traffic.maxExpVulnerabilitySeverity,
        connection.vulnerabilities.aggregatedValues.maxExpSeverity,
      );
    });
  }
}

function parseIpLists(ipListsData) {
  ipLists = {...ipLists, ...ipListsData};
}

// Handle the old cached graphs with the IP List details in the node
function parseNodeIpLists(ipListsData) {
  if (ipListsData && isNaN(ipListsData[0])) {
    ipListsData.forEach(ipList => {
      ipLists[ipList.href.split('/').pop()] = {
        href: ipList.href,
        name: ipList.name,
      };
    });
  }
}

function parseAppGroupsVes(data) {
  data.forEach(appGroupVes => {
    const appGroupHref = appGroupVes.labels
      .map(label => label.href.split('/').pop())
      .sort((a, b) => RenderUtils.collator.compare(a, b))
      .join('x');
    const appGroup = appGroupNodes[appGroupHref] || phantomAppGroupNodes[appGroupHref];
    const vulnerability = {
      wideExposure: appGroupVes.vulnerable_port_wide_exposure,
      maxSeverity: appGroupVes.max_vulnerability_score,
      vulnerabilityExposureScore: appGroupVes.vulnerability_exposure_score / 10,
      exposureApplicable: true,
    };

    if (appGroup) {
      appGroup.vulnerability = vulnerability;
    } else {
      createAppGroup(appGroupHref, appGroupVes.labels, vulnerability);
    }
  });
}

function updateAppGroupRuleCoverage(appGroup, data) {
  const node = appGroupNodes[appGroup];

  if (node) {
    node.intraGroupCoverage = data.intra_group;
    node.extraGroupCoverage = data.inter_group;
    node.ipListCoverage = data.ip_list;
    node.lastCalculated = data.updated_at;
    node.coverageStale = appGroup.stale;
  }
}

function parseAppGroupRuleCoverage(data) {
  _.forOwn(data, appGroup => {
    const node = appGroup && appGroup.href && appGroupNodes[appGroup.href.split('/').pop()];

    if (node) {
      node.intraGroupCoverage = appGroup.intra_group;
      node.extraGroupCoverage = appGroup.inter_group;
      node.ipListCoverage = appGroup.ip_list;
      node.lastCalculated = appGroup.updated_at;
      node.coverageStale = appGroup.stale;
    }
  });
}

function getModes(mode, keys) {
  return (keys || []).reduce((result, key, index) => {
    result[key] = mode[index];

    return result;
  }, {});
}

function getEnforcement(mode, containerWorkloadMode, modeKey) {
  const enforcement = getModes(
    mode.reduce((enforced, m, i) => {
      enforced[i] = m + containerWorkloadMode[i];

      return enforced;
    }, []),
    modeKey,
  );

  return enforcement;
}

function parseAppGroupsNodes(data) {
  if (!data || !data.nodes) {
    return {};
  }

  // TBD remove slice
  return data.nodes.reduce((result, appGroup) => {
    const href = appGroup.href;
    const labels = getNodeLabelsObject(appGroup, data.labels);
    const appGroupName =
      !appGroup.label_ids || !appGroup.label_ids.length
        ? href
        : ['app', 'env', 'loc']
            .reduce((result, key) => {
              if (labels[key]) {
                result.push(labels[key].value);
              }

              return result;
            }, [])
            .join(' | ');

    result[href] = appGroupNodes[href] || {
      consuming: {},
      providing: {},
      consumingTraffic: {byLink: {}, byPort: {}},
      providingTraffic: {byLink: {}, byPort: {}},
    };
    result[href].type = 'appGroup';
    result[href].href = href;
    result[href].name = appGroupName;
    result[href].workloads = appGroup.num_workloads;
    result[href].labels = Object.values(labels);
    result[href].built = data.updated_at;
    result[href].stale = data.stale;
    result[href].truncated = data.truncated;
    result[href].mode = getModes(appGroup.mode, data.mode_key);
    result[href].containerMode = getModes(appGroup.container_workload_mode, data.mode_key);
    result[href].enforcement = getEnforcement(appGroup.mode, appGroup.container_workload_mode, data.mode_key);
    result[href].log_traffic = appGroup.log_traffic;
    result[href].entityCounts =
      appGroup.num_workloads +
      appGroup.num_virtual_services +
      appGroup.num_virtual_servers +
      appGroup.num_container_workloads;
    result[href].workloadCounts = appGroup.num_workloads;
    result[href].containerWorkloadCounts = appGroup.num_container_workloads;
    result[href].virtualServiceCounts = appGroup.num_virtual_services;
    result[href].virtualServerCounts = appGroup.num_virtual_servers;
    result[href].caps = {
      rulesets: appGroup.caps.rule_sets,
      workloads: appGroup.caps.workloads,
    };

    // If we are on a supercluster or everything is a container or a virtual service remove the workload write, use 'add' flag to indicate user can still add workload
    if (
      result[href].caps.workloads.length === 2 &&
      (SessionStore.isSuperclusterMember() ||
        result[href].containerWorkloadCounts + result[href].virtualServiceCounts === result[href].entityCounts)
    ) {
      result[href].caps.workloads = ['read', 'add'];
    }

    // If we are on a supercluster remove the rulesets write
    if (result[href].caps.rulesets.length > 1 && SessionStore.isSuperclusterMember()) {
      result[href].caps.rulesets = ['read'];
    }

    return result;
  }, {});
}

function createAppGroup(href, labels, vulnerability) {
  // Do not create invalid App Groups
  if (appGroupsType.some(key => !labels.some(label => label.key === key))) {
    return;
  }

  phantomAppGroupNodes[href] = {
    labels: labels.filter(label => appGroupsType.includes(label.key)),
    name:
      _.isEmpty(labels) || !appGroupsType
        ? href
        : appGroupsType.map(key => (labels.find(label => label.key === key) || {}).value).join(' | '),
    consuming: {},
    providing: {},
    consumingTraffic: {byLink: {}, byPort: {}},
    providingTraffic: {byLink: {}, byPort: {}},
    vulnerability,
    type: 'appGroup',
    href,
    caps: {
      rulesets: [],
      workloads: [],
    },
    mode: {test: 0, build: 0, enforced: 0, unmanaged: 0, idle: 0},
    containerMode: {test: 0, build: 0, enforced: 0, unmanaged: 0, idle: 0},
  };
}

function parseNodes(data, nodeType, keys, scope) {
  if (!data) {
    return;
  }

  if (nodeType === 'groups') {
    _.forOwn(data.clusters || data.nodes, dataNode => {
      const href = getNodeHref(dataNode);
      const type = getNodeType(dataNode);

      if (type !== 'group' || (clusterNodes[href] && clusterNodes[href].built > data.updated_at)) {
        return;
      }

      const labels = getNodeLabels(dataNode, data.labels);

      clusterNodes[href] = {
        type,
        href,
      };

      const node = clusterNodes[href];

      node.labels = labels;
      node.roleCounts = _.map(dataNode.role_label_counts, (count, roleId) => ({
        href: data.labels[roleId] || 'discovered',
        roleHref: `${href}-${roleId === '0' ? 'discovered' : roleId}`,
        count,
      }));

      node.mode = getModes(dataNode.mode, data.mode_key);
      node.containerMode = getModes(dataNode.container_workload_mode, data.mode_key);
      node.enforcement = getEnforcement(dataNode.mode, dataNode.container_workload_mode, data.mode_key);
      node.log_traffic = dataNode.log_traffic;
      node.entityCounts =
        dataNode.num_workloads +
        dataNode.num_virtual_servers +
        dataNode.num_virtual_services +
        dataNode.num_container_workloads;
      node.workloadCounts = dataNode.num_workloads;
      node.containerWorkloadCounts = dataNode.num_container_workloads;
      node.virtualServerCounts = dataNode.num_virtual_servers;
      node.virtualServiceCounts = dataNode.num_virtual_services;
      node.appGroupParent = RenderUtils.getAppGroupParent(node, appGroupsType);
      node.name = _.isEmpty(node.labels)
        ? href
        : _.sortBy(node.labels, 'key')
            .map(label => label.value)
            .join(' | ');
      node.tooManyWorkloads = dataNode.max_workloads && dataNode.num_workloads > dataNode.max_workloads;
      node.consumingTraffic = {byLink: {}, byPort: {}};
      node.providingTraffic = {byLink: {}, byPort: {}};
      node.built = node.built && node.built < data.updated_at ? node.built : data.updated_at;

      node.stale = data.stale;
      node.truncated = data.truncated;

      // Store the cluster caps so they don't get overwritten
      if (dataNode.caps) {
        clusterCaps[href] = {
          rulesets: dataNode.caps.rule_sets,
          workloads: dataNode.caps.workloads,
        };

        // If we are on a supercluster or everything is a container or a virtual service remove the workload write
        if (clusterCaps[href].workloads.length === 2 && SessionStore.isSuperclusterMember()) {
          clusterCaps[href].workloads = ['read', 'add'];
        }

        // If we are on a supercluster remove the rulesets write
        if (clusterCaps[href].rulesets.length > 1 && SessionStore.isSuperclusterMember()) {
          clusterCaps[href].rulesets = ['read'];
        }
      }

      node.caps = clusterCaps[href];

      const locationLabel = _.find(node.labels, label => label.key === 'loc');

      if (locationLabel && locationNodes[locationLabel.href]) {
        locationNodes[locationLabel.href].groups[href] = node;

        groupSearch[href] = {...node};
      }
    });
  } else if (nodeType === 'appGroups') {
    _.forOwn(data.nodes, dataNode => {
      const href = getNodeHref(dataNode);
      let node = appGroupNodes[href];

      if (!node) {
        node = appGroupNodes[href] = {type: 'appgroup', href};
        node.name = _.isEmpty(node.labels)
          ? href
          : _.sortBy(node.labels, 'key')
              .map(label => label.value)
              .join(' | ');
        node.labels = getNodeLabels(dataNode, data.labels);
      }

      // todo: it may need change later because roleHref should be 1x2x3-4, but here is 1x2-4
      node.roleCounts = _.map(dataNode.role_label_counts, (count, roleId) => ({
        href: data.labels[roleId] || 'discovered',
        roleHref: `${node.href}-${roleId === '0' ? 'discovered' : roleId}`,
        count,
      }));

      node.mode = getModes(dataNode.mode, data.mode_key);
      node.containerMode = getModes(dataNode.container_workload_mode, data.mode_key);
      node.log_traffic = dataNode.log_traffic;
      node.tooManyWorkloads = dataNode.max_workloads && dataNode.num_workloads > dataNode.max_workloads;

      if (!node.consuming) {
        node.consuming = {};
        node.providing = {};
      }

      node.consumingTraffic = {byLink: {}, byPort: {}};
      node.providingTraffic = {byLink: {}, byPort: {}};
      node.built = node.built && node.built < data.updated_at ? node.built : data.updated_at;
      node.stale = data.stale;
      node.truncated = data.truncated;
      node.entityCounts =
        dataNode.num_workloads +
        dataNode.num_virtual_services +
        dataNode.num_virtual_servers +
        dataNode.num_container_workloads;
      node.workloadCounts = dataNode.num_workloads;
      node.containerWorkloadCounts = dataNode.num_container_workloads;
      node.virtualServiceCounts = dataNode.num_virtual_services;
      node.virtualServerCounts = dataNode.num_virtual_servers;
    });
  } else if (nodeType === 'roles') {
    _.forOwn(data.nodes, dataNode => {
      const href = getNodeHref(dataNode) || '';
      const type = getNodeType(dataNode);

      if (type !== 'role') {
        if (dataNode.ip_lists) {
          parseNodeIpLists(dataNode.ip_lists);
        }

        return;
      }

      const labels = getNodeLabels(dataNode, data.labels);
      const appGroupParent = RenderUtils.getAppGroupParent({labels}, appGroupsType);
      const clusterParent = _.head(href.split('-'));

      // Don't parse roles outside of keys if they are provided
      if (keys && !keys.includes(appGroupParent) && !keys.includes(clusterParent)) {
        return;
      }

      if (!appGroupNodes[appGroupParent] && !phantomAppGroupNodes[appGroupParent]) {
        createAppGroup(appGroupParent, labels);
      }

      let consumingTraffic = {byLink: {}, byPort: {}};
      let providingTraffic = {byLink: {}, byPort: {}};
      let built;
      let counts;
      let entityCounts;
      let workloadCounts;
      let containerWorkloadCounts;
      let virtualServiceCounts;
      let virtualServerCounts;
      let mode = getModes(dataNode.mode, data.mode_key);
      let containerMode = getModes(dataNode.container_workload_mode, data.mode_key);
      let enforcement = getEnforcement(dataNode.mode, dataNode.container_workload_mode, data.mode_key);

      if (roleNodes[href]) {
        consumingTraffic = roleNodes[href].consumingTraffic;
        providingTraffic = roleNodes[href].providingTraffic;
        built = roleNodes[href].built;
        counts = roleNodes[href].counts;
        entityCounts = roleNodes[href].entityCounts;
        workloadCounts = roleNodes[href].workloadCounts;
        containerWorkloadCounts = roleNodes[href].containerWorkloadCounts;
        virtualServiceCounts = roleNodes[href].virtualServiceCounts;
        virtualServerCounts = roleNodes[href].virtualServerCounts;
        mode = roleNodes[href].mode;
        containerMode = roleNodes[href].containerMode;
        enforcement = roleNodes[href].enforcement;
      }

      roleNodes[href] = {
        href,
        type,
        consumingTraffic,
        providingTraffic,
        counts,
        entityCounts,
        workloadCounts,
        containerWorkloadCounts,
        virtualServiceCounts,
        virtualServerCounts,
        mode,
        containerMode,
        enforcement,
      };

      const node = roleNodes[href];

      node.clusterParent = clusterParent;
      node.labels = labels;
      node.appGroupParent = appGroupParent;

      // TODO(swu): temp
      const id = dataNode.key && _.last(dataNode.key.split('-'));

      if (id && id !== 'discovered') {
        dataNode.label_ids.push(id);
        dataNode.label_ids = _.uniq(dataNode.label_ids);
      }

      const role = _.find(node.labels, label => label.key === 'role');
      const newCounts = {
        entityCounts:
          dataNode.num_workloads +
          dataNode.num_virtual_services +
          dataNode.num_virtual_servers +
          dataNode.num_container_workloads,
        workloadCounts: dataNode.num_workloads,
        containerWorkloadCounts: dataNode.num_container_workloads,
        virtualServiceCounts: dataNode.num_virtual_services,
        virtualServerCounts: dataNode.num_virtual_servers,
        mode: getModes(dataNode.mode, data.mode_key),
        containerMode: getModes(dataNode.container_workload_mode, data.mode_key),
        enforcement: getEnforcement(dataNode.mode, dataNode.container_workload_mode, data.mode_key),
      };

      node.roleHref = (role && role.href) || 'discovered';
      node.name = (role && role.value) || 'discovered';
      node.log_traffic = dataNode.log_traffic;
      node.built = built && built < data.updated_at ? built : data.updated_at;
      node.stale |= data.stale;
      node.truncated |= data.truncated;
      node.caps = {
        ...(
          appGroupNodes[appGroupParent] ||
          clusterNodes[clusterParent] ||
          phantomAppGroupNodes[appGroupParent] || {caps: {rulesets: [], workloads: []}}
        ).caps,
      };

      const isScopeNotRole = scope && !scope[0].includes('-');

      // If newCounts must be overwritten in roleNodes.
      let getCount = false;

      if (MapPageStore.getMapType() === 'app') {
        //If app group map, overwrite count based of on peer count data.
        // In case of FQDN, scope is role based, hence use split scope.
        getCount =
          isScopeNotRole || scope
            ? (scope[0] && scope[0].split('-')[0] === clusterParent) || scope[0].split('-')[0] === appGroupParent
            : false;
      } else {
        // If location map, do not overwrite peer count data, match with clusterParent.
        // In case of FQDN, scope is role based, hence use split scope.
        getCount = isScopeNotRole
          ? scope[0] === clusterParent || scope[0] === appGroupParent
          : scope
            ? scope[0].split('-')[0] === clusterParent || scope[0].split('-')[0] === appGroupParent
            : true;
      }

      // Counts of connected appGroups.
      if (node.counts) {
        // Save the counts with respect to the scope of the graph
        node.counts[scope] = newCounts;
      } else {
        node.counts = {[scope]: newCounts};
      }

      if (getCount) {
        // Promote the counts pertaining to it's own app group
        if (node.counts[appGroupParent]) {
          roleNodes[href] = {...node, ...node.counts[appGroupParent]};
        } else {
          // For roles from clusters instead of App Groups
          roleNodes[href] = {...node, ...newCounts};
        }
      }

      roleNodes[href].counts ||= {};
    });
  } else {
    _.forOwn(data.nodes, dataNode => {
      const href = getNodeHref(dataNode) || '';
      const type = getNodeType(dataNode);

      if (type !== 'workload' && type !== 'virtualService') {
        if (dataNode.ip_lists) {
          parseNodeIpLists(dataNode.ip_lists);
        }

        return;
      }

      parseNode(dataNode, href, type, data, keys);
    });
  }
}

function parseNode(dataNode, href, type, data, keys) {
  dataNode = dataNode.traffic_workload || dataNode;
  dataNode = dataNode.length ? dataNode[0] : dataNode;

  const node = {
    href,
    type,
    labels: getNodeLabels(dataNode, data.labels),
  };

  calculateWorkloadParents(node);

  // Don't parse nodes outside of keys if they are provided
  if (keys && !keys.includes(node.appGroupParent) && !keys.includes(node.clusterParent)) {
    return;
  }

  if (!appGroupNodes[node.appGroupParent] && !phantomAppGroupNodes[node.appGroupParent]) {
    createAppGroup(node.appGroupParent, node.labels);
  }

  node.name = dataNode.name;
  node.mode = dataNode.mode;
  node.log_traffic = dataNode.log_traffic;
  node.status = dataNode.status;
  node.unmanaged = dataNode.unmanaged;
  node.subType = dataNode.subtype;
  node.connectedLinks = workloadNodes[href] ? workloadNodes[href].connectedLinks : {};
  node.consumingTraffic = workloadNodes[href] ? workloadNodes[href].consumingTraffic : {byLink: {}, byPort: {}};
  node.providingTraffic = workloadNodes[href] ? workloadNodes[href].consumingTraffic : {byLink: {}, byPort: {}};
  node.built = node.built && node.built < data.updated_at ? node.built : data.updated_at;
  node.stale = data.stale;
  node.truncated = data.truncated;
  node.caps = {
    ...(
      appGroupNodes[node.appGroupParent] ||
      clusterNodes[node.clusterParent] ||
      phantomAppGroupNodes[node.appGroupParent] || {caps: {rulesets: [], workloads: []}}
    ).caps,
  };

  // Virtual Servers are only editable for Admin/Owners for now
  if (node.subType === 'virtual_server') {
    if (SessionStore.isGlobalEditEnabled()) {
      node.caps.workloads = ['read', 'write'];
    } else if (node.caps.workloads.length > 1) {
      node.caps.workloads = ['read', 'add'];
    }
  }

  // Remove write caps for containers as nothing can be edited for containers
  if (node.subType === 'container' && node.caps.workloads.length > 1) {
    node.caps.workloads = ['read', 'add'];
  }

  workloadNodes[href] = node;
}

function getNewTraffic(newDetails) {
  return {
    connections: {},
    ruleKeys: {},
    connectionsForRules: {any: {port: -1, protocol: -1}},
    totalConnectionRulesPerRuleset: {},
    totalAddressRulesPerRuleset: {},
    workloadTraffics: {},
    serviceNum: 0,
    weight: 0,
    totalSessions: 0,
    filteredSessions: 0,
    totalConnections: 0,
    filteredConnections: 0,
    totalRules: 0,
    totalDenyRules: 0,
    totalConnectionRules: 0,
    totalAddressConnections: 0,
    totalAddressRules: 0,
    filteredRules: 0,
    filteredDenyRules: 0,
    filteredRuleDenyRules: 0,
    filtered: RenderUtils.getNewPolicyDecisions(0),
    total: RenderUtils.getNewPolicyDecisions(0),
    maxVulnerabilitySeverity: -1,
    maxExpVulnerabilitySeverity: -1,
    ...newDetails,
  };
}

function parseTraffic(data, nodeType, requestedNodes, connectedGroup, scope) {
  if (!data) {
    return;
  }

  const fqdnMapping = localStorage.getItem('dns_iplist_mapping') || 'iplist';

  const nodesObj = _.countBy(requestedNodes);
  const nodeCount = Object.keys(data.nodes).length;
  const traffics = getTrafficsByType(nodeType);

  const portIndex = _.indexOf(data.key, 'port');
  const protocolIndex = _.indexOf(data.key, 'protocol');
  const processIdIndex = _.indexOf(data.key, 'pn_id');
  const serviceIdIndex = _.indexOf(data.key, 'sn_id');
  const fqdnIdIndex = _.indexOf(data.key, 'fqdn_id');
  const osIndex = _.indexOf(data.key, 'policy_os_id');
  const classIndex = _.indexOf(data.key, 'class');
  const sessionsIndex = _.indexOf(data.key, 'sessions');
  const numPortsIndex = _.indexOf(data.key, 'num_flows');
  const timestampIndex = _.indexOf(data.key, 'timestamp');
  const policyDecisionIndicies = {
    unknown: _.indexOf(data.key, 'pd_unknown'),
    allowed: _.indexOf(data.key, 'pd_allowed'),
    potentiallyBlocked: _.indexOf(data.key, 'pd_potentially_blocked'),
    potentiallyBlockedByBoundary: _.indexOf(data.key, 'bd_potentially_blocked'),
    blocked: _.indexOf(data.key, 'pd_blocked'),
    blockedByBoundary: _.indexOf(data.key, 'bd_blocked'),
  };

  // Make this call rarely as it is slightly expensive
  const lastProvisionTime = VersionStore.getLatestProvisionTime();

  _.forOwn(data.edges, (edges, source) => {
    source = data.nodes[source];

    if (source) {
      // Get href of node, which comes from 3 different places depending on traffic agent, ipList, all
      const sourceType = getNodeType(source);
      let sourceHref = getNodeHref(source);
      const sourceNode = getNodeByHref(sourceHref);

      // When loading connected group, only load the intra-scope traffic
      if (
        sourceType === 'role' &&
        sourceNode &&
        sourceNode.appGroupParent &&
        connectedGroup &&
        sourceNode.appGroupParent !== connectedGroup
      ) {
        return;
      }

      // some node types (ipLists, vs's) have multiple href's
      // if they do, then join them with 'x's
      let sourceAllHrefs = Array.isArray(sourceHref) ? sourceHref : [sourceHref];

      _.forOwn(edges, (edge, target) => {
        target = data.nodes[target];

        if (target) {
          let targetType = getNodeType(target);
          let targetHref = getNodeHref(target);
          const targetNode = getNodeByHref(targetHref);

          // When loading connected group, only load the intra-scope traffic
          if (
            targetType === 'role' &&
            targetNode &&
            targetNode.appGroupParent &&
            connectedGroup &&
            targetNode.appGroupParent !== connectedGroup
          ) {
            return;
          }

          // Skip edges if the source/target were not parsed
          const skipEdge =
            (sourceType === 'role' && !roleNodes[sourceHref]) ||
            (targetType === 'role' && !roleNodes[targetHref]) ||
            (sourceType === 'workload' && !workloadNodes[sourceHref]) ||
            (targetType === 'workload' && !workloadNodes[targetHref]);

          // For IP List traffic, drop traffic over the limit for IP Addresses
          // Do not parse traffic which neither endpoint isn't in the requested nodes
          const requestedNode = nodesObj[sourceHref] || nodesObj[targetHref];

          if (
            ((sourceType !== 'ipList' && targetType !== 'ipList') || !limitIpListTraffic(source, target)) &&
            !skipEdge &&
            requestedNode
          ) {
            // Save the expected number of connections
            let expectedConnections = (edge.flows || edge).length;

            const numFlows = edge.num_flows ? edge.num_flows : edge[0][numPortsIndex];
            const trafficHrefs = {};

            // For Large Graphs only load 64 edges
            const flows = nodeCount > 5000 ? (edge.flows || edge).slice(0, 256) : edge.flows || edge;

            // add in all the new connections
            // if it already exists, add on the sessions count
            flows.forEach(flow => {
              const port = parseInt(flow[portIndex], 10);
              const protocol = parseInt(flow[protocolIndex], 10);
              // The processName and serviceName are just used in the ruleCoverage apis
              let processName = (processIdIndex !== -1 && data.processes[flow[processIdIndex]]) || 'unknown';

              // The service is the most specific of the process/service
              // determined by the backend, and the displayed name in the command panel
              const fqdn = flow[fqdnIdIndex];
              const fqdnName = data.fqdns[fqdn];
              const fqdnIpListMatch = target.fqdn;

              // Start the targetHref calculation over for each edge
              targetHref = getNodeHref(target);

              let targetAllHrefs = Array.isArray(targetHref) ? targetHref : [targetHref];
              let targetTypeHref = '';

              targetType = getNodeType(target);

              if (processName?.toUpperCase() === 'SYSTEM') {
                // This should not be localized - All Windows process names are case insenitive
                // SYSTEM is a key word for the Illumio Service processes, so force it to all caps
                // In future releases we might want to handle the case insenitiving for all windows process/service names
                processName = 'SYSTEM';
              }

              const serviceName = (serviceIdIndex !== -1 && data.services[flow[serviceIdIndex]]) || 'unknown';
              // Service is no longer sent in the traffic, so choose the more specific between the serviceName and the processName
              const service = serviceName === 'unknown' ? processName : serviceName;
              let osType = (osIndex !== -1 && data.policy_os[flow[osIndex]]) || 'unknown';

              // The FQDN from the edge will now need to be added the target href, so now
              // the href for the traffic can change as we go through the edges
              // If we map traffic with an fqdn and IP matching IPList to domains fqdnIpListMatch is irrelevent
              if (
                fqdnName !== 'unknown' &&
                ((targetType === 'ipList' && (fqdnMapping === 'domains' || fqdnIpListMatch)) ||
                  targetType === 'internet' ||
                  targetType === 'fqdn')
              ) {
                if (target.type !== 'ip_list' || !fqdnIpListMatch) {
                  targetAllHrefs.unshift(reverseFqdnLookup[fqdnName]);
                }

                // Add the fqdn type to the href, because it's possible to have the same IP List
                // Once matched based on the fqdn, and once based on an IP address
                targetTypeHref = 'xfqdn';
                targetType = 'fqdn';
              }

              targetAllHrefs = [...new Set(targetAllHrefs)].sort((a, b) => a - b);
              sourceAllHrefs = [...new Set(sourceAllHrefs)].sort((a, b) => a - b);

              targetHref = targetAllHrefs.join('x') + targetTypeHref;
              sourceHref = sourceAllHrefs.join('x');

              const href = `${sourceHref},${targetHref}`;
              let traffic = getTrafficByHref(href);

              // If we are parsing new traffic into a link, we need to recall the rule coverage for this link
              requestedHrefsForRuleCoverage = requestedHrefsForRuleCoverage.filter(
                ruleCoverageHref => ruleCoverageHref !== href,
              );

              if (!traffic) {
                traffics[href] = traffic = getNewTraffic({
                  href,
                  source: {
                    href: sourceHref,
                    allHrefs: new Set(sourceAllHrefs),
                    type: sourceType,
                  },
                  target: {
                    href: targetHref,
                    allHrefs: new Set(targetAllHrefs),
                    type: targetType,
                    fqdnIpListMatch,
                  },
                });
              }

              // Only do this for the first edge for this href;
              if (!trafficHrefs[href]) {
                if (sourceType === 'workload' && workloadNodes[sourceHref]) {
                  workloadNodes[sourceHref].connectedLinks[href] = href;
                }

                if (targetType === 'workload' && workloadNodes[targetHref]) {
                  workloadNodes[targetHref].connectedLinks[href] = href;
                }

                if (targetHref !== sourceHref && sourceType === 'appGroup' && targetType === 'appGroup') {
                  appGroupNodes[targetHref].consuming[sourceHref] = appGroupNodes[sourceHref];
                  appGroupNodes[sourceHref].providing[targetHref] = appGroupNodes[targetHref];
                }

                const targetNode = getNodeByHref(targetHref);
                const sourceNode = getNodeByHref(sourceHref);
                const targetParent = targetNode && targetNode.appGroupParent;
                const sourceParent = sourceNode && sourceNode.appGroupParent;
                const targetParentNode = appGroupNodes[targetParent] || phantomAppGroupNodes[targetParent];
                const sourceParentNode = appGroupNodes[sourceParent] || phantomAppGroupNodes[sourceParent];

                // For single nodes use the mode, and convert to an object so this value always has the same shape.
                traffic.endpointEnforcement = {
                  source:
                    typeof sourceNode?.mode === 'string'
                      ? {[sourceNode?.unmanaged ? 'unmanaged' : sourceNode?.mode]: 1}
                      : sourceNode?.enforcement,
                  target:
                    typeof targetNode?.mode === 'string'
                      ? {[targetNode?.unmanaged ? 'unmanaged' : targetNode?.mode]: 1}
                      : targetNode?.enforcement,
                };

                if (
                  sourceParent &&
                  targetParent &&
                  sourceParent !== targetParent &&
                  targetParentNode &&
                  targetParentNode.consuming &&
                  sourceParentNode &&
                  sourceParentNode.providing
                ) {
                  targetParentNode.consuming[sourceParent] = sourceParentNode;
                  sourceParentNode.providing[targetParent] = targetParentNode;
                }

                if (traffic.serviceNum) {
                  expectedConnections += Object.keys(traffic.connections).length;
                }

                traffic.serviceNum += numFlows;

                if (targetNode && targetNode.consumingTraffic) {
                  targetNode.consumingTraffic.byLink[href] = href;
                }

                if (sourceNode && sourceNode.providingTraffic) {
                  sourceNode.providingTraffic.byLink[href] = href;
                }
              }

              trafficHrefs[href] = true;

              let ruleCoverageOsType = osType;

              if (osType === 'unknown') {
                // Rule coverage Os Type should ask the broadest question for unknown os Type(windows)
                ruleCoverageOsType = RenderUtils.isInternetIpList(traffic.target) ? 'linux' : 'windows';
                // But the rules should be written as 'linux' rules
                osType = 'linux';
              }

              const sessions = parseInt(flow[sessionsIndex], 10) || 1;
              const timestamp = flow[timestampIndex];

              const policyValues = policyDecisions.reduce((result, policyDecision) => {
                result[policyDecision] = parseInt(flow[policyDecisionIndicies[policyDecision]] || 0, 10);

                return result;
              }, {});

              const friendlyProtocol = ServiceUtils.lookupProtocol(protocol);

              //connectionClass holds the information whether the traffic is Multicast(M), Broadcast(B) or Unicast(U).
              const connectionClass = flow[classIndex];
              const portKey = ServiceUtils.isPortValid(protocol) ? port : '';
              const key = [portKey, protocol, processName, serviceName, connectionClass].join(',');
              const ruleKey = [portKey, protocol, processName, serviceName].join(',');
              let connection = traffic.connections[key];

              const policyDecisionFlags = policyDecisions.reduce((result, policyDecision) => {
                result[policyDecision] = Boolean(policyValues[policyDecision]);

                return result;
              }, {});

              if (!connection) {
                connection = traffic.connections[key] = {
                  key,
                  ruleKey,
                  port,
                  protocol,
                  friendlyProtocol,
                  service,
                  processName,
                  serviceName,
                  osType,
                  ruleCoverageOsType,
                  connectionClass,
                  sessions: 0,
                  rules: [],
                  denyRules: [],
                  sourceAddresses: [],
                  targetAddresses: [],
                  policyCounts: RenderUtils.getNewPolicyDecisions(0),
                  policyDecisionFlags: RenderUtils.getNewPolicyDecisions(false),
                  endpointEnforcement: traffic.endpointEnforcement,
                  groupLink: sourceNode?.type === 'group' || sourceNode?.type === 'appGroup',
                };

                // every time we see new connection, we want to increment total connections
                // and increment the totalFilteredConnections if it is not ignored.
                traffic.totalConnections += 1;
                incrementNotIgnoredServices(
                  traffic,
                  {
                    ...connection,
                    policyDecisionFlags,
                  },
                  null,
                  lastProvisionTime,
                );
                traffic.connectionsForRules[ruleKey] = getConnectionForRule(connection);
              }

              // Handle all the cases in the filtering logic
              // for connection with multiple addresses, always show the latest timestamp
              if (connection.timestamp) {
                connection.timestamp = _.max([connection.timestamp, timestamp]);

                policyDecisions.forEach((policyDecision, index) => {
                  if (connection.policyDecisionFlags[policyDecision] !== policyDecisionFlags[policyDecision]) {
                    if (
                      (connection.timestamp < timestamp && timestamp - connection.timestamp > FLOW_TIME_DIFFERENCE) ||
                      (connection.policyDecisionFlags[policyDecision] &&
                        !policyDecisions.some(
                          (comparePolicyDecision, compareIndex) =>
                            compareIndex > index && policyDecisionFlags[comparePolicyDecision],
                        ))
                    ) {
                      adjustOldPolicyDecision(policyDecision, connection, traffic);
                    } else {
                      // Use the old policy State
                      policyDecisionFlags[policyDecision] = connection.policyDecisionFlags[policyDecision];
                    }
                  }
                });
              } else {
                connection.timestamp = timestamp;
              }

              connection.policyDecisionFlags = {...policyDecisionFlags};

              const oldAddressCount = connection.sourceAddresses.length + connection.targetAddresses.length;

              connection.sourceAddresses = _.union(connection.sourceAddresses, getNodeAddresses(source));
              connection.targetAddresses = _.union(connection.targetAddresses, getNodeAddresses(target, fqdnName));

              // New address count
              const internetCount =
                (traffic.source.type === 'internet' && !connection.sourceAddresses.length) ||
                (traffic.target.type === 'internet' && !connection.targetAddresses.length)
                  ? 1
                  : 0;
              const newAddressCount =
                internetCount + connection.sourceAddresses.length + connection.targetAddresses.length - oldAddressCount;

              // Accumulate only the newly added addresses
              traffic.totalAddressConnections += newAddressCount;

              // this will cause bug which is the sessions will be accumulated again and again
              // Only Joel can help us to fix this if he wants.
              connection.sessions += sessions;

              policyDecisions.forEach(
                policyDecision => (connection.policyCounts[policyDecision] += policyValues[policyDecision]),
              );
              traffic.weight += sessions;

              if (SessionStore.areVulnerabilitiesEnabled()) {
                calculateTrafficVulnerability(traffic, key);
              }

              // every time parse a connection, we want to increment total sessions
              // and see if it's within ignored services.  if it's not, increment filtered sessions
              traffic.totalSessions += sessions;
              policyDecisions.forEach(
                policyDecision =>
                  (traffic.total[policyDecision] += connection.policyDecisionFlags[policyDecision] ? sessions : 0),
              );

              incrementNotIgnoredServices(traffic, connection, sessions, lastProvisionTime);

              // if we've already gotten rules back, apply existing rules
              // to this specific connection
              const ruleLink = rules[traffic.href];

              if (ruleLink) {
                const connRules = ruleLink.connections && ruleLink.connections[key] && ruleLink.connections[key].rules;
                const connDenyRules =
                  ruleLink.connections && ruleLink.connections[key] && ruleLink.connections[key].denyRules;

                applyRuleToConnection(traffic, connRules, connDenyRules, connection, sessions, newAddressCount);
                applyDenyRuleToConnection(traffic, connDenyRules, connRules, connection, sessions);
              }
            });

            Object.keys(trafficHrefs).forEach(href => {
              const traffic = traffics[href];

              // Reduce the serviceNum by the number of duplicates
              // Which is the number of expected - minus the actual
              traffic.serviceNum -= expectedConnections - Object.keys(traffic.connections).length;

              // Save this link in avenger format for the Rule Coverage API call.
              if (traffic.source.type !== 'appGroup') {
                saveTrafficForRuleCoverage(traffic, traffic.connectionsForRules);
              }

              if (nodeType === 'roles') {
                traffic.childrenTraffics = {};
              } else if (nodeType === 'workloads') {
                mapWorkloadTrafficToRoleTraffic(traffic);
              }
            });
          }
          // } else if (traffic && requestedNode) {
          //   traffic.droppedTraffic = true;
          // }
        }
      });
    }
  });

  if (nodeType === 'workloads') {
    calculateMixedRoleTraffics();
  } else if (nodeType === 'roles') {
    // if node type is roles, we must have refetched
    // so fill role traffic with their children workload traffic again
    _.forOwn(workloadTraffics, traffic => {
      mapWorkloadTrafficToRoleTraffic(traffic);
    });
    // Recalculate the mixed role traffic
    calculateMixedRoleTraffics();

    if (!connectedGroup) {
      // Recalculate App Group Traffic
      calculateAppGroupTraffic(scope && scope[0]);
    }
  }
}

function adjustOldPolicyDecision(policyDecision, adjustedConnection, traffic) {
  // Increment or decrement the totalActualRules
  const increment = adjustedConnection.policyDecisionFlags[policyDecision] ? -1 : 1;

  traffic.total[policyDecision] += increment * adjustedConnection.sessions;
}

function getConnectionForRule(connection) {
  const {port, protocol, processName, serviceName, ruleCoverageOsType} = connection;

  const connectionForRule = {
    port,
    protocol,
    process_name: processName !== 'unknown' && processName,
    windows_service_name: serviceName !== 'unknown' && serviceName,
    os_type: ruleCoverageOsType,
  };

  if (!connectionForRule.process_name) {
    delete connectionForRule.process_name;
  }

  if (!connectionForRule.windows_service_name) {
    delete connectionForRule.windows_service_name;
  }

  if (!ServiceUtils.isPortValid(protocol)) {
    delete connectionForRule.port;
  }

  return connectionForRule;
}

function incrementNotIgnoredServices(traffic, connection, sessions, lastProvisionTime) {
  if (!isConnectionFiltered(connection, lastProvisionTime)) {
    if (sessions) {
      traffic.filteredSessions += sessions;

      const draftAllowed = RenderUtils.isConnection('allowed', connection, 'draft');
      const draftBlocked = RenderUtils.isConnection('blocked', connection, 'draft');
      const draftPotentiallyBlocked = RenderUtils.isConnection('potentiallyBlockedByBoundary', connection, 'draft');

      if (draftAllowed && draftBlocked) {
        traffic.filteredAllowDenyRules += sessions;
      } else if (draftAllowed) {
        traffic.filteredRules += sessions;
      } else if (draftBlocked || draftPotentiallyBlocked) {
        traffic.filteredDenyRules += sessions;
      }

      const policyValues = policyDecisions.map(policyDecision =>
        RenderUtils.isConnection(policyDecision, connection, 'reported'),
      );

      // Get the current filters
      const policyFilters = policyDecisions.map(
        policyDecision => connectionFilters[`allow${_.upperFirst(policyDecision)}Traffic`],
      );

      policyDecisions.forEach((policyDecision, index) => {
        // Count this policy decision if the filter matches this connection
        if (
          policyFilters[index] &&
          policyValues[index] &&
          // and none of the higher priority policyDecisions are counted
          policyDecisions.every(
            (comparePolicyDecision, compareIndex) =>
              compareIndex <= index || !policyFilters[compareIndex] || !policyValues[compareIndex],
          )
        ) {
          traffic.filtered[policyDecision] += sessions;
        }
      });
    } else {
      traffic.filteredConnections += 1;
    }
  }
}

function addIpListRule(trafficConnection, ruleConnection, ruleConnectionHref, type) {
  trafficConnection.ipListRules[ruleConnectionHref] ||= {
    denyRules: [],
    rules: [],
  };

  trafficConnection.ipListRules[ruleConnectionHref][type] = [
    ...trafficConnection.ipListRules[ruleConnectionHref][type],
    ...ruleConnection[type],
  ];

  return trafficConnection;
}

function parseRuleCoverage(links, linkHrefs, data) {
  const newLinkHrefs = _.transform(
    linkHrefs,
    (result, trafficHref) => {
      if (!_.isEmpty(getTrafficByHref(trafficHref))) {
        result.push(trafficHref);
      }
    },
    [],
  );

  if (!newLinkHrefs.length) {
    return;
  }

  newLinkHrefs.forEach(trafficHref => {
    const traffic = getTrafficByHref(trafficHref);

    traffic.totalRules = 0;
    traffic.totalDenyRules = 0;
    traffic.totalConnectionRules = 0;
    traffic.totalAddressRules = 0;
    traffic.filteredRules = 0;
    traffic.filteredDenyRules = 0;
    traffic.filteredAlloweDenyRules = 0;
    traffic.clearRules = true;
  });

  const ruleData = data.rules;
  const denyRuleData = data.deny_rules;

  data.edges.forEach((rulesForLink, linkIndex) => {
    const denyRulesForLink = data.deny_edges && data.deny_edges[linkIndex];
    const traffic = getTrafficByHref(linkHrefs[linkIndex]);

    if (!traffic) {
      return;
    }

    if (traffic.clearRules) {
      traffic.totalConnectionRulesPerRuleset = {};
      traffic.totalAddressRulesPerRuleset = {};
    }

    const link = links[linkIndex];

    if (link && traffic) {
      let rule = rules[traffic.href];

      rule ||= rules[traffic.href] = {
        href: traffic.href,
        source: {
          href: traffic.source.href,
          type: traffic.source.type,
        },
        target: {
          href: traffic.target.href,
          type: traffic.target.type,
        },
        connections: {},
      };

      _.forOwn(rulesForLink, (serviceRules, serviceIndex) => {
        const serviceDenyRules = denyRulesForLink && denyRulesForLink[serviceIndex];
        const reqService = link.services[serviceIndex];
        const port = reqService.port;
        const protocol = ServiceUtils.reverseLookupProtocol(reqService.protocol);
        const process = reqService.process_name || 'unknown';
        const service = reqService.windows_service_name || 'unknown';

        ['U', 'B', 'M'].forEach(connectionClass => {
          const connectionKey = [port, protocol, process, service, connectionClass].join(',');
          let ruleConnection = rule.connections[connectionKey];

          // set connection information on the rules object
          ruleConnection ||= rule.connections[connectionKey] = {
            port,
            protocol,
            process,
            service,
            rules: [],
            denyRules: [],
            ipListRules: {},
            ruleSources: {allowed: {}, deny: {}},
            ruleTargets: {allowed: {}, deny: {}},
          };

          if (serviceRules.length) {
            ruleConnection.rules = serviceRules.map(rule => Object.values(ruleData[rule])[0]);

            if (['ipList', 'fqdn', 'internet'].includes(traffic.source.type)) {
              ruleConnection.ruleSources.allowed = link.source.ip_list
                ? link.source.ip_list
                : {href: link.source.actors};
            }

            if (['ipList', 'fqdn', 'internet'].includes(traffic.target.type)) {
              ruleConnection.ruleTargets.allowed = link.destination.ip_list
                ? link.destination.ip_list
                : {href: link.destination.actors};
            }
          }

          if (serviceDenyRules && serviceDenyRules.length) {
            ruleConnection.denyRules = serviceDenyRules.map(rule => Object.values(denyRuleData[rule])[0]);

            if (['ipList', 'fqdn', 'internet'].includes(traffic.source.type)) {
              ruleConnection.ruleSources.deny = link.source.ip_list ? link.source.ip_list : {href: link.source.actors};
            }

            if (['ipList', 'fqdn', 'internet'].includes(traffic.target.type)) {
              ruleConnection.ruleTargets.deny = link.destination.ip_list
                ? link.destination.ip_list
                : {href: link.destination.actors};
            }
          }

          // TBD HANDLE DENY RULES HERE
          // The blocked connections can not be filtered out until we have the rules
          if (
            !ruleConnection.rules.length &&
            traffic.connections[connectionKey] &&
            !connectionFilters.allowBlockedTraffic
          ) {
            traffic.filteredSessions -= traffic.connections[connectionKey].sessions;
          }

          if (port === -1 && protocol === -1 && (traffic.clearRules || !traffic.allServicesRule)) {
            // Determine if the allServicesRule is valid
            traffic.allServicesRule = serviceRules.length;
            traffic.allSerivcesDenyRule = serviceDenyRules && serviceDenyRules.length;
          } else if (port !== -1 || protocol !== -1) {
            // apply this rule to the traffic
            const trafficConnection = traffic.connections[connectionKey];

            if (traffic.clearRules && trafficConnection) {
              // if we just got the rules back for the link clear previously existing rules
              trafficConnection.ipListRules = {};
              trafficConnection.rules = [];
              trafficConnection.denyRules = [];
              trafficConnection.ruleSources = {allowed: {}, deny: {}};
              trafficConnection.ruleTargets = {allowed: {}, deny: {}};
            }

            if (trafficConnection) {
              if (ruleConnection.ruleSources?.allowed?.href) {
                addIpListRule(trafficConnection, ruleConnection, ruleConnection.ruleSources.allowed.href, 'rules');
                trafficConnection.ruleSources.allowed[ruleConnection.ruleSources.allowed.href] = true;
              }

              if (ruleConnection.ruleTargets?.allowed?.href) {
                addIpListRule(trafficConnection, ruleConnection, ruleConnection.ruleTargets.allowed.href, 'rules');
                trafficConnection.ruleTargets.allowed[ruleConnection.ruleTargets.allowed.href] = true;
              }

              if (ruleConnection.ruleSources?.deny?.href) {
                addIpListRule(trafficConnection, ruleConnection, ruleConnection.ruleSources.deny.href, 'denyRules');
                trafficConnection.ruleSources.deny[ruleConnection.ruleSources.deny.href] = true;
              }

              if (ruleConnection.ruleTargets?.deny?.href) {
                addIpListRule(trafficConnection, ruleConnection, ruleConnection.ruleTargets.deny.href, 'denyRules');
                trafficConnection.ruleTargets.deny[ruleConnection.ruleTargets.deny.href] = true;
              }
            }

            applyRuleToConnection(traffic, ruleConnection.rules, ruleConnection.denyRules, trafficConnection);
            applyDenyRuleToConnection(traffic, ruleConnection.denyRules, ruleConnection.rules, trafficConnection);
          }
        });
      });
    }

    // after going through the traffic connections at least once
    // we know we don't have to clear it anymore
    traffic.clearRules = false;
  });

  calculateFilteredConnections(workloadTraffics, true);
  calculateFilteredConnections(clusterTraffics, true);
  calculateFilteredConnections(roleTraffics, true);
  calculateFilteredConnections(mixedRoleTraffics, true);
  aggregateAllAppGroupTraffic();
}

function saveTrafficForRuleCoverage(traffic, servicesForRules) {
  const originalRuleTraffics = [...(trafficForRuleCoverage[traffic.href] || [])];

  trafficForRuleCoverage[traffic.href] = [];

  const ruleTraffics = trafficForRuleCoverage[traffic.href];

  traffic.source.allHrefs.forEach(sourceHref => {
    let targetForRules;

    const sourceForRules = getRuleCoverageEntity({
      type: traffic.source.type,
      href: sourceHref,
    });

    traffic.target.allHrefs.forEach(targetHref => {
      if (traffic.target.type !== 'fqdn' || isNaN(targetHref)) {
        targetForRules = getRuleCoverageEntity({
          type: traffic.target.type,
          href: targetHref,
        });
      } else if (traffic.target.type === 'fqdn') {
        targetForRules = getRuleCoverageEntity({
          type: 'internet',
          href: targetHref,
        });
      }

      const ruleHasEmptyLabels =
        !targetForRules ||
        (sourceForRules?.labels && !sourceForRules?.labels.length) ||
        (targetForRules?.labels && !targetForRules?.labels.length);

      if (ruleHasEmptyLabels) {
        // won't send the empty labels to the rule coverage,
        // but we are marking it as true so the line won't stay yellow
        rules[traffic.href] = true;
      } else {
        let originalServices = {};

        // Sometimes the role traffic which is aggregated into the app group traffic has slightly different
        // data than the group traffic (ie missing process names), so we need to aggregate the services
        if ((traffic.source.type === 'group' || traffic.source.type === 'appGroup') && originalRuleTraffics?.length) {
          originalServices = originalRuleTraffics[0].services.reduce((result, service) => {
            let key = [
              service.port,
              service.protocol,
              service.process_name || 'unknown',
              service.windows_service_name || 'unknown',
            ].join(',');

            if (service.port === -1) {
              key = 'any';
            }

            result[key] = service;

            return result;
          }, {});
        }

        ruleTraffics.push({
          source: sourceForRules,
          destination: targetForRules,
          services: Object.values({...originalServices, ...servicesForRules}),
          resolve_labels_as: {
            source: RenderUtils.getEntityTypes(getNodeByHref(sourceHref)),
            destination: RenderUtils.getEntityTypes(getNodeByHref(targetHref)),
          },
        });
      }
    });
  });

  if (originalRuleTraffics && !_.isEqual(originalRuleTraffics, ruleTraffics)) {
    requestedHrefsForRuleCoverage = requestedHrefsForRuleCoverage.filter(href => href !== traffic.href);
  }

  if (!ruleTraffics.length) {
    delete trafficForRuleCoverage[traffic.href];
  }
}

function applyRuleToConnection(traffic, rules, denyRules, connection, newSessions, newAddresses, forceIncrement) {
  if (!rules || !rules.length || !connection) {
    return;
  }

  let increment = connection.sessions;
  const targetAddressCount = connection.targetAddresses ? connection.targetAddresses.length : 0;
  const sourceAddressCount = connection.sourceAddresses ? connection.sourceAddresses.length : 0;
  const internetCount =
    (traffic.source.type === 'internet' && !sourceAddressCount) ||
    (traffic.target.type === 'internet' && !targetAddressCount)
      ? 1
      : 0;
  let addressIncrement = targetAddressCount + sourceAddressCount + internetCount;

  // If we have rule on this connection (port/protocol/service)
  // Only increment by the newest session count
  // If we can clear out the old traffic every time, we can remove this, because we will always have a clean slate.
  // But due to traffic asymmetry we still have to accumulate the traffic
  if (newSessions && !_.isEmpty(connection.rules)) {
    increment = newSessions;
    addressIncrement = newAddresses;
  }

  // AND the rule is applicable, then we want to increment the total number of rules
  const rulesetId = RenderUtils.getRuleSetId(rules[0]);
  const allRules = [...rules, ...connection.rules];
  const allDenyRules = [...denyRules, ...connection.denyRules];

  if (!connection.rules.length || forceIncrement) {
    traffic.totalRules += increment;
    traffic.totalConnectionRules += 1;
    traffic.totalAddressRules += addressIncrement;

    // If every rule is in the same ruleset, increment the connections for that ruleset
    if (rules.every(item => RenderUtils.getRuleSetId(item) === rulesetId)) {
      traffic.totalConnectionRulesPerRuleset[rulesetId] = (traffic.totalConnectionRulesPerRuleset[rulesetId] || 0) + 1;
      traffic.totalAddressRulesPerRuleset[rulesetId] =
        (traffic.totalAddressRulesPerRuleset[rulesetId] || 0) + addressIncrement;
    }

    if (allRules.length && !isConnectionFiltered({...connection, rules: allRules})) {
      if (allDenyRules.length && !isConnectionFiltered({...connection, denyRules: allDenyRules})) {
        traffic.filteredAllowDenyRules += increment;
      } else {
        traffic.filteredRules += increment;
      }
    }
  } else if (connection.rules.length) {
    const existingRulesetId = RenderUtils.getRuleSetId(connection.rules[0]);

    // If the existing Rules were all from the same ruleset, but the new ones are different, decrement the connection
    if (
      connection.rules.every(rule => RenderUtils.getRuleSetId(rule) === rulesetId) &&
      (existingRulesetId !== rulesetId || !rules.every(rule => RenderUtils.getRuleSetId(rule) === rulesetId))
    ) {
      traffic.totalConnectionRulesPerRuleset[rulesetId] = (traffic.totalConnectionRulesPerRuleset[rulesetId] || 1) - 1;
      traffic.totalAddressRulesPerRuleset[rulesetId] =
        (traffic.totalAddressRulesPerRuleset[rulesetId] || addressIncrement) - addressIncrement;
    }
  }

  connection.rules = allRules;
}

function applyDenyRuleToConnection(traffic, denyRules, rules, connection, newSessions, newAddresses, forceIncrement) {
  if (!denyRules || !denyRules.length || !connection) {
    return;
  }

  // Deny rules are not supported in policy generator yet, so we don't need to keep track of rulset ids
  // and address increments yet
  let increment = connection.sessions;

  // If we have rule on this connection (port/protocol/service)
  // Only increment by the newest session count
  // If we can clear out the old traffic every time, we can remove this, because we will always have a clean slate.
  // But due to traffic asymmetry we still have to accumulate the traffic
  if (newSessions && !_.isEmpty(connection.denyRules)) {
    increment = newSessions;
  }

  const allRules = [...rules, ...connection.rules];
  const allDenyRules = [...denyRules, ...connection.denyRules];

  // If the rules were previously empty
  // Only increment the counts if there are no allow rules for this connection
  // Allow rules will allow traffic, even if there is also a deny rule
  if (!connection.rules.length && (!connection.denyRules.length || forceIncrement)) {
    traffic.totalDenyRules += increment;
    traffic.totalConnectionDenyRules += 1;

    if (allDenyRules.length && !isConnectionFiltered({...connection, denyRules: allDenyRules})) {
      if (allRules.length && !isConnectionFiltered({...connection, rules: allRules})) {
        traffic.filteredAllowDenyRules += increment;
      } else {
        traffic.filteredDenyRules += increment;
      }
    }
  }

  connection.denyRules = allDenyRules;
}

function mapWorkloadTrafficToRoleTraffic(traffic) {
  let sourceRoleHref = traffic.source.href;
  let targetRoleHref = traffic.target.href;

  if (!RenderUtils.isInternetIpList(traffic.source)) {
    sourceRoleHref = workloadNodes[traffic.source.href].roleParent;
  }

  if (!RenderUtils.isInternetIpList(traffic.target)) {
    targetRoleHref = workloadNodes[traffic.target.href].roleParent;
  }

  traffic.roleParentTraffic = `${sourceRoleHref},${targetRoleHref}`;

  // let the parent role traffic know about this workload traffic
  const roleParentTraffic = roleTraffics[traffic.roleParentTraffic];

  if (roleParentTraffic) {
    roleParentTraffic.childrenTraffics[traffic.href] = traffic;
  }

  // now calculate mixed role parent traffic
  let sourceExpanded = expandedRoleHrefs.includes(sourceRoleHref);
  let targetExpanded = expandedRoleHrefs.includes(targetRoleHref);
  const sourceIsInternet = RenderUtils.isInternetIpList(traffic.source);
  const targetIsInternet = RenderUtils.isInternetIpList(traffic.target);

  // also see if role has more than roleThreshold children to determine if it's expanded
  if (!sourceIsInternet && roleNodes[sourceRoleHref]) {
    sourceExpanded ||= roleNodes[sourceRoleHref].entityCounts < roleThreshold;
  }

  if (!targetIsInternet && roleNodes[targetRoleHref]) {
    targetExpanded ||= roleNodes[targetRoleHref].entityCounts < roleThreshold;
  }

  if (
    (sourceExpanded && targetExpanded) ||
    (sourceIsInternet && targetExpanded) ||
    (sourceExpanded && targetIsInternet)
  ) {
    return;
  }

  if (sourceExpanded || targetExpanded) {
    if (sourceExpanded) {
      traffic.mixedRoleParentTraffic = `${traffic.source.href},${targetRoleHref}`;
    } else {
      traffic.mixedRoleParentTraffic = `${sourceRoleHref},${traffic.target.href}`;
    }

    // let the parent role traffic know about this workload traffic
    const mixedRoleParentTraffic = mixedRoleTraffics[traffic.mixedRoleParentTraffic];

    if (mixedRoleParentTraffic) {
      aggregateNewTraffic(traffic, mixedRoleParentTraffic);
    }
  }
}

// calculate traffic between collapsed roles and expanded workloads
function calculateMixedRoleTraffics() {
  // clean mixedRoleTraffic every time it's calculated
  mixedRoleTraffics = {};
  _.forOwn(roleTraffics, roleTraffic => {
    let sourceExpanded = expandedRoleHrefs.includes(roleTraffic.source.href);
    let targetExpanded = expandedRoleHrefs.includes(roleTraffic.target.href);
    const sourceIsInternet = RenderUtils.isInternetIpList(roleTraffic.source);
    const targetIsInternet = RenderUtils.isInternetIpList(roleTraffic.target);

    // also see if role has more than roleThreshold children to determine if it's expanded
    if (!sourceIsInternet && roleTraffic.source.type !== 'virtualServer') {
      sourceExpanded ||=
        (roleNodes[roleTraffic.source.href] && roleNodes[roleTraffic.source.href].entityCounts) < roleThreshold;
    }

    if (!targetIsInternet && roleTraffic.target.type !== 'virtualServer') {
      targetExpanded ||=
        (roleNodes[roleTraffic.target.href] && roleNodes[roleTraffic.target.href].entityCounts) < roleThreshold;
    }

    if (
      (sourceExpanded && targetExpanded) ||
      (sourceIsInternet && targetExpanded) ||
      (sourceExpanded && targetIsInternet)
    ) {
      // if both sides are expanded, or one of the sides is internet
      // then don't do anything
      return;
    }

    if (sourceExpanded || targetExpanded) {
      // if only one side is expanded,
      // calculate mixed RoleTraffic from childrenTraffics under each roleTraffic
      _.forOwn(roleTraffic.childrenTraffics, workloadTraffic => {
        let mixedRoleKey;

        if (sourceExpanded) {
          mixedRoleKey = `${workloadTraffic.source.href},${roleTraffic.target.href}`;
        } else {
          mixedRoleKey = `${roleTraffic.source.href},${workloadTraffic.target.href}`;
        }

        let traffic = mixedRoleTraffics[mixedRoleKey];

        if (!traffic) {
          traffic = mixedRoleTraffics[mixedRoleKey] = getNewTraffic({
            href: mixedRoleKey,
            source: {
              type: sourceExpanded ? workloadTraffic.source.type : roleTraffic.source.type,
              href: sourceExpanded ? workloadTraffic.source.href : roleTraffic.source.href,
              allHrefs: new Set(sourceExpanded ? [] : roleTraffic.source.allHrefs),
            },
            target: {
              type: targetExpanded ? workloadTraffic.target.type : roleTraffic.target.type,
              href: targetExpanded ? workloadTraffic.target.href : roleTraffic.target.href,
              allHrefs: new Set(targetExpanded ? [] : roleTraffic.target.allHrefs),
            },
            maxSeverity: -1,
          });

          traffic.childrenTraffics = [];
        }

        // if sourceExpanded, the mixedRoleTraffic.source.allHrefs is
        // based on all corresponding childrenTraffics.
        // The same thing for target side.
        if (sourceExpanded) {
          // add array into a set
          workloadTraffic.source.allHrefs.forEach(traffic.source.allHrefs.add.bind(traffic.source.allHrefs));
        } else {
          workloadTraffic.target.allHrefs.forEach(traffic.target.allHrefs.add.bind(traffic.target.allHrefs));
        }

        workloadTraffic.mixedRoleTrafficParent = traffic.href;
        traffic.childrenTraffics.push(workloadTraffic);
      });

      _.forOwn(mixedRoleTraffics, traffic => {
        traffic.childrenTraffics.forEach(workloadTraffic => aggregateNewTraffic(workloadTraffic, traffic));
        // Now add the connections we know about after the aggregation
        // This will keep duplicate ports which get aggregated to a single port from getting double counted
        traffic.serviceNum += Object.keys(traffic.connections).length;
      });
    }
  });
}

function aggregateNewTraffic(newTraffic, aggregatedTraffic, skipRuleCoverage) {
  const lastProvisionTime = VersionStore.getLatestProvisionTime();

  // Get all the extra connections we don't know about
  aggregatedTraffic.serviceNum = newTraffic.serviceNum - Object.keys(newTraffic.connections).length;

  // only aggregate the connections if the traffic has all the connections back
  _.forOwn(newTraffic.connections, (connection, key) => {
    let resultingConnection = aggregatedTraffic.connections[key];

    if (!resultingConnection) {
      aggregatedTraffic.connections[key] = resultingConnection = {
        port: connection.port,
        protocol: connection.protocol,
        friendlyProtocol: connection.friendlyProtocol,
        connectionClass: connection.connectionClass,
        service: connection.service,
        processName: connection.processName,
        serviceName: connection.serviceName,
        sessions: 0,
        osType: connection.osType,
        ruleCoverageOsType: connection.ruleCoverageOsType,
        rules: [],
        denyRules: [],
        timestamp: connection.timestamp,
        maxSeverity: connection.maxSeverity,
        policyCounts: RenderUtils.getNewPolicyDecisions(0),
        policyDecisionFlags: RenderUtils.getNewPolicyDecisions(true),
        endpointEnforcement: connection.endpointEnforcement,
        groupLink: connection.groupLink,
      };

      // every time we see new connection, we want to increment total connections
      aggregatedTraffic.totalConnections += 1;

      incrementNotIgnoredServices(aggregatedTraffic, connection, null, lastProvisionTime);

      calculateTrafficVulnerability(aggregatedTraffic, key);
      aggregatedTraffic.connectionsForRules[connection.ruleKey] = getConnectionForRule(connection);
    }

    resultingConnection.endpointEnforcement = {
      ...resultingConnection.endpointEnforcement,
      ...connection.endpointEnforcement,
    };

    if (
      resultingConnection.osType !== connection.osType &&
      (resultingConnection.osType === 'unknown' || connection.osType === 'linux')
    ) {
      resultingConnection.osType = connection.osType;
    }

    // Default the rulecoverage osType to windows to ask the broadest question
    if (resultingConnection.ruleCoverageOsType !== connection.ruleCoverageOsType) {
      resultingConnection.osType = 'windows';
    }

    // We always want to increment total sessions
    // and see if it's within ignored services.  if it's not, increment filtered sessions
    aggregatedTraffic.totalSessions += connection.sessions;

    policyDecisions.forEach(policyDecision => {
      aggregatedTraffic.total[policyDecision] += connection.policyDecisionFlags[policyDecision]
        ? connection.sessions
        : 0;
    });

    incrementNotIgnoredServices(aggregatedTraffic, connection, connection.sessions, lastProvisionTime);

    resultingConnection.sessions += connection.sessions;

    policyDecisions.forEach(policyDecision => {
      resultingConnection.policyDecisionFlags[policyDecision] &= connection.policyDecisionFlags[policyDecision];
      resultingConnection.policyCounts[policyDecision] += connection.policyCounts[policyDecision];
    });

    // apply rules to aggregatedTraffic
    let ruleLink = rules[aggregatedTraffic.href];

    if (aggregatedTraffic.source.type === 'appGroup') {
      ruleLink = rules[newTraffic.href];
    }

    if (ruleLink) {
      const connRules = ruleLink.connections && ruleLink.connections[key] && ruleLink.connections[key].rules;
      const connDenyRules = ruleLink.connections && ruleLink.connections[key] && ruleLink.connections[key].denyRules;

      // There might be multiple workload traffic using the same rule, so force an increment of the
      // totalRules for each piece of traffic
      const targetAddressCount = connection.targetAddresses ? connection.targetAddresses.length : 0;
      const sourceAddressCount = connection.sourceAddresses ? connection.sourceAddresses.length : 0;

      applyRuleToConnection(
        aggregatedTraffic,
        connRules,
        connDenyRules,
        resultingConnection,
        connection.sessions,
        sourceAddressCount + targetAddressCount,
        true,
      );

      applyDenyRuleToConnection(
        aggregatedTraffic,
        connDenyRules,
        connRules,
        resultingConnection,
        connection.sessions,
        sourceAddressCount + targetAddressCount,
        true,
      );

      // If any aggregated connection is missing rules, mark the resulting connection blocked
      // we need to keep the fact that there are both allowed and blocked connections
      // so we leave it in place for both allowed/blocked filter cases
      if (!connRules?.length) {
        resultingConnection.blockedConnection = true;
      }

      if (
        ruleLink.connections &&
        ruleLink.connections['-1,-1'] &&
        ruleLink.connections['-1,-1'].rules &&
        ruleLink.connections['-1,-1'].rules.length
      ) {
        aggregatedTraffic.allServicesRule = true;
      }
    }
  });

  if (!skipRuleCoverage) {
    saveTrafficForRuleCoverage(aggregatedTraffic, aggregatedTraffic.connectionsForRules);
  }
}

function calculateAppGroupTraffic(appGroup, skipRuleCoverage) {
  // clean appGroupTraffic for this appGroup every time it's calculated
  appGroupTraffics[appGroup] = {};

  const ownHref = [appGroup, appGroup].join(',');

  _.forOwn(roleTraffics, roleTraffic => {
    const sourceIsInternet = RenderUtils.isInternetIpList(roleTraffic.source);
    const targetIsInternet = RenderUtils.isInternetIpList(roleTraffic.target);
    const sourceParent = roleNodes[roleTraffic.source.href]?.appGroupParent;
    const targetParent = roleNodes[roleTraffic.target.href]?.appGroupParent;
    const href = [sourceParent, targetParent].join(',');

    if (
      !sourceIsInternet &&
      !targetIsInternet &&
      sourceParent &&
      targetParent &&
      (sourceParent === appGroup || targetParent === appGroup) &&
      ownHref !== href
    ) {
      let traffic = appGroupTraffics[appGroup][href];

      if (!traffic) {
        traffic = appGroupTraffics[appGroup][href] = getNewTraffic({
          href,
          source: {
            ...(appGroupNodes[sourceParent] || phantomAppGroupNodes[sourceParent]),
            allHrefs: new Set([sourceParent]),
          },
          target: {
            ...(appGroupNodes[targetParent] || phantomAppGroupNodes[targetParent]),
            allHrefs: new Set([targetParent]),
          },
          maxSeverity: -1,
          ruleCoverageToBeLoaded: false,
        });

        traffic.childrenTraffics = [];
      }

      traffic.childrenTraffics.push(roleTraffic);
    }
  });

  const policyStates = {visibility: 0, selective: 0, enforced: 0, unmanaged: 0, idle: 0};
  const policyStateFilters = TrafficFilterStore.getHiddenPolicyStates();

  // add a new flag “isFiltered” for each aggregated app group data
  // (if everything in this app group is filtered out, isFiltered = true)
  for (const [key, node] of Object.entries(roleNodes)) {
    if (!node.mode) {
      roleNodes[key].isFiltered = false;

      continue;
    }

    const filteredWorkloads = RenderUtils.getPolicyStateGroupWorkloads(node, policyStateFilters);
    const filteredContainers = RenderUtils.getPolicyStateGroupContainerWorkloads(node, policyStateFilters);

    roleNodes[key].isFiltered = node.entityCounts - filteredWorkloads - filteredContainers === 0;
  }

  _.forOwn(appGroupTraffics[appGroup], traffic => {
    if (traffic.source.href === appGroup) {
      traffic.target.mode = {...policyStates};
      traffic.target.containerMode = {...policyStates};
      traffic.target.entityCounts = 0;
    } else {
      traffic.source.mode = {...policyStates};
      traffic.source.containerMode = {...policyStates};
      traffic.source.entityCounts = 0;
    }

    traffic.source.isFiltered = true;
    traffic.target.isFiltered = true;

    traffic.childrenTraffics.forEach(roleTraffic => {
      const source = roleNodes[roleTraffic.source.href];
      const target = roleNodes[roleTraffic.target.href];

      traffic.source.isFiltered &&= Boolean(source.isFiltered);
      traffic.target.isFiltered &&= Boolean(target.isFiltered);

      aggregateNewTraffic(roleTraffic, traffic, skipRuleCoverage);

      Object.keys(policyStates).forEach(policyState => {
        if (traffic.source.href === appGroup) {
          const policyStateModeCounts = target.counts[appGroup]?.mode[policyState];
          const policyStateContainerModeCounts = target.counts[appGroup]?.containerMode[policyState];

          if (policyStateModeCounts) {
            traffic.target.mode[policyState] += policyStateModeCounts;
          }

          if (policyStateContainerModeCounts) {
            traffic.target.containerMode[policyState] += policyStateContainerModeCounts;
          }
        } else {
          const sourceModeCounts = source.counts[appGroup]?.mode[policyState];
          const sourceContainerCounts = source.counts[appGroup]?.containerMode[policyState];

          if (sourceModeCounts) {
            traffic.source.mode[policyState] += sourceModeCounts;
          }

          if (sourceContainerCounts) {
            traffic.source.containerMode[policyState] += sourceContainerCounts;
          }
        }
      });

      const targetEntityCounts = target.counts[appGroup]?.entityCounts;
      const sourceEntityCounts = source.counts[appGroup]?.entityCounts;

      if (traffic.source.href === appGroup && targetEntityCounts) {
        traffic.target.entityCounts += targetEntityCounts;
      } else if (sourceEntityCounts) {
        traffic.source.entityCounts += sourceEntityCounts;
      }

      if (!rules[roleTraffic.href]) {
        traffic.ruleCoverageToBeLoaded = true;
      }
    });

    // Now add the connections we know about after the aggregation
    // This will keep duplicate ports which get aggregated to a single port from getting double counted
    traffic.serviceNum += Object.keys(traffic.connections).length;
  });

  graphReadyAppGroupTraffic[appGroup] = Object.values(appGroupTraffics[appGroup]).reduce((result, traffic) => {
    result[traffic.href] = {
      href: traffic.href,
      connections: {...traffic.connections},
      data: traffic,
    };

    return result;
  }, {});
}

function aggregateAllAppGroupTraffic() {
  // The Rule coverage format does not need to be calculated again in this case, and it is expensive
  Object.keys(appGroupTraffics).forEach(appGroup => calculateAppGroupTraffic(appGroup, true));
}

function isTrafficLoaded(type, key, labels, exclude, connected) {
  const transmissionFilters = TrafficFilterStore.getTransmissionFilters();
  const nodeKey = key || 'no_key';
  const labelsKey = labels || JSON.stringify([]);
  const excludeKey = exclude || JSON.stringify([]);
  const connectedKey = connected ? 'true' : 'false';
  const mapLevel = MapPageStore.getMapLevel();

  // For Traffic Loading with effective transmission type filtering, the keys have a traffic_class included in the key as well, which needs to be checked here.
  // Condition comes up in full map
  // If the nodeKey already includes the trafficClass, skip this
  if (
    mapLevel === 'full' &&
    type === 'workloads' &&
    !trafficClasses.some(trafficClass => nodeKey[0].includes(trafficClass))
  ) {
    let isTrafficLoadedBool = true;

    transmissionFilters.forEach((transmissionFilter, i) => {
      if (transmissionFilter) {
        isTrafficLoadedBool &&= getLoadedTrafficByKey(
          type,
          `${nodeKey}x${trafficClasses[i]}`,
          labelsKey,
          excludeKey,
          connectedKey,
          'loaded',
        );
      }
    });

    return isTrafficLoadedBool;
  }

  return getLoadedTrafficByKey(type, nodeKey, labelsKey, excludeKey, connectedKey, 'loaded');
}

export default createApiStore(['NETWORK_TRAFFIC_'], {
  dispatchHandler(action) {
    switch (action.type) {
      case Constants.APP_GROUPS_GET_COLLECTION_SUCCESS:
        parseAppGroupsVes(action.data);
        break;

      case Constants.APP_GROUPS_OBSERVED_RULE_COVERAGE_SUCCESS:
        ruleBuilderCoverageIsLoaded = true;
        parseAppGroupRuleCoverage(action.data);
        break;

      case Constants.APP_GROUP_OBSERVED_RULE_COVERAGE_SUCCESS:
        updateAppGroupRuleCoverage(action.options.params.app_group_id, action.data);
        break;

      case Constants.LOCATION_SUMMARY_GET_SUCCESS:
        if (
          !action.data ||
          (MapPageStore.getMapLoadingOption() === 'rebuildStale' && action.data.stale) ||
          action.data.version < CURRENT_TRAFFIC_VERSION
        ) {
          return;
        }

        locationNodes = {};
        _.forOwn(action.data.nodes, location => {
          locationNodes[location.href] = location;
          locationNodes[location.href].entityCounts =
            location.num_workloads +
            location.num_container_workloads +
            location.num_virtual_services +
            location.num_virtual_servers;
          locationNodes[location.href].built = action.data.updated_at;
          locationNodes[location.href].stale = action.data.stale;
          locationNodes[location.href].truncated = action.data.truncated;
          locationNodes[location.href].groups = {};
        });

        // Remove any existing clusters which are not contained in the location summary
        Object.keys(clusterNodes).forEach(href => {
          if (!action.data.clusters[href]) {
            delete clusterNodes[href];
          }
        });

        parseNodes(action.data, 'groups');
        loadedLocationSummarySuccess = true;

        setLoadedTrafficKeys('locations', ['no_key'], '[]', '[]', 'false', 'loaded');
        break;

      case Constants.APP_GROUP_SUMMARY_GET_SUCCESS:
        if (
          !action.data ||
          (MapPageStore.getMapLoadingOption() === 'rebuildStale' && action.data.stale) ||
          action.data.version < CURRENT_TRAFFIC_VERSION
        ) {
          return;
        }

        appGroupNodes = parseAppGroupsNodes(action.data);
        matchingAppGroupType = matchingAppGroupTypes();
        loadedAppGroupsSuccess = true;
        ruleBuilderCoverageIsLoaded = false;
        setLoadedTrafficKeys('appGroups', ['no_key'], '[]', '[]', 'false', 'loaded');
        this.emitAppGroupsLoaded();
        break;

      case Constants.NETWORK_TRAFFIC_GET_SUCCESS:
        const query = action.options && action.options.query;

        if (GraphDataUtils.isDataOld(action.data, action.options.query)) {
          return;
        }

        if (action.data.fqdns) {
          addFqdns(action.data.fqdns);
        }

        let nodeType = 'workloads';
        let scopeType;
        let scope = [];
        const labels = query.labels ? JSON.parse(query.labels) : [];

        if (query && query.clusters) {
          nodeType = 'groups';
        } else if (query && query.app_groups) {
          nodeType = 'appGroups';
        } else if (query && query.roles) {
          nodeType = 'roles';
        }

        if (query && query.cluster_keys && query.cluster_keys !== '[]') {
          scope = JSON.parse(query.cluster_keys) || [];
          scopeType = 'groups';
        } else if (query && query.app_group_keys && query.app_group_keys !== '[]') {
          scope = JSON.parse(query.app_group_keys);
          scopeType = 'appGroups';
        } else if (query && query.role_keys && query.role_keys !== '[]') {
          scope = JSON.parse(query.role_keys) || [];
          scopeType = 'roles';
        } else if (query && query.workloads && query.workloads !== '[]') {
          scope = JSON.parse(query.workloads) || [];
          scopeType = 'workloads';
        } else if (query && query.clusters && query.labels) {
          scopeType = 'location';
        } else {
          scopeType = 'all';
        }

        if (nodeType === 'groups' && scopeType === 'groups') {
          _.forEach(scope, cluster => {
            clustersLoaded[cluster] = query.labels;
          });
        }

        const route = MapPageStore.getMapRoute();
        const connected =
          route &&
          route.previd &&
          route.prevtype === 'focused' &&
          (query.app_group_keys || query.role_keys) &&
          route.id.split('x').every(id => scope.length && scope[0].split('-')[0].split('x').includes(id));

        let nodes = getNodesForScope(action.data.labels, nodeType, scopeType, scope, labels);

        const isSingleClusterLink = action.options.isSingleClusterLink;
        const groupScope = scope[0] && scope[0].split('-')[0];

        const isConnectedGroup =
          route &&
          nodeType === 'roles' &&
          scopeType === 'appGroups' &&
          ((route.prevtype === 'focused' && route.id === groupScope) ||
            (route.prevtype === 'location' &&
              route.id !== groupScope &&
              // Do not consider it a different group if the request was the app group 2 labelled version
              // of the three labelled group in the location map
              (!groupScope || groupScope.split('x').some(id => !scope || !scope[0].split('x').includes(id)))));

        let labelScope = query.labels || '[]';

        if (isConnectedGroup) {
          labelScope = [route.prevtype === 'focused' ? route.previd : route.id, groupScope].join(',');
        }

        if (action.data.ip_lists) {
          parseIpLists(action.data.ip_lists);
        }

        if (query.traffic_class === 'broadcast') {
          broadcastTrafficLoaded = true;
        }

        if (query.traffic_class === 'multicast') {
          multicastTrafficLoaded = true;
        }

        // Check if this traffic was already loaded.
        const wasTrafficLoaded = isTrafficLoaded(
          nodeType,
          scope.length ? scope.map(s => `${s}x${query.traffic_class}`) : ['no_key'],
          labelScope,
          query.exclude || '[]',
          false,
        );

        // Get the base query
        const genericQuery = {...query};

        delete genericQuery.traffic_class;
        delete genericQuery.max_ports;
        delete genericQuery.max_nodes;
        delete genericQuery.max_ip_lists;

        // If the corresponding traffic was loaded or forced 'clear on next',
        // remove nodes for that traffic.
        if ((!connected && wasTrafficLoaded) || clearOnNext.has(JSON.stringify(genericQuery))) {
          if (nodeType !== 'appGroups' && !connected) {
            removeNodes(nodes);
          }

          removeTrafficForNodes(
            nodeType,
            nodes,
            true,
            nodeType !== 'groups',
            false,
            isSingleClusterLink,
            action.data.updated_at,
            connected,
          );
          // Remove the loaded keys for all traffic classes
          removeLoadedTraffic(nodeType, scope.length ? scope : ['no_key'], ['loaded']);
          // Remove requested keys for traffic classes that are currently filtered out
          // We can't remove the requested traffic for the visible graphs because it will cause an infinite loop of requesting these graphs
          // But we must remove them from the filtered graphs so they will be loaded when they are unfiltered
          removeLoadedTraffic(nodeType, scope.length ? scope : ['no_key'], ['requested'], true);
        }

        // Set the loaded key for this traffic class
        setLoadedTrafficKeys(
          nodeType,
          scope.length ? scope.map(s => `${s}x${query.traffic_class}`) : ['no_key'],
          labelScope,
          query.exclude || '[]',
          'false',
          'loaded',
        );

        // Remove this query from the clear on next list
        clearOnNext.delete(JSON.stringify(genericQuery));

        // Do not send the scope for groups, unless a group is equivalent to an App Group
        parseNodes(
          action.data,
          nodeType,
          isConnectedGroup ? labelScope.split(',') : null,
          (scopeType !== 'groups' || appGroupsType.length === 3) && scope,
        );

        // Get new nodes for scope after parsing the new nodes
        nodes = getNodesForScope(action.data.labels, nodeType, scopeType, scope, labels);
        parseTraffic(action.data, nodeType, nodes, connected && route.id, scope);

        if (nodeType === 'workloads') {
          this.emitWorkloadsLoaded();
        }

        // Clearing out caps data when clusters are reloaded as a result of network traffic update.
        loadedForCaps = {loaded: {}, requested: {}};

        break;

      case Constants.SELECT_TRAFFIC_FILTERS:
        aggregateAllAppGroupTraffic();
        break;

      case Constants.SEC_POLICY_RULE_COVERAGE_CREATE_SUCCESS:
        if (action.options.type === 'illumination') {
          const links = action.options.data ? action.options.data : [];
          const linkHrefs = action.options.trafficHrefs ? action.options.trafficHrefs : [];

          parseRuleCoverage(links, linkHrefs, action.data);
        }

        if (action.options.type === 'ringfence') {
          ringFenceRules[action.options.href] = {
            allow: Object.values(action.data.rules).flatMap(rule => Object.values(rule)),
            deny: Object.values(action.data.deny_rules).flatMap(denyRule => Object.values(denyRule)),
          };
        }

        break;

      case Constants.UPDATE_RING_FENCE_RULES:
        ringFenceRules = {};
        break;

      case Constants.AGGREGATED_DETECTED_VULNERABILITIES_GET_COLLECTION_SUCCESS:
      case Constants.DETECTED_VULNERABILITIES_GET_COLLECTION_SUCCESS:
        const node = action.options.node;

        parseVulnerabilities(action.data, action.options.node);
        loadedNodesForVulnerabilities.loaded[node] = true;
        break;

      case Constants.RULE_SET_PROJECTED_VES_SUCCESS:
      case Constants.RULE_SETS_PROJECTED_VES_SUCCESS:
        parseProjectedVulnerabilities(action.data);
        break;

      case Constants.WORKLOADS_VERIFY_CAPS_SUCCESS:
        // TBD: Can we remove this entire mechanism?
        //this.writeCapsData(action.options.nodeType, action.options.nodeData, action.data.caps);
        break;

      case Constants.SEC_POLICY_RULES_FOR_SCOPE:
        removeRuleLoadedLinksForScope(action.data);
        ringFenceRules = {};
        this.emitUpdateChange();

        return true;

      case Constants.SEC_POLICY_RULES_FOR_WORKLOAD:
        removeRuleLoadedLinksForNodes(action.data.workloadsHref);
        ringFenceRules = {};
        this.emitUpdateChange();

        return true;

      case Constants.SEC_POLICY_RULES_FOR_ALL:
        rules = {};
        requestedHrefsForRuleCoverage = [];
        ringFenceRules = {};
        this.emitUpdateChange();

        return true;

      case Constants.UPDATE_REQUESTED_LINKS:
        requestedHrefsForRuleCoverage = _.union(requestedHrefsForRuleCoverage, action.data);
        break;

      case Constants.NETWORK_TRAFFIC_UPDATE_FOR_NODES:
        // Make an array out of 'nodes', and then map the workload_hrefs out of the depths
        const workloadHrefs = _.map(_.values(action.data), 'traffic_workload.href');

        removeTrafficForNodes('workloads', workloadHrefs, true, true);
        ringFenceRules = {};
        this.emitUpdateChange();

        return true;

      case Constants.NETWORK_TRAFFIC_UPDATE_FOR_SCOPES:
        removeTrafficForScopes(action.data, true);
        ringFenceRules = {};
        this.emitUpdateChange();

        return true;

      case Constants.NETWORK_TRAFFIC_UPDATE_FOR_All:
        trafficForRuleCoverage = {}; // Contains the avenger data of the links
        requestedHrefsForRuleCoverage = [];
        loadedNodesForVulnerabilities = {loaded: {}, requested: {}};
        rules = {};
        this.emitUpdateChange();

        return true;

      case Constants.UPDATE_FOR_All:
        broadcastTrafficLoaded = false;
        multicastTrafficLoaded = false;
        trafficForRuleCoverage = {}; // Contains the avenger data of the links
        requestedHrefsForRuleCoverage = [];
        rules = {};

        if (!action.data) {
          appGroupNodes = {};
        }

        loadedAppGroupsForTraffic = {loaded: {}, requested: {}};
        loadedClustersForTraffic = {loaded: {}, requested: {}};
        loadedRolesForTraffic = {loaded: {}, requested: {}};
        loadedWorkloadsForTraffic = {loaded: {}, requested: {}};
        loadedNodesForVulnerabilities = {loaded: {}, requested: {}};
        loadedForCaps = {loaded: {}, requested: {}};
        loadedAppGroupsSuccess = false;
        clusterTraffics = {};
        appGroupTraffics = {};
        graphReadyAppGroupTraffic = {};
        workloadTraffics = {};
        roleTraffics = {};
        mixedRoleTraffics = {};
        ringFenceRules = {};
        this.emitUpdateChange();

        return true;

      case Constants.CLEAR_TRAFFIC_ON_NEXT:
        clearOnNext.add(action.data);
        break;

      case Constants.RESET_DEFAULT_FILTERS:
        // Ensure the TrafficFilterStore is done so aggregating the app group links has the latest filters
        dispatcher.waitFor([TrafficFilterStore.dispatchToken]);
        resetTrafficFilters();
        break;

      case Constants.SELECT_SERVICE_FILTERS:
        setTrafficFilters(action.data, 'serviceFilters');
        break;

      case Constants.SELECT_TRAFFIC_CONNECTION_FILTERS:
        setTrafficFilters(action.data, 'connectionFilters');
        break;

      case Constants.SELECT_TRAFFIC_TIME_FILTERS:
        dispatcher.waitFor([VersionStore.dispatchToken]);
        setTrafficFilters(action.data, 'timeFilters');
        break;

      case Constants.EXPAND_ROLE:
        setExpandedRoles(action.data);
        calculateMixedRoleTraffics();
        break;

      case Constants.COLLAPSE_ROLE:
        removeExpandedRoles(action.data);
        calculateMixedRoleTraffics();
        break;

      case Constants.UPDATE_TRAFFIC_PARAMETERS:
        // only trigger mixed role recalculation if the role count changed
        if (action.data.roleCollapse !== roleThreshold) {
          roleThreshold = parseInt(localStorage.getItem('role_collapse'), 10);
          calculateMixedRoleTraffics();
          break;
        }

        // if role count threshold hasn't changed, then return true so not to emit change
        return true;

      case Constants.SET_MAP_POLICY_VERSION:
        // if policy version is draft, don't hideOldTraffic
        dispatcher.waitFor([MapPageStore.dispatchToken]);
        setTrafficFilters();
        break;

      case Constants.UPDATE_MAP_ROUTE:
        const newFocused = action.data.previd || action.data.id;

        if (newFocused && oldFocused !== newFocused) {
          oldFocused = newFocused;

          // When only supporting the App Group Map at scale, clear out the old App Group Data,
          // when switching App Groups
          // Should we make this it's own switch? We would need to do more testing
          // to make sure we don't affect the location view if we do.
          if (MapPageStore.getLocMapType() === 'none') {
            broadcastTrafficLoaded = false;
            multicastTrafficLoaded = false;
            trafficForRuleCoverage = {}; // Contains the avenger data of the links
            requestedHrefsForRuleCoverage = [];
            rules = {};
            loadedClustersForTraffic = {loaded: {}, requested: {}};
            loadedRolesForTraffic = {loaded: {}, requested: {}};
            loadedWorkloadsForTraffic = {loaded: {}, requested: {}};
            loadedNodesForVulnerabilities = {loaded: {}, requested: {}};
            loadedForCaps = {loaded: {}, requested: {}};
            clusterTraffics = {};
            workloadTraffics = {};
            roleTraffics = {};
            mixedRoleTraffics = {};
          }
        }

        break;

      case Constants.ORGS_GET_INSTANCE_SUCCESS:
        dispatcher.waitFor([OrgStore.dispatchToken]);
        appGroupsType = OrgStore.getOrg().app_groups_default || [];
        matchingAppGroupType = matchingAppGroupTypes();
        this.emitUpdateChange();
        break;

      case Constants.UPDATE_POLICY_GENERATOR_RULESET:
        break;

      case Constants.CLEAR_LOADED_ROLES:
        removeLoadedTraffic('workloads', [action.data]);
        break;

      default:
        return true;
    }

    this.emitChange();

    return true;
  },

  emitUpdateChange() {
    this.emit(UPDATE_EVENT);
  },

  addUpdateListener(callback) {
    this.on(UPDATE_EVENT, callback);
  },

  removeUpdateListener(callback) {
    this.removeListener(UPDATE_EVENT, callback);
  },

  emitLocationsLoaded() {
    this.emit(LOCATIONS_LOADED_EVENT);
  },

  addLocationsLoadedListener(callback) {
    this.on(LOCATIONS_LOADED_EVENT, callback);
  },

  removeLocationsLoadedListener(callback) {
    this.removeListener(LOCATIONS_LOADED_EVENT, callback);
  },

  emitAppGroupsLoaded() {
    this.emit(APP_GROUPS_LOADED_EVENT);
  },

  addAppGroupsLoadedListener(callback) {
    this.on(APP_GROUPS_LOADED_EVENT, callback);
  },

  removeAppGroupsLoadedListener(callback) {
    this.removeListener(APP_GROUPS_LOADED_EVENT, callback);
  },

  emitWorkloadsLoaded() {
    this.emit(WORKLOADS_LOADED_EVENT);
  },

  addWorkloadsLoadedListener(callback) {
    this.on(WORKLOADS_LOADED_EVENT, callback);
  },

  removeWorkloadsLoadedListener(callback) {
    this.removeListener(WORKLOADS_LOADED_EVENT, callback);
  },

  getAllLocationNodes: () => Object.values(locationNodes),

  getLocations: () => locationNodes,

  getLocation: href => locationNodes[href],

  getAllAppGroupNodes: () => Object.values(appGroupNodes),

  getAppGroupNodesObject: () => appGroupNodes,

  getAllPhantomAppGroupNodes: () => Object.values(phantomAppGroupNodes),

  getTrafficAppGroupNodes: () =>
    Object.values(appGroupNodes).filter(node => node.type && node.caps.rulesets.includes('read')),

  getLoadRulesForAppGroups: () =>
    Object.values(appGroupNodes).length < (localStorage.getItem('load_rule_coverage') || 200),

  getIncrementallyLoadRulesForAppGroups: () =>
    Object.values(appGroupNodes).length < (localStorage.getItem('load_rule_coverage') || 200) * 2,

  getAppGroupNode: href => appGroupNodes[href] || phantomAppGroupNodes[href],

  getAllClusterNodes: () => Object.values(clusterNodes),

  getAllClusterTraffics: () => Object.values(clusterTraffics),

  getAllAppGroupTraffics: () => Object.values(appGroupTraffics),

  getAppGroupTrafficsForAppGroup: href => graphReadyAppGroupTraffic[href] || {},

  getAllWorkloadNodes: () => Object.values(workloadNodes),

  getAllWorkloadTraffics: () => Object.values(workloadTraffics),

  getAllRoleNodes: () => Object.values(roleNodes),

  getAllRoleTraffics: () => Object.values(roleTraffics),

  getAllMixedRoleTraffics: () => Object.values(mixedRoleTraffics),

  getTraffic: href => getTrafficByHref(href),

  getNode: href => getNodeByHref(href),

  getCluster: href => clusterNodes[href],

  getNodeForPolicyGenerator: href => {
    const appGroup = appGroupNodes[href];

    if (_.isEmpty(appGroup)) {
      return null;
    }

    return _.omit(appGroup, ['providing', 'consuming', 'roleCounts']);
  },

  isLocationSummaryLoaded: () => loadedLocationSummarySuccess,

  isAppGroupsLoaded: () => loadedAppGroupsSuccess,

  isAppGroupMapEnabled: () =>
    !SessionStore.isUserScoped() || (loadedAppGroupsSuccess && Object.keys(appGroupNodes)?.length),

  isLinkTrafficLoaded: link => Boolean(trafficForRuleCoverage[link]) || rules[link],

  isClusterLoaded: cluster => clustersLoaded[cluster],

  getRingFenceRules: href => ringFenceRules[href],

  isLinkLoadedForRules: href => Boolean(rules[href]),

  isNodeLoadedForVulnerabilities: href => loadedNodesForVulnerabilities.loaded[href],

  getRequestedHrefsForRuleCoverage: () => requestedHrefsForRuleCoverage,

  getServiceFilters: () => serviceFilters,

  getTrafficTimeFilters: () => trafficTimeFilters,

  //Since Default Connection Filters are {allowBroadCastTraffic:false, allowMultiCastTraffic:false, allowUnicastTraffic:true}, we need to flip the filter check logic for broadcast and multicast traffic.
  getConnectionFilters: () =>
    Object.entries(connectionFilters).some(filter =>
      ['allowBroadcastTraffic', 'allowMulticastTraffic'].includes(filter[0]) ? filter[1] : !filter[1],
    ),

  getIplist: href => ipLists[href.split('/').pop()],

  getGroupSearch: () => groupSearch,

  getCurrentTrafficVersion: () => CURRENT_TRAFFIC_VERSION,

  isAppGroupDisabled: () => !appGroupsType.length,

  getAppGroupsType: () => appGroupsType,

  getFqdn: href => fqdns[href],

  isAppGroupTypeMatching: () => matchingAppGroupType,

  isPolicyGeneratorCoverageLoaded: () => ruleBuilderCoverageIsLoaded,

  isBroadcastTrafficLoaded: () => broadcastTrafficLoaded,

  isMulticastTrafficLoaded: () => multicastTrafficLoaded,

  getConnectedLinks(href) {
    if (!workloadNodes[href] || _.isEmpty(workloadTraffics)) {
      return;
    }

    return _.map(workloadNodes[href].connectedLinks, linkHref => workloadTraffics[linkHref]);
  },

  isTrafficLoaded: (type, key, labels, exclude, connected) => isTrafficLoaded(type, key, labels, exclude, connected),

  isTrafficRequested(type, key, labels, exclude, connected) {
    const nodeKey = key || 'no_key';
    const labelsKey = labels || JSON.stringify([]);
    const excludesKey = exclude || JSON.stringify([]);
    const connectedKey = connected ? 'true' : 'false';

    const isLoaded =
      getLoadedTrafficByKey(type, nodeKey, labelsKey, excludesKey, connectedKey, 'requested') ||
      getLoadedTrafficByKey(type, nodeKey, labelsKey, excludesKey, false, 'requested');

    setLoadedTrafficKeys(type, [nodeKey], labelsKey, excludesKey, connectedKey, 'requested');

    return isLoaded;
  },

  getUnrequestedNodesForVulnerabilities(nodes) {
    const unrequestedNodes = _.filter(nodes, node => !loadedNodesForVulnerabilities.requested[node]);

    addRequestedNodesForVulnerabilities(unrequestedNodes);

    return unrequestedNodes;
  },

  getUnrequestedNodesForCaps(nodes) {
    const unrequestedNodes = _.filter(nodes, node => !loadedForCaps.requested[node]);

    addRequestedNodesForCaps(unrequestedNodes);

    return unrequestedNodes;
  },

  getUnrequestedClustersForCaps(clusters) {
    const unrequestedClusters = _.filter(clusters, cluster => !loadedForCaps.requested[cluster]);

    addRequestedClustersForCaps(unrequestedClusters);

    return unrequestedClusters;
  },

  getLoadedTrafficForType(type) {
    switch (type) {
      case 'groups':
        return loadedClustersForTraffic;
      case 'roles':
        return loadedRolesForTraffic;
      case 'workloads':
        return loadedWorkloadsForTraffic;
      // no default
    }
  },

  getReadyHrefsForRuleCoverage(linkHrefs) {
    // takes in link hrefs and sees which are rule coverage ready
    // and gives back the hrefs of those that are
    const readyHrefs = _.filter(linkHrefs, href => Boolean(trafficForRuleCoverage[href]));

    requestedHrefsForRuleCoverage = _.union(requestedHrefsForRuleCoverage, readyHrefs);

    return readyHrefs;
  },

  getTrafficForRuleCoverage(linkHrefs) {
    const trafficsForRuleCoverage = [];
    const trafficHrefs = [];

    linkHrefs.forEach(href => {
      const ruleTraffics = trafficForRuleCoverage[href];

      if (ruleTraffics) {
        ruleTraffics.forEach(ruleTraffic => {
          trafficHrefs.push(href);
          trafficsForRuleCoverage.push(ruleTraffic);
        });
      }
    });

    return {trafficsForRuleCoverage, trafficHrefs};
  },

  getNodeVulnerabilities() {
    return nodeVulnerabilities;
  },

  getNodeVulnerabilityByHref(href) {
    if (href) {
      return nodeVulnerabilities[href];
    }
  },
});
