/**
 * Copyright 2015 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import intl from '@illumio-shared/utils/intl';
import WorkloadStore from '../stores/WorkloadStore';
import VirtualServerStore from '../stores/VirtualServerStore';
import RulesetStore from '../stores/RulesetStore';
import MapPageStore from '../stores/MapPageStore';
import TrafficStore from '../stores/TrafficStore';
import SessionStore from '../stores/SessionStore';
import TrafficFilterStore from '../stores/TrafficFilterStore';
import GraphStore from '../stores/GraphStore';
import RestApiUtils from './RestApiUtils';
import RenderUtils from './RenderUtils';
import RuleGraphUtils from './RuleGraphUtils';
import actionCreators from '../actions/actionCreators';
import AlertDialog from '../components/AlertDialog';
import UserMixin from '../mixins/UserMixin';
import GraphDataUtils from './GraphDataUtils';
import {getInstanceUri, getSessionUri} from '../lib/api';
import {PolicyGeneratorUtils} from '.';
import ServiceUtils from './ServiceUtils';
import {getId} from './GeneralUtils';

export function calculateWorkloadActions(node) {
  const actions = {};

  actions.type = 'workloadActions';
  actions.title = null;
  actions.msg = null;
  actions.secondaryActions = [];

  const trafficIsWritable = node.caps?.rulesets.includes('write');

  if (trafficIsWritable) {
    const groupLabels = MapPageStore.getMapType() === 'app' ? TrafficStore.getAppGroupsType() : ['app', 'env', 'loc'];

    const clearTrafficAction = !UserMixin.isSuperclusterUser() && {
      text: intl('Map.Traffic.ClearCounters'),
      clearTrafficAction: {
        title: intl('Map.Traffic.ClearCounters'),
        message: `${intl('Map.Traffic.ClearTrafficMessage')}:`,
        labels: node.labels,
        groupLabels,
        onClearRoleConfirm: _.partial(getLabelBasedClearTraffic, node, groupLabels),
        onClearAppConfirm: _.partial(getLabelBasedClearTraffic, node.cluster, groupLabels),
        onClearOrgConfirm: getOrgClearTraffic,
      },
    };

    actions.secondaryActions = [clearTrafficAction];
  }

  if (
    node.type === 'workload' &&
    !UserMixin.isSuperclusterUser() &&
    trafficIsWritable &&
    !node.unmanaged &&
    node.policyState !== 'idle'
  ) {
    // Increase rate action
    const increaseRateAction = {
      text: intl('Common.IncreaseTrafficUpdateRate'),
      modalAction: {
        title: intl('Common.IncreaseTrafficUpdateRate'),
        message: intl('Common.IncreaseTrafficUpdateRateMessage', {minutes: 10}),
        onConfirm: increaseVenUpdateRate(node),
      },
    };

    actions.secondaryActions.push(increaseRateAction);
  }

  if (node.collapsible) {
    const collapseRole = {
      text: intl('Role.Collapse'),
      collapseRoleAction: {
        data: [node.roleParent],
      },
      icon: 'collapse-role',
    };

    actions.primaryAction = collapseRole;
  }

  return actions;
}

export function calculateRoleActions(node) {
  const actions = {};

  actions.type = 'roleActions';
  actions.title = null;
  actions.msg = null;
  actions.primaryAction = {};
  actions.secondaryActions = [];

  const trafficIsWritable = node.caps?.rulesets.includes('write');
  const maxExpand = localStorage.getItem('role_expand') || 50;
  const groupLabels = MapPageStore.getMapType() === 'app' ? TrafficStore.getAppGroupsType() : ['app', 'env', 'loc'];

  const clearTrafficAction = !UserMixin.isSuperclusterUser() && {
    text: intl('Map.Traffic.ClearCounters'),
    clearTrafficAction: {
      title: intl('Map.Traffic.ClearCounters'),
      message: `${intl('Map.Traffic.ClearTrafficMessage')}:`,
      labels: node.labels,
      groupLabels,
      onClearRoleConfirm: _.partial(getLabelBasedClearTraffic, node, groupLabels),
      onClearAppConfirm: _.partial(getLabelBasedClearTraffic, node.cluster, groupLabels),
      onClearOrgConfirm: getOrgClearTraffic,
    },
  };

  if (node.workloadsNum > maxExpand) {
    actions.primaryAction = {
      text: intl('Workloads.View'),
      workloadListAction: {
        labels: _.map(node.labels, label => _.clone(label)),
      },
      icon: 'external-link',
    };
  } else {
    actions.primaryAction = {
      text: intl('Role.Expand'),
      expandRoleAction: {
        data: [node.href],
      },
      icon: 'expand-role',
    };
  }

  if (!SessionStore.isSuperclusterMember() && trafficIsWritable) {
    actions.secondaryActions.push(clearTrafficAction);
  }

  return actions;
}

export function calculateTrafficActions(link, trafficSelection, info, mapType) {
  // Rules should be viewable if either side has read caps
  const trafficRulesetsIsReadable =
    info.targets[0]?.caps?.rulesets.includes('read') || info.sources[0]?.caps?.rulesets.includes('read');
  const trafficWorkloadsIsWritable =
    info.targets[0]?.caps?.workloads.includes('write') || info.sources[0]?.caps?.workloads.includes('write');

  let trafficAllowed = false;
  let trafficDeny = false;

  if (trafficSelection.service) {
    const selectedService = _.find(info.services, service =>
      RenderUtils.isSelectedService(service, trafficSelection.service),
    );

    trafficAllowed =
      selectedService?.filteredPdCounts?.allowed || selectedService?.filteredPdCounts?.allowedAcrossBoundary;
    trafficDeny =
      selectedService?.filteredPdCounts?.blockedByBoundary ||
      selectedService?.filteredPdCounts?.potentiallyBlockedByBoundary ||
      selectedService?.filteredPdCounts?.allowedAcrossBoundary;
  }

  if (trafficSelection.pb && info.targets.length > 1) {
    const selectedPb = _.find(info.targets, pb => trafficSelection.pb.href === pb.href);

    // "view rule" if one of selected pb and ub is green
    trafficAllowed = selectedPb?.filteredPdCounts?.allowed || selectedPb?.filteredPdCounts?.allowedAcrossBoundary;
    trafficDeny =
      selectedPb?.filteredPdCounts?.blockedByBoundary ||
      selectedPb?.filteredPdCounts?.potentiallyBlockedByBoundary ||
      selectedPb?.filteredPdCounts?.allowedAcrossBoundary;
  }

  if (trafficSelection.ub && info.sources.length > 1) {
    const selectedUb = _.find(info.sources, ub => trafficSelection.ub.href === ub.href);

    trafficAllowed = selectedUb?.filteredPdCounts?.allowed || selectedUb?.filteredPdCounts?.allowedAcrossBoundary;
    trafficDeny =
      selectedUb?.filteredPdCounts?.blockedByBoundary ||
      selectedUb?.filteredPdCounts?.potentiallyBlockedByBoundary ||
      selectedUb?.filteredPdCounts?.allowedAcrossBoundary;
  }

  // no actionBar for the link from virtual server to workload
  // or if it's a yellow link denoting that info is still being loaded
  // or if it's user read only for all (& no extra role scopes) and it's not "view rule"
  // or if either the sources or targets is empty because something has been deleted
  const availableSources = _.filter(info.sources, source => source.name || !_.isEmpty(source.labels));
  const availableTargets = _.filter(info.targets, target => target.name || !_.isEmpty(target.labels));

  if (
    link.type === 'unknown' ||
    link.type === 'discovered' ||
    _.isEmpty(availableSources) ||
    _.isEmpty(availableTargets)
  ) {
    return;
  }

  const actions = {};

  actions.type = 'trafficActions';
  actions.secondaryActions = [];

  // source and target are either internet or should have all 4 labels to be able to add rule
  const sourceHasAllLabels = RenderUtils.isInternetIpList(link.source) || RenderUtils.hasAllLabels(link.source);
  const targetHasAllLabels = RenderUtils.isInternetIpList(link.target) || RenderUtils.hasAllLabels(link.target);
  const isInterapp = RenderUtils.isInterappTraffic(link);

  const addRuleAction = {
    text: intl('Rule.Add'),
    actionType: 'addRuleAction',
    icon: 'caret-right',
  };

  const viewRuleAction = {
    text: intl('Rule.View'),
    actionType: 'viewRuleAction',
    icon: 'caret-right',
  };

  const viewEnforcementBoundaryAction = {
    text: intl('Workloads.ViewEnforcementBoundary'),
    actionType: 'viewEnforcementBoundaryAction',
    icon: 'caret-right',
  };

  const targetGroupHref = link.target.clusterParent || link.target.href;
  const sourceGroupHref = link.source.clusterParent || link.source.href;

  const workloadEditAction = {
    text: intl('Workloads.Edit'),
    navAction: {
      to: 'groupWorkloads',
      params: {
        id: (!targetHasAllLabels && targetGroupHref) || (!sourceHasAllLabels && sourceGroupHref),
      },
    },
    icon: 'external-link',
  };

  let createVirtualServiceAction;

  // Do not allow Virtual Service Creation for ICMP or for Supercluster members
  // Only owners and admin can create virtual services
  if (
    !SessionStore.isSuperclusterMember() &&
    SessionStore.isVirtualServiceEditEnabled() &&
    trafficSelection.pb &&
    trafficSelection.service &&
    trafficSelection.service.protocol !== 1 &&
    trafficSelection.service.protocol !== 58 &&
    trafficSelection.pb.type === 'fqdn' &&
    RenderUtils.isHrefFqdn(trafficSelection.pb.href)
  ) {
    const fqdn = TrafficStore.getFqdn(trafficSelection.pb.href);
    const service = PolicyGeneratorUtils.buildService(trafficSelection.service);
    const ports = [
      {
        port: trafficSelection.service.port,
        proto: trafficSelection.service.protocol,
      },
    ];

    const creationValues = JSON.stringify({
      name: fqdn,
      service_addresses: [{fqdn}],
      [service ? 'service' : 'service_ports']: service ? service[0] : ports,
    });

    createVirtualServiceAction = {
      text: intl('VirtualServices.Add'),
      navAction: {
        to: 'virtualServices.create',
        query: {creationValues},
      },
    };
  }

  let navAction;

  if (mapType === 'app') {
    navAction = {
      to: 'appGroupRules',
      params: {id: link.target.appGroupParent || link.source.appGroupParent},
    };
  } else if (link.target.type === 'group') {
    navAction = {
      to: 'groupRules',
      params: {id: link.target.href},
    };
  } else {
    navAction = {
      to: 'groupRules',
      params: {id: link.target.clusterParent || link.source.clusterParent},
    };
  }

  const rulesetViewAction = {
    text: intl('Rulesets.View'),
    navAction,
    icon: 'external-link',
  };

  const isLinkWithVirtualServer =
    (link.source.type === 'virtualService' && link.source.subType && link.source.subType === 'virtual_server') ||
    (link.target.type === 'virtualService' && link.target.subType && link.target.subType === 'virtual_server');

  // start to put secondaryActions for enforcement Boundary
  if (
    MapPageStore.getPolicyVersion() === 'draft' &&
    trafficDeny &&
    trafficRulesetsIsReadable &&
    SessionStore.canUserViewEnforcementBoundaries()
  ) {
    actions.secondaryActions.push(viewEnforcementBoundaryAction);
  }

  // start to put primaryActions
  if (MapPageStore.getPolicyVersion() === 'reported') {
    actions.primaryAction = rulesetViewAction;
  } else if (isLinkWithVirtualServer) {
    actions.primaryAction = null;
  } else if (trafficAllowed && trafficRulesetsIsReadable) {
    actions.primaryAction = viewRuleAction;
  } else if ((sourceHasAllLabels && targetHasAllLabels) || isInterapp || mapType === 'app') {
    // if both source and target have all 4 labels (or one or the other is internet), or it is an interapp link
    // but do not allow add rule action for virtual servers --todo
    actions.primaryAction = addRuleAction;
  } else if ((!sourceHasAllLabels || !targetHasAllLabels) && trafficWorkloadsIsWritable) {
    // if link isn't interapp, and thus the source and target are in the same cluster
    // but don't have all 4 labels on either the source or target
    // then redirect them to Group detail page so they can edit labels
    actions.title = intl('Labels.Assign');
    actions.msg = intl('Map.AssignLabelsTraffic');
    actions.primaryAction = workloadEditAction;
  } else {
    actions.primaryAction = {};
  }

  if (SessionStore.isVirtualServiceEditEnabled() && createVirtualServiceAction) {
    actions.secondaryActions.push(createVirtualServiceAction);
  }

  return actions;
}

export function calculateAppGroupActions(focusedAppGroup, connectedAppGroup, appGroup, mapRoute, filters, type) {
  // App Group action bar is only available when focused app group is the providing
  // or no connected app group is opened
  // and not UserReadOnly
  const ruleWritingNotAvailable = type === 'appGroupTraffic';
  const draftView = MapPageStore.getPolicyVersion() === 'draft';
  const actions = {};
  const trafficIsWritable = connectedAppGroup ? false : focusedAppGroup.caps?.rulesets.includes('write');
  const groupWorkloadsIsWritable = connectedAppGroup ? false : focusedAppGroup.caps?.workloads.includes('write');
  const groupHasManagedWorkload = !focusedAppGroup.nodes.every(node => node.unmanaged && node.type === 'workload');

  actions.type = 'appGroupActions';
  actions.title = null;
  actions.msg = null;
  actions.secondaryActions = [];

  if (!ruleWritingNotAvailable && draftView) {
    actions.ringFencingRulesets = getRulesetWithLabels(_.map(focusedAppGroup.labels, 'href'));
    actions.ringFencingRules = TrafficStore.getRingFenceRules(appGroup.href);
  }

  // Expanding or collapsing roles in the cluster
  const maxGroupExpand = localStorage.getItem('group_expand') || 50;
  const maxRoleExpand = localStorage.getItem('role_expand') || 50;

  let tooManyWorkloads = appGroup ? appGroup.workloads > maxGroupExpand : false;
  const appGroupRulesetsIsReadable = appGroup?.caps?.rulesets?.includes('read');

  const rolesToExpand = [];

  const appGroupNodes = connectedAppGroup ? connectedAppGroup.nodes : focusedAppGroup.nodes;

  const rolesToCollapse = appGroupNodes.reduce((collapse, node) => {
    if (node.collapsible && !collapse.includes(node.roleParent)) {
      collapse.push(node.roleParent);
    }

    if (node.type === 'role' && node.workloadsNum <= maxRoleExpand) {
      rolesToExpand.push(node);
    }

    tooManyWorkloads |= node.workloadsNum > maxRoleExpand;

    return collapse;
  }, []);

  const expandRolesAction = {
    text: intl('Role.GroupExpand'),
    expandRoleAction: {
      data: _.map(rolesToExpand, 'href'),
    },
  };

  const collapseRolesAction = {
    text: intl('Role.GroupCollapse'),
    collapseRoleAction: {
      data: rolesToCollapse,
    },
  };

  // Increase rate action
  const increaseRateAction = {
    text: intl('Common.IncreaseTrafficUpdateRate'),
    modalAction: {
      title: intl('Common.IncreaseTrafficUpdateRate'),
      message: intl('Common.IncreaseTrafficUpdateRateMessage', {minutes: 10}),
      onConfirm: increaseVenUpdateRate(focusedAppGroup),
    },
  };

  const groupLabels = TrafficStore.getAppGroupsType();

  // Clear Traffic Action
  const clearTrafficAction = {
    text: intl('Map.Traffic.ClearCounters'),
    clearTrafficAction: {
      title: intl('Map.Traffic.ClearCounters'),
      message: `${intl('Map.Traffic.ClearTrafficMessage')}:`,
      labels: focusedAppGroup.labels,
      groupLabels,
      onClearAppConfirm: _.partial(getLabelBasedClearTraffic, focusedAppGroup, groupLabels),
      onClearOrgConfirm: getOrgClearTraffic,
    },
  };

  let loadConnectedGroupAction;
  let illuminationMapAction;

  if (appGroup) {
    const loadConnectedGroups = JSON.parse(sessionStorage.getItem('loadConnectedGroups')) || [];
    const connectedGroupKey = [mapRoute.previd, mapRoute.id].join(',');

    if (mapRoute.previd && mapRoute.prevtype === 'focused' && !loadConnectedGroups.includes(connectedGroupKey)) {
      loadConnectedGroups.push(connectedGroupKey);
      loadConnectedGroupAction = {
        text: intl('Map.LoadConnectedGroupData'),
        loadConnectedGroupAction: JSON.stringify(loadConnectedGroups),
      };
    } else if (mapRoute.previd && mapRoute.prevtype === 'focused') {
      loadConnectedGroups.splice(loadConnectedGroups.indexOf(connectedGroupKey), 1);
      loadConnectedGroupAction = {
        text: intl('Map.CollapseConnectedGroupData'),
        loadConnectedGroupAction: JSON.stringify(loadConnectedGroups),
      };
    }
  }

  let appGroupTypeAction;

  const addRuleAction = {
    text: intl('Rule.Add'),
    actionType: 'addRuleAction',
    icon: 'caret-right',
  };

  const viewRuleAction = {
    text: intl('Rule.View'),
    actionType: 'viewRuleAction',
    icon: 'caret-right',
  };

  const viewEnforcementBoundaryAction = {
    text: intl('Workloads.ViewEnforcementBoundary'),
    actionType: 'viewEnforcementBoundaryAction',
    icon: 'caret-right',
  };

  if (SessionStore.areVulnerabilitiesEnabled()) {
    appGroupTypeAction = {
      text: MapPageStore.getAppMapVersion() === 'vulnerability' ? intl('Map.Policy') : intl('Map.Vulnerability'),
    };
  }

  if (ruleWritingNotAvailable || !draftView) {
    if (illuminationMapAction && SessionStore.isIlluminationMapEnabled()) {
      actions.primaryAction = illuminationMapAction;
    } else {
      // There's nothing to display
      actions.primaryAction = {};
    }
  } else if (draftView) {
    if (actions.ringFencingRules && actions.ringFencingRules.allow.length && appGroupRulesetsIsReadable) {
      // if there are rules already, show them
      actions.primaryAction = viewRuleAction;
    } else if (actions.ringFencingRules && actions.ringFencingRules.deny.length) {
      if (!UserMixin.isUserReadOnlyAll()) {
        actions.primaryAction = addRuleAction;
      }

      if (SessionStore.canUserViewEnforcementBoundaries()) {
        actions.secondaryActions.push(viewEnforcementBoundaryAction);
      }
    } else {
      // if not, only allow adding rules if user isn't read only for all
      actions.primaryAction = addRuleAction;
    }
  }

  if (appGroupTypeAction) {
    actions.secondaryActions.push(appGroupTypeAction);
  }

  if (
    illuminationMapAction &&
    SessionStore.isIlluminationMapEnabled() &&
    actions.primaryAction?.text !== intl('Common.IlluminationMap')
  ) {
    actions.secondaryActions.push(illuminationMapAction);
  }

  if (rolesToExpand.length && !tooManyWorkloads && !rolesToCollapse.length) {
    // if there are roles to expand
    actions.secondaryActions.push(expandRolesAction);
  }

  if (rolesToCollapse.length) {
    // if there are roles to collapse
    actions.secondaryActions.push(collapseRolesAction);
  }

  if (loadConnectedGroupAction && connectedAppGroup.caps && connectedAppGroup.caps.rulesets.includes('read')) {
    actions.secondaryActions.push(loadConnectedGroupAction);
  }

  actions.tertiaryActions = [];

  // don't clear traffic for clusters with no labels AND unexpanded roles
  if (
    (rolesToCollapse.length || !_.isEmpty(focusedAppGroup.labels)) &&
    !SessionStore.isSuperclusterMember() &&
    trafficIsWritable
  ) {
    actions.tertiaryActions.push(clearTrafficAction);
  }

  if (
    !UserMixin.isSuperclusterUser() &&
    groupWorkloadsIsWritable &&
    groupHasManagedWorkload &&
    rolesToCollapse.length
  ) {
    actions.tertiaryActions.push(increaseRateAction);
  }

  // if too few items (tertiaryActions) in drop down, push tertiaryActions into secondaryAction and not display "more"
  // case: 0 secondaryAction, 1 tertiaryAction --> remove drop down
  if (actions.secondaryActions.length === 0 || actions.tertiaryActions.length === 1) {
    actions.secondaryActions = actions.secondaryActions.concat(actions.tertiaryActions);
    actions.tertiaryActions = [];
  }

  return actions;
}

export function calculateGroupActions(group, appGroup, info) {
  const actions = {};
  const hasAllScopes = RenderUtils.hasAllScopes(group);
  const hasOneOrTwoLabels = RenderUtils.hasOneOrTwoLabels(group);
  const trafficIsWritable = info.caps?.rulesets.includes('write');
  const groupWorkloadsIsWritable = info.caps?.workloads.includes('write');
  const groupHasManagedWorkload = !group.nodes.every(node => node.unmanaged && node.type === 'workload');

  actions.type = 'groupActions';
  actions.primaryAction = {};
  actions.secondaryActions = [];
  actions.tertiaryActions = [];

  const addRuleAction = {
    text: intl('Rule.Add'),
    actionType: 'addRuleAction',
    icon: 'caret-right',
  };

  const viewRuleAction = {
    text: intl('Rule.View'),
    actionType: 'viewRuleAction',
    icon: 'caret-right',
  };

  const viewEnforcementBoundaryAction = {
    text: intl('Workloads.ViewEnforcementBoundary'),
    actionType: 'viewEnforcementBoundaryAction',
    icon: 'caret-right',
  };

  const workloadEditAction = {
    text: intl('Workloads.Edit'),
    navAction: {
      to: 'groupWorkloads',
      params: {id: group.href},
    },
    icon: 'external-link',
  };

  const rulesetViewAction = {
    text: intl('Rulesets.View'),
    navAction: {
      to: 'groupRules',
      params: {id: group.href},
    },
    icon: 'external-link',
  };

  let appGroupAction;
  let vulnerabilityAction;
  let policyGeneratorAction;

  if (appGroup) {
    appGroupAction = {
      text: intl('Common.ApplicationMap'),
      tid: 'app-group-map',
      appGroup,
      navAction: {
        newMap: true,
        to: 'appMapLevel',
        params: {
          type: 'focused',
          id: appGroup.href,
        },
      },
      icon: 'external-link',
    };

    if (SessionStore.areVulnerabilitiesEnabled()) {
      vulnerabilityAction = {
        ...appGroupAction,
        text: intl('Map.Vulnerability'),
      };
    }

    policyGeneratorAction = {
      text: intl('PolicyGenerator.StartPolicyGenerator'),
      tid: 'policygenerator',
      appGroup,
      navAction: {
        to: 'policygenerator',
      },
      icon: 'external-link',
    };
  }

  const groupLabels = ['app', 'env', 'loc'];
  // Clear Traffic Action
  const clearTrafficAction = {
    text: intl('Map.Traffic.ClearCounters'),
    clearTrafficAction: {
      title: intl('Map.Traffic.ClearCounters'),
      message: `${intl('Map.Traffic.ClearTrafficMessage')}:`,
      labels: info.labels,
      groupLabels,
      onClearAppConfirm: _.partial(getLabelBasedClearTraffic, info, groupLabels),
      onClearOrgConfirm: getOrgClearTraffic,
    },
  };

  // Increase rate action
  const increaseRateAction = {
    text: intl('Common.IncreaseTrafficUpdateRate'),
    disabled: !info.caps, //When Caps is read, disable the action.
    modalAction: {
      title: intl('Common.IncreaseTrafficUpdateRate'),
      message: intl('Common.IncreaseTrafficUpdateRateMessage', {minutes: 10}),
      onConfirm: increaseVenUpdateRate(group),
    },
  };

  // Expanding or collapsing roles in the cluster
  const maxGroupExpand = localStorage.getItem('group_expand') || 50;
  const maxRoleExpand = localStorage.getItem('role_expand') || 50;

  let tooManyWorkloads = group.workloadsNum > maxGroupExpand;

  const rolesToExpand = [];
  const rolesToCollapse = group.nodes.reduce((collapse, node) => {
    if (node.collapsible && !collapse.includes(node.roleParent)) {
      collapse.push(node.roleParent);
    }

    if (node.type === 'role' && node.workloadsNum <= maxRoleExpand) {
      rolesToExpand.push(node);
    }

    tooManyWorkloads |= node.workloadsNum > maxRoleExpand;

    return collapse;
  }, []);

  const expandRolesAction = {
    text: intl('Role.GroupExpand'),
    expandRoleAction: {
      data: _.map(rolesToExpand, 'href'),
    },
  };

  const collapseRolesAction = {
    text: intl('Role.GroupCollapse'),
    collapseRoleAction: {
      data: rolesToCollapse,
    },
  };

  // In both draft view and reported view, if an app group doesn't have all labels, show edit workload
  if (!hasAllScopes && info.workloadsNum && groupWorkloadsIsWritable) {
    // In draft view, show assign label
    if (MapPageStore.getPolicyVersion() === 'draft') {
      actions.title = intl('Labels.Assign');
      actions.msg = intl('Map.AssignLabelsCluster');
    }

    actions.primaryAction = workloadEditAction;
  }

  // For Draft version Primary action is view or add rule
  if (MapPageStore.getPolicyVersion() === 'draft') {
    // Get ringFencingRulesets and ringFencingRules for an app group that has one or two labels or all scopes
    actions.ringFencingRulesets = getRulesetWithLabels(_.map(group.labels, 'href'));
    actions.ringFencingRules = TrafficStore.getRingFenceRules(group.href);

    // If an app group has all scopes
    if (hasAllScopes) {
      if (!(actions.ringFencingRules && actions.ringFencingRules.allow.length)) {
        // add rule
        actions.primaryAction = addRuleAction;
      } else if (actions.ringFencingRules && actions.ringFencingRules.allow.length) {
        // view rule
        actions.primaryAction = viewRuleAction;
      } else if (actions.ringFencingRules && actions.ringFencingRules.deny.length) {
        if (!UserMixin.isUserReadOnlyAll()) {
          actions.primaryAction = addRuleAction;
        }

        if (SessionStore.canUserViewEnforcementBoundaries()) {
          actions.secondaryActions.push(viewEnforcementBoundaryAction);
        }
      } else if (actions.ringFencingRules && actions.ringFencingRulesets.length) {
        // If all else fails send them to the group rulesets
        actions.primaryAction = rulesetViewAction;
      } else {
        actions.primaryAction = appGroupAction || rulesetViewAction;
      }
    } else if (hasOneOrTwoLabels) {
      // If an app group has one or two labels
      if (actions.ringFencingRules && actions.ringFencingRules.deny.length) {
        if (!UserMixin.isUserReadOnlyAll()) {
          actions.primaryAction = addRuleAction;
        }

        if (SessionStore.canUserViewEnforcementBoundaries()) {
          actions.secondaryActions.push(viewEnforcementBoundaryAction);
        }
      }
    }
  } else if (appGroupAction) {
    // If the app groups are configured direct them there
    actions.primaryAction = appGroupAction;
  } else {
    // If all else fails send them to the group rulesets
    actions.primaryAction = rulesetViewAction;
  }

  // If not the primary action add to the secondary ones
  if (appGroupAction && actions.primaryAction?.text !== intl('Common.ApplicationMap')) {
    actions.secondaryActions.push(appGroupAction);
  }

  if (vulnerabilityAction) {
    actions.secondaryActions.push(vulnerabilityAction);
  }

  if (actions.primaryAction?.text !== intl('Rulesets.View')) {
    actions.secondaryActions.push(rulesetViewAction);
  }

  //Calculate secondary/tertiary actions for non-read only users

  if (policyGeneratorAction) {
    actions.secondaryActions.push(policyGeneratorAction);
  }

  // Workload Manager Actions

  // put in these actions only if the cluster isn't empty
  // only clear traffic for clusters with labels and expanded roles
  // currently avenger doesn't support clearing role link traffic
  const collapsedRoles = _.filter(group.nodes, node => node.type === 'role');

  // don't clear traffic for clusters with no labels AND unexpanded roles
  if (
    (!collapsedRoles.length || !_.isEmpty(group.labels)) &&
    !SessionStore.isSuperclusterMember() &&
    trafficIsWritable
  ) {
    actions.tertiaryActions.push(clearTrafficAction);
  }

  if (
    !UserMixin.isSuperclusterUser() &&
    groupWorkloadsIsWritable &&
    groupHasManagedWorkload &&
    (rolesToCollapse.length || MapPageStore.getMapLevel() === 'full')
  ) {
    actions.tertiaryActions.push(increaseRateAction);
  }

  if (rolesToExpand.length && !tooManyWorkloads && !rolesToCollapse.length) {
    // if there are roles to expand
    actions.tertiaryActions.push(expandRolesAction);
  }

  if (rolesToCollapse.length) {
    // if there are roles to collapse
    actions.tertiaryActions.push(collapseRolesAction);
  }

  // if too few items (tertiaryActions) in drop down, push tertiaryActions into secondaryAction and not display "more"
  // case: 0 secondaryAction, 1 tertiaryAction --> remove drop down
  if (actions.secondaryActions.length === 0 || actions.tertiaryActions.length === 1) {
    actions.secondaryActions = actions.secondaryActions.concat(actions.tertiaryActions);
    actions.tertiaryActions = [];
  }

  return actions;
}

export function calculateLocationActions(location) {
  const actions = {};

  actions.type = 'locationActions';
  actions.primaryAction = {};
  actions.secondaryActions = [];

  if (location.href === 'discovered' || location.href === 'no_location') {
    return null;
  }

  // location ring fencing rule
  actions.ringFencingRulesets = getRulesetWithLabels([location.href]);
  actions.ringFencingRules = TrafficStore.getRingFenceRules(getId(location.href));

  const addRuleAction = {
    text: intl('Rule.Add'),
    actionType: 'addRuleAction',
    icon: 'caret-right',
  };

  const viewRuleAction = {
    text: intl('Rule.View'),
    actionType: 'viewRuleAction',
    icon: 'caret-right',
  };

  const viewEnforcementBoundaryAction = {
    text: intl('Workloads.ViewEnforcementBoundary'),
    actionType: 'viewEnforcementBoundaryAction',
    icon: 'caret-right',
  };

  if (MapPageStore.getPolicyVersion() === 'reported') {
    const labels = [
      {
        href: location.href,
        key: 'loc',
        value: location.name,
      },
    ];

    actions.primaryAction = {
      text: intl('Rulesets.View'),
      rulesetListAction: {
        labels,
      },
      icon: 'external-link',
    };
  } else if (actions.ringFencingRules && actions.ringFencingRules.allow.length) {
    // if there are rules already, show them
    actions.primaryAction = viewRuleAction;
  } else if (actions.ringFencingRules && actions.ringFencingRules.deny.length) {
    if (!UserMixin.isUserReadOnlyAll()) {
      actions.primaryAction = addRuleAction;
    }

    if (SessionStore.canUserViewEnforcementBoundaries()) {
      actions.secondaryActions.push(viewEnforcementBoundaryAction);
    }
  } else if (!UserMixin.isUserReadOnlyAll()) {
    // if not, only allow adding rules if user isn't read only for all
    actions.primaryAction = addRuleAction;
  } else if (UserMixin.isUserReadOnlyAll()) {
    // if it's readonly for all & no other role scopes, don't show action bar
    return null;
  }

  if (MapPageStore.getMapRoute().type === 'location') {
    const findGroup = {
      text: intl('Map.SearchGroups'),
      actionType: 'findGroupAction',
    };

    actions.secondaryActions.push(findGroup);
  }

  const clearTrafficAction = !UserMixin.isSuperclusterUser() && {
    text: intl('Map.Traffic.ClearCounters'),
    clearTrafficAction: {
      title: intl('Map.Traffic.ClearCounters'),
      message: `${intl('Map.Traffic.ClearTrafficMessage')}:`,
      labels: location.labels,
      onClearLocationConfirm: _.partial(
        getLabelBasedClearTraffic,
        {...location, labels: {loc: {href: location.href}}},
        ['loc'],
      ),
      onClearOrgConfirm: getOrgClearTraffic,
    },
  };

  actions.secondaryActions.push(clearTrafficAction);

  return actions;
}

function reloadTrafficForEachClass(query) {
  const transmissionFilters = TrafficFilterStore.getTransmissionFilters();
  const trafficClasses = ['unicast', 'broadcast', 'multicast', 'core_service'];

  transmissionFilters.forEach((transmissionFilter, i) => {
    if (transmissionFilter) {
      GraphDataUtils.getTraffic({...query, traffic_class: trafficClasses[i]}, {type: 'rebuild'});
    }
  });
}

function reloadWorkloadLevelTraffic() {
  GraphDataUtils.loadTraffic('rebuild');

  if (MapPageStore.getMapLevel() === 'workload') {
    const query = {
      labels: JSON.stringify([
        getSessionUri(getInstanceUri('labels'), {
          label_id: MapPageStore.getMapRoute().previd,
        }),
      ]),
      clusters: true,
    };

    reloadTrafficForEachClass(query);
  }
}

export async function getLabelBasedClearTraffic(nodeToClear, groupLabels) {
  const {labels} = nodeToClear;
  const labelSet = [...groupLabels];
  let {href} = nodeToClear;

  // If this is a role, finde the group href
  if (href.includes('-')) {
    href = href.split('-')[0];

    labelSet.push('role');
  }

  const labelsForClearTraffic = labelSet.reduce(
    (result, type) => {
      if (labels[type]?.href) {
        result.labels.push(labels[type]?.href);
      } else {
        result.noLabels.push(type);
      }

      return result;
    },
    {labels: [], noLabels: []},
  );

  await RestApiUtils.agentTraffic.delete({
    labels: JSON.stringify(labelsForClearTraffic.labels),
    ...(labelsForClearTraffic.noLabels.length ? {no_labels: JSON.stringify(labelsForClearTraffic.noLabels)} : {}),
  });

  if (MapPageStore.getMapLevel() === 'full') {
    const clusterQuery = {cluster_keys: JSON.stringify([href.includes('workload') ? nodeToClear.clusterParent : href])};

    reloadTrafficForEachClass(clusterQuery);
  } else {
    reloadWorkloadLevelTraffic();
  }

  actionCreators.updateTrafficForScopes([labels]);
  actionCreators.resetLayout({href});
}

export async function getOrgClearTraffic() {
  const labelsForClearTraffic = [];

  await RestApiUtils.agentTraffic.delete({
    labels: JSON.stringify(labelsForClearTraffic),
  });

  GraphDataUtils.loadTraffic('rebuild');
  actionCreators.updateForAll();
}

export function getRulesetWithLabels(labels) {
  const labelSet = new Set(labels);

  return _.transform(
    RulesetStore.getAll(),
    (result, ruleset) => {
      // if the ruleset is about to be deleted, or disabled, don't include it in the list
      if (ruleset.update_type === 'delete' || !ruleset.enabled || !ruleset.caps.includes('write')) {
        return result;
      }

      const rulesetWithCompleteLabelScope = ruleset.scopes.some(scope =>
        // Look for any scope where every item is contained in the labels even if the scope is wider
        scope.every(item => item && item.label && labelSet.has(item.label.href)),
      );

      // if ring fencing ruleset exist, calculate ring fencing rule.
      if (rulesetWithCompleteLabelScope) {
        result.push(ruleset);
      }
    },
    [],
  );
}

export function getRulesetWithExternalReference(name) {
  return _.transform(
    RulesetStore.getAll(),
    (result, ruleset) => {
      // if the ruleset is about to be deleted, or disabled, don't include it in the list
      if (ruleset.update_type === 'delete' || !ruleset.enabled || !ruleset.caps.includes('write')) {
        return result;
      }

      // if ring fencing ruleset exist, calculate ring fencing rule.
      if (ruleset.external_data_reference === name) {
        result.push(ruleset);
      }
    },
    [],
  );
}

export function increaseVenUpdateRate(cluster) {
  const onFail = error => {
    let title;
    let message;

    if (error.status === 429) {
      // If user has clicked update within the last 10 minutes
      title = intl('Map.RequestLimit');
      message = intl('Map.RequestLimitMessage', {minutes: 10});
    } else if (error.status === 403) {
      // If there are too many workloads in the app container
      title = intl('Map.Workloads.Limit');
      message = intl('Map.Workloads.LimitMessage');
    } else if (error.status === 406) {
      // 406 will happen in RBAC cases where the virtual services are bound to workloads
      // outside of scope. Showing the alert is just annoying and raises issues.
      return;
    }

    actionCreators.openDialog(<AlertDialog title={title} message={message} />);
  };
  // the payload prop names should match backend schema
  const typeMap = {
    virtualService: 'virtual_services',
    virtualServer: 'virtual_servers',
    containerWorkload: 'container_workloads',
    workload: 'workloads',
  };
  const data = {};

  if (cluster.type === 'workload') {
    if (cluster.subType === 'container') {
      data.container_workloads = [{href: cluster.href}];
    } else {
      data.workloads = [{href: cluster.href}];
    }

    return () => {
      RestApiUtils.workloads.setFlowReportingFrequency(data).catch(onFail);
    };
  }

  cluster.nodes.forEach(node => {
    if (node.type === 'role' || node.subType === 'virtual_server') {
      return;
    }

    let type = typeMap[node.type];

    if (node.subType === 'container') {
      type = 'container_workloads';
    }

    data[type] ||= [];

    data[type].push({href: node.href});
  });

  return () => {
    RestApiUtils.workloads.setFlowReportingFrequency(data).catch(onFail);
  };
}

export function assignRoleAction(node) {
  const actions = {};

  actions.currentRole = node.labels.role && node.labels.role.value;
  actions.type = 'assignRole';

  return actions;
}

export function getGroupActionValues(groups, totalNumOfChars, individualLabelList, type) {
  const singleValues = {};
  const uniqueGroupNames = {};
  let truncatedGroupName;
  let diff;

  if (!_.isEmpty(groups)) {
    (Array.isArray(groups) ? groups : Object.values(groups)).forEach(group => {
      if (group.name) {
        group.name = RenderUtils.trimLabels(group.name).join(' | ');

        truncatedGroupName = RenderUtils.truncateAppGroupName(group.name, totalNumOfChars, individualLabelList);

        if (!uniqueGroupNames.hasOwnProperty(truncatedGroupName)) {
          uniqueGroupNames[truncatedGroupName] = {};
        }

        if (group && group.name) {
          uniqueGroupNames[truncatedGroupName][group.name] = group.name.length;
        }
      }
    });

    (Array.isArray(groups) ? groups : Object.values(groups)).forEach(group => {
      if (group.name) {
        const vulnerability = TrafficStore.getNodeVulnerabilityByHref(group.href);

        if (MapPageStore.getAppMapVersion() === 'vulnerability' && vulnerability) {
          group.vulnerability = vulnerability.aggregatedValues;
          group.policyState = group.mode ? RenderUtils.getPolicyState(group, 'group') : 'enforced';
        }

        group.name = RenderUtils.trimLabels(group.name).join(' | ');
        truncatedGroupName = RenderUtils.truncateAppGroupName(group.name, totalNumOfChars, individualLabelList);
        group.searchName = group.name;
        group.truncatedName = group.truncatedGroupName;

        if (!singleValues.hasOwnProperty(truncatedGroupName)) {
          group.searchName = (type || '') + group.searchName;
          group.truncatedName = (type || '') + truncatedGroupName;
          singleValues[(type || '') + truncatedGroupName] = group;
        } else if (Object.keys(uniqueGroupNames[truncatedGroupName])[0] !== group.name) {
          diff = RenderUtils.composeTruncatedStringDifference(
            group.name,
            Object.keys(uniqueGroupNames[truncatedGroupName])[0],
          );
          group.searchName = (type || '') + group.searchName;
          group.truncatedName = (type || '') + diff;
          singleValues[(type || '') + diff] = group;
        }
      }
    });

    return _.transform(singleValues, (result, value) => (result[value.searchName] = value));
  }
}

export function findGroupAction(info) {
  const groups = info.locationGroups;
  const singleValues = getGroupActionValues(groups, 35, [15, 10, 10]);

  return {type: 'findGroup', singleValues};
}

export function findConnectedGroupAction(info) {
  const groups = info.connectedClusters;
  const singleValues = getGroupActionValues(groups, 35, [15, 10, 10]);

  return {type: 'findConnectedGroup', singleValues};
}

export function detailAction(info, type) {
  return {type, info};
}

export function findConnectedAppGroupAction(mapRoute, type) {
  const focusedData = GraphStore.getLocationNodes();
  const singleValues = getGroupActionValues(
    focusedData && focusedData[type]?.connectedAppGroups.filter(appGroup => !appGroup.isHidden),
    50,
    [30, 10, 10],
  );

  return {
    type: type === 'consuming' ? 'findConsumingAppGroup' : 'findProvidingAppGroup',
    singleValues,
  };
}

export function setPolicyStateAction(info, cluster) {
  const actions = {};

  actions.type = 'setPolicyState';
  actions.currentState = info.policyState;

  if (cluster) {
    actions.labels = _.map(cluster.labels, 'href');
    actions.xxxlabels = RenderUtils.constructxxxLabels(cluster.labels, true);
    actions.nodesNum = cluster.workloadsNum;

    const workloads = _.transform(
      WorkloadStore.getAll(),
      (result, node) => {
        if (RuleGraphUtils.isNodeInScopes(node, [actions.labels])) {
          result.push({
            type: 'workload',
            href: node.href,
          });
        }
      },
      [],
    );
    const virtualServers = _.transform(
      VirtualServerStore.getAll(),
      (result, node) => {
        if (RuleGraphUtils.isNodeInScopes(node, [actions.labels])) {
          result.push({
            type: 'virtualServer',
            href: node.href,
          });
        }
      },
      [],
    );

    actions.nodes = _.union(workloads, virtualServers);
  }

  return actions;
}

export function addRingFencingRuleAction(selection, groupTypes) {
  return {
    type: 'addRule',
    ruleType: 'ringfencing',
    labels:
      selection.type === 'location'
        ? {loc: {href: selection.href, value: selection.name, key: 'loc'}}
        : selection.labels,
    name: selection.name || selection.data.name,
    groupTypes,
  };
}

export function addRuleAction(traffic, trafficSelection, groupTypes) {
  return {
    type: 'addRule',
    traffic,
    ruleType: trafficSelection.service,
    trafficSelection,
    groupTypes,
  };
}

export function viewRingFencingRuleAction(selection, commandPanel, type) {
  const actions = {};
  const allowRules = _.uniq(commandPanel.ringFencingRules?.allow || []);
  const denyRules = _.uniq(commandPanel.ringFencingRules?.deny || []);

  if (_.isEmpty(allowRules) && _.isEmpty(denyRules)) {
    return;
  }

  let rules;

  if (type === 'rule') {
    rules = [...allowRules];
  } else if (type === 'enforcement') {
    rules = [...denyRules];
  }

  actions.rules = rules;

  actions.type = 'viewRule';

  return actions;
}

export function viewRuleAction(link, trafficSelection, type) {
  const actions = {};

  if (!_.isEmpty(trafficSelection)) {
    const {protocol, processName, serviceName, connectionClass} = trafficSelection.service;

    const connectionKey = [
      ServiceUtils.getPort(trafficSelection.service),
      protocol,
      processName || 'unknown',
      serviceName || 'unknown',
      connectionClass,
    ].join(',');

    const ispbInternet = ['fqdn', 'internet', 'ipList'].includes(trafficSelection.pb?.type);
    const isubInternet = ['fqdn', 'internet', 'ipList'].includes(trafficSelection.ub?.type);

    let connection;
    let rules;

    const connections = Object.values(link.connections).reduce(
      (result, connection) => {
        if (connection.denyRules.length) {
          connection.denyRules.forEach(denyRule => {
            result.denyRules.add(denyRule);
          });
        }

        if (connection.rules.length) {
          connection.rules.forEach(rule => {
            result.rules.add(rule);
          });
        }

        return result;
      },
      {rules: new Set(), denyRules: new Set()},
    );

    // Viewing rule or single enforcement
    if (ispbInternet || isubInternet) {
      const internetNode1 = ispbInternet ? trafficSelection.pb : trafficSelection.ub;
      const internets = ispbInternet ? link.target.internets : link.source.internets;
      const href = internetNode1.href;

      if (internetNode1.type === 'fqdn') {
        let linkHrefs = internets.filter(internet => String(internet.href) === String(href));

        linkHrefs = linkHrefs.map(linkHref => linkHref.linkHref);

        if (linkHrefs.length) {
          const matchingInternetLinks = link.internetLinks.filter(internetLink =>
            linkHrefs.includes(internetLink.href),
          );

          matchingInternetLinks.forEach(InternetLinkHref => {
            if (InternetLinkHref.connections[connectionKey]) {
              connection = InternetLinkHref.connections[connectionKey];
            }
          });
        }
      } else if (internetNode1.type === 'internet') {
        const linkHref = ispbInternet ? `${link.source.href},${href}` : `${href},${link.target.href}`;

        connection = link.internetLinks.find(internetLink => internetLink.href === linkHref).connections[connectionKey];
      } else if (internetNode1.type === 'ipList') {
        // Find all internetLinks that includes href
        const internetLinks = link.internetLinks.filter(internetLink => internetLink.href.includes(href));
        // Find the first internetlink that includes the connectionKey
        const internetLinkWithConnectionKey = internetLinks.find(internetLink =>
          Object.keys(internetLink.connections)?.includes(connectionKey),
        );

        connection = internetLinkWithConnectionKey?.connections[connectionKey]?.ipListRules?.[href] || [];
      }
    } else {
      connection = link.connections[connectionKey];
    }

    switch (type) {
      // if user is viewing rule, get rid of enforcement boundary for rules & denyRules
      case 'rules':
        // if it doesn't have rules anymore, return null
        if (_.isEmpty(connections?.rules)) {
          return;
        }

        rules = [...connections.rules];
        break;
      case 'enforcement':
        // if it doesn't have enforcement boundary anymore, return null
        if (_.isEmpty(connections?.denyRules)) {
          return;
        }

        rules = [...new Set(connection.denyRules)];
        break;
      case 'enforcements':
        // if it doesn't have enforcement boundaries anymore, return null
        if (_.isEmpty(connections?.denyRules)) {
          return;
        }

        // get aggregated enforcement boundaries
        rules = [...connections.denyRules];
        break;
      case 'rule':
      default:
        // if it doesn't have rule anymore, return null
        if (_.isEmpty(connection?.rules)) {
          return;
        }

        rules = [...new Set(connection.rules)];
        break;
    }

    actions.type = 'viewRule';
    // note: only for virtualServerLink
    actions.isVirtualServerLink = RenderUtils.isToVirtualServerTraffic(link);
    actions.rules = rules;
    actions.readOnly = UserMixin.isUserReadOnly();

    return actions;
  }
}

export function getNodeHref(node) {
  if (node.type === 'workload' || node.type === 'virtualServer') {
    return node.href;
  }

  if (node.type === 'ipList') {
    return 'ip_list';
  }

  if (node.type === 'internet') {
    return 'internet';
  }
}

export function getTrafficNodeAvengerReady(node, virtualServerAddress) {
  const obj = {};
  let type = node.type === 'internet' ? 'all' : node.type;
  let key;
  let value;

  if (node.type === 'workload') {
    type = 'traffic_workload';
    key = 'href';
    value = node.href;
  } else if (node.type === 'virtualService') {
    type = 'virtual_service';
    key = 'href';
    value = node.href;
  } else if (node.type === 'virtualServer') {
    type = 'virtual_server';
    key = 'address';
    value = virtualServerAddress;
  } else if (node.type === 'internet' || node.type === 'fqdn') {
    type = 'all';
    key = 'label';
    value = 'internet';
  } else if (node.type === 'ipList') {
    type = 'ip_list';
    key = 'href';
    value = _.map(node.internets, internet => internet.href);
  }

  obj[type] = {};
  obj[type][key] = value;

  return obj;
}

export function getRuleActionType(type) {
  switch (type) {
    case 'viewRulesAction':
      return 'rules';
    case 'viewEnforcementBoundaryAction':
      return 'enforcement';
    case 'viewEnforcementBoundariesAction':
      return 'enforcements';
    case 'viewRuleAction':
    default:
      return 'rule';
  }
}

export function getRingFencingRuleActionType(type) {
  switch (type) {
    case 'viewEnforcementBoundaryAction':
    case 'viewEnforcementBoundariesAction':
      return 'enforcement';
    case 'viewRuleAction':
    case 'viewRulesAction':
    default:
      return 'rule';
  }
}

export default {
  calculateWorkloadActions,
  calculateRoleActions,
  calculateTrafficActions,
  calculateAppGroupActions,
  calculateGroupActions,
  calculateLocationActions,
  assignRoleAction,
  setPolicyStateAction,
  addRingFencingRuleAction,
  addRuleAction,
  viewRingFencingRuleAction,
  viewRuleAction,
  getNodeHref,
  getGroupActionValues,
  getRuleActionType,
  getRingFencingRuleActionType,
  findGroupAction,
  findConnectedGroupAction,
  detailAction,
  findConnectedAppGroupAction,
};
