/**
 * Copyright 2015 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import intl from '@illumio-shared/utils/intl';
import {State} from 'react-router';
import {Badge, Grid, Banner} from '.';
import {RuleNote} from './RuleWriting';
import React, {PropTypes} from 'react';
import {GridDataUtils, ProviderConsumerUtils, RulesetUtils} from '../utils';

export default React.createClass({
  propTypes: {
    rules: PropTypes.array,
    rulesetEnabled: PropTypes.bool,
    version: PropTypes.string,
    extraScopeEnabled: PropTypes.bool,
    disableEmptyRules: PropTypes.bool,
  },

  mixins: [State],

  getInitialState() {
    return {
      showNote: null,
      showNoteTop: false,
    };
  },

  componentDidMount() {
    document.addEventListener('click', this.handleCompClick);
  },

  componentWillUnmount() {
    document.removeEventListener('click', this.handleCompClick);
  },

  getRuleNote(value, row) {
    const {showNote, showNoteTop} = this.state;

    return (
      <RuleNote
        isReadOnly
        href={row.href}
        onNoteIconClick={this.handleNoteIconClick}
        onNoteCloseClick={this.handleNoteCloseClick}
        value={value}
        showNote={row.href === showNote}
        showNoteTop={showNoteTop}
        onSetNoteBox={this.handleSetNoteBox}
      />
    );
  },

  handleCompClick(evt) {
    const isNoteIcon = evt.target.classList.contains('Icon-RulesetRules-NoteIcon');

    if (!this.state.showNote || !this.noteBox || isNoteIcon) {
      return;
    }

    if (!this.noteBox.contains(evt.target)) {
      this.handleNoteCloseClick();
    }
  },

  handleNoteCloseClick() {
    this.setState({showNote: null});
  },

  handleNoteIconClick(href, showNoteTop) {
    if (this.state.showNote === href) {
      this.setState({showNote: null});

      return;
    }

    this.setState({showNote: href, showNoteTop});
  },

  handleSetNoteBox(node) {
    this.noteBox = node;
  },

  render() {
    const {
      props: {rules, version, providerConsumerOrder, tid},
    } = this;

    const emptyRules = (
      <Banner type="notice" header={intl(_.isEmpty(rules) ? 'Common.NoData' : 'Common.NoMatchRules')} />
    );
    const ruleColumns = [
      {
        key: 'status',
        label: intl('Common.ProvisionStatus'),
        style: 'tag',
        format: (value, rule) => {
          const {type, text} = RulesetUtils.getRuleProvisionStatus(rule, version);

          if (type && text) {
            return (
              <Badge key={type} type={type}>
                {text}
              </Badge>
            );
          }

          return null;
        },
      },
      ...ProviderConsumerUtils.setProviderConsumerColumnOrderArrow(
        {
          key: 'providers',
          label: intl('Common.Destinations'),
          style: 'providers',
          format: (value, row) => GridDataUtils.formatRuleEntities(value, row),
        },
        {
          key: 'ingress_services',
          label: intl('Rulesets.Rules.ProvidingService'),
          style: 'service',
          format: GridDataUtils.formatRuleService,
        },
        {
          key: 'consumer_to_provider_arrow',
          style: 'consumerToProviderArrow',
        },
        {
          key: 'consumers',
          label: intl('Common.Sources'),
          style: 'consumers',
          format: (value, row) =>
            GridDataUtils.formatRuleEntities(
              this.props.extraScopeEnabled && row.unscoped_consumers ? [...value, {extraScope: true}] : value,
              row,
              true,
            ),
        },
        providerConsumerOrder,
      ),
      {
        key: 'description',
        sortable: false,
        label: intl('Common.Note'),
        style: 'RulesetRules-Note',
        format: (value, row) => this.getRuleNote(value, row),
      },
    ];

    return (
      <div className="RuleGrid">
        <Grid
          columns={ruleColumns}
          rowClass={GridDataUtils.formatStatusRowClass}
          data={rules}
          idField="href"
          emptyContent={emptyRules}
          tid={tid}
        />
      </div>
    );
  },
});
