/**
 * Copyright 2016 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import cx from 'classnames';
import intl from '@illumio-shared/utils/intl';
import {PropTypes} from 'react';
import {ToolBar} from '../ToolBar';
import {RuleService, RulesetRuleButtonDropdown} from '.';
import {GridDataUtils, ProviderConsumerUtils, RulesetUtils, RuleWritingUtils} from '../../utils';
import {Button, Grid, Pagination, Badge, OSEntitySelect, NotificationGroup, Icon} from '..';
import {SessionStore} from '../../stores';

RulesetRuleGrid.propTypes = {
  addRule: PropTypes.bool.isRequired,
  anyHref: PropTypes.string.isRequired,
  comparedRules: PropTypes.array.isRequired,
  editingHref: PropTypes.string.isRequired,
  errors: PropTypes.array.isRequired,
  getRuleNote: PropTypes.func.isRequired,
  hideRules: PropTypes.bool.isRequired,
  newRule: PropTypes.object.isRequired,
  onAdd: PropTypes.func.isRequired,
  onAddCancel: PropTypes.func.isRequired,
  onAddSave: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onEditCancel: PropTypes.func.isRequired,
  onEditSave: PropTypes.func.isRequired,
  onEditSecondary: PropTypes.func.isRequired,
  onErrorsChange: PropTypes.func.isRequired,
  onHideToggle: PropTypes.func.isRequired,
  onPageChange: PropTypes.func.isRequired,
  onRowSelect: PropTypes.func.isRequired,
  onSort: PropTypes.func.isRequired,
  formatRowClass: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  pageLength: PropTypes.number.isRequired,
  proposed: PropTypes.bool,
  readonly: PropTypes.bool.isRequired,
  ruleset: PropTypes.object.isRequired,
  selection: PropTypes.array.isRequired,
  sorting: PropTypes.array.isRequired,
  type: PropTypes.string.isRequired,
  version: PropTypes.string.isRequired,
  diffRuleset: PropTypes.object,
  filter: PropTypes.object,
  moveRow: PropTypes.func,
  dragOrder: PropTypes.array,
  reorderMode: PropTypes.string,
};

export default function RulesetRuleGrid(props) {
  const readonly = props.readonly;
  const isIntra = props.type === 'intra';
  let rules = props.ruleset.rules.map((rule, idx) => ({...rule, index: idx + 1}));
  let emptyText;

  if (isIntra) {
    rules = rules.filter(rule => !rule.unscoped_consumers);
  } else {
    rules = rules.filter(rule => Boolean(rule.unscoped_consumers));
  }

  if (props.ruleset.update_type !== 'create') {
    rules = props.comparedRules;
  }

  if (rules.length) {
    emptyText = intl(isIntra ? 'Rulesets.Rules.IntraScope.NoMatch' : 'Rulesets.Rules.ExtraScope.NoMatch');
  } else {
    emptyText = intl(isIntra ? 'Rulesets.Rules.IntraScope.NoData' : 'Rulesets.Rules.ExtraScope.NoData');
  }

  const filteredRules = RulesetUtils.filterRules(props.filter, rules);
  const isFiltered = filteredRules !== rules;
  const emptyRules = <span className="RulesetRules-list-empty">{emptyText}</span>;
  const activeAdd = props.addRule && props.newRule;
  const activeEdit = rules && props.editingHref && _.cloneDeep(rules.find(rule => rule.href === props.editingHref));
  const hideStatusAndNote = activeAdd || activeEdit;
  const editingNotifications = props.errors.length ? (
    <NotificationGroup notifications={props.errors} onChange={props.onErrorsChange} />
  ) : null;
  const notifications = {};
  let virtualServicesProvidersExists = true;
  let virtualServicesConsumersExists = true;
  let addServiceExists;
  let editServiceExists;

  if (props.diffRuleset) {
    // Converting a Rule from Intra-Scope to Extra-Scope or vice versa is not
    // supported in the UI, however, it can be done via the API.
    // When that happens, the UI should show a notification letting users know
    // that the Rule's unscoped_consumers flag was updated
    const diffIds = {};

    for (const diffRule of props.diffRuleset.rules || []) {
      diffIds[GridDataUtils.getIdFromHref(diffRule.href)] = diffRule;
    }

    for (const rule of props.ruleset.rules) {
      const ruleId = GridDataUtils.getIdFromHref(rule.href);

      if (diffIds[ruleId] && rule.unscoped_consumers !== diffIds[ruleId].unscoped_consumers) {
        let title;

        if (diffIds[ruleId].unscoped_consumers) {
          title = intl('Rulesets.Rules.ExtraToIntra');
        } else {
          title = intl('Rulesets.Rules.IntraToExtra');
        }

        notifications[rule.href] ||= [];
        notifications[rule.href].push({type: 'instruction', title});
      }
    }
  }

  [activeAdd, activeEdit].forEach((object, index) => {
    if (!object) {
      return;
    }

    let serviceExists;

    if (_.get(object, 'ingress_services', []).length) {
      serviceExists = true;
    }

    const resolveLabelsAsProviders = _.get(object, 'resolve_labels_as.providers', []);
    const resolveLabelsAsConsumers = _.get(object, 'resolve_labels_as.consumers', []);

    if (resolveLabelsAsProviders.length === 1 && resolveLabelsAsProviders[0] === 'virtual_services') {
      serviceExists = true;
    }

    if (resolveLabelsAsProviders.includes('virtual_services')) {
      virtualServicesProvidersExists = object.providers.filter(
        consumer => !consumer.usesVirtualServicesWorkloads && !consumer.usesVirtualServices,
      ).length;
    }

    if (resolveLabelsAsConsumers.includes('virtual_services')) {
      virtualServicesConsumersExists = object.consumers.filter(
        consumer => !consumer.usesVirtualServicesWorkloads && !consumer.usesVirtualServices,
      ).length;
    }

    if (index === 0) {
      addServiceExists = serviceExists;
    } else {
      editServiceExists = serviceExists;
    }
  });

  // Show a warning if there's an IP List in Providing Entities in an Extra Scope Rule
  if (props.type === 'extra') {
    // Remove the warning if the IP List is deleted
    const ruleWithIpListInPE = filteredRules.find(rule =>
      rule.providers.find(provider => provider.ip_list && !provider.deleted),
    );

    if (
      ruleWithIpListInPE &&
      (!activeEdit || (activeEdit && (SessionStore.isUserOwner() || SessionStore.isUserAdmin())))
    ) {
      notifications[ruleWithIpListInPE.href] ||= [];
      notifications[ruleWithIpListInPE.href].push({
        type: 'warning',
        title: intl('Rulesets.Rules.ExtraScope.IPListWarning'),
      });
    }

    // Only show warning for Owner and Admin.  Scoped users will see an error message instead.
    if (
      activeAdd &&
      activeAdd.providers &&
      activeAdd.providers.some(provider => provider.ip_list) &&
      (SessionStore.isUserOwner() || SessionStore.isUserAdmin())
    ) {
      notifications.activeAdd = [
        {
          type: 'warning',
          title: intl('Rulesets.Rules.ExtraScope.IPListWarning'),
        },
      ];
    }
  }

  filteredRules.forEach((rule, index) => {
    const readOnlyService = rule.ingress_services.some(service => service.port === -1);

    if (readOnlyService) {
      notifications[rule.href] ||= [];
      notifications[rule.href].push({
        type: 'warning',
        title: intl('Rulesets.Rules.ReadonlyService'),
      });
    }

    // This is for the "No." Column for reordering rules.
    rule.index = index + 1;
    rule.grabHandle = true;
  });

  const columns = [];

  if (!hideStatusAndNote) {
    columns.push({
      key: 'index',
      label: intl('Rulesets.Rules.IndexColumnLabel'),
      style: 'reorderNumber',
      sortable: true,
    });
  }

  if (props.reorderMode === props.type) {
    columns.unshift({
      key: 'grabHandle',
      label: '',
      style: 'reorderNumber',
      format: GridDataUtils.formatReorderRulesGrabHandle,
    });
  }

  if (props.reorderMode !== props.type) {
    columns.push({
      key: 'provision_status',
      label: intl('Common.ProvisionStatus'),
      style: 'tag',
      sortable: !hideStatusAndNote,
      format: (value, rule) => {
        const ruleStatus = RulesetUtils.getRuleProvisionStatus(rule, props.version);

        if (ruleStatus.type && ruleStatus.text) {
          return (
            <Badge key={ruleStatus.type} type={ruleStatus.type}>
              {ruleStatus.text}
            </Badge>
          );
        }
      },
      sortValue: (value, rule) => RulesetUtils.getRuleProvisionStatus(rule, props.version).text,
    });
  }

  const {providerConsumerOrder} = props;

  columns.push(
    ...ProviderConsumerUtils.setProviderConsumerColumnOrderArrow(
      {
        key: 'providers',
        label: intl('Common.Destinations'),
        style: 'providers',
        format: (value, row) => GridDataUtils.formatRuleEntities(value, row),
        addComponent: activeAdd ? (
          <OSEntitySelect
            type="provider"
            selected={RuleWritingUtils.getUnfriendlyEntities(activeAdd.providers)}
            onChange={_.partial(props.onEntityChange, 'providers')}
            version="draft"
            anyHref={props.anyHref}
            autoFocus={providerConsumerOrder === 'providerFirst'}
            isExtra={!isIntra}
            noFilterByScope
          />
        ) : null,
        editComponent: activeEdit ? (
          <OSEntitySelect
            type="provider"
            selected={
              activeEdit &&
              RuleWritingUtils.getUnfriendlyEntities(activeEdit.providers.filter(provider => !provider.deleted))
            }
            onChange={_.partial(props.onEntityChange, 'providers')}
            version="draft"
            anyHref={props.anyHref}
            autoFocus={providerConsumerOrder === 'providerFirst'}
            isExtra={!isIntra}
            noFilterByScope
          />
        ) : null,
      },
      {
        key: 'ingress_services',
        label: intl('Rulesets.Rules.DestinationServices'),
        style: 'service',
        format: (value, row) => GridDataUtils.formatRuleService(value, row),
        addComponent: activeAdd ? (
          <RuleService
            selected={activeAdd}
            disableSpinner
            onChange={_.partial(props.onEntityChange, 'service')}
            isDenyRule={!isIntra}
          />
        ) : null,
        editComponent: activeEdit ? (
          <RuleService
            // Filter removed services in edit mode of existing rule
            selected={_.cloneDeepWith(activeEdit, RuleWritingUtils.filterDeletedServices)}
            disableSpinner
            onChange={_.partial(props.onEntityChange, 'service')}
            isDenyRule={!isIntra}
          />
        ) : null,
      },
      {
        key: 'consumer_to_provider_arrow',
        style: 'consumerToProviderArrow',
      },
      {
        key: 'consumers',
        label: isIntra ? intl('Common.Sources') : intl('Common.ConsumersGlobal'),
        style: 'consumers',
        format: (value, row) => GridDataUtils.formatRuleEntities(value, row, true),
        addComponent: activeAdd ? (
          <OSEntitySelect
            type="consumer"
            selected={RuleWritingUtils.getUnfriendlyEntities(
              activeAdd.consumers,
              activeAdd.consuming_security_principals,
            )}
            onChange={_.partial(props.onEntityChange, 'consumers')}
            version="draft"
            anyHref={props.anyHref}
            isExtra={!isIntra}
            autoFocus={providerConsumerOrder === 'consumerFirst'}
            noFilterByScope
          />
        ) : null,
        editComponent: activeEdit ? (
          <OSEntitySelect
            type="consumer"
            selected={
              activeEdit &&
              RuleWritingUtils.getUnfriendlyEntities(
                activeEdit.consumers.filter(consumer => !consumer.deleted),
                activeEdit.consuming_security_principals.filter(ug => !ug.deleted),
              )
            }
            onChange={_.partial(props.onEntityChange, 'consumers')}
            version="draft"
            anyHref={props.anyHref}
            isExtra={!isIntra}
            autoFocus={providerConsumerOrder === 'consumerFirst'}
            noFilterByScope
          />
        ) : null,
      },
      providerConsumerOrder,
    ),
  );

  if (!hideStatusAndNote) {
    columns.push({
      key: 'description',
      sortable: false,
      label: intl('Common.Note'),
      style: 'RulesetRules-Note',
      format: (value, row) => props.getRuleNote(value, row),
    });
  }

  if (!hideStatusAndNote && props.reorderMode !== props.type) {
    columns.splice(2, 0, {
      key: 'status',
      label: intl('Common.Status'),
      style: 'status',
      sortable: !hideStatusAndNote,
      format: (value, rule) => {
        if (!rule.enabled || !props.ruleset.enabled) {
          return <span className="fw-500">{intl('Common.Disabled')}</span>;
        }

        return intl('Common.Enabled');
      },
      sortValue: (value, rule) => {
        if (!rule.enabled || !props.ruleset.enabled) {
          return intl('Common.Disabled');
        }

        return intl('Common.Enabled');
      },
    });
  }

  const addFilled =
    activeAdd &&
    !_.isEmpty(activeAdd.providers) &&
    !_.isEmpty(activeAdd.consumers) &&
    addServiceExists &&
    virtualServicesProvidersExists &&
    virtualServicesConsumersExists;
  const editFilled =
    activeEdit &&
    !_.isEmpty(activeEdit.providers.filter(obj => !obj.deleted)) &&
    !_.isEmpty(activeEdit.consumers.filter(obj => !obj.deleted)) &&
    editServiceExists &&
    virtualServicesProvidersExists &&
    virtualServicesConsumersExists;

  if (!readonly && props.reorderMode !== props.type && (isIntra || SessionStore.canUserCUDExtraScopeRules())) {
    columns.push({
      key: 'actions',
      style: 'actions',
      addComponent: () => [
        <Button
          icon="save"
          content="icon-only"
          onClick={props.onAddSave}
          tid="add-save"
          key="save"
          disabled={Boolean(props.errors.length) || !addFilled}
          customClass="Button--save"
        />,
        <Button
          icon="cancel"
          type="secondary"
          content="icon-only"
          onClick={props.onAddCancel}
          tid="add-cancel"
          key="cancel"
        />,
      ],
      editComponent: () => [
        <Button
          icon="save"
          content="icon-only"
          onClick={props.onEditSave}
          tid="edit-save"
          key="save"
          disabled={Boolean(props.errors.length) || !editFilled}
          customClass="Button--save"
        />,
        <Button
          icon="cancel"
          type="secondary"
          content="icon-only"
          onClick={props.onEditCancel}
          tid="edit-cancel"
          key="cancel"
        />,
      ],
      format: (value, row) => (
        <RulesetRuleButtonDropdown
          row={row}
          onEdit={props.onEdit}
          onEditSecondary={props.onEditSecondary}
          proposed={props.proposed}
        />
      ),
    });
  }

  const classNames = cx('RulesetRules-section', {
    'RulesetRules-section--intrascope': isIntra,
    'RulesetRules-section--extrascope': !isIntra,
    'RulesetRules-section--empty': !rules.length,
  });

  return (
    <div className={classNames} data-tid={`page-rulesetrules-${props.type}scope`}>
      <ToolBar>
        <div className="RulesetRules-section-title">
          <Icon
            name={`caret-${props.hideRules ? 'right' : 'down'}`}
            size="medium"
            styleClass="RulesetRules-section-icon"
            onClick={props.onHideToggle}
          />
          <span data-tid="rules-section-count-title">
            {intl(isIntra ? 'Rulesets.Rules.IntraScope.Rules' : 'Rulesets.Rules.ExtraScope.Rules', {
              count: rules.length,
            })}
          </span>
          {!readonly && props.reorderMode === 'none' && !props.proposed && (
            <Button
              content="icon-only"
              type="secondary"
              icon="add"
              onClick={props.onAdd}
              tid="add"
              disabled={Boolean(activeAdd) || (!isIntra && !SessionStore.canUserCUDExtraScopeRules())}
            />
          )}
          {!readonly && props.reorderMode === 'none' && !props.proposed && (
            <Button
              text={intl('Rulesets.ReorderRules')}
              type="secondary"
              tid="reorder"
              onClick={_.partial(props.onStartReorder, props.type)}
            />
          )}
          {!readonly && props.reorderMode === props.type && (
            <Button
              text={intl('Rulesets.SaveOrder')}
              type="secondary"
              tid="saveOrder"
              onClick={_.partial(props.onReorderSave, props.type)}
            />
          )}
          {!readonly && props.reorderMode === props.type && (
            <Button
              text={intl('Common.Cancel')}
              type="secondary"
              tid="reorderCancel"
              onClick={_.partial(props.onReorderCancel, props.type)}
            />
          )}
        </div>
        {filteredRules.length && !props.hideRules && !activeEdit ? (
          <Pagination
            totalRows={filteredRules.length}
            pageLength={props.pageLength}
            onPageChange={props.onPageChange}
            page={props.page}
            count={
              isFiltered
                ? {
                    matched: filteredRules.length,
                    total: rules.length,
                  }
                : null
            }
            isFiltered={isFiltered}
          />
        ) : null}
      </ToolBar>
      {props.hideRules ? null : (
        <div className="RulesetRules-list">
          <div className="RuleGrid">
            {activeAdd && notifications.activeAdd ? (
              <NotificationGroup notifications={notifications.activeAdd} />
            ) : null}
            {activeAdd ? editingNotifications : null}
            <Grid
              renderAddBar={!readonly && Boolean(activeAdd)}
              editable={!readonly && true}
              editingId={props.editingHref}
              editingNotifications={activeEdit ? editingNotifications : null}
              notificationsStyle="above"
              data={props.reorderMode === props.type ? props.dragOrder : filteredRules}
              columns={columns}
              selectable={
                !readonly &&
                props.reorderMode !== props.type &&
                !props.proposed &&
                (isIntra || SessionStore.canUserCUDExtraScopeRules())
              }
              selection={props.selection}
              sorting={props.sorting}
              sortable={!hideStatusAndNote}
              onSort={props.onSort}
              onRowSelectToggle={props.onRowSelect}
              allowShiftSelect={props.allowShiftSelect}
              lastSelected={props.lastSelected}
              rowClass={props.formatRowClass}
              rowSelectable={row => !row.deleted && row.update_type !== 'delete'}
              idField="href"
              emptyContent={emptyRules}
              resultsPerPage={props.pageLength}
              currentPage={props.page}
              allowReorder={props.reorderMode === props.type}
              moveRow={props.moveRow}
              notifications={notifications}
              forceNotifications
            />
          </div>
        </div>
      )}
    </div>
  );
}
