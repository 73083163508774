/**
 * Copyright 2016 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import cx from 'classnames';
import intl from '@illumio-shared/utils/intl';
import {PropTypes} from 'react';
import {RulesetRuleButtonDropdown} from '.';
import {ToolBar} from '../ToolBar';
import {GridDataUtils, RulesetUtils, RuleWritingUtils} from '../../utils';
import {Button, Grid, Pagination, Badge, OSEntitySelect, NotificationGroup, Select, CustomIptableInput, Icon} from '..';

const IP_OPTIONS = () => [
  {
    value: '4',
    label: intl('Protocol.IPv4'),
  },
  {
    value: '6',
    label: intl('Protocol.IPv6'),
  },
];

RulesetIptableGrid.propTypes = {
  addRule: PropTypes.bool.isRequired,
  editingHref: PropTypes.string.isRequired,
  comparedIpTableRules: PropTypes.array.isRequired,
  errors: PropTypes.array.isRequired,
  getRuleNote: PropTypes.func.isRequired,
  hideRules: PropTypes.bool.isRequired,
  newRule: PropTypes.object.isRequired,
  onAdd: PropTypes.func.isRequired,
  onAddCancel: PropTypes.func.isRequired,
  onAddSave: PropTypes.func.isRequired,
  onEntityChange: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onEditCancel: PropTypes.func.isRequired,
  onEditSave: PropTypes.func.isRequired,
  onErrorsChange: PropTypes.func.isRequired,
  onHideToggle: PropTypes.func.isRequired,
  onPageChange: PropTypes.func.isRequired,
  onRowSelect: PropTypes.func.isRequired,
  onSort: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  pageLength: PropTypes.number.isRequired,
  readonly: PropTypes.bool.isRequired,
  ruleset: PropTypes.object.isRequired,
  selection: PropTypes.array.isRequired,
  sorting: PropTypes.array.isRequired,
  version: PropTypes.string.isRequired,
  filter: PropTypes.object,
  dragOrder: PropTypes.array,
  moveRow: PropTypes.func,
  reorderMode: PropTypes.string,
};

export default function RulesetIptableGrid(props) {
  const readonly = props.readonly;
  let rules = props.ruleset.ip_tables_rules.map((rule, idx) => ({...rule, index: idx + 1}));
  let emptyRules = <span className="RulesetRules-list-empty">{intl('Rulesets.Rules.IpTables.NoMatch')}</span>;

  if (props.ruleset.update_type !== 'create') {
    rules = props.comparedIpTableRules;
  }

  if (!rules.length) {
    emptyRules = <span className="RulesetRules-list-empty">{intl('Rulesets.Rules.IpTables.NoData')}</span>;
  }

  const filteredRules = RulesetUtils.filterRules(props.filter, rules);
  const isFiltered = filteredRules !== rules;
  const activeAdd = props.addRule && props.newRule;
  const activeEdit = rules && props.editingHref && _.cloneDeep(rules.find(rule => rule.href === props.editingHref));
  const hideStatusAndNote = activeAdd || activeEdit;
  const editingNotifications = props.errors.length ? (
    <NotificationGroup notifications={props.errors} onChange={props.onErrorsChange} />
  ) : null;
  const saveDisabled =
    activeAdd && activeAdd.statements && activeAdd.statements.some(statement => statement.duplicate || statement.error);
  const editDisabled =
    activeEdit &&
    activeEdit.statements &&
    activeEdit.statements.some(statement => statement.duplicate || statement.error);
  const columns = [
    {
      key: 'provision_status',
      label: intl('Common.ProvisionStatus'),
      style: 'tag',
      sortable: !hideStatusAndNote,
      format: (value, rule) => {
        const ruleStatus = RulesetUtils.getRuleProvisionStatus(rule, props.version);

        if (ruleStatus.type && ruleStatus.text) {
          return (
            <Badge key={ruleStatus.type} type={ruleStatus.type}>
              {ruleStatus.text}
            </Badge>
          );
        }

        return null;
      },
      sortValue: (value, rule) => {
        const ruleStatus = RulesetUtils.getRuleProvisionStatus(rule, props.version);

        return ruleStatus.text;
      },
    },
    {
      key: 'actors',
      label: intl('Rulesets.Rules.IpTables.Actors'),
      style: 'actors',
      format: (value, row) => GridDataUtils.formatRuleEntities(value, row),
      addComponent: activeAdd ? (
        <OSEntitySelect
          type="actor"
          selected={RuleWritingUtils.getUnfriendlyEntities(activeAdd.actors)}
          onChange={_.partial(props.onEntityChange, 'actors')}
          version="draft"
          autoFocus={true}
        />
      ) : null,
      editComponent: activeEdit ? (
        <OSEntitySelect
          type="actor"
          selected={RuleWritingUtils.getUnfriendlyEntities(activeEdit.actors.filter(actor => !actor.deleted))}
          onChange={_.partial(props.onEntityChange, 'actors')}
          version="draft"
          autoFocus={true}
        />
      ) : null,
    },
    {
      key: 'ip_version',
      label: intl('Rulesets.Rules.IpTables.Form.IpVersion'),
      style: 'ipversion',
      sortable: !hideStatusAndNote,
      format: (value, row) => {
        let result = '';
        const className = row.ipVersionChanged ? 'IpVersion--changed' : '';

        switch (value) {
          case '4':
            result = intl('Protocol.IPv4');
            break;
          case '6':
            result = intl('Protocol.IPv6');
            break;
          default:
          //no default
        }

        return <div className={className}>{result}</div>;
      },
      sortValue: value => value,
      addComponent: activeAdd ? (
        <Select
          options={IP_OPTIONS()}
          selected={activeAdd.ip_version || '4'}
          onSelect={_.partial(props.onEntityChange, 'ip_version')}
          tid="lifespan"
        />
      ) : null,
      editComponent: activeEdit ? (
        <Select
          options={IP_OPTIONS()}
          selected={activeEdit.ip_version}
          onSelect={_.partial(props.onEntityChange, 'ip_version')}
          tid="lifespan"
        />
      ) : null,
    },
    {
      key: 'statements',
      label: intl('Rulesets.Rules.IpTables.Grid.Statements'),
      style: 'statements',
      format: statements =>
        _.map(statements, value => {
          const result = [];
          let dataTid = 'iptable-rule';

          if (value.table_name) {
            result.push(`-t ${value.table_name}`);
          }

          if (value.chain_name) {
            result.push(`-A ${value.chain_name}`);
          }

          if (value.parameters) {
            result.push(value.parameters);
          }

          let className = 'Statement';

          if (value.created) {
            className += ' Statement--created';
            dataTid += '-created';
          } else if (value.deleted) {
            className += ' Statement--deleted';
            dataTid += '-deleted';
          }

          return (
            <div data-tid={dataTid} className={className}>
              {result.join(' ')}
            </div>
          );
        }),
      addComponent: activeAdd ? (
        <CustomIptableInput
          values={activeAdd.statements || []}
          onChange={_.partial(props.onEntityChange, 'statements')}
        />
      ) : null,
      editComponent: activeEdit ? (
        <CustomIptableInput values={activeEdit.statements} onChange={_.partial(props.onEntityChange, 'statements')} />
      ) : null,
    },
  ];

  if (!hideStatusAndNote) {
    columns.push({
      key: 'description',
      sortable: false,
      label: intl('Common.Note'),
      style: 'RulesetRules-Note',
      format: (value, row) => props.getRuleNote(value, row, true),
    });
  }

  if (!hideStatusAndNote) {
    columns.splice(1, 0, {
      key: 'status',
      label: intl('Common.Status'),
      style: 'status',
      sortable: !hideStatusAndNote,
      format: (value, rule) => {
        if (!rule.enabled || !props.ruleset.enabled) {
          return <span className="fw-500">{intl('Common.Disabled')}</span>;
        }

        return intl('Common.Enabled');
      },
      sortValue: (value, rule) => {
        if (!rule.enabled || !props.ruleset.enabled) {
          return intl('Common.Disabled');
        }

        return intl('Common.Enabled');
      },
    });
  }

  if (!readonly) {
    columns.push({
      key: 'actions',
      style: 'actions',
      addComponent: () => [
        <Button
          icon="save"
          content="icon-only"
          onClick={props.onAddSave}
          tid="add-save"
          key="save"
          disabled={
            Boolean(props.errors.length) ||
            saveDisabled ||
            !props.newRule ||
            !Array.isArray(props.newRule.statements) ||
            props.newRule.statements.length === 0 ||
            props.newRule.statements[0].text === '' ||
            !Array.isArray(props.newRule.actors) ||
            props.newRule.actors.length === 0
          }
          customClass="Button--save"
        />,
        <Button
          icon="cancel"
          type="secondary"
          content="icon-only"
          onClick={props.onAddCancel}
          tid="add-cancel"
          key="cancel"
        />,
      ],
      editComponent: () => [
        <Button
          icon="save"
          content="icon-only"
          onClick={props.onEditSave}
          tid="edit-save"
          key="save"
          disabled={Boolean(props.errors.length) || editDisabled}
          customClass="Button--save"
        />,
        <Button
          icon="cancel"
          type="secondary"
          content="icon-only"
          onClick={props.onEditCancel}
          tid="edit-cancel"
          key="cancel"
        />,
      ],
      format: (value, row) => (
        <RulesetRuleButtonDropdown row={row} onEdit={props.onEdit} onEditSecondary={props.onEditSecondary} />
      ),
    });
  }

  const classNames = cx('RulesetRules-section', 'RulesetRules-section--iptables', {
    'RulesetRules-section--empty': !rules.length,
  });

  return (
    <div className={classNames} data-tid="page-rulesetrules-iptables">
      <ToolBar>
        <div className="RulesetRules-section-title">
          <Icon
            name={`caret-${props.hideRules ? 'right' : 'down'}`}
            size="medium"
            styleClass="RulesetRules-section-icon"
            onClick={props.onHideToggle}
          />
          <span data-tid="rules-section-count-title">
            {intl('Rulesets.Rules.IpTables.Rules', {count: rules.length})}
          </span>
          {!readonly && props.reorderMode === 'none' && (
            <Button
              content="icon-only"
              type="secondary"
              icon="add"
              onClick={props.onAdd}
              tid="add"
              disabled={Boolean(activeAdd)}
            />
          )}
        </div>
        {filteredRules.length && !props.hideRules && !activeEdit ? (
          <Pagination
            totalRows={filteredRules.length}
            pageLength={props.pageLength}
            onPageChange={props.onPageChange}
            page={props.page}
            count={
              isFiltered
                ? {
                    matched: filteredRules.length,
                    total: rules.length,
                  }
                : null
            }
            isFiltered={isFiltered}
          />
        ) : null}
      </ToolBar>
      {props.hideRules ? null : (
        <div className="RulesetRules-list">
          <div className="RuleGrid">
            {activeAdd ? editingNotifications : null}
            <Grid
              renderAddBar={!readonly && Boolean(activeAdd)}
              editable={!readonly && true}
              editingId={props.editingHref}
              editingNotifications={activeEdit ? editingNotifications : null}
              notificationsStyle="above"
              data={props.reorderMode === props.type ? props.dragOrder : filteredRules}
              columns={columns}
              selectable={!readonly}
              selection={props.selection}
              allowShiftSelect={props.allowShiftSelect}
              lastSelected={props.lastSelected}
              sorting={props.sorting}
              sortable={!hideStatusAndNote}
              onSort={props.onSort}
              onRowSelectToggle={props.onRowSelect}
              rowClass={GridDataUtils.formatStatusRowClass}
              rowSelectable={row => !row.deleted && row.update_type !== 'delete'}
              idField="href"
              emptyContent={emptyRules}
              resultsPerPage={props.pageLength}
              currentPage={props.page}
            />
          </div>
        </div>
      )}
    </div>
  );
}
