/**
 * Copyright 2016 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import React from 'react';
import intl from '@illumio-shared/utils/intl';
import {Navigation} from 'react-router';
import Icon from './Icon.jsx';
import {Menu, MenuItem, MenuDelimiter} from './Menu';
import {SessionStore} from '../stores';

const tid = 'comp-navbar-';

export default React.createClass({
  mixins: [Navigation],

  renderContent() {
    const supportLink = SessionStore.getSupportLink();
    const helpLink = `${SessionStore.getHelpLink()}/${SessionStore.getHelpVersion()}/Home.htm`;
    const termsLink = 'https://www.illumio.com/eula';
    const privacyLink = 'https://www.illumio.com/privacy-policy';
    const patentLink = 'https://www.illumio.com/patents';
    const whatsNewLink = SessionStore.getWhatsNewDocUrl();
    const product = _.capitalize(SessionStore.getProduct());

    return [
      <MenuItem link={{href: helpLink, target: '_blank'}} text={intl('Common.Help')} data-tid={`${tid}help`} />,
      <MenuDelimiter />,
      <MenuItem
        link={{href: supportLink, target: '_blank'}}
        text={intl('Common.Support')}
        data-tid={`${tid}support`}
      />,
      <MenuDelimiter />,
      <MenuItem link={{href: termsLink, target: '_blank'}} text={intl('Common.Terms')} data-tid={`${tid}terms`} />,
      <MenuItem
        link={{href: privacyLink, target: '_blank'}}
        text={intl('Common.PrivacyPolicy')}
        data-tid={`${tid}privacy-policy`}
      />,
      <MenuItem link={{href: patentLink, target: '_blank'}} text={intl('Common.Patents')} data-tid={`${tid}patents`} />,
      <MenuDelimiter />,
      <MenuItem
        link={{href: whatsNewLink, target: '_blank'}}
        text={intl('WhatsNew.WhatsNewIn', {product})}
        data-tid={`${tid}whatsnew`}
      />,
    ];
  },

  render() {
    return (
      <Menu tid="Navbar-help" icon={<Icon name="dropdown" size="xlarge" />}>
        {this.renderContent}
      </Menu>
    );
  },
});
