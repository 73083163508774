/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import {Document as FSDocument} from 'flexsearch';
import intl from '@illumio-shared/utils/intl';
import React from 'react';
import {findDOMNode} from 'react/lib/ReactDOM';
import {State} from 'react-router/lib';
import {KEY_BACK_SPACE, KEY_RETURN, KEY_RIGHT, KEY_UP, KEY_DOWN, KEY_TAB} from 'keycode-js';
import {RouterMixin, StoreMixin} from '../../mixins';
import {SessionStore, MatchesStore, InstantSearchStore} from '../../stores';
import actionCreators from '../../actions/actionCreators';
import {scrollToElement} from '../../utils/dom';
import RestApiUtils, {kvPair} from '../../utils/RestApiUtils';
import {getId, isMac, cmdOrCtrlPressed, camelCaseToSlug} from '../../utils/GeneralUtils';
import {RoutesMap} from '../../Routes';
import Constants from '../../constants';
import {Button, Dialog, Icon, Tooltip} from '..';
import tesseReactRouteNamesMap from './InstantSearchContainerProperties';
import AnalyticsUtils from '../../utils/AnalyticsUtils';
import DataFormatUtils from '../../utils/DataFormatUtils';

const highlightedTextCache = new Map();

function getStateFromStores() {
  const matches = [];

  if (this.state?.value.length && this.state?.filter?.length && MatchesStore.getAll()) {
    for (const match of MatchesStore.getAll()) {
      matches.push({key: match.name || match.value, value: match});
    }
  } else if (!this.state?.filter && InstantSearchStore.getHistory().length) {
    for (const item of InstantSearchStore.getHistory()) {
      matches.push({key: item.value, value: item});
    }
  }

  return {
    matches,
    status: MatchesStore.getStatus(), // Status to trigger loading indicator
    noResultsFound: this.state?.value && !matches.length,
  };
}

export default React.createClass({
  mixins: [State, RouterMixin, StoreMixin([MatchesStore, InstantSearchStore], getStateFromStores)],

  getInitialState() {
    return {
      value: '',
      filter: '',
      selected: 0,
      shake: false,
    };
  },

  async componentDidMount() {
    this.focusInput();
    await this.setupRoutesIndex();
    document.addEventListener('keydown', this.handleKeydown, false);
    MatchesStore.clear();

    this.debounceAutocompleteSearch = _.debounce(() => {
      const {filter: type, value} = this.state;
      const query = {query: value, facet: 'name', max_results: 25};

      RestApiUtils[type].autocomplete('draft', query);
    }, 450);

    if (this.refs.inputContainer) {
      this.refs.inputContainer.addEventListener('animationend', this.handleAnimationEnd);
    }
  },

  componentWillUnmount() {
    MatchesStore.clear();
    this.debounceAutocompleteSearch?.cancel();
    document.removeEventListener('keydown', this.handleKeydown);

    if (this.refs.inputContainer) {
      this.refs.inputContainer.addEventListener('animationend', this.handleAnimationEnd);
    }
  },

  async setupRoutesIndex() {
    this.routesIndex = new FSDocument({
      worker: true,
      document: {
        id: 'id',
        store: ['viewName', 'collectionName', 'name', 'isAvailable', 'params'],

        index: [
          {
            field: 'viewName',
            tokenize: 'full', // To support forward, reverse, and partial matches e.g. Typing 'ser' or 'ervi' or 'es', will match Services
          },
          {
            field: 'collectionName',
            tokenize: 'strict', // Use default since we don't do direct input search on Collections
          },
        ],
      },
    });

    let i = 0;

    const routeEntries = Array.from(RoutesMap.entries()).reduce((result, [name, {handler}]) => {
      if (!handler) {
        return result;
      }

      if (handler.displayName === 'JumpToNew') {
        if (tesseReactRouteNamesMap()[name]) {
          const {viewName, collectionName, isAvailable, aliases, params} = tesseReactRouteNamesMap()[name];

          if (__DEV__) {
            if (typeof viewName !== 'function') {
              throw new TypeError('viewName should be a function.');
            }
          }

          const view = viewName();

          if (!result.get(view)) {
            result.set(
              view,
              this.routesIndex.addAsync({id: i, viewName: view, name, collectionName, isAvailable, params}),
            );
            i += 1;
          }

          if (aliases?.length) {
            aliases.forEach(alias => {
              if (__DEV__) {
                if (typeof alias.viewName !== 'function') {
                  throw new TypeError('alias.viewName should be a function.');
                }
              }

              const aliasView = alias.viewName();

              if (!result.get(aliasView)) {
                result.set(
                  aliasView,
                  this.routesIndex.addAsync({
                    id: i,
                    viewName: aliasView,
                    name,
                    collectionName,
                    isAvailable: alias.isAvailable,
                    params: alias.params,
                  }),
                );
                i += 1;
              }
            });
          }
        }
      }

      if (handler.viewName) {
        if (handler.forRoute && handler.forRoute !== name) {
          return result;
        }

        if (__DEV__) {
          if (typeof handler.viewName !== 'function') {
            throw new TypeError(`handler.viewName=${handler.viewName} should be a function.`);
          }
        }

        const viewName = handler.viewName();

        if (!result.get(viewName)) {
          result.set(
            viewName,
            this.routesIndex.addAsync({
              id: i,
              viewName,
              name,
              collectionName: handler.collectionName,
              isAvailable: handler.isAvailable,
              params: handler.params,
            }),
          );
          i += 1;
        }
      }

      return result;
    }, new Map());

    await Promise.all(Array.from(routeEntries.values())); // Call all routesIndex.addAsync() entries for a route
  },

  focusInput() {
    if (this.refs?.input) {
      findDOMNode(this.refs.input).focus();
    }
  },

  clearFilter() {
    this.setState(
      {
        selected: 0,
        value: '',
        filter: '',
      },
      () => {
        this.setState({...getStateFromStores.call(this)});
      },
    );
  },

  async handleKeydown(evt) {
    // Handle 'tab' key.
    // This results in either a filter being added to the searchbar.
    // Otherwise, an animation is triggered in order to alert the user about invalid filtering.
    if (evt.keyCode === KEY_TAB) {
      evt.preventDefault();

      if (this.state.matches.length) {
        const value = this.state.matches[this.state.selected].value;

        if (!this.state.filter && value?.collectionName) {
          AnalyticsUtils.sendAnalyticsEvent('instantSearch.tab', {
            routeName: value?.collectionName,
            method: 'keyboard',
          });
          this.setState({
            value: '',
            filter: value.collectionName,
            matches: [],
            selected: 0,
          });

          this.forceUpdate();
        }
      } else {
        // Work around for unsupported onAnimationEnd css animation callback
        this.setState({shake: true});
      }

      return;
    }

    // Enter - navigate to selection
    if (this.state.matches.length && evt.keyCode === KEY_RETURN) {
      evt.preventDefault();
      this.navigate();

      return;
    }

    // Provides arrow key functionality and highlights active suggestion
    if (evt.keyCode === KEY_DOWN || evt.keyCode === KEY_UP) {
      evt.preventDefault(); // Prevent jumpting between input start / end

      const {selected} = this.state;
      let selectedIndex;

      if (evt.keyCode === KEY_DOWN && selected < this.state.matches.length - 1) {
        selectedIndex = selected + 1;

        this.setState({selected: selectedIndex});
      }

      if (evt.keyCode === KEY_UP && selected > 0) {
        selectedIndex = selected - 1;

        this.setState({selected: selectedIndex});
      }

      const {listRef} = this.refs;

      if (listRef && listRef?.children[selectedIndex]) {
        const listElement = listRef.children[selectedIndex];

        scrollToElement({element: listElement});
      }

      return;
    }

    // Fill input with matching text after pressing right arrow
    if (this.state.matches.length && (evt.keyCode === KEY_RIGHT || evt.keyCode === KEY_TAB)) {
      evt.preventDefault();
      highlightedTextCache.clear();

      const {matches, selected} = this.state;

      if (evt.keyCode === KEY_TAB) {
        const value = matches[selected]?.value;

        if (value?.collectionName) {
          return;
        }
      }

      const autocompleteValue = matches[selected]?.key || matches[selected]?.value?.hostname;

      this.setState({value: autocompleteValue}, () => {
        // Move cursor to end of value
        evt.target.scrollLeft = evt.target.scrollWidth;
        evt.target.setSelectionRange(evt.target.value.length, evt.target.value.length);

        this.forceUpdate();
      });

      return;
    }

    if (cmdOrCtrlPressed(evt)) {
      if (evt.keyCode === KEY_BACK_SPACE) {
        if (evt.shiftKey) {
          // Search History: Clear all
          this.handleDeleteAllSearchItems('keyboard', evt);
        } else if (isMac() && (this.state.value || this.state.filter)) {
          // Mac OS: CMD + DEL - clear line
          this.clearFilter();
        } else {
          // Search History: Clear selected item
          this.handleDeleteSearchItem('keyboard', evt);
        }

        return;
      }
    }

    // Clear filter
    if (!this.state.value?.length && this.state.filter && evt.keyCode === KEY_BACK_SPACE) {
      this.clearFilter();

      return;
    }
  },

  async navigate(evt, method = 'keyboard') {
    const {matches, selected} = this.state;
    const dest = matches[selected].value;

    if (dest) {
      if (dest.href) {
        const filter = this.state.filter || dest.collectionName;
        const routeName = this.routesIndex.search(filter, {enrich: true, limit: 1, pluck: 'collectionName'})?.[0]?.doc
          .name;
        const params = {id: getId(dest.href)};

        if (!['workloads', 'labels', 'container_workloads', 'container_clusters'].includes(filter)) {
          params.pversion = 'draft';
        }

        // Parse route into route translatable form e.g.
        // 'app.workloads.list' --> 'workloads'
        // 'app.workloads.vens.list' --> 'workloads.vens'
        const parsedRouteName = routeName.slice(routeName.indexOf('.') + 1, routeName.lastIndexOf('.'));
        const searchHistory = InstantSearchStore.getHistory();
        const mostRecentSearch = searchHistory[0];

        if (
          matches[selected]?.key !== mostRecentSearch?.value ||
          dest.hostname !== mostRecentSearch?.value ||
          dest.name !== mostRecentSearch?.value
        ) {
          const historyObject = {
            value: matches[selected]?.key || dest.hostname,
            name: `${parsedRouteName}.item`,
            collectionName: filter,
            href: dest.href,
          };

          if (params) {
            historyObject.params = params;
          }

          const history = [
            historyObject,
            ...searchHistory.filter(historyItem => !_.isEqual(historyItem, historyObject)),
          ];

          this.formatHistoryCollectionNames(history);

          await RestApiUtils.kvPair.update(SessionStore.getUserId(), 'instant_search_history', history);
        }

        AnalyticsUtils.sendAnalyticsEvent('instantSearch.navigate', {
          routeName: `${parsedRouteName}.item`,
          method,
        });

        // Navigate to item view
        this.transitionTo(`${parsedRouteName}.item`, params);
      } else {
        const params = dest.params;
        const searchHistory = InstantSearchStore.getHistory();
        const mostRecentSearch = searchHistory[0];

        if (matches[selected]?.key !== mostRecentSearch?.value) {
          const historyObject = {
            value: matches[selected]?.key,
            name: dest.name,
          };

          if (dest.params) {
            historyObject.params = dest.params;
          }

          if (dest.collectionName) {
            historyObject.collectionName = dest.collectionName;
          }

          const history = [
            historyObject,
            ...searchHistory.filter(historyItem => !_.isEqual(historyItem, historyObject)),
          ];

          this.formatHistoryCollectionNames(history);

          await kvPair.update(SessionStore.getUserId(), 'instant_search_history', history);
        }

        const formattedRoute = DataFormatUtils.pages.getRouteWithoutAppPrefix(dest.name);

        AnalyticsUtils.sendAnalyticsEvent('instantSearch.navigate', {
          routeName: formattedRoute,
          method,
        });

        // Navigate to menu level view
        this.transitionTo(formattedRoute, params);
      }

      actionCreators.closeInstantSearch();
    }
  },

  getHint(match, value) {
    // Only show hint when input is not overflowing
    if (this.refs?.input) {
      const {input} = this.refs;
      const isOverflown = input.scrollHeight > input.clientHeight || input.scrollWidth > input.clientWidth;

      if (isOverflown) {
        return '';
      }
    }

    let hint = match?.key || match?.value?.hostname || match;

    if (value?.length && hint?.toLowerCase().startsWith(value.toLowerCase())) {
      hint = Array.from(hint).reduce((acc, char, index) => {
        if (value[index]?.toLowerCase() === char.toLowerCase()) {
          return acc + value[index];
        }

        return acc + char;
      }, '');
    } else {
      hint = '';
    }

    return hint;
  },

  formatHistoryItem(historyItem) {
    let historyItemText = historyItem.key;

    if (
      historyItem.value.collectionName &&
      this.routesIndex?.search(historyItem.value.collectionName, {enrich: true, limit: 1, pluck: 'collectionName'})?.[0]
        ?.doc.viewName !== historyItemText
    ) {
      historyItemText = `${
        this.routesIndex?.search(historyItem.value.collectionName, {
          enrich: true,
          limit: 1,
          pluck: 'collectionName',
        })?.[0]?.doc.viewName
      } - ${historyItemText}`;
    }

    return historyItemText;
  },

  formatHistoryCollectionNames(history) {
    for (const item of history) {
      item.collectionName &&= camelCaseToSlug(item.collectionName, '_');
    }
  },

  handleOnChange(evt) {
    const {value: input} = evt.target;

    if (input?.length) {
      highlightedTextCache.clear();

      if (this.state.filter?.length) {
        this.setState({value: input, selected: 0, moving: false}, () => {
          this.debounceAutocompleteSearch();
        });
      } else {
        const matches = [];

        for (const {doc: match} of this.routesIndex.search(input, 25, {
          enrich: true,
          pluck: 'viewName',
        })) {
          const {viewName: key, isAvailable, ...value} = match;

          if (isAvailable && isAvailable() === false) {
            continue;
          }

          matches.push({key, value});
        }

        this.setState({
          matches,
          value: input,
          selected: 0,
          shake: false,
          moving: false,
          noResultsFound: !matches.length,
        });
      }
    } else if (this.state.value?.length) {
      this.setState({value: '', selected: 0, moving: false}, () => {
        MatchesStore.clear();
      });
    }
  },

  handleOnClick(item, evt) {
    this.setState({selected: item}, () => this.navigate(evt, 'click'));
  },

  handleOnClickToFilter(evt) {
    evt.preventDefault();
    evt.stopPropagation();

    const {filter, matches, selected} = this.state;
    const value = matches[selected].value;

    AnalyticsUtils.sendAnalyticsEvent('instantSearch.tab', {
      routeName: value?.collectionName,
      method: 'click',
    });

    if (!filter && value?.collectionName) {
      this.setState(
        {
          value: '',
          filter: value.collectionName,
          matches: [],
          selected: 0,
        },
        () => this.forceUpdate(),
      ); // Force update to trigger input padding calculations based on ref.
    }
  },

  handleDeleteAllSearchItems(method = 'keyboard', evt) {
    evt.preventDefault();
    evt.stopPropagation();

    AnalyticsUtils.sendAnalyticsEvent('instantSearch.clear', {
      method,
      length: InstantSearchStore.getHistory()?.length,
    });

    kvPair.update(SessionStore.getUserId(), 'instant_search_history', []);
  },

  handleDeleteSearchItem(method = 'keyboard', evt) {
    evt.preventDefault();
    evt.stopPropagation();

    const {matches, selected} = this.state;

    if (matches.length) {
      const value = matches[selected].value;
      const history = InstantSearchStore.getHistory();

      AnalyticsUtils.sendAnalyticsEvent('instantSearch.clear', {
        routeName: value?.value,
        method,
        length: history?.length,
      });

      const newHistory = history?.filter(item => !_.isEqual(value, item));

      this.formatHistoryCollectionNames(newHistory);

      kvPair.update(SessionStore.getUserId(), 'instant_search_history', newHistory);
    }
  },

  setActive(selected) {
    if (this.state.moving) {
      this.setState({selected, moving: false});
    }
  },

  highlightText(match, input) {
    let matchLocations = [];

    if (input?.trim()) {
      if (highlightedTextCache.has(match)) {
        matchLocations = highlightedTextCache.get(match);
      } else {
        let startIndex = 0;
        const lowerInput = input.trim().toLocaleLowerCase();
        const lowerMatch = match.toLocaleLowerCase();

        while (startIndex < lowerMatch.length) {
          const index = lowerMatch.indexOf(lowerInput, startIndex);

          if (index === -1) {
            break;
          }

          startIndex = index + lowerInput.length;
          matchLocations.push([index, startIndex]);
        }

        highlightedTextCache.set(match, matchLocations);
      }
    }

    const result = match?.split('').reduce((nodes, char, index) => {
      let currentNode = char;

      for (const location of matchLocations) {
        if (index >= location[0] && index < location[1]) {
          currentNode = (
            <span key={index} className="InstantSearch-highlight">
              {char}
            </span>
          );
        }
      }

      if (nodes[nodes.length - 1]) {
        const lastNode = nodes[nodes.length - 1];

        if (typeof currentNode === 'string' && typeof lastNode === 'string') {
          nodes.pop();
          currentNode = lastNode + char;
        }
      }

      nodes.push(currentNode);

      return nodes;
    }, []);

    return result;
  },

  handleAnimationEnd() {
    this.setState({shake: false});
  },

  handleOnClose() {
    actionCreators.closeInstantSearch();
  },

  render() {
    const {selected, value, filter, matches, status, shake, noResultsFound} = this.state;
    const showHistory = !value?.length && !filter && InstantSearchStore.getHistory().length > 0;
    let suggestions = null;

    if (value?.length && status === Constants.STATUS_BUSY) {
      suggestions = _.range(4).map((item, index) => (
        <li data-tid="is-suggestion-loading-skeleton" key={index} className="InstantSearch-suggestionItem">
          <span className="InstantSearch-textSkeleton" />
        </li>
      ));
    } else {
      suggestions = matches.map((match, index) => (
        <li
          data-tid={`is-suggestion-${index}`}
          key={index}
          className={
            index === selected
              ? 'InstantSearch-selectedSuggestionItem'
              : showHistory
                ? 'InstantSearch-suggestionHistoryItem'
                : 'InstantSearch-suggestionItem'
          }
          onClick={_.partial(this.handleOnClick, index)}
          onMouseMove={() => this.setState({moving: true})}
          onMouseOver={_.partial(this.setActive, index)}
        >
          <div className="InstantSearch-animateSuggestionItem">
            {showHistory && (
              <div
                className={
                  index === selected ? 'InstantSearch-recentSearchesIconSelected' : 'InstantSearch-recentSearchesIcon'
                }
              >
                <Icon name="search" />
              </div>
            )}
            <span className="InstantSearch-suggestionText">
              {showHistory
                ? this.formatHistoryItem(match)
                : this.highlightText(match?.key || match.value?.hostname || match, value)}
            </span>
            <span className="InstantSearch-actions">
              {match.value?.collectionName && (
                <Button
                  size="medium"
                  type="secondary"
                  icon="filter"
                  text={intl('InstantSearch.TabToFilter')}
                  onClick={this.handleOnClickToFilter}
                />
              )}
              {showHistory && (
                <Tooltip
                  content={`${intl('Common.Delete')} (${isMac() ? 'Cmd + Delete' : 'Ctrl + Delete'})`}
                  width={170}
                  location="left"
                >
                  <Button
                    tid="is-delete-search-item"
                    customClass={index === selected ? 'Button--delete-selected' : 'Button--delete'}
                    content="icon-only"
                    size="medium"
                    type="nofill"
                    icon="close"
                    title={intl('Common.Delete')}
                    iconTitle={intl('Common.Delete')}
                    aria-label={`${intl('Common.Delete')} (${isMac() ? 'Cmd + Delete' : 'Ctrl + Delete'})`}
                    onClick={_.partial(this.handleDeleteSearchItem, 'click')}
                  />
                </Tooltip>
              )}
            </span>
          </div>
        </li>
      ));
    }

    const filterViewName = this.routesIndex?.search(filter, {enrich: true, limit: 1, pluck: 'collectionName'})?.[0]?.doc
      .viewName;
    const hint = this.getHint(matches && matches[selected], value, selected);

    return (
      <Dialog modal alert type="instantSearch" className="InstantSearch" onClose={this.handleOnClose}>
        <div
          data-tid="instant-search"
          ref="inputContainer"
          className={`InstantSearch-inputContainer ${shake ? 'InstantSearch-shake' : ''}`}
          style={{animationPlayState: shake ? 'running' : 'paused'}}
        >
          <div className="InstantSearch-inputContainerBorder">
            <div className="InstantSearch-searchIcon">
              <Icon name="search" />
            </div>
            {filterViewName?.length > 0 && (
              <span data-tid="is-filter" ref="filterRef" className="InstantSearch-filter">
                {filterViewName}
              </span>
            )}
            <input
              ref="hint"
              tabIndex="-1"
              readOnly
              data-tid="comp-field-input-instantSearchHint"
              style={{
                paddingLeft: filterViewName && `calc(${this.refs?.filterRef?.clientWidth}px + 55px)`,
                paddingRight: value.length ? 'calc(100px + 40px)' : '0',
              }}
              className={`InstantSearch-inputField ${
                suggestions.length ? 'InstantSearch-inputTextBorderRadiusTopHint' : 'InstantSearch-inputTextHint'
              } InstantSearch-fieldDisplay`}
              value={hint}
            />
            <input
              ref="input"
              tabIndex="0"
              data-tid="comp-field-input-instantSearchInput"
              placeholder={
                filterViewName?.length
                  ? intl('InstantSearch.SearchByFilter', {filter: filterViewName})
                  : intl('Common.Search')
              }
              style={{
                paddingLeft: filterViewName && `calc(${this.refs?.filterRef?.clientWidth}px + 55px)`,
                paddingRight: value.length ? 'calc(100px + 40px)' : '0',
              }}
              className={`InstantSearch-inputField ${
                suggestions.length ? 'InstantSearch-inputTextBorderRadiusTop' : 'InstantSearch-inputText'
              } InstantSearch-fieldDisplay`}
              value={value}
              onChange={this.handleOnChange}
            />
            {noResultsFound && (
              <span data-tid="is-no-results" className="InstantSearch-noResultsFound">
                {intl('Common.NoResultsFound')}
              </span>
            )}
            {showHistory && (
              <div className="InstantSearch-recentSearches">
                <span data-tid="is-recent-searches" className="InstantSearch-recentSearchesText">
                  {intl('InstantSearch.RecentSearches')}
                </span>
                <Tooltip
                  content={`${intl('InstantSearch.ClearAll')} (${
                    isMac() ? 'Cmd + Shift + Delete' : 'Ctrl + Shift + Delete'
                  })`}
                  width={225}
                  location="top"
                >
                  <Button
                    aria-label={`${intl('InstantSearch.ClearAll')} (${
                      isMac() ? 'Cmd + Shift + Delete' : 'Ctrl + Shift + Delete'
                    })`}
                    tid="is-clear-all"
                    size="small"
                    type="nofill"
                    text={intl('InstantSearch.ClearAll')}
                    onClick={_.partial(this.handleDeleteAllSearchItems, 'click')}
                  />
                </Tooltip>
              </div>
            )}
            <ul
              ref="listRef"
              className="InstantSearch-suggestions"
              style={{
                height: suggestions.length ? `calc(${suggestions.length} * 42px)` : 0,
                overflowY: suggestions.length >= 7 ? 'auto' : 'hidden',
              }}
            >
              {suggestions}
            </ul>
            {suggestions.length > 0 && (
              <div className="InstantSearch-shortcuts">
                <span className="InstantSearch-shortcut">↑</span>
                <span className="InstantSearch-shortcut">↓</span>
                <span className="InstantSearch-shortcutText">to navigate</span>
                <span className="InstantSearch-shortcutOffset">↵</span>
                <span className="InstantSearch-shortcutText">to select</span>
                <span className="InstantSearch-shortcut">esc</span>
                <span className="InstantSearch-shortcutText">to close</span>
              </div>
            )}
          </div>
        </div>
      </Dialog>
    );
  },
});
