/**
 * Copyright 2014 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import React from 'react';
import intl from '@illumio-shared/utils/intl';
import {Icon, Label, Vulnerability} from '..';
import {Link} from 'react-router';
import Tooltip from '../Tooltip.jsx';
import WorkloadStore from '../../stores/WorkloadStore';
import MapPageStore from '../../stores/MapPageStore';
import TrafficStore from '../../stores/TrafficStore';
import SessionStore from '../../stores/SessionStore';
import HealthStore from '../../stores/HealthStore';
import LabelStore from '../../stores/LabelStore';
import {RenderUtils, RestApiUtils, WorkloadUtils, ServiceUtils} from '../../utils';
import {getPolicyIntlByState, getWorkloadStatusIntl} from '@illumio-shared/utils/intl/dynamic';
import SetPolicyState from './SetPolicyStatePanel';
import AssignRolePanel from './AssignRolePanel';
import actionCreators from '../../actions/actionCreators';

export default React.createClass({
  getInitialState() {
    return {
      editPolicyState: false,
      editRole: false,
      labelDisplayName: LabelStore.getLabelDisplayName(),
    };
  },

  componentDidMount() {
    const id = _.last(this.props.data.href.split('/'));

    if (this.props.data.subType === 'container') {
      _.defer(() => {
        RestApiUtils.workloads.getCollection({}, true);
        RestApiUtils.containerWorkloads.getInstance(id);
      });
    } else if (
      !WorkloadStore.getSpecified(this.props.data.href) ||
      _.isEmpty(WorkloadStore.getSpecified(this.props.data.href).services)
    ) {
      const workloadsIsReadable = this.props.data.caps.workloads.includes('read');

      if (workloadsIsReadable) {
        _.defer(() => RestApiUtils.workloads.getInstance(id, {representation: 'workload_labels_services'}, true));
      }
    }
  },

  shouldComponentUpdate(nextProps) {
    const data = nextProps.data;
    const id = _.last(data.href.split('/'));

    // This could be improved if we had an api to get the workload with a given agent href
    if (data.subType === 'container' && data.href !== this.props.data.href) {
      _.defer(() => {
        RestApiUtils.workloads.getCollection({}, true);
        RestApiUtils.containerWorkloads.getInstance(id);
      });
    } else if (
      data.subType !== 'container' &&
      (!WorkloadStore.getSpecified(data.href) || !WorkloadStore.getSpecified(data.href).services)
    ) {
      // if the node we're trying to display isn't in WorkloadStore, go get it from Avenger
      const workloadsIsReadable = this.props.data.caps.workloads.includes('read');

      if (workloadsIsReadable) {
        _.defer(() => RestApiUtils.workloads.getInstance(id, {representation: 'workload_labels_services'}, true));
      }

      return false;
    }

    if (data.subType !== 'container' && this.props.data.name !== data.name) {
      // Update services data if the selected workload changes.
      const workloadsIsReadable = this.props.data.caps.workloads.includes('read');

      if (workloadsIsReadable) {
        _.defer(() => RestApiUtils.workloads.getInstance(id, {representation: 'workload_labels_services'}, true));
      }

      this.setState({
        editPolicyState: false,
        editRole: false,
      });
    }

    return true;
  },

  renderRoleLabel(label, subType) {
    const {caps} = this.props.data;
    const {labelDisplayName} = this.state;
    const workloadsIsWritable = caps.workloads.includes('write');
    const disableEditRole = !workloadsIsWritable || subType === 'container';

    const roleLabel = (
      <td className="MapInfoPanel-Row-Value MapInfoPanel-Row-Value-Overflow" data-tid="map-info-panel-row-value">
        {label ? <Label type={label.key} text={label.value} /> : intl('Common.NoRole')}
        {!disableEditRole && (
          <span className="Icon-Edit">
            <Icon name="edit" onClick={this.handleEditRole} tid="edit-role-label" />
          </span>
        )}
      </td>
    );

    const roleData = {
      href: this.props.data.href,
      labels: this.props.data.labels,
      currentRole: label && label.value,
      fullMap: MapPageStore.getMapType() === 'full',
    };

    const editRole = (
      <td className="MapInfoPanel-Row-Value MapInfoPanel-Row-Value-Overflow" data-tid="map-info-panel-row-value">
        <AssignRolePanel onClose={this.handleCloseRole} data={roleData} />
      </td>
    );

    return (
      <tr className="MapInfoPanel-Row" data-tid="map-info-panel-row">
        <th className="MapInfoPanel-Row-Label" data-tid="map-info-panel-row-label">
          <div className={this.state.editRole ? 'MapInfoPanel-edit' : 'MapInfoPanel-role'}>
            {label && labelDisplayName[label.key]}
          </div>
        </th>
        {!this.state.editRole && roleLabel}
        {this.state.editRole && editRole}
      </tr>
    );
  },

  handleEditRole() {
    this.setState({editRole: !this.state.editRole, editPolicyState: false});
  },

  handleCloseRole() {
    this.setState({editRole: false});
  },

  handleEditPolicyState() {
    this.setState({editPolicyState: !this.state.editPolicyState, editRole: false});
  },

  handleClosePolicyState() {
    this.setState({editPolicyState: false});
  },

  selectVulnerability(vulnerability) {
    _.defer(() => {
      actionCreators.selectVulnerability({
        type: 'vulnerability',
        formData: vulnerability,
      });
    });
  },

  render() {
    const {data} = this.props;

    let services = null;
    let serviceHeader = null;
    let ipAddress = data.ipAddress;

    const {caps} = this.props.data;
    const workloadsIsWritable = caps.workloads.includes('write');
    const workloadsIsReadable = caps.workloads.includes('read');

    if (data.services && data.services.length && !data.vulnerabilities) {
      data.services.forEach(service => {
        if (service.serviceName.length > 35) {
          service.tooltipName = service.serviceName.replaceAll('\\\\', '').slice(0);
        }

        service.serviceName = RenderUtils.truncateAppGroupName(service.serviceName.replaceAll('\\\\', ''), 35, [35]);
      });

      services = _.map(data.services, (service, index) => (
        <tr className="MapSubInfoPanel-Row MapSubInfoPanel-Row--NoHover--NoPointer" data-tid="map-sub-info-panel-row">
          <th className="MapSubInfoPanel-Row-Label MapSubInfoPanel-Row-Label-Long">
            <Tooltip content={service.tooltipName} width={372} location="services">
              <div
                key={`${service.serviceName}-${index}`}
                className="MapSubInfoPanel-Row-Width-Long"
                data-tid="map-sub-info-panel-row-label"
              >
                {service.serviceName}
              </div>
            </Tooltip>
          </th>
          <td
            key={`${service.protocol}-${index}`}
            className="MapSubInfoPanel-Row-Value MapSubInfoPanel-Row--TrafficPadding"
            data-tid="map-sub-info-panel-row-value"
          >
            {service.port} {service.protocol}
          </td>
        </tr>
      ));

      serviceHeader = (
        <tr
          className="MapSubInfoPanel-Header MapSubInfoPanel-Row--NoHover--NoPointer"
          data-tid="map-info-panel-row-header"
        >
          <th colSpan="2" className="MapInfoPanel-Row-Label">{`${services.length} ${intl('Common.Services')}`}</th>
        </tr>
      );

      services = (
        <tr className="MapInfoPanel-Row" data-tid="map-info-panel-entities-row">
          <td colSpan="2">
            <div className="MapInfoPanel-Row--Scroll">
              <table className="MapSubInfoPanel" data-tid="map-sub-info-panel">
                <tbody>
                  {serviceHeader}
                  {services}
                </tbody>
              </table>
            </div>
          </td>
        </tr>
      );
    }

    const roleLabel = this.renderRoleLabel(data.labels.role, data.subType);
    let group;

    if (data.group) {
      const groupParams = {id: data.group.href};
      let groupName = data.group.name;
      let tooltipContent;
      const currentRoute = MapPageStore.getMapRoute();
      const isCurrentFocused =
        currentRoute.type === 'focused' ||
        (currentRoute.prevtype === 'focused' && currentRoute.previd === groupParams.id);

      if (groupName === 'discovered' || data.group.discovered) {
        groupName = intl('Map.DiscoveredGroup');
      }

      if (groupName.length > 40) {
        tooltipContent = groupName;
      }

      let groupType = intl('Common.Group');

      let groupLink = (
        <div className="MapInfoPanel-Row-Group" data-tid="map-info-panel-row-value">
          <Link to="groupWorkloads" className="MapInfoPanel-Link" params={groupParams}>
            {RenderUtils.truncateAppGroupName(groupName, 40, [20, 10, 10])}
          </Link>
        </div>
      );

      if (MapPageStore.getMapType() === 'app') {
        groupType = intl('Common.Application');
        groupLink = (
          <div className="MapInfoPanel-Row-Group" data-tid="map-info-panel-row-value">
            {workloadsIsReadable && !isCurrentFocused ? (
              <Link to="appMapLevel" className="MapInfoPanel-Link" params={{type: 'focused', ...groupParams}}>
                {RenderUtils.truncateAppGroupName(groupName, 40, [20, 10, 10])}
              </Link>
            ) : (
              RenderUtils.truncateAppGroupName(groupName, 40, [20, 10, 10])
            )}
          </div>
        );
      }

      if (groupName) {
        group = (
          <tr className="MapInfoPanel-Row" data-tid="map-info-panel-row">
            <th className="MapInfoPanel-Row-Label">
              <span data-tid="map-info-panel-row-label">{groupType}</span>
            </th>
            <td className="MapInfoPanel-Row-Value MapInfoPanel-Row-Value-Overflow">
              {tooltipContent ? (
                <Tooltip content={tooltipContent} position="workload" width={372} location="bottomright">
                  {groupLink}
                </Tooltip>
              ) : (
                groupLink
              )}
            </td>
          </tr>
        );
      }
    }

    let location;

    if (
      MapPageStore.getMapType() === 'app' &&
      TrafficStore.getAppGroupsType().length === 2 &&
      data.labels.loc &&
      data.labels.loc.href
    ) {
      const locationName = data.labels.loc && data.labels.loc.value;
      const locationFull = MapPageStore.getLocMapType() === 'full';

      location = (
        <tr className="MapInfoPanel-Row" data-tid="map-info-panel-row">
          <th className="MapInfoPanel-Row-Label">
            <span data-tid="map-info-panel-row-label">{intl('Common.Location')}</span>
          </th>
          <td className="MapInfoPanel-Row-Value MapInfoPanel-Row-Value-Overflow" data-tid="map-info-panel-row-value">
            <div className="MapInfoPanel-Row-Group" data-tid="map-info-panel-row-value">
              {data.labels.loc ? (
                workloadsIsReadable ? (
                  <Link
                    to={locationFull ? 'map' : 'mapLevel'}
                    className="MapInfoPanel-Link"
                    params={locationFull ? {} : {type: 'location', id: data.labels.loc.href.split('/').pop()}}
                  >
                    {RenderUtils.truncateAppGroupName(locationName, 40, [40])}
                  </Link>
                ) : (
                  RenderUtils.truncateAppGroupName(locationName, 40, [40])
                )
              ) : (
                intl('Common.None')
              )}
            </div>
          </td>
        </tr>
      );
    }

    // All the different possible policy values are: bulding, testing, enforced, none
    // And flow visibility are: flow_summary, flow_drops, flow_off
    let policyState = null;

    if (data.policyState) {
      const policyStateData = {
        type: 'workload',
        subType: data.subType,
        href: data.href,
        currentState: data.policyState,
      };

      const editPolicyStatePanel = (
        <td
          className="MapInfoPanel-Row-Value MapInfoPanel-Row-Value-Overflow MapInfoPanel-Row-Value-Enforcement"
          data-tid="map-info-panel-row-value"
        >
          <SetPolicyState onClose={this.handleClosePolicyState} data={policyStateData} />
        </td>
      );

      const {editPolicyState} = this.state;

      const disablePolicyState = !workloadsIsWritable || this.props.data.subType === 'container';

      const displayPolicyState = (
        <td className="MapInfoPanel-Row-Value MapInfoPanel-Row-Value-Overflow" data-tid="map-info-panel-row-value">
          {getPolicyIntlByState(data.policyState)}
          {!disablePolicyState && (
            <span className="Icon-Edit">
              <Icon onClick={this.handleEditPolicyState} name="edit" tid="edit-policy-state" />
            </span>
          )}
        </td>
      );

      policyState = workloadsIsReadable ? (
        <tr className="MapInfoPanel-Row" data-tid="map-info-panel-row">
          <th className="MapInfoPanel-Row-Label" data-tid="map-info-panel-row-label">
            <div className={editPolicyState ? 'MapInfoPanel-edit' : 'MapInfoPanel-role'}>
              {intl('Common.Enforcement')}
            </div>
          </th>
          {editPolicyState && editPolicyStatePanel}
          {!editPolicyState && displayPolicyState}
        </tr>
      ) : null;
    }

    // This will return the react element with the health tag
    const policyStatusTag = WorkloadUtils.workloadHealthTag(
      data.agent,
      {labelOnly: true, withIcon: true, isSuperclusterMember: SessionStore.isSuperclusterMember()},
      HealthStore.getLocalData(),
      data.online,
    );
    let venHealth = null;

    if (policyStatusTag) {
      venHealth = (
        <tr className="MapInfoPanel-Row" data-tid="map-info-panel-row">
          <th className="MapInfoPanel-Row-Label" data-tid="map-info-panel-row-label">
            {intl('Workloads.PolicySync')}
          </th>
          <td className="MapInfoPanel-Row-Value" data-tid="map-info-panel-row-value">
            {policyStatusTag}
          </td>
        </tr>
      );
    }

    let workload;

    if (data.workload) {
      workload = (
        <tr className="MapInfoPanel-Row" data-tid="map-info-panel-row">
          <th className="MapInfoPanel-Row-Label" data-tid="map-info-panel-row-label">
            {intl('Common.Workload')}
          </th>
          <td className="MapInfoPanel-Row-Value" data-tid="map-info-panel-row-value">
            <Link
              className="Grid-link"
              to="workloads.item"
              params={{id: data.workload.href.split('/').pop()}}
              data-tid="map-info-panel-workload"
            >
              {WorkloadUtils.friendlyName(data.workload)}
            </Link>
          </td>
        </tr>
      );
    }

    // Different status values: online, offline, uninstalling, out_of_sync, none
    const statusIcon = data.status || null;
    const statusStyle = _.startCase(data.status) || null;
    let venConnectivity;

    if (workloadsIsReadable && data.status) {
      venConnectivity = (
        <tr className="MapInfoPanel-Row" data-tid="map-info-panel-row">
          <th className="MapInfoPanel-Row-Label" data-tid="map-info-panel-row-label">
            {intl('Common.Connectivity')}
          </th>
          <td className="MapInfoPanel-Row-Value" data-tid="map-info-panel-row-value">
            <Icon name={statusIcon} styleClass={statusStyle} position="before" />
            {getWorkloadStatusIntl(data.status || 'none')}
          </td>
        </tr>
      );
    }

    const pceHealth = !SessionStore.isUserScoped() && data.pce && (
      <tr className="MapInfoPanel-Row" data-tid="map-info-panel-row">
        <th className="MapInfoPanel-Row-Label" data-tid="map-info-panel-row-label">
          {intl('Health.PCEHealth')}
        </th>
        <td className="MapInfoPanel-Row-Value" data-tid="map-info-panel-row-value">
          {data.pceHealth}
        </td>
      </tr>
    );

    if (Array.isArray(ipAddress) && ipAddress.length) {
      ipAddress = (
        <tr className="MapInfoPanel-Row" data-tid="map-info-panel-row">
          <th className="MapInfoPanel-Row-Label" data-tid="map-info-panel-row-label">
            {intl('Common.IPAddress')}
          </th>
          <td className="MapInfoPanel-Row-Value" data-tid="map-info-panel-row-value">
            {ipAddress.map(address => (
              <div className="MapInfoPanel-Address" key={address}>
                {address}
              </div>
            ))}
          </td>
        </tr>
      );
    }

    let vulnerabilities;

    if (data.vulnerabilities) {
      vulnerabilities = data.vulnerabilities.map(vulnerability => (
        <tr
          className="MapSubInfoPanel-Row"
          onClick={_.partial(this.selectVulnerability, {...vulnerability})}
          data-tid="vulnerability-sub-info-panel-row"
        >
          <td
            className="MapSubInfoPanel-Row-Label MapSubInfoPanel-Row-Width-Vulnerability"
            data-tid="map-info-panel-row-value-vulnerability-name"
          >
            <span className="MapSubInfoPanel-Row" data-tid="map-sub-info-panel-row">
              <Tooltip content={vulnerability.details.name} position="location-group" width={372} location="fixed">
                {vulnerability.details.name}
              </Tooltip>
            </span>
          </td>
          <td className="MapSubTrafficPanel-Row-Port-Protocol">
            {vulnerability.port || vulnerability.protocol
              ? `${vulnerability.port ? vulnerability.port : ''} ${
                  vulnerability.protocol ? ServiceUtils.lookupProtocol(vulnerability.protocol) : ''
                }`
              : intl('Common.NA')}
          </td>
          <td className="MapSubInfoPanel-Row-Value-Vulnerability">
            <Vulnerability vulnerability={{...vulnerability}} opacity />
            <Icon name="next" size="xxlarge" position="after" tid="nav-to-vulnerability" />
          </td>
        </tr>
      ));
      vulnerabilities = workloadsIsReadable ? (
        <tr className="MapInfoPanel-Row" data-tid="map-info-panel-entities-row">
          <td colSpan="2">
            <div className="MapInfoPanel-Row--Scroll">
              <table className="MapSubInfoPanel MapSubInfoPanel-Row-Auto-Layout">
                <tbody>
                  <tr className="MapSubInfoPanel-Header" data-tid="map-info-panel-row-header">
                    <th colSpan="2" className="MapInfoPanel-Row-Label" data-tid="map-info-panel-row-label">
                      {`${data.vulnerabilities.length} ${intl('Common.Vulnerabilities')}`}
                    </th>
                  </tr>
                  {vulnerabilities}
                </tbody>
              </table>
            </div>
          </td>
        </tr>
      ) : null;
    }

    return (
      <table className="MapInfoPanel" data-tid="map-info-panel-table">
        <tbody>
          {group}
          {location}
          {roleLabel}
          {services}
          {policyState}
          {workload}
          {venHealth}
          {data.subType !== 'container' && venConnectivity}
          {pceHealth}
          {vulnerabilities}
          {ipAddress}
        </tbody>
      </table>
    );
  },
});
