/**
 * Copyright 2016 Illumio, Inc. All Rights Reserved.
 */
import intl from '@illumio-shared/utils/intl';
import Icon from '../Icon.jsx';

//This file maps all the routes to a list of help menu items
//Each help menu item is an object with a title and subtitle
export default {
  map: [
    {
      title: intl('Map.ReportedView'),
      subtitle: intl('Help.Desc.ReportedView'),
    },
    {
      title: intl('Common.IlluminationMap'),
      subtitle: intl('Help.Desc.IlluminationMap'),
    },
    {
      title: intl('Common.Location'),
      subtitle: intl('Help.Desc.NoHelp'),
    },
    {
      title: intl('Common.Workload'),
      subtitle: intl('Help.Desc.Workload'),
    },
    {
      title: intl('Common.Group'),
      subtitle: intl('Help.Desc.NoHelp'),
    },
    {
      title: intl('Common.Enforcement'),
      subtitle: intl('Help.Desc.Enforcement'),
    },
    {
      title: intl('Map.ConnectedGroup'),
      subtitle: intl('Help.Desc.ConnectedGroup'),
    },
    {
      title: intl('Common.Provision'),
      subtitle: intl('Help.Desc.Provision'),
    },
  ],
  mapDraft: [
    {
      title: intl('Map.DraftView'),
      subtitle: intl('Help.Desc.DraftView'),
    },
    {
      title: intl('Explorer.Policy'),
      subtitle: intl('Help.Desc.Rulesets'),
    },
    {
      title: intl('Common.Service'),
      subtitle: intl('Help.Desc.Services'),
    },
    {
      title: intl('Common.Destinations'),
      subtitle: intl('Help.Desc.Destination'),
    },
    {
      title: intl('Common.Sources'),
      subtitle: intl('Help.Desc.Source'),
    },
    {
      title: intl('Common.SecureConnect'),
      subtitle: intl('Help.Desc.SecureConnect'),
    },
    {
      title: intl('Common.Provision'),
      subtitle: intl('Help.Desc.Provision'),
    },
    {
      title: intl('Help.Title.TrafficLines'),
      subtitle: intl('Help.Desc.TrafficLines'),
    },
  ],
  appMap: [
    {
      title: intl('Common.Application'),
      subtitle: intl('Help.Desc.Application'),
    },
    {
      title: intl('Common.Workload'),
      subtitle: intl('Help.Desc.Workload'),
    },
    {
      title: intl('Common.Enforcement'),
      subtitle: intl('Help.Desc.Enforcement'),
    },
    {
      title: intl('Map.ConsumingApplications'),
      subtitle: intl('Help.Desc.ConsumingApplications'),
    },
    {
      title: intl('Map.ProvidingApplications'),
      subtitle: intl('Help.Desc.ProvidingApplications'),
    },
    {
      title: intl('Common.Service'),
      subtitle: intl('Help.Desc.Services'),
    },
    {
      title: intl('Explorer.Policy'),
      subtitle: intl('Help.Desc.Rulesets'),
    },
    {
      title: intl('Help.Title.TrafficLines'),
      subtitle: intl('Help.Desc.TrafficLines'),
    },
  ],
  appMapDraft: [
    {
      title: intl('Common.Application'),
      subtitle: intl('Help.Desc.Application'),
    },
    {
      title: intl('Common.Workload'),
      subtitle: intl('Help.Desc.Workload'),
    },
    {
      title: intl('Common.Enforcement'),
      subtitle: intl('Help.Desc.Enforcement'),
    },
    {
      title: intl('Map.ConsumingApplications'),
      subtitle: intl('Help.Desc.ConsumingApplications'),
    },
    {
      title: intl('Map.ProvidingApplications'),
      subtitle: intl('Help.Desc.ProvidingApplications'),
    },
    {
      title: intl('Common.Service'),
      subtitle: intl('Help.Desc.Services'),
    },
    {
      title: intl('Explorer.Policy'),
      subtitle: intl('Help.Desc.Rulesets'),
    },
    {
      title: intl('Common.Provision'),
      subtitle: intl('Help.Desc.Provision'),
    },
    {
      title: intl('Help.Title.Rule'),
      subtitle: intl('Help.Desc.Rule'),
    },
    {
      title: intl('Common.Destinations'),
      subtitle: intl('Help.Desc.Destination'),
    },
    {
      title: intl('Common.Sources'),
      subtitle: intl('Help.Desc.Source'),
    },
    {
      title: intl('Common.SecureConnect'),
      subtitle: intl('Help.Desc.SecureConnect'),
    },
    {
      title: intl('Help.Title.TrafficLines'),
      subtitle: intl('Help.Desc.TrafficLines'),
    },
  ],
  appMapVulnerability: [
    {
      title: intl('Vulnerability.VulnerabilityExposureScore'),
      subtitle: `${intl('Help.Desc.VEScore')} ${intl('Help.Desc.VEScoreMultipleAggregation')}`,
    },
    {
      title: intl('Vulnerability.Score'),
      subtitle: intl('Help.Desc.VulnerabilityScore'),
    },
    {
      title: intl('Common.ExposureScore'),
      subtitle: intl('Help.Desc.ExposureScore'),
    },
    {
      title: intl('Vulnerability.EWExposure'),
      subtitle: intl('Help.Desc.EWExposure'),
    },
    {
      title: (
        <span className="HelpCards-Icon">
          <Icon name="internet" size="xlarge" position="before" /> {intl('Vulnerability.InternetExposure')}
        </span>
      ),
      subtitle: intl('Help.Desc.InternetExposure'),
    },
    {
      title: intl('Common.Severity'),
      subtitle: (
        <div>
          {intl('Help.Desc.Severity')}
          <div className="Vulnerability-Text--info">{intl('Help.Desc.SeverityInfo')}</div>
          <div className="Vulnerability-Text--low">{intl('Help.Desc.SeverityLow')}</div>
          <div className="Vulnerability-Text--medium">{intl('Help.Desc.SeverityMedium')}</div>
          <div className="Vulnerability-Text--high">{intl('Help.Desc.SeverityHigh')}</div>
          <div className="Vulnerability-Text--critical">{intl('Help.Desc.SeverityCritical')}</div>
          <div>{intl('Help.Desc.ApplicationMapSeverity')}</div>
        </div>
      ),
    },
    {
      title: intl('Common.Application'),
      subtitle: intl('Help.Desc.Application'),
    },
    {
      title: intl('Common.Workload'),
      subtitle: intl('Help.Desc.Workload'),
    },
    {
      title: intl('Common.Enforcement'),
      subtitle: intl('Help.Desc.Enforcement'),
    },
    {
      title: intl('Map.ConsumingApplications'),
      subtitle: intl('Help.Desc.ConsumingApplications'),
    },
    {
      title: intl('Map.ProvidingApplications'),
      subtitle: intl('Help.Desc.ProvidingApplications'),
    },
    {
      title: intl('Common.Service'),
      subtitle: intl('Help.Desc.Services'),
    },
    {
      title: intl('Explorer.Policy'),
      subtitle: intl('Help.Desc.Rulesets'),
    },
    {
      title: intl('Help.Title.TrafficLines'),
      subtitle: intl('Help.Desc.TrafficLines'),
    },
  ],
  appMapVulnerabilityDraft: [
    {
      title: intl('Vulnerability.VulnerabilityExposureScore'),
      subtitle: `${intl('Help.Desc.VEScore')} ${intl('Help.Desc.VEScoreMultipleAggregation')}`,
    },
    {
      title: intl('Vulnerability.Score'),
      subtitle: intl('Help.Desc.VulnerabilityScore'),
    },
    {
      title: intl('Common.ExposureScore'),
      subtitle: intl('Help.Desc.ExposureScore'),
    },
    {
      title: intl('Vulnerability.EWExposure'),
      subtitle: intl('Help.Desc.EWExposure'),
    },
    {
      title: (
        <span className="HelpCards-Icon">
          <Icon name="internet" size="xlarge" position="before" /> {intl('Vulnerability.InternetExposure')}
        </span>
      ),
      subtitle: intl('Help.Desc.InternetExposure'),
    },
    {
      title: intl('Common.Severity'),
      subtitle: (
        <div>
          {intl('Help.Desc.Severity')}
          <div className="Vulnerability-Text--info">{intl('Help.Desc.SeverityInfo')}</div>
          <div className="Vulnerability-Text--low">{intl('Help.Desc.SeverityLow')}</div>
          <div className="Vulnerability-Text--medium">{intl('Help.Desc.SeverityMedium')}</div>
          <div className="Vulnerability-Text--high">{intl('Help.Desc.SeverityHigh')}</div>
          <div className="Vulnerability-Text--critical">{intl('Help.Desc.SeverityCritical')}</div>
          <div>{intl('Help.Desc.ApplicationMapSeverity')}</div>
        </div>
      ),
    },
    {
      title: intl('Common.Application'),
      subtitle: intl('Help.Desc.Application'),
    },
    {
      title: intl('Common.Workload'),
      subtitle: intl('Help.Desc.Workload'),
    },
    {
      title: intl('Common.Enforcement'),
      subtitle: intl('Help.Desc.Enforcement'),
    },
    {
      title: intl('Map.ConsumingApplications'),
      subtitle: intl('Help.Desc.ConsumingApplications'),
    },
    {
      title: intl('Map.ProvidingApplications'),
      subtitle: intl('Help.Desc.ProvidingApplications'),
    },
    {
      title: intl('Common.Service'),
      subtitle: intl('Help.Desc.Services'),
    },
    {
      title: intl('Explorer.Policy'),
      subtitle: intl('Help.Desc.Rulesets'),
    },
    {
      title: intl('Common.Provision'),
      subtitle: intl('Help.Desc.Provision'),
    },
    {
      title: intl('Help.Title.Rule'),
      subtitle: intl('Help.Desc.Rule'),
    },
    {
      title: intl('Common.Destinations'),
      subtitle: intl('Help.Desc.Destination'),
    },
    {
      title: intl('Common.Sources'),
      subtitle: intl('Help.Desc.Source'),
    },
    {
      title: intl('Common.SecureConnect'),
      subtitle: intl('Help.Desc.SecureConnect'),
    },
    {
      title: intl('Help.Title.TrafficLines'),
      subtitle: intl('Help.Desc.TrafficLines'),
    },
  ],
  appGroupDetailsVulnerability: [
    {
      title: intl('Common.Workload'),
      subtitle: intl('Help.Desc.Workload'),
    },
    {
      title: intl('Vulnerability.VulnerabilityExposureScore'),
      subtitle: `${intl('Help.Desc.VEScore')} ${intl('Help.Desc.VEScoreApplicationAggregation')}`,
    },
    {
      title: intl('Vulnerability.Score'),
      subtitle: intl('Help.Desc.VulnerabilityScore'),
    },
    {
      title: intl('Common.ExposureScore'),
      subtitle: intl('Help.Desc.ExposureScore'),
    },
    {
      title: intl('Vulnerability.EWExposure'),
      subtitle: intl('Help.Desc.EWExposure'),
    },
    {
      title: (
        <span className="HelpCards-Icon">
          <Icon name="internet" size="xlarge" position="before" /> {intl('Vulnerability.InternetExposure')}
        </span>
      ),
      subtitle: intl('Help.Desc.InternetExposure'),
    },
  ],
  segmentationTemplates: [
    {
      title: intl('SegmentationTemplates.SegmentationTemplate'),
      subtitle: intl('Help.Desc.SegmentationTemplate'),
    },
    {
      title: intl('Common.Policies'),
      subtitle: intl('Help.Desc.Rulesets'),
    },
    {
      title: intl('Common.Services'),
      subtitle: intl('Help.Desc.Services'),
    },
    {
      title: intl('Role.Label'),
      subtitle: intl('Help.Desc.RoleLabel'),
    },
    {
      title: intl('Labels.Application'),
      subtitle: intl('Help.Desc.ApplicationLabel'),
    },
    {
      title: intl('Labels.Environment'),
      subtitle: intl('Help.Desc.EnvironmentLabel'),
    },
    {
      title: intl('Labels.Location'),
      subtitle: intl('Help.Desc.LocationLabel'),
    },
    {
      title: intl('Labels.Group'),
      subtitle: intl('Help.Desc.LabelGroup'),
    },
    {
      title: intl('Common.IPList'),
      subtitle: intl('Help.Desc.IPList'),
    },
  ],
  policygenerator: [
    {
      title: intl('ApplicationsCoverage.IntraScope'),
      subtitle: intl('Help.Desc.IntraScope'),
    },
    {
      title: intl('ApplicationsCoverage.ExtraScope'),
      subtitle: intl('Help.Desc.ExtraScope'),
    },
    {
      title: intl('Help.Title.RuleCoverage'),
      subtitle: intl('Help.Desc.RuleCoverage'),
    },
    {
      title: intl('Help.Title.Microsegmentation'),
      subtitle: intl('Help.Desc.Microsegmentation'),
    },
    {
      title: intl('Help.Title.Nanosegmentation'),
      subtitle: intl('Help.Desc.Nanosegmentation'),
    },
    {
      title: intl('Help.Title.Connection'),
      subtitle: intl('Help.Desc.Connection'),
    },
  ],
  policygeneratorConfigureIntraScopeVulnerability: [
    {
      title: intl('ApplicationsCoverage.IntraScope'),
      subtitle: intl('Help.Desc.IntraScope'),
    },
    {
      title: intl('Vulnerability.IntraScopeExposure'),
      subtitle: intl('Help.Desc.IntraScopeVulnerabilityExposure'),
    },
    {
      title: intl('PolicyGenerator.Constrained'),
      subtitle: intl('Help.Desc.IntraScopeConstrained'),
    },
    {
      title: intl('Common.Denied'),
      subtitle: intl('Help.Desc.IntraScopeBlocked'),
    },
    {
      title: intl('Common.Severity'),
      subtitle: (
        <div>
          {intl('Help.Desc.Severity')}
          <div className="Vulnerability-Text--info">{intl('Help.Desc.SeverityInfo')}</div>
          <div className="Vulnerability-Text--low">{intl('Help.Desc.SeverityLow')}</div>
          <div className="Vulnerability-Text--medium">{intl('Help.Desc.SeverityMedium')}</div>
          <div className="Vulnerability-Text--high">{intl('Help.Desc.SeverityHigh')}</div>
          <div className="Vulnerability-Text--critical">{intl('Help.Desc.SeverityCritical')}</div>
          <div>{intl('Help.Desc.PolicyGeneratorSeverity')}</div>
        </div>
      ),
    },
    {
      title: intl('Vulnerability.Exposed'),
      subtitle: intl('Help.Desc.ExposedVulnerabilities'),
    },
    {
      title: intl('Help.Title.RuleCoverage'),
      subtitle: intl('Help.Desc.RuleCoverage'),
    },
    {
      title: intl('Help.Title.Microsegmentation'),
      subtitle: intl('Help.Desc.Microsegmentation'),
    },
    {
      title: intl('Help.Title.Nanosegmentation'),
      subtitle: intl('Help.Desc.Nanosegmentation'),
    },
    {
      title: intl('Help.Title.Connection'),
      subtitle: intl('Help.Desc.Connection'),
    },
  ],
  policygeneratorConfigureExtraScopeVulnerability: [
    {
      title: intl('ApplicationsCoverage.ExtraScope'),
      subtitle: intl('Help.Desc.ExtraScope'),
    },
    {
      title: intl('Vulnerability.ExtraScopeExposure'),
      subtitle: intl('Help.Desc.ExtraScopeVulnerabilityExposure'),
    },
    {
      title: intl('PolicyGenerator.Constrained'),
      subtitle: intl('Help.Desc.ExtraScopeConstrained'),
    },
    {
      title: intl('Common.Denied'),
      subtitle: intl('Help.Desc.ExtraScopeBlocked'),
    },
    {
      title: intl('Common.Severity'),
      subtitle: (
        <div>
          {intl('Help.Desc.Severity')}
          <div className="Vulnerability-Text--info">{intl('Help.Desc.SeverityInfo')}</div>
          <div className="Vulnerability-Text--low">{intl('Help.Desc.SeverityLow')}</div>
          <div className="Vulnerability-Text--medium">{intl('Help.Desc.SeverityMedium')}</div>
          <div className="Vulnerability-Text--high">{intl('Help.Desc.SeverityHigh')}</div>
          <div className="Vulnerability-Text--critical">{intl('Help.Desc.SeverityCritical')}</div>
          <div>{intl('Help.Desc.PolicyGeneratorSeverity')}</div>
        </div>
      ),
    },
    {
      title: intl('Vulnerability.Exposed'),
      subtitle: intl('Help.Desc.ExposedVulnerabilities'),
    },
    {
      title: intl('Help.Title.RuleCoverage'),
      subtitle: intl('Help.Desc.RuleCoverage'),
    },
    {
      title: intl('Help.Title.Microsegmentation'),
      subtitle: intl('Help.Desc.Microsegmentation'),
    },
    {
      title: intl('Help.Title.Nanosegmentation'),
      subtitle: intl('Help.Desc.Nanosegmentation'),
    },
    {
      title: intl('Help.Title.Connection'),
      subtitle: intl('Help.Desc.Connection'),
    },
  ],
  policygeneratorIpListConfigureVulnerability: [
    {
      title: intl('Vulnerability.InternetExposure'),
      subtitle: intl('Help.Desc.InternetVulnerabilityExposure'),
    },
    {
      title: intl('Vulnerability.InternetExposure'),
      subtitle: intl('Help.Desc.InternetConstrained'),
    },
    {
      title: intl('Common.None'),
      subtitle: intl('Help.Desc.InternetBlocked'),
    },
    {
      title: intl('Common.Severity'),
      subtitle: (
        <div>
          {intl('Help.Desc.Severity')}
          <div className="Vulnerability-Text--info">{intl('Help.Desc.SeverityInfo')}</div>
          <div className="Vulnerability-Text--low">{intl('Help.Desc.SeverityLow')}</div>
          <div className="Vulnerability-Text--medium">{intl('Help.Desc.SeverityMedium')}</div>
          <div className="Vulnerability-Text--high">{intl('Help.Desc.SeverityHigh')}</div>
          <div className="Vulnerability-Text--critical">{intl('Help.Desc.SeverityCritical')}</div>
          <div>{intl('Help.Desc.PolicyGeneratorSeverity')}</div>
        </div>
      ),
    },
    {
      title: intl('Vulnerability.Exposed'),
      subtitle: intl('Help.Desc.ExposedVulnerabilities'),
    },
    {
      title: intl('Help.Title.RuleCoverage'),
      subtitle: intl('Help.Desc.RuleCoverage'),
    },
    {
      title: intl('Help.Title.Microsegmentation'),
      subtitle: intl('Help.Desc.Microsegmentation'),
    },
    {
      title: intl('Help.Title.Nanosegmentation'),
      subtitle: intl('Help.Desc.Nanosegmentation'),
    },
    {
      title: intl('Help.Title.Connection'),
      subtitle: intl('Help.Desc.Connection'),
    },
  ],
  services: [
    {
      title: intl('Common.Services'),
      subtitle: intl('Help.Desc.Services'),
    },
    {
      title: intl('Common.ProvisionStatus'),
      subtitle: intl('Help.Desc.ProvisionStatus'),
    },
    {
      title: intl('Common.Provision'),
      subtitle: intl('Help.Desc.Provision'),
    },
  ],
  iplists: [
    {
      title: intl('Common.IPList'),
      subtitle: intl('Help.Desc.IPList'),
    },
    {
      title: intl('Common.ProvisionStatus'),
      subtitle: intl('Help.Desc.ProvisionStatus'),
    },
    {
      title: intl('Common.Provision'),
      subtitle: intl('Help.Desc.Provision'),
    },
  ],
  labelGroups: [
    {
      title: intl('Labels.Group'),
      subtitle: intl('Help.Desc.LabelGroup'),
    },
    {
      title: intl('Common.ProvisionStatus'),
      subtitle: intl('Help.Desc.ProvisionStatus'),
    },
    {
      title: intl('Common.Provision'),
      subtitle: intl('Help.Desc.Provision'),
    },
  ],
  userGroups: [
    {
      title: intl('Common.UserGroups'),
      subtitle: intl('Help.Desc.UserGroup'),
    },
    {
      title: intl('UserGroups.SID'),
      subtitle: intl('Help.Desc.SID'),
    },
  ],
  virtualServers: [
    {
      title: intl('Common.VirtualServers'),
      subtitle: intl('Help.Desc.VirtualServers'),
    },
  ],
  settings: [
    {
      title: intl('Common.SecuritySettings'),
      subtitle: intl('Help.Desc.SecuritySettings'),
    },
  ],
  provision: [
    {
      title: intl('Common.Provision'),
      subtitle: intl('Help.Desc.Provision'),
    },
    {
      title: intl('Common.Revert'),
      subtitle: intl('Help.Desc.Revert'),
    },
  ],
  workloads: [
    {
      title: intl('Common.Workload'),
      subtitle: intl('Help.Desc.Workload'),
    },
    {
      title: intl('Role.Label'),
      subtitle: intl('Help.Desc.RoleLabel'),
    },
    {
      title: intl('Labels.Application'),
      subtitle: intl('Help.Desc.ApplicationLabel'),
    },
    {
      title: intl('Labels.Environment'),
      subtitle: intl('Help.Desc.EnvironmentLabel'),
    },
    {
      title: intl('Labels.Location'),
      subtitle: intl('Help.Desc.LocationLabel'),
    },
    {
      title: intl('Common.Enforcement'),
      subtitle: intl('Help.Desc.Enforcement'),
    },
    {
      title: intl('Common.Unmanaged'),
      subtitle: intl('Help.Desc.Unmanaged'),
    },
    {
      title: intl('Common.Managed'),
      subtitle: intl('Help.Desc.Managed'),
    },
  ],
  workloadDetails: [
    {
      title: intl('Workloads.VENConnectivity'),
      subtitle: intl('Help.Desc.VENConnectivity'),
    },
    {
      title: intl('Workloads.PolicySync'),
      subtitle: intl('Help.Desc.PolicySync'),
    },
    {
      title: intl('Common.Attributes'),
      subtitle: intl('Help.Desc.Attributes'),
    },
    {
      title: intl('Firewall.Coexistence.Title'),
      subtitle: intl('Help.Desc.FirewallCoexistence'),
    },
    {
      title: intl('Common.StaticPolicy'),
      subtitle: intl('Help.Desc.StaticPolicy'),
    },
    {
      title: intl('Settings.Containers.ContainerInheritHostPolicy'),
      subtitle: intl('Help.Desc.ContainerInheritHostPolicy'),
    },
    {
      title: intl('Common.BlockAction'),
      subtitle: intl('Help.Desc.RejectConnections'),
    },
  ],
  workloadDetailsVulnerabilities: [
    {
      title: intl('Vulnerability.VulnerabilityExposureScore'),
      subtitle: `${intl('Help.Desc.VEScore')} ${intl('Help.Desc.VEScoreWorkloadAggregation')}`,
    },
    {
      title: intl('Vulnerability.HighestVEScore'),
      subtitle: intl('Help.Desc.HighestVEScore'),
    },
    {
      title: intl('Vulnerability.Score'),
      subtitle: intl('Help.Desc.VulnerabilityScore'),
    },
    {
      title: intl('Common.ExposureScore'),
      subtitle: intl('Help.Desc.ExposureScore'),
    },
    {
      title: (
        <span className="HelpCards-Icon">
          <Icon name="internet" size="xlarge" position="before" /> {intl('Vulnerability.InternetExposure')}
        </span>
      ),
      subtitle: intl('Help.Desc.InternetExposure'),
    },
    {
      title: intl('Common.Severity'),
      subtitle: (
        <div>
          {intl('Help.Desc.Severity')}
          <div className="Vulnerability-Text--info">{intl('Help.Desc.SeverityInfo')}</div>
          <div className="Vulnerability-Text--low">{intl('Help.Desc.SeverityLow')}</div>
          <div className="Vulnerability-Text--medium">{intl('Help.Desc.SeverityMedium')}</div>
          <div className="Vulnerability-Text--high">{intl('Help.Desc.SeverityHigh')}</div>
          <div className="Vulnerability-Text--critical">{intl('Help.Desc.SeverityCritical')}</div>
        </div>
      ),
    },
    {
      title: intl('Workloads.VENConnectivity'),
      subtitle: intl('Help.Desc.VENConnectivity'),
    },
    {
      title: intl('Workloads.PolicySync'),
      subtitle: intl('Help.Desc.PolicySync'),
    },
    {
      title: intl('Common.Attributes'),
      subtitle: intl('Help.Desc.Attributes'),
    },
    {
      title: intl('Firewall.Coexistence.Title'),
      subtitle: intl('Help.Desc.FirewallCoexistence'),
    },
    {
      title: intl('Common.StaticPolicy'),
      subtitle: intl('Help.Desc.StaticPolicy'),
    },
    {
      title: intl('Settings.Containers.ContainerInheritHostPolicy'),
      subtitle: intl('Help.Desc.ContainerInheritHostPolicy'),
    },
    {
      title: intl('Common.BlockAction'),
      subtitle: intl('Help.Desc.RejectConnections'),
    },
  ],
  workloadVulnerabilities: [
    {
      title: intl('Vulnerability.VulnerabilityExposureScore'),
      subtitle: `${intl('Help.Desc.VEScore')} ${intl('Help.Desc.VEScoreWorkloadAggregation')}`,
    },
    {
      title: intl('Vulnerability.Score'),
      subtitle: intl('Help.Desc.VulnerabilityScore'),
    },
    {
      title: intl('Common.ExposureScore'),
      subtitle: intl('Help.Desc.ExposureScore'),
    },
    {
      title: intl('Vulnerability.EWExposure'),
      subtitle: intl('Help.Desc.EWExposure'),
    },
    {
      title: (
        <span className="HelpCards-Icon">
          <Icon name="internet" size="xlarge" position="before" /> {intl('Vulnerability.InternetExposure')}
        </span>
      ),
      subtitle: intl('Help.Desc.InternetExposure'),
    },
    {
      title: intl('Vulnerability.TotalVEScore'),
      subtitle: intl('Help.Desc.TotalWorkloadVEScore'),
    },
    {
      title: intl('Common.Severity'),
      subtitle: (
        <div>
          {intl('Help.Desc.Severity')}
          <div className="Vulnerability-Text--info">{intl('Help.Desc.SeverityInfo')}</div>
          <div className="Vulnerability-Text--low">{intl('Help.Desc.SeverityLow')}</div>
          <div className="Vulnerability-Text--medium">{intl('Help.Desc.SeverityMedium')}</div>
          <div className="Vulnerability-Text--high">{intl('Help.Desc.SeverityHigh')}</div>
          <div className="Vulnerability-Text--critical">{intl('Help.Desc.SeverityCritical')}</div>
        </div>
      ),
    },
    {
      title: intl('Vulnerability.ProvidedTraffic'),
      subtitle: intl('Help.Desc.ProvidedTraffic'),
    },
    {
      title: intl('Port.Protocol'),
      subtitle: intl('Help.Desc.VulnerabilityPortProtocol'),
    },
    {
      title: intl('Vulnerability.CVEIds'),
      subtitle: intl('Help.Desc.CVEIds'),
    },
    {
      title: intl('Common.Name'),
      subtitle: intl('Help.Desc.VulnerabilityName'),
    },
  ],
  appGroupVulnerabilities: [
    {
      title: intl('Vulnerability.VulnerabilityExposureScore'),
      subtitle: `${intl('Help.Desc.VEScore')} ${intl('Help.Desc.VEScoreApplicationAggregation')}`,
    },
    {
      title: intl('Vulnerability.Score'),
      subtitle: intl('Help.Desc.VulnerabilityScore'),
    },
    {
      title: intl('Vulnerability.EWExposure'),
      subtitle: intl('Help.Desc.EWExposure'),
    },
    {
      title: (
        <span className="HelpCards-Icon">
          <Icon name="internet" size="xlarge" position="before" /> {intl('Vulnerability.InternetExposure')}
        </span>
      ),
      subtitle: intl('Help.Desc.InternetExposure'),
    },
    {
      title: intl('Vulnerability.TotalVEScore'),
      subtitle: intl('Help.Desc.TotalApplicationVEScore'),
    },
    {
      title: intl('Vulnerability.Workloads'),
      subtitle: intl('Help.Desc.WorkloadCount'),
    },
    {
      title: intl('Port.Protocol'),
      subtitle: intl('Help.Desc.VulnerabilityPortProtocol'),
    },
    {
      title: intl('Vulnerability.CVEIds'),
      subtitle: intl('Help.Desc.CVEIds'),
    },
    {
      title: intl('Common.Name'),
      subtitle: intl('Help.Desc.VulnerabilityName'),
    },
  ],
  virtualServices: [
    {
      title: intl('Common.VirtualServices'),
      subtitle: intl('Help.Desc.VirtualServices'),
    },
    {
      title: intl('Role.Label'),
      subtitle: intl('Help.Desc.RoleLabel'),
    },
    {
      title: intl('Labels.Application'),
      subtitle: intl('Help.Desc.ApplicationLabel'),
    },
    {
      title: intl('Labels.Environment'),
      subtitle: intl('Help.Desc.EnvironmentLabel'),
    },
    {
      title: intl('Labels.Location'),
      subtitle: intl('Help.Desc.LocationLabel'),
    },
  ],
  labels: [
    {
      title: intl('Role.Label'),
      subtitle: intl('Help.Desc.RoleLabel'),
    },
    {
      title: intl('Labels.Application'),
      subtitle: intl('Help.Desc.ApplicationLabel'),
    },
    {
      title: intl('Labels.Environment'),
      subtitle: intl('Help.Desc.EnvironmentLabel'),
    },
    {
      title: intl('Labels.Location'),
      subtitle: intl('Help.Desc.LocationLabel'),
    },
  ],
  pairingProfiles: [
    {
      title: intl('PairingProfiles.Profile'),
      subtitle: intl('Help.Desc.PairingProfile'),
    },
    {
      title: intl('Role.Label'),
      subtitle: intl('Help.Desc.RoleLabel'),
    },
    {
      title: intl('Labels.Application'),
      subtitle: intl('Help.Desc.ApplicationLabel'),
    },
    {
      title: intl('Labels.Environment'),
      subtitle: intl('Help.Desc.EnvironmentLabel'),
    },
    {
      title: intl('Labels.Location'),
      subtitle: intl('Help.Desc.LocationLabel'),
    },
  ],
  loadBalancers: [
    {
      title: intl('Menu.LoadBalancers'),
      subtitle: intl('Help.Desc.LoadBalancers'),
    },
  ],
  blockedTraffic: [
    {
      title: intl('Common.Denied'),
      subtitle: intl('Help.Desc.Blocked'),
    },
    {
      title: intl('Common.PotentiallyBlocked'),
      subtitle: intl('Help.Desc.PotentiallyBlocked'),
    },
    {
      title: intl('Common.Destinations'),
      subtitle: intl('Help.Desc.Destination'),
    },
    {
      title: intl('Common.Sources'),
      subtitle: intl('Help.Desc.Source'),
    },
  ],
  orgevents: [
    {
      title: intl('Common.OrganizationEvents'),
      subtitle: intl('Help.Desc.OrgEvents'),
    },
  ],
  connectivity: [
    {
      title: intl('Policy.Check'),
      subtitle: intl('Help.Desc.PolicyCheck'),
    },
    {
      title: intl('Common.Destinations'),
      subtitle: intl('Help.Desc.Destination'),
    },
    {
      title: intl('Common.Sources'),
      subtitle: intl('Help.Desc.Source'),
    },
    {
      title: intl('Connectivity.PortAndProtocol'),
      subtitle: intl('Help.Desc.PortAndProtocol'),
    },
  ],
  rulesets: [
    {
      title: intl('Common.Policies'),
      subtitle: intl('Help.Desc.Rulesets'),
    },
    {
      title: intl('Common.Scope'),
      subtitle: intl('Help.Desc.Scope'),
    },
    {
      title: intl('Role.Label'),
      subtitle: intl('Help.Desc.RoleLabel'),
    },
    {
      title: intl('Labels.Application'),
      subtitle: intl('Help.Desc.ApplicationLabel'),
    },
    {
      title: intl('Labels.Environment'),
      subtitle: intl('Help.Desc.EnvironmentLabel'),
    },
    {
      title: intl('Labels.Location'),
      subtitle: intl('Help.Desc.LocationLabel'),
    },
    {
      title: intl('Common.ProvisionStatus'),
      subtitle: intl('Help.Desc.ProvisionStatus'),
    },
    {
      title: intl('Common.Status'),
      subtitle: intl('Help.Desc.Status'),
    },
  ],
  rulesetRules: [
    {
      title: intl('Explorer.Policy'),
      subtitle: intl('Help.Desc.Rulesets'),
    },
    {
      title: intl('Common.Scope'),
      subtitle: intl('Help.Desc.Scope'),
    },
    {
      title: intl('Role.Label'),
      subtitle: intl('Help.Desc.RoleLabel'),
    },
    {
      title: intl('Labels.Application'),
      subtitle: intl('Help.Desc.ApplicationLabel'),
    },
    {
      title: intl('Labels.Environment'),
      subtitle: intl('Help.Desc.EnvironmentLabel'),
    },
    {
      title: intl('Labels.Location'),
      subtitle: intl('Help.Desc.LocationLabel'),
    },
    {
      title: intl('Common.ProvisionStatus'),
      subtitle: intl('Help.Desc.ProvisionStatus'),
    },
    {
      title: intl('Common.Destinations'),
      subtitle: intl('Help.Desc.Destination'),
    },
    {
      title: intl('Rulesets.Rules.ProvidingService'),
      subtitle: intl('Help.Desc.ProvidingService'),
    },
    {
      title: intl('Common.Sources'),
      subtitle: intl('Help.Desc.Source'),
    },
    {
      title: intl('Common.SecureConnect'),
      subtitle: intl('Help.Desc.SecureConnect'),
    },
    {
      title: intl('Common.Status'),
      subtitle: intl('Help.Desc.Status'),
    },
    {
      title: intl('Rulesets.DuplicatePolicy'),
      subtitle: intl('Help.Desc.DuplicateRuleset'),
    },
    {
      title: intl('PolicyGenerator.IntraScopeRules'),
      subtitle: intl('Help.Desc.IntraScopeRules'),
    },
    {
      title: intl('PolicyGenerator.ExtraScopeRules'),
      subtitle: intl('Help.Desc.ExtraScopeRules'),
    },
    {
      title: intl('Help.Title.CustomiptablesRules'),
      subtitle: intl('Help.Desc.CustomiptablesRules'),
    },
    {
      title: intl('Help.Title.DraftVersion'),
      subtitle: intl('Help.Desc.DraftVersion'),
    },
    {
      title: intl('Help.Title.ActiveVersion'),
      subtitle: intl('Help.Desc.ActiveVersion'),
    },
  ],
  explorer: [
    {
      title: intl('Common.Explorer'),
      subtitle: intl('Help.Desc.Explorer'),
    },
    {
      title: intl('TrafficParameters.ExplorerSearchFormats'),
      subtitle: intl('Help.Desc.ExplorerSearchFormats'),
    },
  ],
  appGroups: [
    {
      title: intl('Common.Application'),
      subtitle: intl('Help.Desc.ApplicationList'),
    },
    {
      title: intl('Common.Workload'),
      subtitle: intl('Help.Desc.Workload'),
    },
    {
      title: intl('Common.Enforcement'),
      subtitle: intl('Help.Desc.Enforcement'),
    },
  ],
  appGroupsVulnerabilities: [
    {
      title: intl('Common.Application'),
      subtitle: intl('Help.Desc.ApplicationList'),
    },
    {
      title: intl('Vulnerability.VulnerabilityExposureScore'),
      subtitle: `${intl('Help.Desc.VEScore')} ${intl('Help.Desc.VEScoreWorkloadAggregation')}`,
    },
    {
      title: intl('Common.Severity'),
      subtitle: (
        <div>
          {intl('Help.Desc.Severity')}
          <div className="Vulnerability-Text--info">{intl('Help.Desc.SeverityInfo')}</div>
          <div className="Vulnerability-Text--low">{intl('Help.Desc.SeverityLow')}</div>
          <div className="Vulnerability-Text--medium">{intl('Help.Desc.SeverityMedium')}</div>
          <div className="Vulnerability-Text--high">{intl('Help.Desc.SeverityHigh')}</div>
          <div className="Vulnerability-Text--critical">{intl('Help.Desc.SeverityCritical')}</div>
        </div>
      ),
    },
    {
      title: intl('Common.Workload'),
      subtitle: intl('Help.Desc.Workload'),
    },
    {
      title: intl('Common.Enforcement'),
      subtitle: intl('Help.Desc.Enforcement'),
    },
  ],
  appGroupType: [
    {
      title: intl('Applications.ApplicationType'),
      subtitle: intl('Help.Desc.ApplicationType'),
    },
  ],
  sso: [
    {
      title: intl('Users.SSOConfig.SSOConfig'),
      subtitle: intl('Help.Desc.SSOConfig'),
    },
  ],
  appGroupDetailsRules: [
    {
      title: intl('Common.Rules'),
      subtitle: intl('Help.Desc.ApplicationDetailsRules'),
    },
    {
      title: intl('Settings.EssentialServiceRules'),
      subtitle: intl('Help.Desc.EssentialServiceRules'),
    },
  ],
  appGroupExplorer: [
    {
      title: intl('Common.Explorer'),
      subtitle: intl('Help.Desc.ApplicationExplorer'),
    },
  ],

  appGroupPolicyGenerator: [
    {
      title: intl('PolicyGenerator.PolicyGenerator'),
      subtitle: intl('Help.Desc.ApplicationPolicyGenerator'),
    },
  ],

  appGroupDetailsPP: [
    {
      title: intl('PairingProfiles.Profile'),
      subtitle: intl('Help.Desc.PairingProfile'),
    },
  ],
  appGroupContainerWorkloads: [
    {
      title: intl('Common.ContainerWorkloads'),
      subtitle: intl('Help.Desc.ContainerWorkloadInIllumination'),
    },
  ],
  appGroupVirtualServices: [
    {
      title: intl('Common.VirtualServices'),
      subtitle: intl('Help.Desc.VirtualService'),
    },
  ],
  appGroupVirtualServers: [
    {
      title: intl('Common.VirtualServers'),
      subtitle: intl('Help.Desc.VirtualServers'),
    },
  ],
  appGroupDetails: [
    {
      title: intl('Common.Workload'),
      subtitle: intl('Help.Desc.Workload'),
    },
  ],
  pceHealth: [
    {
      title: intl('Health.PCEHealth'),
      subtitle: intl('Help.Desc.PCEHealth'),
    },
  ],
  apiKeys: [
    {
      title: intl('Users.APIKeys.Name'),
      subtitle: intl('Help.Desc.APIKeys'),
    },
  ],
  ruleSearch: [
    {
      title: intl('Common.RuleSearch'),
      subtitle: intl('Help.Desc.RuleSearch'),
    },
  ],
  noRoute: [
    {
      title: intl('Help.Title.NoHelp'),
      subtitle: intl('Help.Desc.NoHelp'),
    },
  ],
};
