/**
 * Copyright 2014 Illumio, Inc. All Rights Reserved.
 */
import cx from 'classnames';
import React from 'react';
import StoreMixin from './mixins/StoreMixin';
import DialogStore from './stores/DialogStore';
import SessionStore from './stores/SessionStore';
import SettingStore from './stores/SettingStore';
import HealthStore from './stores/HealthStore';
import OrgStore from './stores/OrgStore';

import {State, Navigation, RouteHandler} from 'react-router';
import {SpinnerOverlay} from './components';

const pagesWithoutGreyBorder = [
  'map',
  'mapLevel',
  'prevMapLevel',
  'appMap',
  'appMapLevel',
  'prevAppMapLevel',
  'explorer',
  'segmentationTemplates.list',
  'segmentationTemplate.detail',
  'helpmenu',
  'helpmenuold',
];

let prevRouteHref;

function getStateFromStores() {
  return {
    dialog: DialogStore.getActiveDialog(),
    session: SessionStore.hasValidSession(),
    selfSignedCert: SessionStore.isCertSelfSigned(),
    certExpiration: SessionStore.getCertExpiration(),
    sessionTimeout: SessionStore.getSessionTimeout(),
    listenOnlyEnableTime: HealthStore.getLocalListenOnlyEnableTime(),
    remainingRollingUpgradeCount: HealthStore.getRemainingRollingUpgradeCount(),
    rollingUpgradeCount: HealthStore.getRollingUpgradeCount(),
    org: OrgStore.getOrg(),
  };
}

export default React.createClass({
  mixins: [State, Navigation, StoreMixin([HealthStore, DialogStore, SessionStore, SettingStore], getStateFromStores)],

  componentDidUpdate() {
    if (prevRouteHref !== window.location.hash) {
      prevRouteHref = window.location.hash;

      if (window.top !== window.self) {
        // rendered in iframe, move any navigation to parent window
        window.top.postMessage({type: 'iframe-navigation', hash: window.location.hash}, '*');
      }
    }
  },

  render() {
    if (!this.state.session) {
      return null;
    }

    const noBorderInLightning = window.frameId === 'legacy-iframe';
    const route = this.getRoutes()[1].name;
    const rootClass = cx('Root', {
      RootWithBorder: !noBorderInLightning && !pagesWithoutGreyBorder.includes(route),
    });

    return (
      <div>
        <div className={rootClass}>
          {this.state.redirecting ? <SpinnerOverlay /> : null}
          <RouteHandler />
          {this.state.dialog}
        </div>
      </div>
    );
  },
});
