/**
 * Copyright 2017 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import intl from '@illumio-shared/utils/intl';
import Constants from '../constants';
import {SessionStore, TrafficStore} from '.';
import {createApiStore} from '../lib/store';
import {RenderUtils, ServiceUtils, WorkloadUtils} from '../utils';
import {getId} from '../utils/GeneralUtils';
import {ExplorerPolicyUtils, ExplorerUtils} from '../utils/Explorer';
import UserStore from './UserStore';
import dispatcher from '../actions/dispatcher';
import {aggregateLinks} from '../utils/Explorer/ExplorerUtils';

const HOVER_MENU_TRIGGER_EVENT = 'hoverMenuTriggered';
const AND_OR_VALUE_EVENT = 'andOrValueSelectionChanged';
const RULE_CHANGE_EVENT = 'ruleChange';

let vizLinks = [];
let tableLinks = [];
let aggregatedTableLinks = [];
let ipTraffic = [];
let domainTraffic = [];
let blockedTraffic = [];
let appGroupTableLinks = [];
let appGroupVizLinks = [];
let appGroupIpLinks = [];
let appGroupDomainLinks = [];
let appGroupData;
let count = {};
let isDataReady = false;
let selectedSource = 'all';
let selectedTarget = 'all';
let clickedTick = {};
let clickedTickPath = {};
let favorites = [];
let recents = [];
let dnsLookupInterrupted = false;
let andOrFlagType = 'and';
let explorerType = 'main';
let queries = [];
let filteredQueries = [];
let newQueryStatus = Constants.STATUS_IDLE;
let loadedQuery = {};
let matchedServices = {};
let loadedUuid = null;
let exceededResultsLimit = false;
let ruleCoverageType = 'visible';
let exceededWarningConfirm = false;

const connectionStateMap = {
  'active': intl('Common.Active'),
  'closed': intl('Explorer.Closed'),
  'timed out': intl('Explorer.TimedOut'),
  'static': intl('Common.Static'),
  'snapshot': intl('Common.Static'),
  'new': intl('Common.New'),
};

let hoverFilters = {};
let filters;

const emptyFilters = {
  consumerInclude: [],
  consumerExclude: [],
  consumerOrProviderInclude: [],
  providerInclude: [],
  providerExclude: [],
  consumerOrProviderExclude: [],
  portsInclude: [],
  portsExclude: [],
  action: [],
  time: intl('Explorer.LastDays', {count: 1}),
};

function getLabel(data, type) {
  if (data.labels) {
    const item = data.labels.find(item => item.key === type);
    const value = item && item.value;

    return _.isEmpty(value) ? null : value;
  }
}

function getLabelHref(data, type) {
  if (data.labels) {
    const item = data.labels.find(item => item.key === type);
    const value = item && item.href;

    return _.isEmpty(value) ? null : value;
  }
}

function getAppGroup(data) {
  const appGroupType = TrafficStore.getAppGroupsType();

  if (appGroupType) {
    const name = _.compact(appGroupType.map(type => getLabel(data, type, true)));

    if (name.length === appGroupType.length) {
      return name.join(' | ');
    }
  }

  return intl('Common.NoApplication');
}

function getScope(workload, labels) {
  return labels.every(label => workload.labels.find(wlLabel => wlLabel.href === label)) ? 'intra' : 'extra';
}

function sortLabels(a, b) {
  const order = ['role', 'app', 'env', 'loc'];

  return order.indexOf(a.key) - order.indexOf(b.key);
}

function getRuleWritingCaps(link) {
  const appGroups = TrafficStore.getAppGroupNodesObject();
  const isOnlyLimitedRulesetManager = SessionStore.isUserOnlyLimitedRulesetManager();
  const isGlobalRuleWriter = SessionStore.isGlobalRuleWritingEnabled();
  const userPermissions = SessionStore.getUserPermissions();

  // If app groups are not loaded or the target is unlabelled or the app group is ruleset-writable
  let ruleWritingAvailable =
    isGlobalRuleWriter ||
    !link.dst_labelled ||
    appGroups[link.dst_group_href]?.caps?.rulesets.includes('write') ||
    (!link.dst_group_href && ExplorerPolicyUtils.areLabelsInRuleWritingUserScope(link.dst_labels, userPermissions));

  if (ruleWritingAvailable && isOnlyLimitedRulesetManager) {
    // For limited ruleset managers check the source app group too
    ruleWritingAvailable =
      isGlobalRuleWriter ||
      !link.src_labelled ||
      appGroups[link.src_group_href]?.caps?.rulesets.includes('write') ||
      ExplorerPolicyUtils.areLabelsInRuleWritingUserScope(link.src_labels, userPermissions);
  }

  return ruleWritingAvailable;
}

function parseData(data, viz, labels) {
  return (data || []).map((link, index) => {
    let workload = link.src.workload || link.src.virtual_service || link.src.virtual_server;
    const consumerType = link.src.workload
      ? intl('Common.Workloads')
      : link.src.virtual_service
        ? intl('Common.VirtualServices')
        : link.src.virtual_server
          ? intl('Common.VirtualServers')
          : link.src.ip_lists
            ? intl('Common.IPList')
            : '';
    const providerType = link.dst.workload
      ? intl('Common.Workloads')
      : link.dst.virtual_service
        ? intl('Common.VirtualServices')
        : link.dst.virtual_server
          ? intl('Common.VirtualServers')
          : link.dst.ip_lists
            ? intl('Common.IPList')
            : '';

    const srcEntityType = link.src.workload
      ? 'workload'
      : link.src.virtual_service
        ? 'virtual_service'
        : link.src.virtual_server
          ? 'virtual_server'
          : link.src.ip_lists
            ? 'ip_list'
            : '';
    const dstEntityType = link.dst.workload
      ? 'workload'
      : link.dst.virtual_service
        ? 'virtual_service'
        : link.dst.virtual_server
          ? 'virtual_server'
          : link.dst.ip_lists
            ? 'ip_list'
            : '';

    const source = workload
      ? {
          src_ip: link.src.ip,
          [`src_${srcEntityType}`]: WorkloadUtils.friendlyName(workload) || intl('Common.DeletedWorkload'),
          src_name: workload.href ? workload.name : intl('Common.DeletedWorkload'),
          src_hostname: workload.hostname,
          src_href: workload.href,
          src_role: getLabel(workload, 'role') || (viz ? intl('Common.NoRole') : ''),
          src_env: getLabel(workload, 'env') || (viz ? intl('Common.NoEnvironment') : ''),
          src_app: getLabel(workload, 'app') || (viz ? intl('Common.NoApplication') : ''),
          src_loc: getLabel(workload, 'loc') || (viz ? intl('Common.NoLocation') : ''),
          src_group: getAppGroup(workload),
          src_group_href: RenderUtils.getAppGroupParent(workload, TrafficStore.getAppGroupsType()),
          src_role_href: getLabelHref(workload, 'role'),
          src_app_href: getLabelHref(workload, 'app'),
          src_env_href: getLabelHref(workload, 'env'),
          src_loc_href: getLabelHref(workload, 'loc'),
          src_scope: (labels && getScope(workload, labels)) || 'extra',
          src_type: consumerType,
          src_labels: workload.labels.sort((a, b) => sortLabels(a, b)),
          src_os: workload.os_type,
          src_mode: workload.enforcement_mode,
          src_managed: workload.managed,
          src_labelled: true,
        }
      : {
          src_ip: link.src.ip,
          src_href: link.src.ip_list ? link.src.ip_list.href : '',
          src_workload: viz ? intl('Common.IPAddress') : '',
          src_role: viz ? intl('Common.IPAddress') : '',
          src_env: viz ? (link.src.ip_list ? intl('Common.IPList') : intl('Common.IPAddress')) : '',
          src_app: viz ? intl('Common.IPAddress') : '',
          src_loc: viz ? intl('Common.IPAddress') : '',
          src_group: viz ? intl('Common.IPAddress') : '',
          src_scope: 'extra',
          src_ip_list: link.src.ip_lists ? link.src.ip_lists[0] : null,
          src_ip_lists: link.src.ip_lists || null,
          src_type: consumerType,
        };

    if (workload) {
      source.src_all = 'Labels';
    } else if (link.src.ip_lists) {
      source.src_all = intl('Common.IPList');
    } else {
      source.src_all = intl('Common.IPAddress');
    }

    workload = link.dst.workload || link.dst.virtual_service || link.dst.virtual_server;

    const target = workload
      ? {
          dst_ip: link.dst.ip,
          [`dst_${dstEntityType}`]: WorkloadUtils.friendlyName(workload) || intl('Common.DeletedWorkload'),
          dst_name: workload.href ? workload.name : intl('Common.DeletedWorkload'),
          dst_hostname: workload.hostname,
          dst_href: workload.href,
          dst_role: getLabel(workload, 'role') || (viz ? intl('Common.NoRole') : ''),
          dst_env: getLabel(workload, 'env') || (viz ? intl('Common.NoEnvironment') : ''),
          dst_app: getLabel(workload, 'app') || (viz ? intl('Common.NoApplication') : ''),
          dst_loc: getLabel(workload, 'loc') || (viz ? intl('Common.NoLocation') : ''),
          dst_group: getAppGroup(workload),
          dst_group_href: RenderUtils.getAppGroupParent(workload, TrafficStore.getAppGroupsType()),
          dst_role_href: getLabelHref(workload, 'role'),
          dst_app_href: getLabelHref(workload, 'app'),
          dst_env_href: getLabelHref(workload, 'env'),
          dst_loc_href: getLabelHref(workload, 'loc'),
          dst_scope: (labels && getScope(workload, labels)) || 'extra',
          dst_type: providerType,
          dst_labels: workload.labels.sort((a, b) => sortLabels(a, b)),
          dst_os: workload.os_type,
          dst_mode: workload.enforcement_mode,
          dst_managed: workload.managed,
          dst_transmission:
            link && link.transmission
              ? `${link.transmission.charAt(0).toUpperCase()}${link.transmission.slice(1)}`
              : 'Unicast',
          dst_labelled: true,
        }
      : {
          dst_ip: link.dst.ip,
          dst_href: link.dst.ip_list ? link.dst.ip_list.href : '',
          dst_workload: viz ? intl('Common.IPAddress') : '',
          dst_role: viz ? intl('Common.IPAddress') : '',
          dst_env: viz ? (link.dst.ip_list ? intl('Common.IPList') : intl('Common.IPAddress')) : '',
          dst_app: viz ? intl('Common.IPAddress') : '',
          dst_loc: viz ? intl('Common.IPAddress') : '',
          dst_group: viz ? intl('Common.IPAddress') : '',
          dst_scope: 'extra',
          dst_ip_list: link.dst.ip_lists ? link.dst.ip_lists[0] : null,
          dst_ip_lists: link.dst.ip_lists || null,
          dst_type: providerType,
          dst_transmission:
            link && link.transmission
              ? `${link.transmission.charAt(0).toUpperCase()}${link.transmission.slice(1)}`
              : 'Unicast',
        };

    if (workload) {
      target.dst_all = 'Labels';
    } else if (link.dst.fqdn) {
      target.dst_all = 'FQDNs';
      target.dst_domain = link.dst.fqdn ? link.dst.fqdn : '';
    } else if (link.dst.ip_lists) {
      target.dst_all = intl('Common.IPList');
    } else {
      target.dst_all = intl('Common.IPAddress');
    }

    const serviceKey = [link.service.port, link.service.proto].join(',');
    const serviceMappingKey = [
      link.service.port,
      link.service.proto,
      link.service.process_name,
      link.service.windows_service_name,
    ].join(',');

    const connectionKey = [serviceKey, source.src_ip, target.dst_ip].join(',');

    const profile = `[${link.network.name}]`;

    return {
      index,
      port: link.service.port,
      protocol: ServiceUtils.lookupProtocol(link.service.proto),
      protocolNum: link.service.proto,
      processName: link.service.process_name,
      windowsService: link.service.windows_service_name,
      icmpType: link.icmp_type,
      icmpCode: link.icmp_code,
      username: link.service.user_name,
      flow_direction: link.flow_direction,
      numFlows: link.num_connections,
      policy: ExplorerUtils.getPolicyDecisionName(link.policy_decision),
      boundaryDecision: link.boundary_decision,
      byteIn: link.dst_bi,
      byteOut: link.dst_bo,
      state: connectionStateMap[link.state] || intl('Common.Unknown'),
      firstDetected: link.timestamp_range.first_detected,
      lastDetected: link.timestamp_range.last_detected,
      network: link.network,
      ruleWritingAvailable: getRuleWritingCaps({...source, ...target}),
      connectionKey,
      serviceKey,
      serviceMappingKey,
      ...source,
      ...target,
      ...(profile && {profile}),
    };
  });
}

function parseIpTraffic(data) {
  return parseIpTrafficDirection(data, 'src').concat(parseIpTrafficDirection(data, 'dst'));
}

function parseDomainTraffic(data) {
  return parseDomainTrafficDirection(data, 'dst');
}

// Reduce the traffic into one entry per IP Address per direction
function parseIpTrafficDirection(links, endpoint) {
  return Object.values(
    (links || []).reduce((result, link) => {
      if (!link[endpoint].workload) {
        result[link[endpoint].ip] = mergeIpTraffic(result[link[endpoint].ip], link, endpoint);
      }

      return result;
    }, {}),
  ).map(traffic => ({
    ...traffic,
    workloads: Object.keys(traffic.workloads),
    ports: Object.keys(traffic.ports),
  }));
}

function parseRuleCoverage(links, linkIndicies, data, href) {
  for (const [linkIndex, link] of links.entries()) {
    for (const [serviceIndex] of link.services.entries()) {
      linkIndicies[linkIndex][serviceIndex].forEach(index => {
        const tableLink = href && href === appGroupData ? appGroupTableLinks[index] : tableLinks[index];

        if (tableLink) {
          tableLink.ruleType = link.source.labels || link.destination.labels ? 'labels' : 'workloads';
          tableLink.rules = [
            ...(tableLink.rules || []),
            ...(data.edges[linkIndex][serviceIndex] || []).map(rule => Object.values(data.rules[rule])[0]),
          ];
        }

        if (tableLink && data.deny_edges) {
          tableLink.denyRules = [
            ...(tableLink.denyRules || []),
            ...(data.deny_edges[linkIndex][serviceIndex] || []).map(rule => Object.values(data.deny_rules[rule])[0]),
          ];
        }
      });
    }
  }
}

// Reduce the traffic into one entry per IP Address per direction
function parseDomainTrafficDirection(links, endpoint) {
  return Object.values(
    (links || []).reduce((result, link) => {
      if (
        !link[endpoint].workload &&
        !link[endpoint].virtual_service &&
        link[endpoint].fqdn &&
        ServiceUtils.lookupProtocol(link.service.proto) !== 'ICMP'
      ) {
        const domainKey = `${link[endpoint].domain}-${link.service.port}-${ServiceUtils.lookupProtocol(
          link.service.proto,
        )}-${link.src.workload}`;

        result[domainKey] = mergeDomainTraffic(result[domainKey], link);
      }

      return result;
    }, {}),
  ).map(traffic => ({
    ...traffic,
    workloads: Object.keys(traffic.workloads),
    ports: Object.keys(traffic.ports),
    address: Object.keys(traffic.address),
  }));
}

function mergeDomainTraffic(domainKey, newLink) {
  const otherend = 'src';
  const portKey = `${ServiceUtils.getPort(newLink.service) || ''} ${ServiceUtils.lookupProtocol(
    newLink.service.proto,
  )} ${newLink.service.process_name} ${newLink.service.windows_service_name}`;
  let ports = {};
  let workloads = {};
  let address = {};
  let flows = newLink.num_connections;
  const domain = newLink.dst.fqdn;
  let transmission = newLink.transmission ? newLink.transmission : intl('Map.Traffic.Unicast');

  transmission &&= transmission.charAt(0).toUpperCase() + transmission.slice(1);

  // If an entry already exists for this address, aggregate it
  if (domainKey) {
    workloads = domainKey.workloads;
    ports = domainKey.ports;
    address = domainKey.address;
    flows += domainKey.flows;
  }

  const portObject = (domainKey && domainKey.portObject) || {};

  portObject[portKey] = newLink.service;
  ports[portKey] = true;
  address[newLink.dst.ip] = true;

  if (newLink[otherend].workload) {
    workloads[newLink[otherend].workload.hostname] = true;
  }

  // Return all the unique items
  return {
    id: [domain, `${newLink.service.port}-${ServiceUtils.lookupProtocol(newLink.service.proto)}`].join('-'),
    domain,
    transmission,
    address,
    workloads,
    ports,
    portObject,
    newLink,
    flows,
  };
}

// Aggregate a new piece of traffic to the rest of the traffic for that address - in the same direction
// We need:
// Number of workloads this address is talking to
// A list of Ports it is talking on
// Number of flows to/from the address
// The direction of the flow
function mergeIpTraffic(ipAddress, newLink, endpoint) {
  // Collect the information from the newLink
  const otherend = endpoint === 'src' ? 'dst' : 'src';

  newLink.service.windows_service_name ||= '';

  newLink.service.process_name ||= '';

  const portKey = `${ServiceUtils.getPort(newLink.service) || ''} ${ServiceUtils.lookupProtocol(
    newLink.service.proto,
  )} ${newLink.service.process_name} ${newLink.service.windows_service_name}`;
  let ports = {};
  let workloads = {};
  let flows = newLink.num_connections;

  // If an entry already exists for this address, aggregate it
  if (ipAddress) {
    workloads = ipAddress.workloads;
    ports = ipAddress.ports;
    flows += ipAddress.flows;
  }

  const address = newLink[endpoint].ip;
  const direction = endpoint === 'src' ? intl('Common.Inbound') : intl('Common.Outbound');
  const domain = newLink?.dst?.fqdn || ipAddress?.domain || '';
  const portObject = (ipAddress && ipAddress.portObject) || {};
  let transmission = newLink.transmission ? newLink.transmission : intl('Map.Traffic.Unicast');

  transmission = transmission.charAt(0).toUpperCase() + transmission.slice(1);

  portObject[portKey] = newLink.service;
  ports[portKey] = true;

  if (newLink[otherend].workload) {
    workloads[newLink[otherend].workload.hostname] = true;
  }

  // Return all the unique items
  return {
    id: [address, direction].join(','),
    domain,
    transmission,
    address,
    workloads,
    ports,
    portObject,
    newLink,
    flows,
    direction,
  };
}

function updateSelectedDimension(dimension, pcLabel = {}) {
  if (!_.isEmpty(clickedTick) && !_.isEmpty(clickedTick[dimension])) {
    return clickedTick[dimension].nextLabel;
  }

  const currentFilters = filters || emptyFilters;

  const items = currentFilters.Or
    ? currentFilters.consumerOrProviderInclude
    : dimension === 'source'
      ? currentFilters.consumerInclude
      : currentFilters.providerInclude;

  if (explorerType !== 'main') {
    if (pcLabel.type === dimension) {
      return pcLabel.label;
    }

    if (dimension === 'source') {
      return selectedSource;
    }

    return selectedTarget;
  }

  if (!items) {
    return null;
  }

  if (_.isEmpty(items)) {
    return 'all';
  }

  let selectedDimension = 'all';
  const keysHash = {
    role: 'role',
    appgroups: 'group',
    app: 'app',
    env: 'env',
    loc: 'loc',
    iplist: 'all',
    all: 'all',
    fqdn: 'domain',
    workloads: 'workload',
  };

  for (const {key} of items) {
    if (keysHash[key]) {
      selectedDimension = keysHash[key];
      break;
    }
  }

  if (pcLabel.type === dimension) {
    selectedDimension = pcLabel.label;
  }

  return selectedDimension;
}

function clearRules() {
  let existingRules = false;

  tableLinks = tableLinks.map(link => {
    link.ruleType = null;

    if (link.rules) {
      existingRules = true;
    }

    link.rules = null;
    link.denyRules = null;

    return link;
  });

  appGroupTableLinks = appGroupTableLinks.map(link => {
    link.ruleType = null;

    if (link.rules) {
      existingRules = true;
    }

    link.rules = null;
    link.denyRules = null;

    return link;
  });

  return existingRules;
}

/**
 * Keeps only the queries requested from the main Explorer
 * Transforms the name
 *
 * @param {array} queries The queries to parse
 */
function parseQueries(queries = []) {
  return (
    (queries || [])
      .filter(query => query.query_parameters.query_name.startsWith(ExplorerUtils.UI_EXPLORER_QUERY_PREFIX))
      // Remove the main Explorer tag from the query name
      .map(query => ({
        ...query,
        name: query.query_parameters.query_name.replace(ExplorerUtils.UI_EXPLORER_QUERY_PREFIX, ''),
      }))
  );
}

function getServiceMatches(links) {
  const uniqServices = links.reduce((result, link) => {
    result[link.serviceMappingKey] ||= link;

    return result;
  }, {});

  Object.keys(uniqServices).forEach(serviceKey => {
    if (!matchedServices[serviceKey]) {
      const link = uniqServices[serviceKey];

      const connection = {
        port: link.port,
        protocol: link.protocol || ServiceUtils.lookupProtocol(link.proto),
        processName: link.processName || link.process_name,
        windowsService: link.windowsService || link.windows_service_name,
        osType: link.windowsService || link.windows_service_name || link.dst_os === 'windows' ? 'windows' : 'linux',
      };

      // Add to the global set
      matchedServices[serviceKey] = ServiceUtils.matchConnectionWithService({
        ...connection,
        protocol: ServiceUtils.reverseLookupProtocol(connection.protocol),
        // Make this sorting deterministic
      }).sort(
        (a, b) =>
          a.serviceCount - b.serviceCount ||
          b.serviceSpecificity - a.serviceSpecificity ||
          b.windowsServiceSpecificity - a.windowsServiceSpecificity ||
          (a.name > b.name ? 1 : a.name < b.name ? -1 : 0),
      );
    }
  });
}

function hydrateServices(links) {
  return links.map(link => ({
    ...link,
    services: matchedServices[link.serviceMappingKey],
  }));
}

export const mapQueryNamePrefix = 'MAP_QUERY_';

/**
 * returns true if query's filters are in illumination plus format
 * @param query
 * @returns {boolean}
 */
export function isIlluminationPlusQuery(query) {
  return (
    Boolean(query.filters.policyDecisions) ||
    Boolean(query.filters.servicesInclude) ||
    Boolean(query.filters.servicesExclude)
  );
}

export function hasMismatchedAppGroupFilter(query) {
  if (isIlluminationPlusQuery(query)) {
    return true;
  }

  const appGroups = TrafficStore.getAppGroupNodesObject();

  return [
    'consumerInclude',
    'consumerExclude',
    'providerInclude',
    'providerExclude',
    'consumerOrProviderInclude',
    'consumerOrProviderExclude',
  ].some(filterType =>
    (query.filters[filterType] || []).some(filter => {
      let missing = false;

      if (filter.key === 'appgroups') {
        missing = !(appGroups || {})[filter.detail?.href || filter.href];
      }

      return missing;
    }),
  );
}

/**
 * Converts illumination plus favorite to legacy explorer format
 * @param favorite
 * @returns {{date, queryName, label, filters: {}}}
 */
export function convertIlluminationPlusFavoriteToLegacy(favorite) {
  const {queryName, label, date, filters} = favorite;

  const convertedFilters = Object.keys(filters).reduce((legacyFilters, key) => {
    switch (key) {
      case 'consumerInclude':
      case 'consumerExclude':
      case 'providerInclude':
      case 'providerExclude':
      case 'consumerOrProviderInclude':
      case 'consumerOrProviderExclude':
        legacyFilters[key] = Object.keys(filters[key]).reduce((legacyFilterValues, filterKey) => {
          filters[key][filterKey].forEach(filter => {
            switch (filterKey) {
              case 'labelsAndLabelGroups':
                legacyFilterValues.push({
                  ...filter,
                });
                break;
              case 'appgroups':
                legacyFilterValues.push({
                  ...filter,
                  key: 'appgroups',
                  value: filter.value,
                  href: filter.detail?.href || filter.href,
                });
                break;
              case 'workload':
                if (filter.value === 'all_workloads') {
                  legacyFilterValues.push({
                    allWorkloads: true,
                  });
                } else {
                  legacyFilterValues.push({
                    ...filter,
                    key: 'workloads',
                  });
                }

                break;
              case 'container_workload':
                legacyFilterValues.push({
                  ...filter,
                  key: 'containerWorkloads',
                });
                break;
              case 'ip_list':
                legacyFilterValues.push({
                  ...filter,
                  key: 'iplist',
                  href: filter.href,
                  name: filter.name,
                  value: [...(filter.ip_ranges ?? []), ...(filter.fqdns ?? [])],
                });
                break;
              case 'ip_or_cidr':
                legacyFilterValues.push({
                  ...filter,
                  key: /\/\d+$/.test(filter.value) ? 'cidrBlock' : 'ipaddress',
                  value: filter.value,
                  href: filter.value,
                });
                break;
              case 'fqdn':
                legacyFilterValues.push({
                  ...filter,
                  key: 'fqdn',
                  value: filter.value,
                  href: filter.value,
                });
                break;
              case 'transmission':
                legacyFilterValues.push({
                  key: 'transmission',
                  value: filter.label,
                  href: filter.value,
                });
                break;
            }
          });

          return legacyFilterValues;
        }, []);

        break;
      case 'servicesInclude':
      case 'servicesExclude':
        legacyFilters[{servicesInclude: 'portsInclude', servicesExclude: 'portsExclude'}[key]] = Object.keys(
          filters[key],
        ).reduce((legacyFilterValues, filterKey) => {
          const legacyFilterKey = {
            policy_services: 'policyService',
            port_proto: 'portProtocol',
            port_range: 'portRange',
            process_name: 'processName',
            windows_service_name: 'windowsService',
          }[filterKey];

          filters[key][filterKey].forEach(filter => {
            switch (legacyFilterKey) {
              case 'processName':
              case 'windowsService':
                legacyFilterValues.push({
                  key: legacyFilterKey,
                  value: filter,
                  href: filter,
                });
                break;
              case 'policyService':
                legacyFilterValues.push({
                  ...filter,
                  key: legacyFilterKey,
                  value: filter.service_ports ?? [],
                });
                break;
              case 'portProtocol':
                let {value} = filter;

                if (/^port/i.test(value)) {
                  // Port 21 -> 21
                  value = value.replace(/^port\s*/i, '');
                } else if (/^protocol\s\d+\s\([^)]+\)/i.test(value)) {
                  // Protocol 6 (TCP) -> TCP
                  value = value.replace(/^protocol\s\d+\s\(([^)]+)\)?/i, '$1');
                } else if (/^protocol\s\d+/i.test(value)) {
                  // Protocol 7 ->
                  value = '';
                }

                if (value) {
                  legacyFilterValues.push({
                    ...filter,
                    key: legacyFilterKey,
                    value,
                    href: value,
                  });
                }

                break;
              default:
                legacyFilterValues.push({
                  ...filter,
                  key: legacyFilterKey,
                  value: filter.value,
                  href: filter.value,
                });
                break;
            }
          });

          return legacyFilterValues;
        }, []);

        break;
      case 'policyDecisions':
        legacyFilters.action = (filters[key].policy_decisions ?? []).reduce((legacyFilterValues, filter) => {
          legacyFilterValues[filter.label] = [filter.value];

          return legacyFilterValues;
        }, {});
        break;
      case 'time':
        const filterValue = filters[key];

        // Add time filter value using label
        legacyFilters[key] = filterValue.label;

        // Add custom time range dates
        if (filterValue?.range) {
          legacyFilters.dateFrom = filterValue.range.start;
          legacyFilters.dateTo = filterValue.range.end;
        }

        break;
    }

    return legacyFilters;
  }, {});

  return {
    date,
    queryName,
    label,
    filters: convertedFilters,
  };
}

// To be merged with the ExplorerStore
export default createApiStore(['TRAFFIC_FLOWS_TRAFFIC_ANALYSIS_QUERIES_', 'TRAFFIC_FLOWS_ASYNC_QUERIES_DOWNLOAD_'], {
  dispatchHandler(action) {
    switch (action.type) {
      case Constants.TRAFFIC_FLOWS_ASYNC_QUERIES_GET_INSTANCE_SUCCESS:
        const query = action.data;

        if (ExplorerUtils.isQueryComplete(query.status)) {
          exceededResultsLimit =
            query.matches_count > UserStore.getExplorerMaxDownloadResults() ||
            query.flows_count > UserStore.getExplorerMaxResults();
        }

        break;

      case Constants.TRAFFIC_FLOWS_ASYNC_QUERIES_GET_COLLECTION_SUCCESS:
        // Do not update the queries variable if the data are equal because this will trigger unnecessary renders
        if (!_.isEqual(queries, action.data)) {
          queries = action.data;
          filteredQueries = parseQueries(action?.data);
        }

        break;

      // Start the spinner when a new query is started
      case Constants.TRAFFIC_FLOWS_ASYNC_QUERIES_CREATE_INIT:
        newQueryStatus = Constants.STATUS_BUSY;
        break;

      // Stop the spinner when the download is completed or the query polling has stopped
      case Constants.TRAFFIC_FLOWS_ASYNC_QUERIES_DOWNLOAD_COMPLETE:
        newQueryStatus = Constants.STATUS_IDLE;
        break;

      case Constants.CLEAR_NEW_EXPLORER_QUERY:
        newQueryStatus = Constants.STATUS_IDLE;
        break;

      case Constants.TRAFFIC_FLOWS_TRAFFIC_ANALYSIS_QUERIES_SUCCESS:
      case Constants.TRAFFIC_FLOWS_ASYNC_QUERIES_DOWNLOAD_SUCCESS:
        const {params, type, labels, href} = action.options;

        const data = action.data?.slice(0, UserStore.getExplorerMaxResults()) || [];

        if (SessionStore.isAsyncExplorerEnabled()) {
          const uuid = params?.uuid;
          const loadedFilters = recents[uuid]?.filters;

          loadedQuery = queries.find(query => getId(query.href) === uuid);

          if (loadedQuery && loadedQuery.hasOwnProperty('matches_count')) {
            exceededResultsLimit =
              loadedQuery.matches_count > UserStore.getExplorerMaxDownloadResults() ||
              loadedQuery.flows_count > UserStore.getExplorerMaxResults();
          }

          loadedUuid = uuid;

          // Do not replace the filters if the results are downloaded after starting a new query
          // This is because the user could have started changing the filters to run a new query,
          // and we don't want to overwrite those changes
          if (loadedFilters && newQueryStatus !== Constants.STATUS_BUSY) {
            filters = loadedFilters;
            andOrFlagType = filters.Or ? 'or' : 'and';
            this.emitAndOrAction();
          }

          if (!type) {
            // save the uuid so we can load the results on the next explorer load
            localStorage.setItem('tx_filters', JSON.stringify({...(filters || emptyFilters), uuid}));
          }
        }

        if (type === 'blockedTraffic') {
          appGroupData = null;
          blockedTraffic = parseData(data, false);
          count = action.count;
        } else if (type === 'appGroup') {
          appGroupData = href;

          appGroupTableLinks = parseData(data, false, labels);
          appGroupVizLinks = parseData(data, true, labels);
          appGroupIpLinks = parseIpTraffic(data);
          appGroupDomainLinks = parseDomainTraffic(data);

          getServiceMatches(appGroupTableLinks);
          appGroupTableLinks = hydrateServices(appGroupTableLinks);
        } else {
          appGroupData = null;
          vizLinks = parseData(data, true);
          tableLinks = parseData(data, false);
          ipTraffic = parseIpTraffic(data);
          domainTraffic = parseDomainTraffic(data);
          isDataReady = true;

          getServiceMatches(tableLinks);
          tableLinks = hydrateServices(tableLinks);
          aggregatedTableLinks = aggregateLinks(tableLinks);

          // Emit this only for the main table case
          // The app group case will be emitted in the ExplorerFilterStore
          this.emitRuleChange();
        }

        break;

      case Constants.SET_TRAFFIC_EXPLORER_FILTERS:
        filters = {...emptyFilters, ...action.data, uuid: null};
        localStorage.setItem('tx_filters', JSON.stringify(filters));
        clickedTick = {};
        clickedTickPath = {};
        selectedSource = updateSelectedDimension('source');
        selectedTarget = updateSelectedDimension('target');
        isDataReady = false;

        return;

      case Constants.ADD_TRAFFIC_EXPLORER_APPGROUP_FILTER:
      case Constants.REMOVE_TRAFFIC_EXPLORER_APPGROUP_FILTER:
      case Constants.REMOVE_MULTI_TRAFFIC_EXPLORER_APPGROUP_FILTER:
        clickedTick = {};
        clickedTickPath = {};
        selectedSource = updateSelectedDimension('source');
        selectedTarget = updateSelectedDimension('target');

        return;

      case Constants.CLICK_TICK:
        // when user click tick, the info on dimension and links need update
        clickedTick = action.data.clickedTick;
        clickedTickPath = action.data.clickedTickPath;
        selectedSource = updateSelectedDimension('source');
        selectedTarget = updateSelectedDimension('target');
        isDataReady = true;
        break;

      case Constants.CHOOSE_PC_LABEL:
        if (clickedTick[action.data.type]) {
          clickedTick[action.data.type].nextLabel = action.data.label;
        }

        if (clickedTickPath[action.data.type]) {
          clickedTickPath[action.data.type].pop();
        }

        if (clickedTickPath[action.data.type] && clickedTick[action.data.type]) {
          clickedTickPath[action.data.type].push(clickedTick[action.data.type]);
        }

        selectedSource = updateSelectedDimension('source', action.data);
        selectedTarget = updateSelectedDimension('target', action.data);
        isDataReady = true;
        break;

      case Constants.INTERRUPT_DNS_LOOKUP:
        dnsLookupInterrupted = action.data.value;
        break;

      case Constants.KVPAIRS_GET_INSTANCE_SUCCESS:
      case Constants.KVPAIR_UPDATE_SUCCESS:
        if (action.options.params.key === 'explorer_favorites') {
          favorites = action.data || favorites || [];
          favorites = favorites.reduce((result, favorite) => {
            if (favorite.label && favorite.label.includes('[object Object]')) {
              return result;
            }

            const queryName = favorite.label.trim().replaceAll(',', ', ').replaceAll(':', ': ');

            let newFavorite;

            if (isIlluminationPlusQuery(favorite)) {
              newFavorite = convertIlluminationPlusFavoriteToLegacy({...favorite, queryName});
            } else {
              newFavorite = {
                ...favorite,
                label: queryName,
              };
            }

            if (!hasMismatchedAppGroupFilter(newFavorite)) {
              result.push(newFavorite);
            }

            return result;
          }, []);
        } else if (action.options.params.key === 'explorer_recents') {
          recents = Object.fromEntries(
            Object.entries(action.data || recents).filter(
              ([, recent]) =>
                !(recent.queryName ?? recent.label ?? '').startsWith(mapQueryNamePrefix) &&
                !hasMismatchedAppGroupFilter(recent),
            ),
          );
        }

        break;

      case Constants.SEC_POLICY_RULE_COVERAGE_CREATE_SUCCESS:
        if (action.options.type === 'explorer') {
          const links = action.options.data ? action.options.data : [];
          const linkIndicies = action.options.trafficHrefs ? action.options.trafficHrefs : [];

          parseRuleCoverage(links, linkIndicies, action.data, action.options.href);

          if (
            ruleCoverageType === 'visible' ||
            tableLinks.every(
              link =>
                link.rules ||
                link.src_name === intl('Common.DeletedWorkload') ||
                link.dst_name === intl('Common.DeletedWorkload'),
            )
          ) {
            aggregatedTableLinks = aggregateLinks(tableLinks);
          }
        }

        break;

      case Constants.SET_RULE_COVERAGE_TYPE:
        ruleCoverageType = action.data;
        break;

      case Constants.SET_DEFAULT_EXCLUSIONS:
        emptyFilters.consumerExclude = action.data.consumerExclude;
        // Add to transmission filters
        emptyFilters.consumerOrProviderExclude = [
          ...emptyFilters.consumerOrProviderExclude,
          ...action.data.consumerExclude,
        ];
        emptyFilters.portsExclude = action.data.portsExclude;

        break;

      case Constants.SEC_POLICY_RULES_FOR_SCOPE:
      case Constants.UPDATE_FOR_All:
      case Constants.NETWORK_TRAFFIC_UPDATE_FOR_All:
      case Constants.SEC_POLICY_RULES_FOR_ALL:
      case Constants.SEC_POLICY_RULES_FOR_WORKLOAD:
      case Constants.ENFORCEMENT_BOUNDARY_DELETE_SUCCESS:
        const existingRules = clearRules();

        aggregatedTableLinks = aggregateLinks(tableLinks);

        if (existingRules) {
          this.emitRuleChange();
        }

        break;

      case Constants.ADD_FILTER_ON_HOVER_SELECTION:
        hoverFilters = action.data;
        this.emitHoverMenuAction();
        break;

      case Constants.AND_OR_FILTER_TYPE:
        andOrFlagType = action.data;
        this.emitAndOrAction();
        break;

      case Constants.SET_EXPLORER_TYPE:
        if (explorerType !== action.data) {
          selectedSource = 'all';
          selectedTarget = 'all';
          clickedTick = {};
          clickedTickPath = {};
        }

        explorerType = action.data;
        break;

      case Constants.USERS_LOGIN_SUCCESS:
      case Constants.ORGS_GET_INSTANCE_SUCCESS:
        dispatcher.waitFor([SessionStore.dispatchToken]);

        break;

      case Constants.SERVICES_GET_COLLECTION_SUCCESS:
        matchedServices = {};

        return;

      case Constants.APP_GROUP_SUMMARY_GET_SUCCESS:
        dispatcher.waitFor([TrafficStore.dispatchToken]);

        tableLinks.forEach(link => {
          link.ruleWritingAvailable = getRuleWritingCaps(link);
        });

        break;

      case Constants.EXCEEDED_WARNING_CONFIRM:
        exceededWarningConfirm = action.data;
        break;

      default:
        return true;
    }

    this.emitChange();

    return true;
  },

  emitHoverMenuAction() {
    this.emit(HOVER_MENU_TRIGGER_EVENT);
  },

  addHoverMenuActionListener(callback) {
    this.on(HOVER_MENU_TRIGGER_EVENT, callback);
  },

  removeHoverMenuActionListener(callback) {
    this.removeListener(HOVER_MENU_TRIGGER_EVENT, callback);
  },

  emitAndOrAction() {
    this.emit(AND_OR_VALUE_EVENT);
  },

  emitRuleChange() {
    this.emit(RULE_CHANGE_EVENT);
  },

  ruleChangeActionListener(callback) {
    this.on(RULE_CHANGE_EVENT, callback);
  },

  addAndOrActionListener(callback) {
    this.on(AND_OR_VALUE_EVENT, callback);
  },

  removeRuleChangeActionListener(callback) {
    this.removeListener(RULE_CHANGE_EVENT, callback);
  },

  removeAndOrActionListener(callback) {
    this.removeListener(AND_OR_VALUE_EVENT, callback);
  },

  getQueries: () => queries,

  getFilteredQueries: () => filteredQueries,

  getNewQueryStatus: () => newQueryStatus,

  getVizLinks: () => vizLinks,

  // this will create a new object so prop checks will pass
  getTableLinks: () => (appGroupData ? [] : tableLinks),

  getAggregatedTableLinks: () => (appGroupData ? [] : aggregatedTableLinks),

  getAppGroupTableLinks: href => (!href || href === appGroupData ? appGroupTableLinks : []),

  getAppGroupVizLinks: href => (!href || href === appGroupData ? appGroupVizLinks : []),

  getAppGroupIpLinks: href => (!href || href === appGroupData ? appGroupIpLinks : []),

  getAppGroupDomainLinks: href => (!href || href === appGroupData ? appGroupDomainLinks : []),

  getIpTraffic: () => ipTraffic,

  getDomainTraffic: () => domainTraffic,

  getBlockedTraffic: () => [...blockedTraffic],

  getCount: () => count,

  getFilters: () => filters || emptyFilters,

  getIsDataReady: () => isDataReady,

  getRecents: () => recents,

  getFavorites: () => favorites,

  getDnsLookupInterrupted: () => dnsLookupInterrupted,

  getExplorerHoverSelection: () => hoverFilters,

  getAndOrValue: () => andOrFlagType,

  getEmptyFilters: () => emptyFilters,

  getExplorerType: () => explorerType,

  getLoadedQuery: () => loadedQuery,

  getExceededResultsLimit: () => exceededResultsLimit,

  getLoadedUuid: () => loadedUuid,

  getMatchedServices: () => matchedServices,

  getRuleCoverageType: () => ruleCoverageType,

  getExceededWarningConfirm: () => exceededWarningConfirm,

  getSelected: () => ({
    selectedSource,
    selectedTarget,
    clickedTick,
    clickedTickPath,
  }),
});
