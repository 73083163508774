/**
 * Copyright 2015 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import intl from '@illumio-shared/utils/intl';
import {SessionStore} from '../stores';

export default {
  // Translate the entities obj into something which API accepts
  getFriendlyEntities(entities) {
    const facetMap = {
      [intl('Common.Workloads')]: 'workload',
      [intl('Common.Labels')]: 'label',
      [intl('Common.IPLists')]: 'ip_list',
      [intl('Labels.Groups')]: 'label_group',
      ...(SessionStore.isKubernetesSupported() && {
        [intl('Common.VirtualServices')]: 'virtual_service',
      }),
      [intl('Common.VirtualServers')]: 'virtual_server',
      [intl('Common.UserGroups')]: 'security_principal',
    };
    const result = {
      entities: [],
      userGroups: [],
    };
    const singleValues = [
      intl('IPLists.Any'),
      intl('Workloads.All'),
      intl('Common.ContainerHost'),
      intl('Common.UserGroups'),
      ...(SessionStore.isKubernetesSupported()
        ? [intl('Common.UsesVirtualServicesWorkloads'), intl('Common.UsesVirtualServices')]
        : []),
    ];

    Object.keys(entities).forEach(entityCat => {
      const entityName = facetMap[entityCat];

      if (!entities[entityCat]) {
        return;
      }

      entities[entityCat].forEach(entityVal => {
        let obj = {};

        if (singleValues.includes(entityCat)) {
          obj = entityVal;
        } else {
          obj[entityName] = entityVal;

          if (entityVal.hasOwnProperty('exclusion')) {
            obj.exclusion = entityVal.exclusion;
          }
        }

        if (entityCat === intl('Common.UserGroups')) {
          result.userGroups.push(obj);
        } else {
          result.entities.push(obj);
        }
      });
    });

    return result;
  },

  // Translate the entities and userGroups obj into something which Object Selector can render
  getUnfriendlyEntities(entities, userGroups) {
    const facetedObject = {};

    _.each(entities, entity => {
      if (entity.actors && entity.actors === 'ams') {
        facetedObject[intl('Workloads.All')] = [
          {
            actors: 'ams',
          },
        ];
      } else if (entity.actors && entity.actors === 'container_host') {
        facetedObject[intl('Common.ContainerHost')] = [
          {
            actors: 'container_host',
          },
        ];
      } else if (entity.label) {
        if (!facetedObject[intl('Common.Labels')]) {
          facetedObject[intl('Common.Labels')] = [];
        }

        facetedObject[intl('Common.Labels')].push({...entity.label, exclusion: entity.exclusion});
      } else if (entity.label_group) {
        if (!facetedObject[intl('Labels.Groups')]) {
          facetedObject[intl('Labels.Groups')] = [];
        }

        facetedObject[intl('Labels.Groups')].push({...entity.label_group, exclusion: entity.exclusion});
      } else if (entity.ip_list) {
        if (!facetedObject[intl('Common.IPLists')]) {
          facetedObject[intl('Common.IPLists')] = [];
        }

        facetedObject[intl('Common.IPLists')].push(entity.ip_list);
      } else if (entity.workload) {
        if (!facetedObject[intl('Common.Workloads')]) {
          facetedObject[intl('Common.Workloads')] = [];
        }

        facetedObject[intl('Common.Workloads')].push(entity.workload);
      } else if (SessionStore.isKubernetesSupported() && entity.virtual_service) {
        if (!facetedObject[intl('Common.VirtualServices')]) {
          facetedObject[intl('Common.VirtualServices')] = [];
        }

        facetedObject[intl('Common.VirtualServices')].push(entity.virtual_service);
      } else if (entity.virtual_server) {
        if (!facetedObject[intl('Common.VirtualServers')]) {
          facetedObject[intl('Common.VirtualServers')] = [];
        }

        facetedObject[intl('Common.VirtualServers')].push(entity.virtual_server);
      } else if (SessionStore.isKubernetesSupported() && entity.usesVirtualServicesWorkloads) {
        if (!facetedObject[intl('Common.UsesVirtualServicesWorkloads')]) {
          facetedObject[intl('Common.UsesVirtualServicesWorkloads')] = [];
        }

        facetedObject[intl('Common.UsesVirtualServicesWorkloads')].push({
          usesVirtualServicesWorkloads: true,
        });
      } else if (SessionStore.isKubernetesSupported() && entity.usesVirtualServices) {
        if (!facetedObject[intl('Common.UsesVirtualServices')]) {
          facetedObject[intl('Common.UsesVirtualServices')] = [];
        }

        facetedObject[intl('Common.UsesVirtualServices')].push({
          usesVirtualServices: true,
        });
      }
    });
    _.each(userGroups, userGroup => {
      if (!facetedObject[intl('Common.UserGroups')]) {
        facetedObject[intl('Common.UserGroups')] = [];
      }

      facetedObject[intl('Common.UserGroups')].push(userGroup);
    });

    return facetedObject;
  },
  filterDeletedServices(value, key) {
    if (key === 'ingress_services') {
      return value.filter(service => !service.deleted);
    }
  },
};
